import {FC, ReactNode} from 'react';
import Item from './item';
import Split from './split';
import ExchangeItem from './exchangeItem';
import ExpiresItem from './expiresItem';
import Permission from './permission';
import Permissions from './permissions';
import Ip from './ip';
import Ips from './ips';
import cls from './style.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
  header: ReactNode;
}
interface SubComponents {
  Item: typeof Item;
  Split: typeof Split;
  ExchangeItem: typeof ExchangeItem;
  ExpiresItem: typeof ExpiresItem;
  Permissions: typeof Permissions;
  Permission: typeof Permission;
  Ips: typeof Ips;
  Ip: typeof Ip;
}

const ExchangeConnectionContainer: FC<Props> & SubComponents = ({
  children,
  header,
}) => (
  <>
    {header}
    <div className={cls.container}>
      <div className={cls.content}>{children}</div>
    </div>
  </>
);

ExchangeConnectionContainer.Item = Item;
ExchangeConnectionContainer.Split = Split;
ExchangeConnectionContainer.ExchangeItem = ExchangeItem;
ExchangeConnectionContainer.ExpiresItem = ExpiresItem;
ExchangeConnectionContainer.Permissions = Permissions;
ExchangeConnectionContainer.Permission = Permission;
ExchangeConnectionContainer.Ips = Ips;
ExchangeConnectionContainer.Ip = Ip;

export default ExchangeConnectionContainer;
