import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import createAcc1 from '../../../../../assets/onboarding/binance/first/create-account-3.png';
import createAcc2 from '../../../../../assets/onboarding/binance/first/create-account-4.png';

const CreateAccountNextFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.CreateAccountNextFragment.title',
        defaultMessage: 'Sign Up with your phone or email address',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={createAcc1} second={createAcc2} />
  </>
);

export default CreateAccountNextFragment;
