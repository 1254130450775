import Sentry from './Sentry';

class Logger {
  static debug(component: string, method: string, message: string): void {
    if (process.env.NODE_ENV !== 'production') {
      console.debug(`[DEBUG][${component}#${method}]`, message);
    }
  }

  static info(component: string, method: string, message: string): void {
    Sentry.logInfo(component, method, message);
    if (process.env.NODE_ENV !== 'production') {
      console.info(`[INFO][${component}#${method}]`, message);
    }
  }

  static infoEvent(component: string, method: string, message: string): void {
    Sentry.infoEvent(component, method, message);
    if (process.env.NODE_ENV !== 'production') {
      console.info(`[INFO][${component}#${method}]`, message);
    }
  }

  static error(component: string, method: string, message: string): void {
    Sentry.logError(component, method, message);
    if (process.env.NODE_ENV !== 'production') {
      console.error(`[ERROR][${component}#${method}]`, message);
    }
  }

  static devError(
    component: string,
    method: string,
    message: string,
    ...optionalParams: unknown[]
  ): void {
    if (process.env.NODE_ENV !== 'production') {
      console.error(`[ERROR][${component}#${method}]`, message, optionalParams);
    }
  }
}

export default Logger;
