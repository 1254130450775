import {MessageDescriptor} from 'react-intl';

export enum App {
  Binance = 'BINANCE',
  Huobi = 'HUOBI',
  Bybit = 'BYBIT',
  GAuth = 'google-authenticator',
}

export default class AppUtils {
  static formatFullName(app: App): MessageDescriptor {
    switch (app) {
      case App.Binance:
        return {
          id: 'common.app.binance.fullName',
          defaultMessage: 'Binance: Buy Bitcoin & Crypto',
        };
      case App.Huobi:
        return {
          id: 'common.app.huobi.fullName',
          defaultMessage: 'HTX: Buy BTC, Crypto Exchange',
        };
      case App.Bybit:
        return {
          id: 'common.app.bybit.fullName',
          defaultMessage: 'Bybit: Buy & trade Crypto',
        };
      case App.GAuth:
        return {
          id: 'common.app.gauth.fullName',
          defaultMessage: 'Google Authenticator',
        };
    }
  }

  static formatShortDesc(app: App): MessageDescriptor {
    switch (app) {
      case App.Binance:
        return {
          id: 'common.app.binance.shortDesc',
          defaultMessage: 'Buy BTC & ETH | 0% Fee Trading',
        };
      case App.Huobi:
        return {
          id: 'common.app.huobi.shortDesc',
          defaultMessage: 'Trade BTC, ETH, Invest BRC20',
        };
      case App.Bybit:
        return {
          id: 'common.app.bybit.shortDesc',
          defaultMessage: 'Crypto Spot & Futures Exchange',
        };
      case App.GAuth:
        return {
          id: 'common.app.gauth.shortDesc',
          defaultMessage: 'Made by Google LLC',
        };
    }
  }
}
