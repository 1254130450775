import {BottomSheet as RBottomSheet} from 'react-spring-bottom-sheet';
import {FC, memo, ReactNode} from 'react';

interface Props {
  open: boolean;
  children: ReactNode;
  onDismiss?: () => void;
  scollable?: boolean;
}

const BottomSheet: FC<Props> = ({open, children, onDismiss, scollable}) => (
  <RBottomSheet
    open={open}
    onDismiss={onDismiss}
    className={!scollable ? 'no-scroll' : ''}
  >
    {children}
  </RBottomSheet>
);

export default memo(BottomSheet);
