import {FC} from 'react';
import DateUtils from '../../../../utils/DateUtils';

interface Props {
  value: string | Date;
}

const DateTime: FC<Props> = ({value}) => (
  <>{DateUtils.formatFullTimestamp(value)}</>
);

export default DateTime;
