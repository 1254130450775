import {WalletTransactionItemCauseEnum} from '../../../../api';
import {FC, useCallback, useState} from 'react';
import FilterButton from '../../button/FilterButton';
import Menu from '../../container/Menu';
import WalletTransactionUtils from '../../../../utils/WalletTransactionUtils';
import {observer} from 'mobx-react';

interface Props {
  causes: WalletTransactionItemCauseEnum[] | undefined;
  onCauses: (causes: WalletTransactionItemCauseEnum[] | undefined) => void;
}

const TransactionTypeFilter: FC<Props> = ({causes, onCauses}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const dismiss = useCallback(() => setMenuOpen(false), []);

  const allSelected =
    !causes ||
    causes.length >= WalletTransactionUtils.causeGroupsMergedCauses.length;

  return (
    <Menu
      anchor={
        <FilterButton
          text={{id: 'common.filter', defaultMessage: 'Filter'}}
          applied={!allSelected}
          onClick={() => setMenuOpen(true)}
        />
      }
      open={menuOpen}
      onDismiss={dismiss}
    >
      <Menu.Item
        text={{
          id: 'common.walletTransactionCause.allTransactions',
          defaultMessage: 'All transactions',
        }}
        rightIconTint={'green'}
        rightIcon={allSelected ? 'checkmark' : 'empty'}
        icon={'arrow-left-right'}
        iconRoundColor={'default'}
        onClick={() => onCauses(undefined)}
      />
      {WalletTransactionUtils.causeGroups.map((group) => {
        const found =
          causes &&
          !!causes.find(
            (value) => value === group.causes.find((v) => v === value),
          );
        return (
          <Menu.Item
            key={typeof group.name === 'string' ? group.name : group.name.id}
            text={group.name}
            rightIconTint={'green'}
            rightIcon={!causes || found ? 'checkmark' : 'empty'}
            icon={group.direction === 'deposit' ? 'arrow-down' : 'arrow-up'}
            iconRoundColor={group.direction === 'deposit' ? 'green' : 'red'}
            onClick={() => {
              if (found) {
                const c = (causes ?? []).filter(
                  (value) => !group.causes.find((v) => v === value),
                );
                onCauses(c.length === 0 ? undefined : c);
              } else {
                onCauses([...(causes ?? []), ...group.causes]);
              }
            }}
          />
        );
      })}
    </Menu>
  );
};

export default observer(TransactionTypeFilter);
