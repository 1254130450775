import React from 'react';
import Icon from '../Icon';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import Card from '../Card';
import cls from './style.module.scss';
import {Link} from 'react-router-dom';
import Switch from '../Switch';

interface LinkProps {
  name: MessageDescriptor;
  link: string;
}

interface DangerProps {
  name: MessageDescriptor;
  onClick: () => void;
}

interface SwitchProps {
  name: MessageDescriptor;
  checked: boolean;
  onValueChange: (value: boolean) => void;
}

type UProps = LinkProps | DangerProps | SwitchProps;

interface Props {
  children: (React.ReactElement<UProps> | false)[] | React.ReactElement<UProps>;
}

interface Ext {
  LinkAction: React.FC<LinkProps>;
  InternalLinkAction: React.FC<LinkProps>;
  DangerAction: React.FC<DangerProps>;
  SwitchAction: React.FC<SwitchProps>;
}

const UserActions: React.FunctionComponent<Props> & Ext = ({children}) => (
  <Card className={cls.container}>{children}</Card>
);

UserActions.LinkAction = ({link, name}) => (
  <a href={link} className={cls.item}>
    <p>
      <FormattedMessage {...name} />
    </p>{' '}
    <Icon name={'chevron-right'} size={9} />
  </a>
);

UserActions.InternalLinkAction = ({link, name}) => (
  <Link to={link} className={cls.item}>
    <p>
      <FormattedMessage {...name} />
    </p>{' '}
    <Icon name={'chevron-right'} size={9} />
  </Link>
);

UserActions.DangerAction = ({onClick, name}) => (
  <div className={cls.item + ' ' + cls.danger} onClick={onClick}>
    <p>
      <FormattedMessage {...name} />
    </p>
  </div>
);

UserActions.SwitchAction = ({name, checked, onValueChange}) => (
  <div className={cls.item}>
    <p>
      <FormattedMessage {...name} />
    </p>
    <Switch onChanged={onValueChange} defaultChecked={checked} />
  </div>
);

export default UserActions;
