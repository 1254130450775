import React, {FC} from 'react';
import Item from './item';
import FromTree from './fromTree';

interface Props {
  children: React.ReactNode;
}

interface SubComponents {
  Item: typeof Item;
  FromTree: typeof FromTree;
}

const KVCard: FC<Props> & SubComponents = ({children}) => <div>{children}</div>;

KVCard.Item = Item;
KVCard.FromTree = FromTree;

export default KVCard;
