import {RefObject} from 'react';
import useWindowClick from './useWindowClick';

export default function useOutsideClick(
  ref: RefObject<HTMLElement>,
  fn?: () => void,
  enabled = true,
) {
  useWindowClick(
    (evt) => {
      if (fn && evt.target && !ref.current?.contains(evt.target as Node)) {
        fn();
      }
    },
    [fn],
    enabled && !!fn,
  );
}
