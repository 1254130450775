import React, {useMemo} from 'react';
import Scaffolding from '../../component/container/Scaffolding';
import {parseISO} from 'date-fns';
import IntersectionTrigger from '../../component/ui/IntersectionTrigger';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import NotificationsListViewModel from '../../viewmodel/NotificationsListViewModel';
import NotificationItem from '../../component/ui/NotificationItem';
import DialogBottomSheet from '../../component/ui/DialogBottomSheet';
import ErrorUtils from '../../utils/ErrorUtils';

const NotificationsListScreen: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const vm = useMemo(() => new NotificationsListViewModel(), []);
  return (
    <Scaffolding
      header={{
        id: 'nav.notificationsList',
        defaultMessage: 'Notifications',
      }}
      overflow
      actions={[{icon: 'delete', onClick: vm.checkAll}]}
    >
      {vm.items.map((t, idx) =>
        idx === vm.items.length - 5 ? (
          <React.Fragment key={t.id}>
            <IntersectionTrigger onIntersected={vm.nextPage} />
            <NotificationItem
              title={t.title}
              acked={t.acked}
              description={t.description}
              type={t.type}
              timestamp={parseISO(t.timestamp)}
              key={t.id}
              onClick={() => navigate('/app/notifications/' + t.id)}
            />
          </React.Fragment>
        ) : (
          <NotificationItem
            title={t.title}
            acked={t.acked}
            description={t.description}
            type={t.type}
            timestamp={parseISO(t.timestamp)}
            key={t.id}
            onClick={() => navigate('/app/notifications/' + t.id)}
          />
        ),
      )}

      <DialogBottomSheet
        title={{
          id: 'screens.WalletTransactionListScreen.dialog.success.title',
          defaultMessage: 'All notifications marked as "read"',
        }}
        open={vm.ackAllState.status === 'success'}
        onDismiss={vm.resetAckAllState}
        onConfirm={vm.resetAckAllState}
        hideDismissButton
        confirmText={{id: 'common.ok', defaultMessage: 'OK'}}
        icon={'check-circle'}
      />
      <DialogBottomSheet
        title={{
          id: 'screens.WalletTransactionListScreen.dialog.error.title',
          defaultMessage: 'Error occurred while marking all notifications',
        }}
        text={
          vm.ackAllState.status === 'error'
            ? ErrorUtils.getTitle(vm.ackAllState.error)
            : undefined
        }
        open={vm.ackAllState.status === 'error'}
        onDismiss={vm.resetAckAllState}
        onConfirm={vm.checkAll}
        confirmText={{id: 'common.retry', defaultMessage: 'Retry'}}
        icon={'close-circle'}
      />
    </Scaffolding>
  );
};

export default observer(NotificationsListScreen);
