import {
  WalletTransactionDetailsStateEnum,
  WalletTransactionItemCauseEnum,
  WalletTransactionItemStateEnum,
} from '../api';
import {MessageDescriptor} from 'react-intl';
import {HText} from '../component/design/typo/Typography';

interface CauseGroup {
  causes: WalletTransactionItemCauseEnum[];
  name: HText;
  direction: 'deposit' | 'withdrawal';
}

class WalletTransactionUtils {
  static causes: WalletTransactionItemCauseEnum[] = Object.values(
    WalletTransactionItemCauseEnum,
  );
  static causeGroups: CauseGroup[] = [
    {
      name: {
        id: 'common.walletTransactionCauseGroup.rcoDeposit',
        defaultMessage: 'RCO deposit',
      },
      direction: 'deposit',
      causes: [
        WalletTransactionItemCauseEnum.DepositManual,
        WalletTransactionItemCauseEnum.DepositAcquisition,
      ],
    },
    {
      name: {
        id: 'common.walletTransactionCauseGroup.referralDeposit',
        defaultMessage: 'Deposits from referrals',
      },
      direction: 'deposit',
      causes: [WalletTransactionItemCauseEnum.DepositReferralCommission],
    },
    {
      name: {
        id: 'common.walletTransactionCauseGroup.withdrawRobotCommission',
        defaultMessage: 'Robot commission',
      },
      direction: 'withdrawal',
      causes: [WalletTransactionItemCauseEnum.WithdrawRobotCommission],
    },
    {
      name: {
        id: 'common.walletTransactionCauseGroup.withdrawSubscription',
        defaultMessage: 'Subscription payments',
      },
      direction: 'withdrawal',
      causes: [WalletTransactionItemCauseEnum.WithdrawBusinessSubscription],
    },
    {
      name: {
        id: 'common.walletTransactionCauseGroup.withdrawUser',
        defaultMessage: 'RCO withdrawal',
      },
      direction: 'withdrawal',
      causes: [WalletTransactionItemCauseEnum.WithdrawUser],
    },
  ];
  static causeGroupsMergedCauses = WalletTransactionUtils.causeGroups.flatMap(
    (value) => value.causes,
  );

  static formatCause(cause: WalletTransactionItemCauseEnum): MessageDescriptor {
    switch (cause) {
      case 'WITHDRAW_ROBOT_COMMISSION':
        return {
          id: 'common.walletTransactionCause.withdrawRobotCommission',
          defaultMessage: 'Robot commission',
        };
      case 'DEPOSIT_BONUS_REGISTRATION':
        return {
          id: 'common.walletTransactionCause.depositBonusRegistration',
          defaultMessage: 'Registration bonus',
        };
      case 'DEPOSIT_MANUAL':
        return {
          id: 'common.walletTransactionCause.depositManual',
          defaultMessage: 'Manual deposit',
        };
      case 'DEPOSIT_ACQUISITION':
        return {
          id: 'common.walletTransactionCause.depositAcquisition',
          defaultMessage: 'Deposit',
        };
      case 'MONEY_TO_BONUS_TRANSFER':
        return {
          id: 'common.walletTransactionCause.internalTransaction',
          defaultMessage: 'Internal transaction',
        };
      case 'DEPOSIT_REFERRAL_COMMISSION':
        return {
          id: 'common.walletTransactionCause.referralCommissionDeposit',
          defaultMessage: 'Deposit from referrals',
        };
      case 'WITHDRAW_BUSINESS_SUBSCRIPTION':
        return {
          id: 'common.walletTransactionCause.withdrawBusinessSubscription',
          defaultMessage: 'Business subscription',
        };
      case 'WITHDRAW_USER':
        return {
          id: 'common.walletTransactionCause.withdrawUser',
          defaultMessage: 'User withdrawal',
        };
      default:
        return {
          id: 'common.walletTransactionCause.unknown',
          defaultMessage: 'Unknown transaction',
        };
    }
  }

  static formatState(
    state: WalletTransactionItemStateEnum | WalletTransactionDetailsStateEnum,
  ): MessageDescriptor {
    switch (state) {
      case 'FAILED':
        return {
          id: 'common.walletTransactionState.failed',
          defaultMessage: 'Failed',
        };
      case 'IN_PROGRESS':
        return {
          id: 'common.walletTransactionState.inProgress',
          defaultMessage: 'Processing...',
        };
      case 'SUCCESSFUL':
        return {
          id: 'common.walletTransactionState.successful',
          defaultMessage: 'Successful',
        };
    }
  }

  static stateTextClassName(
    state: WalletTransactionItemStateEnum | WalletTransactionDetailsStateEnum,
  ): string {
    switch (state) {
      case 'IN_PROGRESS':
        return 'text-progress';
      case 'FAILED':
        return 'text-fail';
      case 'SUCCESSFUL':
        return 'text-success';
    }
  }
}

export default WalletTransactionUtils;
