import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import AppCard from '../../../../../component/design/data/AppCard';
import FlexRow from '../../../../../component/design/container/FlexRow';
import StoreButton from '../../../../../component/design/button/StoreButton';
import Button from '../../../../../component/design/button/Button';
import {App} from '../../../../../utils/AppUtils';

const DownloadBybitFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.DownloadBybitFragment.title',
        defaultMessage: 'Download Bybit app',
      }}
    />
    <Spacer size={1.5} />
    <AppCard app={App.Bybit} />
    <Spacer size={1.5} />
    <FlexRow gap={1} stretch>
      <StoreButton
        store={'gplay'}
        link={'https://play.google.com/store/apps/details?id=com.bybit.app'}
      />
      <StoreButton
        store={'appstore'}
        link={
          'https://apps.apple.com/us/app/bybit-buy-trade-crypto/id1488296980'
        }
      />
    </FlexRow>
    <Spacer size={1} />
    <Button
      text={{id: 'common.visitWebsite', defaultMessage: 'Visit Website'}}
      icon={'link'}
      type={'default'}
      onClick={() => (window.location.href = 'https://www.bybit.com/en/')}
    />
  </>
);

export default DownloadBybitFragment;
