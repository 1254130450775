import Typography, {HText} from '../../typo/Typography';
import Icon, {IconName} from '../../graphics/Icon';
import React, {FC} from 'react';
import cls from './style.module.scss';
import {TailSpin} from 'react-loader-spinner';
import {COLOR_TEXT} from '../../../../config';

interface Props {
  text?: HText;
  icon?: IconName;
  type: 'primary' | 'gray' | 'error';
  onClick?: () => void;
  flex?: number;
  loading?: boolean;
}

const FlatButton: FC<Props> = ({text, icon, type, onClick, flex, loading}) => {
  let typeStyle = '';
  if (type === 'primary') {
    typeStyle = cls.primary;
  } else if (type === 'gray') {
    typeStyle = cls.gray;
  }

  return (
    <div
      onClick={onClick}
      className={
        cls.button + ' ' + (type === 'error' && cls.error) + ' ' + typeStyle
      }
      style={{flex}}
    >
      {icon && <Icon name={icon} size={24} tint={'white'} />}
      {text && <Typography text={text} />}
      {loading && (
        <div className={cls.loading}>
          <TailSpin
            width={24}
            height={24}
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
            color={COLOR_TEXT}
          />
        </div>
      )}
    </div>
  );
};

export default FlatButton;
