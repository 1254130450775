import React from 'react';
import {
  Notification,
  NotificationDetailsTypeEnum,
  NotificationItemTypeEnum,
} from '../api';
import {IconName} from '../component/ui/Icon';
import {ReactComponent as AttentionBanner} from '../assets/images/notification-banners/header_attention.svg';
import {ReactComponent as BalanceBanner} from '../assets/images/notification-banners/header_balance.svg';
import {ReactComponent as EmailBanner} from '../assets/images/notification-banners/header_email.svg';
import {ReactComponent as NewUserBanner} from '../assets/images/notification-banners/header_new_user.svg';
import {ReactComponent as SuccessBanner} from '../assets/images/notification-banners/header_success.svg';
import {MessageDescriptor} from 'react-intl';

type NotificationType = NotificationItemTypeEnum | NotificationDetailsTypeEnum;

export interface NotificationButton {
  message: MessageDescriptor;
  url: string;
}

export type NotificationContent =
  | {type: 'referral'}
  | {type: 'text'; text: MessageDescriptor}
  | {type: 'money'; target: keyof Notification; symbol: string};

class NotificationUtils {
  static getIcon(type: NotificationType): IconName {
    switch (type) {
      case 'REFERRAL_COMMISSION':
      case 'TRADE_CLOSED':
      case 'DEPOSIT_BALANCE':
        return 'dollar-circle-outline';
      case 'SUCCESSFUL_SUBSCRIPTION':
      case 'SUCCESSFUL_REFERRAL_SUBSCRIPTION':
        return 'check-circle-outline';
      case 'NEW_REFERRAL':
        return 'account-supervisor-circle-outline';
      case 'ROBOT_STOPPED':
      case 'LOW_REFERRAL_BALANCE':
      case 'LOW_BALANCE':
      case 'AUTO_RENEW_FAILED':
      case 'AUTO_RENEW_WILL_FAIL':
        return 'alert-outline';
      default:
        return 'help-circle-outline';
    }
  }

  static getBanner(
    type: NotificationType,
  ): React.FunctionComponent<React.SVGProps<SVGSVGElement>> {
    switch (type) {
      case 'REFERRAL_COMMISSION':
      case 'TRADE_CLOSED':
      case 'DEPOSIT_BALANCE':
        return BalanceBanner;
      case 'SUCCESSFUL_SUBSCRIPTION':
      case 'SUCCESSFUL_REFERRAL_SUBSCRIPTION':
        return SuccessBanner;
      case 'ROBOT_STOPPED':
      case 'LOW_BALANCE':
      case 'LOW_REFERRAL_BALANCE':
      case 'AUTO_RENEW_FAILED':
      case 'AUTO_RENEW_WILL_FAIL':
        return AttentionBanner;
      case 'NEW_REFERRAL':
        return NewUserBanner;
      default:
        return EmailBanner;
    }
  }

  static getButton(
    type: NotificationType,
    notification: Notification,
  ): NotificationButton | undefined {
    switch (type) {
      case 'REFERRAL_COMMISSION':
        return {
          url: `/app/wallet/${notification.transactionId}`,
          message: {
            id: 'notifications.referralCommission.button',
            defaultMessage: 'View transaction',
          },
        };
      case 'TRADE_CLOSED':
        return {
          url: `/app/robot/${notification.robot.id}/trades/${notification.tradeId}`,
          message: {
            id: 'notifications.tradeClosed.button',
            defaultMessage: 'View trade',
          },
        };
      case 'ROBOT_STOPPED':
        if (notification.cause === 'INSUFFICIENT_RCO') {
          return {
            url: '/app/wallet/acquire-crypto/first',
            message: {
              id: 'notifications.robotStoppedInsufficientRco.button',
              defaultMessage: 'Buy RCO',
            },
          };
        } else if (notification.cause === 'EXPIRED_SUBSCRIPTION') {
          return {
            url: '/app/subscription',
            message: {
              id: 'notifications.robotStoppedExpiredSubscription.button',
              defaultMessage: 'Open subscriptions',
            },
          };
        } else {
          return {
            url: `/app/dashboard/robot/${notification.robot.id}`,
            message: {
              id: 'notifications.robotStopped.button',
              defaultMessage: 'View robot',
            },
          };
        }
      case 'DEPOSIT_BALANCE':
        return {
          url: `/app/wallet/${notification.transactionId}`,
          message: {
            id: 'notifications.depositBalance.button',
            defaultMessage: 'View transaction',
          },
        };
      case 'LOW_BALANCE':
        return {
          url: '/app/wallet/acquire-crypto/first',
          message: {
            id: 'notifications.lowBalance.button',
            defaultMessage: 'Buy RCO',
          },
        };
      case 'AUTO_RENEW_FAILED':
        return {
          url: '/app/subscription',
          message: {
            id: 'notifications.autoRenewFailed.button',
            defaultMessage: 'Manually renew',
          },
        };
      case 'AUTO_RENEW_WILL_FAIL':
        return {
          url: '/app/wallet/acquire-crypto/first',
          message: {
            id: 'notifications.autoRenewWillFail.button',
            defaultMessage: 'Buy RCO',
          },
        };
    }
    return undefined;
  }

  static getFooter(
    type: NotificationType,
    data: Notification,
  ): MessageDescriptor[] {
    switch (type) {
      case 'ROBOT_STOPPED':
        switch (data.cause) {
          case 'INSUFFICIENT_RCO':
            return [
              {
                id: 'notifications.robotStopped.footer.errorInsufficientRco',
                defaultMessage: 'Error: insufficient RCO',
              },
            ];
          case 'EXPIRED_SUBSCRIPTION':
            return [
              {
                id: 'notifications.robotStopped.footer.errorExpiredSubscription',
                defaultMessage: 'Error: expired subscription',
              },
            ];
          case 'CORRECTION':
            return [
              {
                id: 'notifications.robotStopped.footer.errorCorrection',
                defaultMessage: 'Error: correction limit reached',
              },
            ];
          case 'ERROR':
          default:
            return [
              {
                id: 'notifications.robotStopped.footer.error',
                defaultMessage: 'Error: {errorMessage}',
              },
            ];
        }
      case 'DEPOSIT_BALANCE':
        return [
          {
            id: 'notifications.depositBalance.footer.txId',
            defaultMessage: 'Transaction ID: {transactionId}',
          },
          {
            id: 'notifications.depositBalance.footer.processedAt',
            defaultMessage: 'Processed at: <ts>{timestamp}</ts>',
          },
        ];
      case 'REFERRAL_COMMISSION':
        return [
          {
            id: 'notifications.referralCommission.footer.txId',
            defaultMessage: 'Transaction ID: {transactionId}',
          },
          {
            id: 'notifications.depositBalance.footer.processedAt',
            defaultMessage: 'Processed at: <ts>{timestamp}</ts>',
          },
        ];
      case 'TRADE_CLOSED':
        return [
          {
            id: 'notifications.tradeClosed.footer.robot',
            defaultMessage:
              'Robot: {robot_name} ({robot_asset} / {robot_money})',
          },
          {
            id: 'notifications.tradeClosed.footer.tradeId',
            defaultMessage: 'Trade ID: {tradeId}',
          },
          {
            id: 'notifications.depositBalance.footer.processedAt',
            defaultMessage: 'Processed at: <ts>{timestamp}</ts>',
          },
        ];
      case 'SUCCESSFUL_REFERRAL_SUBSCRIPTION':
        return [
          {
            id: 'notifications.referralSubscription.footer.processedAt',
            defaultMessage: 'Processed at: <ts>{timestamp}</ts>',
          },
        ];
      case 'SUCCESSFUL_SUBSCRIPTION':
        return [
          {
            id: 'notifications.subscription.footer.txId',
            defaultMessage: 'Transaction ID: {transactionId}',
          },
          {
            id: 'notifications.subscription.footer.processedAt',
            defaultMessage: 'Processed at: <ts>{timestamp}</ts>',
          },
        ];
      case 'NEW_REFERRAL':
        return (data.referralStack ?? []).map((value, index) => ({
          defaultMessage:
            index === 0 ? 'Invited by ' + value : 'Referral of ' + value,
          id: '__do_not_use__',
        }));
    }
    return [];
  }

  static getHeader(type: NotificationType): MessageDescriptor {
    switch (type) {
      case 'REFERRAL_COMMISSION':
        return {
          id: 'notifications.referralCommission.header',
          defaultMessage: 'You received commission from {referralInfo_name}',
        };
      case 'TRADE_CLOSED':
        return {
          id: 'notifications.tradeClosed.header',
          defaultMessage: 'You earned',
        };
      case 'SUCCESSFUL_SUBSCRIPTION':
        return {
          id: 'notifications.successfulSubscription.header',
          defaultMessage: 'You successfully paid for your',
        };
      case 'ROBOT_STOPPED':
        return {
          id: 'notifications.robotStopped.header',
          defaultMessage: 'Bot needs your attention',
        };
      case 'LOW_REFERRAL_BALANCE':
        return {
          id: 'notifications.lowReferralBalance.header',
          defaultMessage: 'To keep {referralInfo_name} bots working',
        };
      case 'DEPOSIT_BALANCE':
        return {
          id: 'notifications.depositBalance.header',
          defaultMessage: 'You deposited to your wallet',
        };
      case 'NEW_REFERRAL':
        return {
          id: 'notifications.newReferral.header',
          defaultMessage: 'You have new team member',
        };
      case 'SUCCESSFUL_REFERRAL_SUBSCRIPTION':
        return {
          id: 'notifications.successfulReferralSubscription.header',
          defaultMessage: 'Referral {referralInfo_name} successfully paid for',
        };
      case 'LOW_BALANCE':
        return {
          id: 'notifications.lowBalance.header',
          defaultMessage: 'To keep your bots working',
        };
      case 'AUTO_RENEW_WILL_FAIL':
        return {
          id: 'notifications.autoRenewWillFail.header',
          defaultMessage: 'Cannot auto-renew\nsubscription',
        };
      case 'AUTO_RENEW_FAILED':
        return {
          id: 'notifications.autoRenewFailed.header',
          defaultMessage: 'Subscription auto-renew\nfailed',
        };
    }
    return {id: 'empty', defaultMessage: ''};
  }

  static getContent(
    type: NotificationType,
    data: Notification,
  ): NotificationContent | undefined {
    switch (type) {
      case 'REFERRAL_COMMISSION':
        return {
          type: 'money',
          target: 'earnedRco',
          symbol: 'RCO',
        };
      case 'TRADE_CLOSED':
        return {
          type: 'money',
          target: 'earnedMoney',
          symbol: (data.robot as unknown as Record<string, string>).money,
        };
      case 'SUCCESSFUL_SUBSCRIPTION':
        return {
          type: 'text',
          text: {
            id: 'common.businessPlan',
            defaultMessage: 'Business plan',
          },
        };
      case 'ROBOT_STOPPED':
        if (data.cause === 'INSUFFICIENT_RCO') {
          return {
            type: 'text',
            text: {
              id: 'notifications.robotStopped.content.insufficientRco',
              defaultMessage:
                'Robot “{robot_name}”\nhas been stopped\ndue to insufficient RCO',
            },
          };
        } else if (data.cause === 'EXPIRED_SUBSCRIPTION') {
          return {
            type: 'text',
            text: {
              id: 'notifications.robotStopped.content.expiredSubscription',
              defaultMessage:
                'Robot “{robot_name}”\nhas been stopped\ndue to expired subscription',
            },
          };
        } else if (data.cause === 'CORRECTION') {
          return {
            type: 'text',
            text: {
              id: 'notifications.robotStopped.content.correction',
              defaultMessage:
                'Robot “{robot_name}”\nhas been stopped\ndue to correction',
            },
          };
        } else {
          return {
            type: 'text',
            text: {
              id: 'notifications.robotStopped.content.error',
              defaultMessage:
                'Robot “{robot_name}”\nhas been stopped\ndue to error',
            },
          };
        }
      case 'LOW_REFERRAL_BALANCE':
        return {
          type: 'text',
          text: {
            id: 'notifications.lowReferralBalance.content',
            defaultMessage: 'They need to \ndeposit RCO to their account',
          },
        };
      case 'DEPOSIT_BALANCE':
        return {
          type: 'money',
          target: 'amount',
          symbol: 'RCO',
        };
      case 'NEW_REFERRAL':
        return {type: 'referral'};
      case 'SUCCESSFUL_REFERRAL_SUBSCRIPTION':
        return {
          type: 'text',
          text: {
            id: 'common.businessPlan',
            defaultMessage: 'Business plan',
          },
        };
      case 'LOW_BALANCE':
        return {
          type: 'text',
          text: {
            id: 'notifications.lowBalance.content',
            defaultMessage: 'Deposit RCO \nto your wallet',
          },
        };
      case 'AUTO_RENEW_FAILED':
        return {
          type: 'text',
          text: {
            id: 'notifications.autoRenewFailed.content',
            defaultMessage: 'You should\nmanually renew\nyour subscription',
          },
        };
      case 'AUTO_RENEW_WILL_FAIL':
        return {
          type: 'text',
          text: {
            id: 'notifications.autoRenewWillFail.content',
            defaultMessage:
              'You should\ndeposit RCO\nfor subscription\nauto-renew to work',
          },
        };
    }
    return undefined;
  }
}

export default NotificationUtils;
