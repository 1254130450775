import {FC, ReactNode} from 'react';
import cls from './style.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
}

const Split: FC<Props> = ({children}) => (
  <div className={cls.split}>{children}</div>
);

export default Split;
