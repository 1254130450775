import {FC, ReactNode} from 'react';
import cls from './style.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
}

const List: FC<Props> = ({children}) => (
  <div className={cls.container}>
    <div className={cls.content}>{children}</div>
  </div>
);

export default List;
