import {RobotInfo, RobotTradeDetailsStateEnum} from '../../../../api';
import React, {FC} from 'react';
import Illustration from '../../graphics/Illustration';
import Typography from '../../typo/Typography';
import DateUtils from '../../../../utils/DateUtils';
import cls from './style.module.scss';
import Icon from '../../graphics/Icon';
import RobotTradeUtils from '../../../../utils/RobotTradeUtils';
import CoinAmount from '../../typo/CoinAmount';

interface Props {
  profit?: number;
  state: RobotTradeDetailsStateEnum;
  createdAt: string;
  robot?: RobotInfo;
}

const RobotTradeHeader: FC<Props> = ({profit, createdAt, state, robot}) => (
  <div className={cls.container}>
    {state === RobotTradeDetailsStateEnum.SellError ? (
      <Illustration.Failure />
    ) : state === RobotTradeDetailsStateEnum.Completed ? (
      <Illustration.Success />
    ) : (
      <Illustration.Progress />
    )}
    {profit && (
      <span className={cls.profit + ' ' + (profit > 0 ? cls.up : cls.down)}>
        <CoinAmount
          amount={profit}
          coin={robot?.pair?.money ?? 'WTF'}
          maxDigits={8}
          coinClassName={cls.coin}
        />
      </span>
    )}
    <span className={cls.time}>{DateUtils.formatFullTimestamp(createdAt)}</span>
    <span
      className={
        cls.badge +
        ' ' +
        (state === RobotTradeDetailsStateEnum.SellError && cls.failure) +
        ' ' +
        (state === RobotTradeDetailsStateEnum.Completed && cls.success)
      }
    >
      <Icon
        name={
          state === RobotTradeDetailsStateEnum.Completed
            ? 'checkmark'
            : state === RobotTradeDetailsStateEnum.SellError
            ? 'close'
            : 'clock-mini'
        }
        size={16}
        tint={
          state === RobotTradeDetailsStateEnum.Completed
            ? 'green'
            : state === RobotTradeDetailsStateEnum.SellError
            ? 'red'
            : 'default'
        }
      />
      <Typography text={RobotTradeUtils.formatState(state)} />
    </span>
  </div>
);

export default RobotTradeHeader;
