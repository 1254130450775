import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import {
  ExchangeConnectionDetails,
  ExchangeConnectionDetailsStateEnum,
} from '../../api';
import {notLoaded, sleep, wrapRefreshablePromise} from '../../utils';
import stores from '../../store';
import ErrorUtils from '../../utils/ErrorUtils';
import {debounce} from 'ts-debounce';

const CHECK_INTERVAL = 500;
const SAVE_NAME_DEBOUNCE = 1000;

class ExchangeConnectionItemViewModel {
  request: RefreshableAsyncState<ExchangeConnectionDetails> = notLoaded();
  name = '';
  private running = true;

  constructor(
    private readonly id: string,
    private readonly showError: (err: string) => void,
    private readonly onNameSaved: () => void,
  ) {
    makeAutoObservable(this, {
      request: observable,
      name: observable,
      refresh: action.bound,
      setName: action.bound,
    });
  }

  init() {
    this.running = true;
    (async () => {
      while (this.running) {
        await wrapRefreshablePromise(
          stores.exchangeConnection.getDetails(this.id),
          this.request,
          (v) => {
            runInAction(() => {
              this.request = v;
              if (v.status === 'success' || v.status === 'refreshing-success') {
                this.name = v.result.name;
                if (
                  v.result.state !== ExchangeConnectionDetailsStateEnum.Fetching
                ) {
                  this.running = false;
                }
              }
            });
          },
        );
        await sleep(CHECK_INTERVAL);
      }
    })();
  }

  stopUpdate() {
    this.running = false;
  }

  private syncName = debounce(() => {
    stores.exchangeConnection
      .editConnection(this.id, {name: this.name})
      .then(() => this.onNameSaved())
      .catch((e) => this.showError(ErrorUtils.getMessage(e)));
  }, SAVE_NAME_DEBOUNCE);

  setName(name: string) {
    this.name = name;
    void this.syncName();
  }

  refresh(onError: (err: string) => void) {
    if (this.request.status !== 'success') {
      return;
    }
    this.request = {status: 'loading'};
    stores.exchangeConnection
      .refreshConnection(this.id)
      .then(() => this.init())
      .catch((e) => onError(ErrorUtils.getMessage(e)));
  }
}

export default ExchangeConnectionItemViewModel;
