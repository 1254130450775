import {RobotInfo, RobotTradeDetailsStateEnum} from '../../../../api';
import {FC} from 'react';
import KVCard from '../../container/KVCard';
import DateTime from '../../typo/DateTime';
import TradeState from '../TradeState';

interface Props {
  id: string;
  createdAt: string;
  state: RobotTradeDetailsStateEnum;
  robot?: RobotInfo;
}

const TradeInfoCard: FC<Props> = ({id, createdAt, state, robot}) => (
  <KVCard>
    <KVCard.Item
      name={{id: 'common.trade.id', defaultMessage: 'Trade ID'}}
      value={id}
      link={'/app/robot/' + robot?.id + '/trades/' + id}
      ellipsis
    />
    <KVCard.Item
      name={{id: 'common.trade.state', defaultMessage: 'Trade state'}}
      value={<TradeState state={state} />}
    />
    <KVCard.Item
      name={{id: 'common.trade.createdAt', defaultMessage: 'Trade started'}}
      value={<DateTime value={createdAt} />}
    />
  </KVCard>
);

export default TradeInfoCard;
