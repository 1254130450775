import React from 'react';
import cls from './style.module.scss';
import {FormattedMessage} from 'react-intl';
import Icon from '../Icon';

const AddBotCard: React.FunctionComponent = () => (
  <div className={cls.card}>
    <Icon size={26} name={'plus'} />
    <p className={cls.text}>
      <FormattedMessage
        id={'components.AddBotCard.addBot'}
        defaultMessage={'Create robot'}
      />
    </p>
  </div>
);

export default AddBotCard;
