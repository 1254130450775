import Sprite from './sprite.svg';
import cls from './style.module.scss';
import {FC} from 'react';

export const ICONS = [
  'empty',
  'menu',
  'close',
  'checkmark',
  'checkmark-fill-round',
  'close-fill-round',
  'menu-dots',
  'arrow-mini-fill-down',
  'arrow-mini-down',
  'arrow-mini-up',
  'arrow-mini-left',
  'arrow-mini-right',
  'arrow-down',
  'arrow-up',
  'arrow-left',
  'arrow-right',
  'arrow-left-right',
  'new-tab',
  'file',
  'copy',
  'paste',
  'delete',
  'edit',
  'plus',
  'minus',
  'mini-plus',
  'mini-minus',
  'full-screen',
  'search',
  'settings',
  'filter',
  'analytics',
  'category',
  'merge',
  'clip',
  'phone',
  'desktop',
  'chip',
  'objects',
  'qr-code',
  'make-photo',
  'cancel',
  'link',
  'link-mini',
  'link-mini-warning',
  'link-mini-error',
  'download',
  'upload',
  'add-avatar',
  'webcam',
  'reset',
  'rotate',
  'undo',
  'redo',
  'image',
  'clock',
  'clock-mini',
  'attention',
  'internet',
  'no-internet',
  'refresh-mini',
  'calendar',
  'failed-transaction',
  'question',
  'info',
  'sell',
  'eye-open',
  'eye-closed',
] as const;
export type IconName = typeof ICONS[number];
export type IconTint =
  | 'default'
  | 'primary'
  | 'yellow'
  | 'green'
  | 'red'
  | 'gray500'
  | 'white'
  | 'black'
  | string;

interface Props {
  name: IconName;
  size: number;
  tint?: IconTint;
  className?: string;
  clickable?: boolean;
  onClick?: () => void;
}

const Icon: FC<Props> = ({name, size, className, clickable, onClick, tint}) => {
  const elemCls = [cls.icon];
  if (clickable) elemCls.push(cls.clickable);
  if (className !== undefined) elemCls.push(className);

  if (!tint || tint === 'default') {
    elemCls.push(cls.colDefault);
  } else if (tint === 'primary') {
    elemCls.push(cls.colPrimary);
  } else if (tint === 'yellow') {
    elemCls.push(cls.colYellow);
  } else if (tint === 'green') {
    elemCls.push(cls.colGreen);
  } else if (tint === 'red') {
    elemCls.push(cls.colRed);
  } else if (tint === 'gray500') {
    elemCls.push(cls.colGray500);
  } else if (tint === 'white') {
    elemCls.push(cls.colWhite);
  } else if (tint === 'black') {
    elemCls.push(cls.colBlack);
  }

  return (
    <svg
      className={elemCls.join(' ')}
      width={size}
      height={size}
      onClick={onClick}
      style={{minWidth: size + 'px', minHeight: size + 'px'}}
    >
      <use xlinkHref={`${Sprite}#${name}`} />
    </svg>
  );
};

export default Icon;
