import InviteCodeStore from '../InviteCodeStore';
import {action, makeAutoObservable, observable} from 'mobx';
import InviteCodeStorage from '../../storage/InviteCodeStorage';
import Logger from '../../manager/Logger';

class RealInviteCodeStore implements InviteCodeStore {
  code: string | undefined;

  constructor() {
    makeAutoObservable(this, {
      code: observable,
      set: action.bound,
    });
    this.code = InviteCodeStorage.get().getInviteCode();
    Logger.debug(
      'RealInviteCodeStore',
      '<init>',
      'Got invite code ' + this.code,
    );
  }

  set(code: string | null): void {
    Logger.debug(
      'RealInviteCodeStore',
      'set',
      'Updating invite code to ' + code,
    );
    if (code !== null) {
      InviteCodeStorage.get().setInviteCode(code);
      this.code = code;
    }
  }
}

export default RealInviteCodeStore;
