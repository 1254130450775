import React from 'react';
import {NotificationItemTypeEnum} from '../../../api';
import Icon from '../Icon';
import DateUtils from '../../../utils/DateUtils';
import NotificationUtils from '../../../utils/NotificationUtils';
import cls from './style.module.scss';

interface Props {
  type: NotificationItemTypeEnum;
  title: string;
  description: string;
  timestamp: Date;
  acked: boolean;
  onClick?: () => void;
}

const NotificationItem: React.FunctionComponent<Props> = ({
  type,
  timestamp,
  title,
  description,
  acked,
  onClick,
}) => (
  <div className={cls.container} onClick={onClick}>
    <Icon
      name={NotificationUtils.getIcon(type)}
      size={24}
      className={cls.icon}
    />
    <div className={cls.main}>
      <div className={cls.topLine}>
        <div className={cls.row}>
          <span className={cls.title}>{title}</span>
          {!acked && <div className={cls.nack} />}
        </div>
        <span className={cls.date}>{DateUtils.formatTimestamp(timestamp)}</span>
      </div>
      <span className={cls.description}>{description}</span>
    </div>
  </div>
);

export default NotificationItem;
