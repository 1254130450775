import {WalletCauseDistribution} from '../../../../api';
import {FC, useState} from 'react';
import Typography from '../../typo/Typography';
import cls from './style.module.scss';
import WalletTransactionUtils from '../../../../utils/WalletTransactionUtils';
import RcoAmount from '../../typo/RcoAmount';
import Icon from '../../graphics/Icon';
import {observer} from 'mobx-react';
import CauseDistributionCardSkeleton from './skeleton';

interface Props {
  distribution: WalletCauseDistribution;
}

interface SubComponents {
  Skeleton: typeof CauseDistributionCardSkeleton;
}

const CauseDistributionCard: FC<Props> & SubComponents = ({distribution}) => {
  const [expanded, setExpanded] = useState(false);

  const groupValues = WalletTransactionUtils.causeGroups
    .map((value) => ({
      group: value,
      sum: distribution.details
        .filter((v) => value.causes.find((q) => q === v.cause))
        .map((v) => v.change)
        .reduce((a, b) => a + b, 0),
    }))
    .filter((value) => value.sum !== 0);
  const incomeSum = groupValues
    .filter((v) => v.group.direction === 'deposit')
    .map((v) => v.sum)
    .reduce((a, b) => a + b, 0);
  const costSum = groupValues
    .filter((v) => v.group.direction === 'withdrawal')
    .map((v) => v.sum)
    .reduce((a, b) => a + b, 0);

  const deposits = groupValues
    .filter((value) => value.group.direction === 'deposit')
    .sort((a, b) => b.sum - a.sum);
  const withdrawals = groupValues
    .filter((value) => value.group.direction === 'withdrawal')
    .sort((a, b) => b.sum - a.sum);

  const depositPercentSum = deposits
    .map((value) => Math.max(5, (value.sum / incomeSum) * 100))
    .reduce((a, b) => a + b, 0);
  const withdrawalPercentSum = withdrawals
    .map((value) => Math.max(5, (value.sum / costSum) * 100))
    .reduce((a, b) => a + b, 0);

  const incomePart = Math.max(
    (incomeSum / (incomeSum - costSum)) * 100,
    groupValues.filter((value) => value.group.direction === 'deposit').length *
      5,
  );
  const withdrawalPart = Math.max(
    (-costSum / (incomeSum - costSum)) * 100,
    groupValues.filter((value) => value.group.direction === 'withdrawal')
      .length * 5,
  );

  return (
    <div className={cls.container}>
      <span className={cls.heading}>
        <Typography
          text={{
            id: 'components.CauseDistributionCard.header',
            defaultMessage: 'Analytics',
          }}
        />
      </span>
      <div className={cls.chart}>
        {incomeSum !== 0 && (
          <div
            className={
              cls.chartGroup +
              ' ' +
              (expanded && cls.expanded) +
              ' ' +
              cls.income
            }
            style={{
              width: `${(incomePart / (incomePart + withdrawalPart)) * 100}%`,
            }}
          >
            {deposits.map((value, idx, arr) => (
              <span
                key={
                  typeof value.group.name === 'string'
                    ? value.group.name
                    : value.group.name.id
                }
                style={{
                  width:
                    (Math.max(5, (value.sum / incomeSum) * 100) /
                      depositPercentSum) *
                      100 +
                    '%',
                  filter:
                    'brightness(' +
                    (100 - (arr.length - idx - 1) * 20 + 20) +
                    '%)',
                }}
              ></span>
            ))}
          </div>
        )}
        {costSum !== 0 && (
          <div
            className={
              cls.chartGroup + ' ' + (expanded && cls.expanded) + ' ' + cls.cost
            }
            style={{
              width: `${
                (withdrawalPart / (incomePart + withdrawalPart)) * 100
              }%`,
            }}
          >
            {withdrawals.map((value, idx) => (
              <span
                key={
                  typeof value.group.name === 'string'
                    ? value.group.name
                    : value.group.name.id
                }
                style={{
                  width:
                    (Math.max(5, (value.sum / costSum) * 100) /
                      withdrawalPercentSum) *
                      100 +
                    '%',
                  filter: 'brightness(' + (100 - idx * 35 + 50) + '%)',
                }}
              ></span>
            ))}
          </div>
        )}
        {incomeSum === 0 && costSum === 0 && (
          <div className={cls.emptyChartGroup} />
        )}
      </div>
      {(incomeSum !== 0 || costSum !== 0) && (
        <div className={cls.totals}>
          {incomeSum !== 0 ? (
            <span className={cls.income}>
              <RcoAmount amount={incomeSum} withoutCurrency />
            </span>
          ) : (
            <span />
          )}
          {costSum !== 0 && (
            <span className={cls.cost}>
              <RcoAmount amount={costSum} withoutCurrency />
            </span>
          )}
        </div>
      )}
      {incomeSum === 0 && costSum === 0 && (
        <div className={cls.noTx}>
          <Icon name={'failed-transaction'} size={16} tint={'gray500'} />
          <Typography
            text={{
              id: 'components.CauseDistributionCard.noTransactions',
              defaultMessage: 'No transaction statistics found',
            }}
          />
        </div>
      )}
      <div className={cls.body + ' ' + (expanded && cls.expanded)}>
        <div className={cls.line} />
        <div className={cls.list}>
          {incomeSum !== 0 && (
            <div className={cls.group + ' ' + cls.income}>
              {deposits.reverse().map((v, idx) => (
                <div
                  className={cls.item}
                  key={
                    typeof v.group.name === 'string'
                      ? v.group.name
                      : v.group.name.id
                  }
                >
                  <div
                    className={cls.icon}
                    style={{
                      filter: 'brightness(' + (100 - idx * 35 + 20) + '%)',
                    }}
                  />
                  <Typography text={v.group.name} className={cls.name} />
                  <span className={cls.amount}>
                    <RcoAmount amount={v.sum} withoutCurrency />
                  </span>
                </div>
              ))}
            </div>
          )}
          {costSum !== 0 && (
            <div className={cls.group + ' ' + cls.cost}>
              {withdrawals.map((v, idx) => (
                <div
                  className={cls.item}
                  key={
                    typeof v.group.name === 'string'
                      ? v.group.name
                      : v.group.name.id
                  }
                >
                  <div
                    className={cls.icon}
                    style={{
                      filter: 'brightness(' + (100 - idx * 30 + 50) + '%)',
                    }}
                  />
                  <Typography text={v.group.name} className={cls.name} />
                  <span className={cls.amount}>
                    <RcoAmount amount={v.sum} withoutCurrency />
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div
        className={
          cls.button +
          ' ' +
          (expanded && cls.expanded) +
          ' ' +
          (costSum === 0 && incomeSum === 0 && cls.disabled)
        }
        onClick={() => {
          if (costSum !== 0 || incomeSum !== 0) {
            setExpanded(!expanded);
          }
        }}
      >
        <Icon
          name={'arrow-mini-down'}
          size={16}
          tint={costSum !== 0 || incomeSum !== 0 ? 'white' : 'default'}
        />
      </div>
    </div>
  );
};

CauseDistributionCard.Skeleton = CauseDistributionCardSkeleton;

export default observer(CauseDistributionCard);
