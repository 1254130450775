import {FC} from 'react';
import AppIcon from '../../graphics/AppIcon';
import Typography from '../../typo/Typography';
import cls from './style.module.scss';
import AppUtils, {App} from '../../../../utils/AppUtils';

interface Props {
  app: App;
}

const AppCard: FC<Props> = ({app}) => (
  <div className={cls.container}>
    <AppIcon market={app} size={72} />
    <div className={cls.texts}>
      <Typography text={AppUtils.formatFullName(app)} className={cls.name} />
      <Typography text={AppUtils.formatShortDesc(app)} className={cls.desc} />
    </div>
  </div>
);

export default AppCard;
