import React, {useCallback, useEffect} from 'react';
import Scaffolding from '../../../component/container/Scaffolding';
import {
  Location,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {SwiperSlide, Swiper} from 'swiper/react';
import {EffectCreative, Pagination} from 'swiper';
import cls from './style.module.scss';
import Spacer from '../../../component/design/utils/Spacer';
import {RobotStore} from '../../../store/RobotStore';
import {inject, observer} from 'mobx-react';
import {ifSuccessful} from '../../../utils';
import AddBotCard from '../../../component/ui/AddBotCard';
import AsyncStateWrapper from '../../../component/ui/AsyncStateWrapper';
import BalanceCard, {CurrentBalance} from '../../../component/ui/BalanceCard';
import {Swiper as SwiperClass} from 'swiper/types';
import {RobotSummaryInfo} from '../../../api';
import {useIntl} from 'react-intl';
import RobotCardActions from '../../../component/smart/RobotCardActions';
import MarketUtils from '../../../utils/MarketUtils';
import NotificationsStore from '../../../store/NotificationsStore';

interface Props {
  robot: RobotStore;
  notifications: NotificationsStore;
}

function computeSliderIndex(
  location: Location,
  summaries: RobotSummaryInfo,
): number {
  if (location.pathname.endsWith('/add-robot')) {
    return summaries.robotSummaries.length + 1;
  } else if (location.pathname.indexOf('/robot/') !== -1) {
    const robot = location.pathname.substring(
      location.pathname.indexOf('/robot/') + '/robot/'.length,
    );
    const idx = summaries.robotSummaries.findIndex(
      (value) => value.id === robot,
    );
    return idx === -1 ? 0 : idx + 1;
  } else {
    return 0;
  }
}

function computeBalances(summaries: RobotSummaryInfo): CurrentBalance[] {
  const balancePairs = new Map<string, CurrentBalance>();
  for (const summary of summaries.robotSummaries) {
    if (!summary.balance) {
      continue;
    }
    const key = summary.pair.asset + '/' + summary.pair.money;
    let current = balancePairs.get(key);
    if (!current) {
      current = {
        moneyCoin: summary.pair.money,
        assetCoin: summary.pair.asset,
        money: 0,
        asset: 0,
      };
      balancePairs.set(key, current);
    }
    current.money += summary.balance.money / 100000000;
    current.asset += summary.balance.asset / 100000000;
  }
  return [...balancePairs.values()];
}

const RobotDashboardFragment: React.FunctionComponent<Props> = ({
  robot,
  notifications,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const noRobots = ifSuccessful(
    robot.summary,
    (t) => t.robotSummaries.length === 0,
    false,
  );
  const onSlideChange = useCallback(
    (swiper: SwiperClass) => {
      const currentTab = swiper.activeIndex;
      const slide = swiper.slides[currentTab] as HTMLElement | undefined;
      if (slide !== undefined) {
        const role = slide.dataset.role;
        const params =
          searchParams.toString().length === 0
            ? ''
            : '?' + searchParams.toString();
        if (role === 'add') {
          navigate('/app/dashboard/add-robot' + params);
        } else if (role === 'summary') {
          navigate('/app/dashboard' + params);
        } else if (role === 'robot') {
          const id = slide.dataset.robot;
          navigate('/app/dashboard/robot/' + id + params);
        }
      }

      if (
        robot.summary.status !== 'success' &&
        robot.summary.status !== 'refreshing-success'
      ) {
        return;
      }
      if (currentTab === 0) {
        robot.onChangeToMain();
      } else if (currentTab - 1 < robot.summary.result.robotSummaries.length) {
        robot.onChangeToRobot(
          robot.summary.result.robotSummaries[currentTab - 1].id,
        );
      }
    },
    [robot, searchParams],
  );
  useEffect(() => {
    if (
      ifSuccessful(robot.summary, (t) => t.robotSummaries.length === 0, false)
    ) {
      navigate('/app/dashboard/add-robot');
    }
  }, [robot.summary]);

  // noinspection JSUnusedLocalSymbols
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _tmp = robot.chartData;
  const hasNewNotifications = notifications.hasNewNotifications;

  return (
    <Scaffolding positionRelative>
      <AsyncStateWrapper state={robot.summary}>
        {(state) => (
          <>
            <Swiper
              pagination={{clickable: true}}
              spaceBetween={12}
              centeredSlides
              slidesPerView={1.1}
              modules={[Pagination, EffectCreative]}
              className={cls.swiper}
              effect={'creative'}
              onSlideChange={onSlideChange}
              initialSlide={noRobots ? 0 : computeSliderIndex(location, state)}
              creativeEffect={{
                prev: {
                  scale: 0.9,
                  translate: ['-98%', 0, 0],
                },
                next: {
                  scale: 0.9,
                  translate: ['98%', 0, 0],
                },
              }}
              grabCursor
            >
              {noRobots && (
                <SwiperSlide data-role={'add'}>
                  <AddBotCard />
                </SwiperSlide>
              )}
              {!noRobots && (
                <>
                  <SwiperSlide data-role={'summary'}>
                    <BalanceCard
                      onlyTotalIncome
                      alwaysShowIncome={state.robotSummaries.length > 0}
                      balances={computeBalances(state)}
                      moneyCurrency={'USDT'}
                      name={intl.formatMessage({
                        id: 'common.total',
                        defaultMessage: 'Total',
                      })}
                      totalMoney={state.aggregatedUsd}
                      income={ifSuccessful(
                        robot.chartData,
                        (d) => d.deltaSum / Math.pow(10, 8),
                        undefined,
                      )}
                      incomePercent={ifSuccessful(
                        robot.chartData,
                        (d) => d.deltaPercent,
                        undefined,
                      )}
                      notifications={hasNewNotifications ? 'has-new' : 'no-new'}
                      onNotificationsClick={() =>
                        navigate('/app/notifications')
                      }
                    />
                  </SwiperSlide>
                  {state.robotSummaries.map((item) => (
                    <SwiperSlide
                      data-role={'robot'}
                      data-robot={item.id}
                      key={item.id}
                    >
                      <BalanceCard
                        alwaysShowIncome={ifSuccessful(
                          robot.chartData,
                          (d) => d.closedCount != 0 || d.openCount != 0,
                          undefined,
                        )}
                        balances={[
                          {
                            asset: (item.balance?.asset ?? -1) / 100000000,
                            money: (item.balance?.money ?? -1) / 100000000,
                            assetCoin: item.pair.asset,
                            moneyCoin: item.pair.money,
                          },
                        ]}
                        moneyCurrency={item.pair.money}
                        name={
                          item.name ??
                          intl.formatMessage(
                            MarketUtils.formatMarket(item.market),
                          )
                        }
                        onNameClick={() =>
                          navigate('/app/robot/' + item.id + '/edit-name')
                        }
                        totalMoney={
                          ((item.balance?.asset ?? 0) / 100000000) *
                            (item.balance?.price ?? 0) +
                          (item.balance?.money ?? -1) / 100000000
                        }
                        income={ifSuccessful(
                          robot.chartData,
                          (d) => d.deltaSum / Math.pow(10, 8),
                          undefined,
                        )}
                        incomePercent={ifSuccessful(
                          robot.chartData,
                          (d) => d.deltaPercent,
                          undefined,
                        )}
                        actions={(open) => (
                          <RobotCardActions id={item.id} modalOpen={open} />
                        )}
                      />
                    </SwiperSlide>
                  ))}
                  <SwiperSlide data-role={'add'}>
                    <AddBotCard />
                  </SwiperSlide>
                </>
              )}
            </Swiper>
            <Spacer size={1} />
            <Outlet />
          </>
        )}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default inject(
  'robot',
  'notifications',
)(observer(RobotDashboardFragment)) as unknown as React.FC;
