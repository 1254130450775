import React from 'react';
import cls from './style.module.scss';

interface Props {
  defaultChecked?: boolean;
  onChanged?: (value: boolean) => void;
  disabled?: boolean;
}

const Switch: React.FunctionComponent<Props> = ({
  defaultChecked,
  onChanged,
  disabled,
}) => (
  <label className={cls.switch + ' ' + (disabled && cls.disabled)}>
    <input
      type={'checkbox'}
      disabled={disabled}
      defaultChecked={defaultChecked}
      onChange={onChanged && ((e) => onChanged(e.target.checked))}
    />
    <span className={cls.slider} />
  </label>
);

export default Switch;
