import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import scrollDown from '../../../../../assets/onboarding/binance/apikeys/scoll-down.png';
import apiKeysService from '../../../../../assets/onboarding/binance/apikeys/api-keys-service.png';

const GoToApiKeysFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.GoToApiKeysFragment.title',
        defaultMessage:
          'Scroll down and click on <h>API Management</h> service',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={scrollDown} second={apiKeysService} />
  </>
);

export default GoToApiKeysFragment;
