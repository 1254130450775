import SubscriptionStore from '../SubscriptionStore';
import {
  CurrentSubscriptionDetailsPeriodEnum,
  GlobalSubscriptionInfo,
  SubscriptionInfo,
} from '../../api';
import {notLoaded, wrapRefreshablePromise} from '../../utils';
import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import ApiStore from '../ApiStore';
import {debounce} from 'ts-debounce';

class RealSubscriptionStore implements SubscriptionStore {
  info: RefreshableAsyncState<SubscriptionInfo> = notLoaded();
  pricingInfo: RefreshableAsyncState<GlobalSubscriptionInfo> = notLoaded();

  constructor() {
    makeAutoObservable(this, {
      info: observable.deep,
      init: action,
      setAutoRenew: action.bound,
    });
  }

  init() {
    void this.loadInfo();
    void this.loadPricing();
  }

  private async loadInfo() {
    await wrapRefreshablePromise(
      ApiStore.subscription.getSubscriptionInfo().then((value) => value.data),
      this.info,
      (v) =>
        runInAction(() => {
          this.info = v;
        }),
    );
  }

  private async loadPricing() {
    await wrapRefreshablePromise(
      ApiStore.subscription
        .getSubscriptionTypesInfo()
        .then((value) => value.data),
      this.pricingInfo,
      (v) =>
        runInAction(() => {
          this.pricingInfo = v;
        }),
    );
  }

  async subscribeBusiness(): Promise<void> {
    const result = await ApiStore.subscription.subscribeBusiness();
    runInAction(() => {
      this.info = {status: 'success', result: result.data};
    });
  }

  setAutoRenew = debounce((checked: boolean, onError: (e: Error) => void) => {
    return ApiStore.subscription
      .editSubscription({
        editCurrentSubscription: {autoRenew: checked},
      })
      .then((result) =>
        runInAction(
          () => (this.info = {status: 'success', result: result.data}),
        ),
      )
      .catch(onError);
  }, 1000);

  async setPeriod(period: CurrentSubscriptionDetailsPeriodEnum): Promise<void> {
    const result = await ApiStore.subscription.editSubscription({
      editCurrentSubscription: {period},
    });
    runInAction(() => (this.info = {status: 'success', result: result.data}));
  }
}

export default RealSubscriptionStore;
