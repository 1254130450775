import {ExchangeConnectionStore} from '../ExchangeConnectionStore';
import {
  ifSuccessful,
  mapRefreshableValues,
  notLoaded,
  wrapRefreshablePromise,
} from '../../utils';
import {
  ClusterIpList,
  CreateConnectionRequestExchangeEnum,
  EditExchangeConnectionRequest,
  ExchangeConnectionDetails,
  ExchangeConnectionItem,
  ExchangeConnectionPublicKey,
} from '../../api';
import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import ApiStore from '../ApiStore';
import ApiKeysStorage from '../../storage/ApiKeysStorage';
import SodiumUtils from '../../utils/SodiumUtils';
import CurrentExchangeConnectionStorage from '../../storage/CurrentExchangeConnectionStorage';
import {parseISO} from 'date-fns';

export default class RealExchangeConnectionStore
  implements ExchangeConnectionStore
{
  clusterIps: RefreshableAsyncState<ClusterIpList> = notLoaded();

  publicKey: RefreshableAsyncState<ExchangeConnectionPublicKey> = notLoaded();

  connections: RefreshableAsyncState<ExchangeConnectionItem[]> = notLoaded();

  constructor() {
    makeAutoObservable(this, {
      clusterIps: observable,
      publicKey: observable,
      ensureClusterIpsLoaded: action.bound,
    });
  }

  ensureClusterIpsLoaded(): void {
    void wrapRefreshablePromise(
      ApiStore.exchangeConnection.getClusterIps().then((value) => value.data),
      this.clusterIps,
      (v) => runInAction(() => (this.clusterIps = v)),
    );
  }

  ensureEncryptorLoaded(): void {
    void wrapRefreshablePromise(
      SodiumUtils.ensureLoaded()
        .then(() =>
          ApiStore.exchangeConnection.getExchangeConnectionPublicKey(),
        )
        .then((value) => value.data),
      this.publicKey,
      (v) => runInAction(() => (this.publicKey = v)),
    );
  }

  loadConnections(): void {
    void wrapRefreshablePromise(
      ApiStore.exchangeConnection
        .getExchangeConnections()
        .then((value) => value.data.connections),
      this.connections,
      (v) =>
        runInAction(
          () =>
            (this.connections = mapRefreshableValues(v, (v1) =>
              v1.sort(
                (a, b) => +parseISO(b.createdAt) - +parseISO(a.createdAt),
              ),
            )),
        ),
    );
  }

  setFormApiKey(key: string): boolean {
    const pk = ifSuccessful(this.publicKey, (t) => t, undefined);
    if (!pk) {
      return false;
    }
    ApiKeysStorage.get().setApiKey(key, pk);
    return true;
  }

  setFormApiSecret(key: string): boolean {
    const pk = ifSuccessful(this.publicKey, (t) => t, undefined);
    if (!pk) {
      return false;
    }
    ApiKeysStorage.get().setApiSecret(key, pk);
    return true;
  }

  createOrEditConnection(
    exchange: CreateConnectionRequestExchangeEnum,
  ): Promise<ExchangeConnectionDetails> {
    const keys = ApiKeysStorage.get().get();
    if (!keys) {
      return Promise.reject(new Error('API keys not set!'));
    }
    if (!keys.key) {
      return Promise.reject(new Error('API Key not set!'));
    }
    if (!keys.secret) {
      return Promise.reject(new Error('API secret not set!'));
    }

    const currentId = CurrentExchangeConnectionStorage.get().id;
    if (currentId) {
      return ApiStore.exchangeConnection
        .updateExchangeConnectionApiKeys({
          id: currentId,
          updateApiKeysRequest: {
            apiKey: keys.key,
            apiSecret: keys.secret,
            publicKey: {
              rev: keys.pk.rev,
              uid: keys.pk.uid,
            },
          },
        })
        .then((value) => {
          ApiKeysStorage.get().reset();
          return value.data;
        });
    } else {
      return ApiStore.exchangeConnection
        .createExchangeConnection({
          createConnectionRequest: {
            exchange: exchange,
            apiKey: keys.key,
            apiSecret: keys.secret,
            publicKey: {
              rev: keys.pk.rev,
              uid: keys.pk.uid,
            },
          },
        })
        .then((value) => {
          ApiKeysStorage.get().reset();
          return value.data;
        });
    }
  }

  resetForm(): void {
    ApiKeysStorage.get().reset();
    CurrentExchangeConnectionStorage.get().id = undefined;
  }

  getDetails(id: string): Promise<ExchangeConnectionDetails> {
    return ApiStore.exchangeConnection
      .getExchangeConnection({id})
      .then((value) => value.data);
  }

  refreshConnection(id: string): Promise<void> {
    return ApiStore.exchangeConnection
      .refreshExchangeConnection({id})
      .then(() => {});
  }

  editConnection(
    id: string,
    args: EditExchangeConnectionRequest,
  ): Promise<void> {
    return ApiStore.exchangeConnection
      .editExchangeConnection({id, editExchangeConnectionRequest: args})
      .then(() => {});
  }
}
