import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import makePayment from '../../../../../assets/onboarding/binance/p2p/make-payment.png';
import confirmPayment from '../../../../../assets/onboarding/binance/p2p/confirm-payment.png';

const PayFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.PayFragment.title',
        defaultMessage:
          'Check order details and press <h>Make Payment</h> button. Then, <h>transfer money</h> to specified bank account and press <h>Transferred, notify seller</h> button',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={makePayment} second={confirmPayment} />
  </>
);

export default PayFragment;
