import {createContext} from 'react';
import {HText} from '../../typo/Typography';

export interface SnackbarContext {
  showInfo(text: HText, delay: 'short' | 'long' | number, key?: string): void;
  showWarning(
    text: HText,
    delay: 'short' | 'long' | number,
    key?: string,
  ): void;
  showError(text: HText, delay: 'short' | 'long' | number, key?: string): void;
}

export const Ctx = createContext<SnackbarContext>({
  showError: () => {},
  showInfo: () => {},
  showWarning: () => {},
});
