import React from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import {FieldConfig, useField, useFormikContext} from 'formik';
import Switch from '../../ui/Switch';
import cls from './style.module.scss';

interface Props {
  header?: MessageDescriptor;
  className?: string;
  dependsOn?: string;
}

function SwitchField(props: Props & FieldConfig<boolean>): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [field, , helpers] = useField<boolean>(props);
  const ctx = useFormikContext();
  return (
    <div className={cls.container}>
      <span className={props.className}>
        <FormattedMessage {...props.header} />
      </span>
      <Switch
        defaultChecked={field.value}
        onChanged={(value) => helpers.setValue(value)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        disabled={props.dependsOn && !ctx.values[props.dependsOn]}
      />
    </div>
  );
}

export default React.memo(SwitchField);
