import {MarketNameEnum} from '../../../../api';
import {FC} from 'react';
import Typography from '../../typo/Typography';
import Button from '../../button/Button';
import MarketUtils from '../../../../utils/MarketUtils';
import cls from './style.module.scss';
import AppIcon from '../../graphics/AppIcon';
import {App} from '../../../../utils/AppUtils';

interface Props {
  onSelect: (exchange: MarketNameEnum) => void;
}

const SelectExchangeButtonSet: FC<Props> = ({onSelect}) => (
  <div className={cls.container}>
    <Typography
      text={{
        id: 'components.SelectExchangeButtonSet.text',
        defaultMessage: 'Select exchange to continue',
      }}
      className={cls.text}
    />
    <div className={cls.content}>
      {MarketUtils.MARKETS.map((value) => (
        <Button
          text={MarketUtils.formatMarket(value)}
          key={value}
          type={'default'}
          onClick={() => onSelect(value)}
          icon={(size) => <AppIcon size={size} market={value as App} />}
        />
      ))}
    </div>
  </div>
);

export default SelectExchangeButtonSet;
