import React from 'react';
import cls from './style.module.scss';
import {FormattedMessage} from 'react-intl';

const NoInfoContent: React.FunctionComponent = () => (
  <div className={cls.centerMain}>
    <h6 className={cls.balanceCrypto}>
      <FormattedMessage
        defaultMessage={'No information available'}
        id={'component.AssetDistributionCard.noInfoHeader'}
      />
    </h6>
    <p className={'caption'}>
      <FormattedMessage
        defaultMessage={'You should start robot to get asset distribution'}
        id={'component.AssetDistributionCard.noInfoText'}
      />
    </p>
  </div>
);

export default React.memo(NoInfoContent);
