import React from 'react';
import Scaffolding from '../../component/container/Scaffolding';
import BottomTabs from '../../component/ui/BottomTabs';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

const DashboardFragment: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Scaffolding
      bottomTabs={
        <BottomTabs
          selected={location.pathname.endsWith('profile') ? 'profile' : 'home'}
          onSelect={(tab) => {
            if (tab === 'profile') {
              navigate('/app/dashboard/profile');
            } else {
              navigate('/app/dashboard');
            }
          }}
        >
          <BottomTabs.Tab
            id={'home'}
            icon={'home'}
            selectedIcon={'home-selected'}
          />
          <BottomTabs.Tab
            id={'profile'}
            icon={'profile'}
            selectedIcon={'profile-selected'}
          />
        </BottomTabs>
      }
    >
      <Outlet />
    </Scaffolding>
  );
};

export default DashboardFragment;
