import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import openFunding from '../../../../../assets/onboarding/binance/p2p/open-funding.png';
import transferToSpot from '../../../../../assets/onboarding/binance/p2p/transfer-to-spot.png';

const TransferToSpotFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.TransferToSpotFragment.title',
        defaultMessage:
          'In <h>Wallet</h> tab, open <h>Funding wallet</h> and press <h>Transfer</h> button. Ensure you are transferring funds to <h>Spot</h> account, press <h>Max</h> to set max amount and press <h>Confirm Transfer</h>',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={openFunding} second={transferToSpot} />
  </>
);

export default TransferToSpotFragment;
