import React from 'react';
import cls from './style.module.scss';
import Card from '../Card';
import {RobotTradeChart} from '../../../api';
import AsyncStateWrapper from '../AsyncStateWrapper';
import {observer} from 'mobx-react';
import Icon from '../Icon';
import {addMonths, parseISO} from 'date-fns';
import {FormattedDate, FormattedMessage} from 'react-intl';
import {Line} from 'react-chartjs-2';
import {
  ChartOptions,
  ScatterDataPoint,
  TooltipItem,
  TooltipModel,
} from 'chart.js';
import {COLOR_PRIMARY, COLOR_TEXT} from '../../../config';

interface Props {
  date: Date;
  onChange: (date: Date) => void;
  chart: AsyncState<RobotTradeChart> | RefreshableAsyncState<RobotTradeChart>;
  onFooterClick?: () => void;
  moneyCurrency: string;
}

interface Point extends ScatterDataPoint {
  label: string;
}

function convertPointsHour(
  history: RobotTradeChart,
  moneyCurrency: string,
): [Point[], number, number] {
  const ret = [];
  let minY = 999999999;
  let maxY = -999999999;
  for (const point of history.points) {
    const datetime = parseISO(point.timestamp);
    const timestamp = datetime.getTime();
    const val = point.money / Math.pow(10, 8);
    const toShow = Math.round(val * 100) / 100;

    if (val > maxY) {
      maxY = val;
    }
    if (val < minY) {
      minY = val;
    }

    ret.push({
      x: timestamp,
      y: val,
      label: `${toShow.toString(10)} ${moneyCurrency}`,
    });
  }
  return [ret, minY, maxY];
}

function getOptions(
  points: Point[],
  minY: number,
  maxY: number,
): ChartOptions<'line'> {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: points.length < 100 ? undefined : false,
    spanGaps: true,
    interaction: {
      mode: 'nearest',
    },
    parsing: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parsing: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      normalized: true,
    },
    elements: {
      point: {
        hitRadius: 6,
      },
      line: {
        tension: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        backgroundColor: COLOR_PRIMARY,
        callbacks: {
          label: (_: TooltipModel<'line'>, ctx: TooltipItem<'line'>) =>
            (ctx?.raw as Point)?.label,
        },
        xAlign: 'center',
        yAlign: 'bottom',
        titleFont: {
          weight: 'normal',
        },
        bodyFont: {
          weight: 'bold',
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
        pan: {
          enabled: true,
          mode: 'xy',
        },
        limits: {
          x: {
            min: points.length > 0 ? points[0].x : undefined,
            max: points.length > 0 ? points[points.length - 1].x : undefined,
          },
          y: {
            min: minY - 20,
            max: maxY + 20,
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        ticks: {
          maxTicksLimit: 6,
          maxRotation: 0,
        },
        time: {
          tooltipFormat: "d 'at' HH:mm",
          displayFormats: {
            millisecond: 'HH:mm:ss.SSS',
            second: 'HH:mm:ss',
            minute: 'dd HH:mm',
            hour: 'dd HH:mm',
          },
        },
        grid: {
          color: COLOR_TEXT + '22',
          // borderColor: COLOR_TEXT + '22',
          // drawBorder: true,
        },
      },
      y: {
        display: false,
      },
    },
  };
}

const OrderStatsCard: React.FunctionComponent<Props> = ({
  date,
  onChange,
  chart,
  onFooterClick,
  moneyCurrency,
}) => (
  <Card className={cls.card}>
    <AsyncStateWrapper state={chart}>
      {(chart) => {
        const [points, minY, maxY] = convertPointsHour(chart, moneyCurrency);
        return (
          <>
            <div className={cls.header}>
              <div
                className={cls.iconContainer}
                onClick={() => onChange(addMonths(date, -1))}
              >
                <Icon name={'chevron-left'} size={9} />
              </div>
              <span className={cls.month}>
                <FormattedDate value={date} year={'numeric'} month={'long'} />
              </span>
              <div
                className={cls.iconContainer}
                onClick={() => onChange(addMonths(date, 1))}
              >
                <Icon name={'chevron-right'} size={9} />
              </div>
            </div>
            <div className={cls.chart}>
              <Line
                options={getOptions(points, minY, maxY)}
                data={{
                  datasets: [
                    {
                      label: '',
                      data: points,
                      fill: false,
                      backgroundColor: 'transparent',
                      borderWidth: 2,
                      pointRadius: 1.5,
                      pointHoverRadius: 1.5,
                      pointBorderColor: COLOR_TEXT,
                      borderColor: COLOR_TEXT,
                      tension: 0.1,
                    },
                  ],
                }}
              />
            </div>
            <div className={cls.footer} onClick={onFooterClick}>
              <span className={cls.footerText}>
                <FormattedMessage
                  id={'components.OrderStatsCard.closedOrders'}
                  defaultMessage={'Orders: closed '}
                />
                <b>{chart.closedCount}</b>
              </span>
              <span className={cls.footerText}>
                <FormattedMessage
                  id={'components.OrderStatsCard.openOrders'}
                  defaultMessage={'open '}
                />
                <b>{chart.openCount}</b>
              </span>
            </div>
            {points.length === 0 && (
              <span className={cls.noData}>
                <FormattedMessage
                  id={'common.noData'}
                  defaultMessage={'No data available'}
                />
              </span>
            )}
          </>
        );
      }}
    </AsyncStateWrapper>
  </Card>
);

export default observer(OrderStatsCard);
