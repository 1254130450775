import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import Icon from '../../graphics/Icon';
import cls from './style.module.scss';

interface Props {
  text: HText;
}

const Feature: FC<Props> = ({text}) => (
  <div className={cls.item}>
    <Icon name={'checkmark'} size={24} tint={'primary'} />
    <Typography text={text} className={cls.text} />
  </div>
);

export default Feature;
