import React from 'react';

interface BottomActionSheetContextType {
  onDismiss?: () => void;
}

const BottomActionSheetContext =
  React.createContext<BottomActionSheetContextType>({});

export default BottomActionSheetContext;
