import {Storage} from './Storage';
import StorageManager from './StorageManager';
import Logger from '../manager/Logger';
import StorageBackend from './backend/StorageBackend';
import LocalStorageBackend from './backend/LocalStorageBackend';
import {WalletTransactionItemCauseEnum} from '../api';

class WalletFilterStorage implements Storage {
  private static instance: WalletFilterStorage;
  private readonly backend: StorageBackend = new LocalStorageBackend(
    'wallet/filter',
  );

  static get(): WalletFilterStorage {
    if (!this.instance) {
      this.instance = new WalletFilterStorage();
      StorageManager.addStorage('walletFilter', this.instance);
      Logger.debug(
        'WalletFilterStorage',
        'start',
        'WalletFilterStorage started',
      );
    }
    return this.instance;
  }

  getCauseFilter(): WalletTransactionItemCauseEnum[] | undefined {
    const f = this.backend.getString('causeFilter');
    if (f) {
      return JSON.parse(f);
    } else {
      return undefined;
    }
  }

  setCauseFilter(filter: WalletTransactionItemCauseEnum[] | undefined) {
    if (filter) {
      this.backend.set('causeFilter', JSON.stringify(filter));
    } else {
      this.backend.delete('causeFilter');
    }
  }

  getDateDelta(): number | undefined {
    const f = this.backend.getString('dateDelta');
    if (f) {
      return parseInt(f);
    } else {
      return undefined;
    }
  }

  setDateDelta(delta: number | undefined) {
    console.log(delta);
    if (delta) {
      this.backend.set('dateDelta', delta.toString(10));
    } else {
      this.backend.delete('dateDelta');
    }
  }

  onUserLogout() {
    this.backend.delete('causeFilter');
    this.backend.delete('dateDelta');
  }
}

export default WalletFilterStorage;
