/* eslint-disable @typescript-eslint/ban-ts-comment,prefer-rest-params */
// https://github.com/facebook/react/issues/11538#issuecomment-417504600
import Logger from './manager/Logger';

function patch11538() {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    // @ts-ignore
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        Logger.devError(
          'Monkeypatching',
          '#11538',
          'Cannot remove a child from a different parent',
          child,
          this,
        );
        return child;
      }
      // @ts-ignore
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    // @ts-ignore
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        Logger.devError(
          'Monkeypatching',
          '#11538',
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this,
        );
        return newNode;
      }
      // @ts-ignore
      return originalInsertBefore.apply(this, arguments);
    };

    Logger.debug('Monkeypatching', '#11538', 'Patches installed');
  }
}

export default function run() {
  patch11538();
}
