import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import cls from './style.module.scss';
import Icon from '../../graphics/Icon';

interface Props {
  text: HText;
}

const InfoBanner: FC<Props> = ({text}) => (
  <div className={cls.container}>
    <Icon name={'info'} size={24} />
    <Typography text={text} className={cls.text} />
  </div>
);

export default InfoBanner;
