import {FieldConfig, useField, useFormikContext} from 'formik';
import AmountInput, {Props as InputProps} from '../../input/AmountInput';
import {memo, useState} from 'react';

interface Props extends Omit<Omit<InputProps, 'value'>, 'onChange'> {
  submitForm?: boolean;
}

function AmountField({
  ...props
}: Props & FieldConfig<number>): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [field, meta, helpers] = useField<number>(props);
  const context = useFormikContext();
  const [val, setVal] = useState('');
  return (
    <AmountInput
      {...props}
      value={val}
      error={meta.error}
      onChange={(text) => {
        setVal(text);
        const v = props.decimals === 0 ? parseInt(text) : parseFloat(text);
        helpers.setValue(v);
      }}
      onBlur={() => field.onBlur(field.name)}
      onSubmitEditing={
        props.submitForm ? () => void context.submitForm() : undefined
      }
    />
  );
}

export default memo(AmountField);
