import {
  MarketNameEnum,
  RobotCreateRequestMarketEnum,
  RobotInfoMarketEnum,
  RobotSummaryMarketEnum,
} from '../../../../api';
import {FC} from 'react';
import BinanceLogo from './BinanceLogo';
import BybitLogo from './BybitLogo';
import HuobiLogo from './HuobiLogo';

interface Props {
  market:
    | RobotInfoMarketEnum
    | RobotSummaryMarketEnum
    | RobotCreateRequestMarketEnum
    | MarketNameEnum;
}

const MarketLogo: FC<Props> = ({market}) => {
  switch (market) {
    case 'BINANCE':
      return <BinanceLogo />;
    case 'BYBIT':
      return <BybitLogo />;
    case 'HUOBI':
      return <HuobiLogo />;
  }
};

export default MarketLogo;
