import {FC} from 'react';
import Typography, {HText} from '../../typo/Typography';
import Icon, {IconName} from '../../graphics/Icon';
import cls from './style.module.scss';

interface Props {
  color: 'primary' | 'yellow' | 'red';
  text: HText;
  icon?: IconName;
}

const StatusHeader: FC<Props> = ({color, icon, text}) => (
  <div
    className={
      cls.header +
      ' ' +
      (color === 'primary' && cls.primary) +
      ' ' +
      (color === 'yellow' && cls.yellow) +
      ' ' +
      (color === 'red' && cls.red)
    }
  >
    {icon && (
      <Icon
        name={icon}
        size={24}
        tint={color === 'yellow' ? 'black' : 'white'}
      />
    )}
    <Typography text={text} />
  </div>
);

export default StatusHeader;
