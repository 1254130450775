import React, {FC, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';
import {useParams} from 'react-router-dom';
import AsyncStateWrapper from '../../../component/ui/AsyncStateWrapper';
import Scaffolding from '../../../component/container/Scaffolding';
import ExchangeConnectionItemViewModel from '../../../viewmodel/exchange-connection/ExchangeConnectionItemViewModel';
import {default as C} from '../../../component/design/container/ExchangeConnectionContainer';
import TextInput from '../../../component/design/input/TextInput';
import {parseISO, format} from 'date-fns';
import {
  AdviceIpWhitelistAdviceLevelEnum,
  AdvicePermissionAdviceLevelEnum,
  ExchangeConnectionDetailsStateEnum,
  ExchangeConnectionStatusIpWhitelistModeEnum,
} from '../../../api';
import ReadOnlyInput from '../../../component/design/input/ReadOnlyInput';
import ExchangeConnectionUtils from '../../../utils/ExchangeConnectionUtils';
import ExchangeConnectionStateHeader from '../../../component/design/data/ExchangeConnectionStateHeader';
import {useSnackbar} from '../../../component/design/container/SnackbarContainer';

const ExchangeConnectionItemScreen: FC = () => {
  const params = useParams();
  const snack = useSnackbar();
  const vm = useMemo(
    () =>
      new ExchangeConnectionItemViewModel(
        params.id ?? '',
        (err) => snack.showError(err, 'short', 'ec-save-name-err'),
        () =>
          snack.showInfo(
            {
              id: 'screens.ExchangeConnectionItemScreen.nameSaved',
              defaultMessage: 'Connection name synchronized',
            },
            'short',
            'ec-save-name-ok',
          ),
      ),
    [params.id],
  );
  useEffect(() => {
    vm.init();
    return () => vm.stopUpdate();
  }, [vm]);

  return (
    <Scaffolding
      header={{
        id: 'screens.ExchangeConnectionItemScreen.header',
        defaultMessage: 'Connection info',
      }}
    >
      <AsyncStateWrapper state={vm.request}>
        {(req) => (
          <C header={<ExchangeConnectionStateHeader state={req.state} />}>
            <TextInput
              value={vm.name}
              label={{id: 'common.name', defaultMessage: 'Name'}}
              onChange={vm.setName}
            />
            {req.status?.apiKey && (
              <ReadOnlyInput
                value={req.status.apiKey}
                label={{id: 'common.apiKey', defaultMessage: 'API Key'}}
              />
            )}
            <C.Split>
              <C.Item
                name={{id: 'common.userId', defaultMessage: 'User ID'}}
                value={req.status?.userId}
              />
              <C.ExchangeItem
                name={{id: 'common.exchange', defaultMessage: 'Exchange'}}
                value={req.exchange}
              />
            </C.Split>
            <C.Split>
              <C.Item
                name={{id: 'common.createdAt', defaultMessage: 'Created at'}}
                value={format(parseISO(req.createdAt), 'dd LLL yyyy, HH:mm')}
              />
              {req.status?.expiresAt && (
                <C.ExpiresItem
                  name={{id: 'common.expiresAt', defaultMessage: 'Expires at'}}
                  value={parseISO(req.status?.expiresAt)}
                  modifier={
                    req.state === ExchangeConnectionDetailsStateEnum.Expired
                      ? 'expired'
                      : req.state ===
                        ExchangeConnectionDetailsStateEnum.ExpiresSoon
                      ? 'soon'
                      : undefined
                  }
                />
              )}
            </C.Split>
            {req.status?.permissions && (
              <C.Permissions>
                {req.status.permissions.map((p) => (
                  <C.Permission
                    key={p.knownPermission ?? p.permissionName}
                    enabled={p.enabled}
                    name={ExchangeConnectionUtils.localizePermission(p)}
                    adviceLevel={
                      p.advice
                        ? p.advice.level ===
                          AdvicePermissionAdviceLevelEnum.Critical
                          ? 'error'
                          : 'warning'
                        : undefined
                    }
                    advice={
                      p.advice
                        ? ExchangeConnectionUtils.localizeAdvice(
                            p.advice.advice,
                          )
                        : undefined
                    }
                  />
                ))}
              </C.Permissions>
            )}
            {req.status?.note && (
              <C.Item
                name={{id: 'common.note', defaultMessage: 'Note'}}
                value={req.status.note}
              />
            )}
            {req.status &&
              req.status.ipWhitelistMode ===
                ExchangeConnectionStatusIpWhitelistModeEnum.List && (
                <C.Ips>
                  {req.status.ipWhitelist.map((p) => (
                    <C.Ip
                      key={p.ip}
                      ip={p.ip}
                      adviceLevel={
                        p.advice
                          ? p.advice.level ===
                            AdviceIpWhitelistAdviceLevelEnum.Critical
                            ? 'error'
                            : 'warning'
                          : undefined
                      }
                      advice={
                        p.advice
                          ? ExchangeConnectionUtils.localizeAdvice(
                              p.advice.advice,
                            )
                          : undefined
                      }
                    />
                  ))}
                </C.Ips>
              )}
          </C>
        )}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default observer(ExchangeConnectionItemScreen);
