import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import checkVerify from '../../../../../assets/onboarding/binance/first/check-verify.png';

const CheckVerifyFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.CheckVerifyFragment.title',
        defaultMessage:
          'You can view identity check status in profile card. Also, it will send you a notification, when check is completed',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={checkVerify} />
  </>
);

export default CheckVerifyFragment;
