import React, {useEffect, useRef, useState} from 'react';
import cls from './style.module.scss';

interface Props {
  children: React.ReactNode[];
  delayMs: number;
}

const RowCarousel: React.FunctionComponent<Props> = ({children, delayMs}) => {
  const childrenCount = useRef(0);
  const currentRef = useRef(0);
  const [current, setCurrent] = useState(0);
  const [last, setLast] = useState(-1);
  const [animating, setAnimating] = useState(false);
  useEffect(() => {
    const int = setInterval(() => {
      setLast(currentRef.current);
      if (currentRef.current + 1 >= childrenCount.current) {
        setCurrent(0);
        currentRef.current = 0;
      } else {
        setCurrent(currentRef.current + 1);
        currentRef.current = currentRef.current + 1;
      }
      setAnimating(true);
    }, delayMs);
    const int1 = {
      interval: undefined as ReturnType<typeof setInterval> | undefined,
    };
    setTimeout(
      () => (int1.interval = setInterval(() => setAnimating(false), delayMs)),
      300,
    );
    return () => {
      clearInterval(int);
      if (int1.interval !== undefined) {
        clearInterval(int1.interval);
      }
    };
  }, [delayMs]);

  childrenCount.current = children.length;

  return (
    <div className={cls.container}>
      {animating && last !== -1 && (
        <div className={cls.item + ' ' + cls.hide}>{children[last]}</div>
      )}
      <div className={cls.item} key={current}>
        {children[current]}
      </div>
    </div>
  );
};

export default RowCarousel;
