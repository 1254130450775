import React from 'react';
import {RobotConfig} from '../../../api';
import Card from '../Card';
import cls from './style.module.scss';
import AsyncStateWrapper from '../AsyncStateWrapper';
import {FormattedMessage, MessageDescriptor} from 'react-intl';

interface Props {
  config: AsyncState<RobotConfig>;
  onClick?: () => void;
}

interface ItemProps {
  label: MessageDescriptor;
  value: string;
  valueClass?: string;
}

const Item: React.FunctionComponent<ItemProps> = ({
  label,
  value,
  valueClass,
}) => (
  <div className={cls.item}>
    <span className={cls.itemLabel}>
      <FormattedMessage {...label} />
    </span>
    <span className={cls.itemValue + ' ' + (valueClass ?? '')}>{value}</span>
  </div>
);

const BotConfigCard: React.FunctionComponent<Props> = ({config, onClick}) => (
  <Card className={cls.card} clickable onClick={onClick}>
    <AsyncStateWrapper state={config}>
      {(config) => (
        <>
          <Item
            label={{
              id: 'components.BotConfigCard.order',
              defaultMessage: 'Order',
            }}
            value={config.order / 100 + '%'}
          />
          <Item
            label={{
              id: 'components.BotConfigCard.buy',
              defaultMessage: 'Buy',
            }}
            value={'-' + config.buy / 100 + '%'}
            valueClass={cls.balanceDown}
          />
          <Item
            label={{
              id: 'components.BotConfigCard.sell',
              defaultMessage: 'Sell',
            }}
            value={'+' + config.sell / 100 + '%'}
            valueClass={cls.balanceUp}
          />
          <Item
            label={{
              id: 'components.BotConfigCard.correction',
              defaultMessage: 'Correction 10 min',
            }}
            value={config.correction10m / 100 + '%'}
          />
          <Item
            label={{
              id: 'components.BotConfigCard.buyOrderTime',
              defaultMessage: 'Buy order expiry time',
            }}
            value={config.sellOrderTimeout + ' hrs'}
          />
        </>
      )}
    </AsyncStateWrapper>
  </Card>
);

export default BotConfigCard;
