import {IntlShape} from 'react-intl';
import {FormatXMLElementFn, PrimitiveType} from 'intl-messageformat';
import {MessageDescriptor} from '@formatjs/intl/src/types';
import {HText} from '../component/design/typo/Typography';
import {Link} from 'react-router-dom';
import React from 'react';

type FormType = keyof FormTypeValues;

interface FormTypeValues {
  positive: undefined;
  'not-empty': undefined;
  'network-unsupported': {net: string};
  'address-invalid': {net: string};
  'at-least': {v: number};
}

class IntlUtils {
  static form<T extends FormType>(
    intl: IntlShape,
    type: T,
    values?: FormTypeValues[T],
  ): string {
    switch (type) {
      case 'not-empty':
        return intl.formatMessage({
          id: 'form.validation.notEmpty',
          defaultMessage: 'Should not be empty',
        });
      case 'positive':
        return intl.formatMessage({
          id: 'form.validation.mustBePositive',
          defaultMessage: 'Value must be positive',
        });
      case 'network-unsupported':
        return intl.formatMessage(
          {
            id: 'form.validation.networkUnsupported',
            defaultMessage: 'Network {net} unsupported',
          },
          values,
        );
      case 'address-invalid':
        return intl.formatMessage(
          {
            id: 'form.validation.addressInvalid',
            defaultMessage: 'Address does not belong to {net} network',
          },
          values,
        );
      case 'at-least':
        return intl.formatMessage(
          {
            id: 'form.validation.atLeast',
            defaultMessage: 'Value should not be less than {v}',
          },
          values,
        );
      default:
        return '!!!INVALID!!!';
    }
  }

  static withdrawalTitle(
    type: 'loading' | 'success' | 'error',
  ): MessageDescriptor {
    switch (type) {
      case 'success':
        return {
          id: 'wallet.withdrawal.title.success',
          defaultMessage: 'The sale was completed',
        };
      case 'loading':
        return {
          id: 'wallet.withdrawal.title.loading',
          defaultMessage: 'The sale is in progress',
        };
      case 'error':
        return {
          id: 'wallet.withdrawal.title.error',
          defaultMessage: 'Failed to sale RCO',
        };
    }
  }

  static withdrawalDescription(type: 'loading' | 'success'): MessageDescriptor {
    switch (type) {
      case 'loading':
        return {
          id: 'wallet.withdrawal.desc.loading',
          defaultMessage:
            'After sale was completed, you will receive notification email',
        };
      case 'success':
        return {
          id: 'wallet.withdrawal.desc.loading',
          defaultMessage:
            'You should not receive your USDT. If not, contact support immediately',
        };
    }
  }

  static acquisitionTitle(
    type: 'loading' | 'success' | 'error',
  ): MessageDescriptor {
    switch (type) {
      case 'success':
        return {
          id: 'wallet.acquisition.title.success',
          defaultMessage: 'Payment completed',
        };
      case 'loading':
        return {
          id: 'wallet.acquisition.title.loading',
          defaultMessage: 'Payment in progress',
        };
      case 'error':
        return {
          id: 'wallet.acquisition.title.error',
          defaultMessage: 'Transaction not found',
        };
    }
  }

  static acquisitionDescription(
    type: 'loading' | 'success' | 'error',
  ): MessageDescriptor {
    switch (type) {
      case 'loading':
        return {
          id: 'wallet.acquisition.desc.loading',
          defaultMessage:
            'After payment was completed, you will receive  your fund and an email.',
        };
      case 'success':
        return {
          id: 'wallet.acquisition.desc.loading',
          defaultMessage:
            'Payment completed. You should receive your funds by now',
        };
      case 'error':
        return {
          id: 'wallet.acquisition.desc.error',
          defaultMessage: 'Check entered TxID and try again',
        };
    }
  }

  static lowBalanceWarning(): HText {
    return {
      id: 'common.warning.lowBalance',
      defaultMessage:
        'Your balance is near zero! To keep your bots working, we recommend to <a>buy RCO</a>',
      values: {
        a: (parts) => (
          <Link to={'/app/wallet/buy'} className={'text-link'}>
            {parts}
          </Link>
        ),
      },
    };
  }

  static exchangeConnectionTitle(
    type: 'loading' | 'success' | 'error',
  ): MessageDescriptor {
    switch (type) {
      case 'success':
        return {
          id: 'exchangeConnection.title.success',
          defaultMessage: 'Connection successful',
        };
      case 'loading':
        return {
          id: 'exchangeConnection.title.loading',
          defaultMessage: 'Connection in progress',
        };
      case 'error':
        return {
          id: 'exchangeConnection.title.error',
          defaultMessage: 'Connection failed',
        };
    }
  }

  static exchangeConnectionDescription(
    type: 'loading' | 'creating-robot' | 'success',
  ): MessageDescriptor {
    switch (type) {
      case 'loading':
        return {
          id: 'exchangeConnection.desc.loading',
          defaultMessage: 'Fetching information from exchange, please wait...',
        };
      case 'creating-robot':
        return {
          id: 'exchangeConnection.desc.createRobot',
          defaultMessage: 'Creating robot, please wait...',
        };
      case 'success':
        return {
          id: 'exchangeConnection.desc.success',
          defaultMessage: 'Successfully connected to exchange',
        };
    }
  }

  static formatMessage(
    intl: IntlShape,
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
  ): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return intl.formatMessage(
      descriptor,
      {
        b: (chunks) => <b>{chunks}</b>,
        ...(values ?? {}),
      },
      undefined,
    );
  }
}

export default IntlUtils;
