import {useEffect, useState} from 'react';

export function useErrorAutoReset(
  timeout = 2000,
): [boolean, () => void, () => void] {
  const [applyErrored, setApplyErrored] = useState(false);
  useEffect(() => {
    const bringBack = setTimeout(() => setApplyErrored(false), timeout);
    return () => clearTimeout(bringBack);
  }, [applyErrored]);
  return [
    applyErrored,
    () => setApplyErrored(true),
    () => setApplyErrored(false),
  ];
}

export function useStateErrorAutoReset(
  state: AsyncState<unknown>,
  timeout = 2000,
): boolean {
  const [err, setErr, clearErr] = useErrorAutoReset(timeout);
  useEffect(() => {
    if (state.status === 'error') {
      setErr();
    } else {
      clearErr();
    }
  }, [state]);
  return err;
}
