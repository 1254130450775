import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/huobi/first/verify.png';
import v2 from '../../../../../assets/onboarding/huobi/first/enter-data.png';

const VerifyFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.huobi.VerifyFragment.title',
        defaultMessage:
          'In <h>Level 1</h> card, press <h>Verify</h>. After, enter your personal details, <h>submit form</h> and wait for verification. It is instant, but sometimes can take from 5 minutes to 48 hours',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default VerifyFragment;
