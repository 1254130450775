import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import gotoVerify1 from '../../../../../assets/onboarding/binance/first/goto-verify-1.png';
import gotoVerify2 from '../../../../../assets/onboarding/binance/first/goto-verify-2.png';

const GoToVerifyFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.GoToVerifyFragment.title',
        defaultMessage:
          'Press on <h>profile icon</h> in upper left corner, then on <h>Action Required</h> button.\n\nThen, complete account verification. It will take from 15 minutes to 48 hours.',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={gotoVerify1} second={gotoVerify2} />
  </>
);

export default GoToVerifyFragment;
