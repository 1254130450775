import {RobotInfo} from '../../../../api';
import {FC} from 'react';
import KVCard from '../../container/KVCard';
import Amount from '../../typo/CoinBalance';
import DateTime from '../../typo/DateTime';

interface Props {
  robot?: RobotInfo;
  sellId: string;
  sellPrice: number;
  sellAmount: number;
  soldTimestamp?: string;
}

const TradeSellCard: FC<Props> = ({
  robot,
  sellId,
  soldTimestamp,
  sellPrice,
  sellAmount,
}) => (
  <KVCard>
    <KVCard.Item
      name={{id: 'common.trade.sellId', defaultMessage: 'Sell ID'}}
      value={sellId}
      copyable
      ellipsis
    />
    <KVCard.Item
      name={{id: 'common.trade.sellPrice', defaultMessage: 'Sell price'}}
      value={
        <Amount
          amount={sellPrice}
          coin={robot?.pair?.money ?? ''}
          maxDigits={8}
        />
      }
    />
    <KVCard.Item
      name={{id: 'common.trade.sellAmount', defaultMessage: 'Sell amount'}}
      value={<Amount amount={sellAmount} coin={robot?.pair?.asset ?? ''} />}
    />
    <KVCard.Item
      name={{id: 'common.trade.sellCost', defaultMessage: 'Sell cost'}}
      value={
        <Amount
          amount={sellPrice * sellAmount}
          coin={robot?.pair?.money ?? ''}
        />
      }
    />
    <KVCard.Item
      name={{id: 'common.trade.soldTimestamp', defaultMessage: 'Sold at'}}
      value={soldTimestamp ? <DateTime value={soldTimestamp} /> : undefined}
    />
  </KVCard>
);

export default TradeSellCard;
