import {WalletStore} from '../WalletStore';
import {
  AcquisitionRequest,
  WalletApiGetWalletCauseDistributionRequest,
  WalletApiGetWalletTransactionsRequest,
  WalletCauseDistribution,
  WalletInfo,
  WalletTransactionDetails,
  WalletTransactionList,
  WithdrawalRequest,
  WithdrawalRequestConfig,
  WithdrawalRequestStatusEnum,
} from '../../api';
import {notLoaded, sleep, wrapRefreshablePromise} from '../../utils';
import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import ApiStore from '../ApiStore';

class RealWalletStore implements WalletStore {
  wallet: RefreshableAsyncState<WalletInfo> = notLoaded();
  withdrawalRequestConfig: RefreshableAsyncState<WithdrawalRequestConfig> =
    notLoaded();

  constructor() {
    makeAutoObservable(this, {
      wallet: observable,
      withdrawalRequestConfig: observable,
      init: action,
      initWithdrawal: action,
      loadWalletInfo: action,
    });
  }

  init() {
    this.loadWalletInfo();
  }

  initWithdrawal() {
    runInAction(() => {
      void wrapRefreshablePromise(
        ApiStore.wallet.withdrawalRequestConfig().then((value) => value.data),
        this.withdrawalRequestConfig,
        (v) => runInAction(() => (this.withdrawalRequestConfig = v)),
      );
    });
  }

  loadWalletInfo() {
    runInAction(() => {
      void wrapRefreshablePromise(
        ApiStore.wallet.getWalletInfo().then((value) => value.data),
        this.wallet,
        (v) => runInAction(() => (this.wallet = v)),
      );
    });
  }
  loadTransactionDetails(id: string): Promise<WalletTransactionDetails> {
    return ApiStore.wallet
      .getTransactionDetails({id})
      .then((value) => value.data);
  }

  loadTransactionPage(
    req: WalletApiGetWalletTransactionsRequest,
  ): Promise<WalletTransactionList> {
    return ApiStore.wallet
      .getWalletTransactions(req)
      .then((value) => value.data);
  }

  getWalletCauseDistribution(
    req: WalletApiGetWalletCauseDistributionRequest,
  ): Promise<WalletCauseDistribution> {
    return ApiStore.wallet
      .getWalletCauseDistribution(req)
      .then((value) => value.data);
  }

  async createAcquisitionRequest(txid: string): Promise<AcquisitionRequest> {
    return ApiStore.wallet
      .createAcquisitionRequest({
        createAcquisitionRequest: {transactionId: txid},
      })
      .then((value) => value.data);
  }

  async getAcquisitionRequest(id: string): Promise<AcquisitionRequest> {
    return ApiStore.wallet
      .getAcquisitionRequest({
        id,
      })
      .then((value) => value.data);
  }

  async createWithdrawal(
    address: string,
    amount: number,
    network: string,
  ): Promise<WithdrawalRequest> {
    let withdrawal = await ApiStore.wallet
      .createWithdrawalRequest({
        createRequestDto: {
          amount,
          network,
          address,
        },
      })
      .then((value) => value.data);
    while (withdrawal.status === WithdrawalRequestStatusEnum.Created) {
      await sleep(500);
      withdrawal = await ApiStore.wallet
        .getWithdrawalRequest({id: withdrawal.id})
        .then((value) => value.data);
    }
    if (withdrawal.status !== WithdrawalRequestStatusEnum.WaitingConfirmation) {
      if (withdrawal.error) {
        return Promise.reject(new Error(withdrawal.error));
      } else {
        return Promise.reject(new Error('Unknown error'));
      }
    }
    return withdrawal;
  }

  getWithdrawal(id: string): Promise<WithdrawalRequest> {
    return ApiStore.wallet
      .getWithdrawalRequest({id})
      .then((value) => value.data);
  }

  async confirmWithdrawal(
    id: string,
    code: string,
  ): Promise<WithdrawalRequest> {
    let withdrawal = await this.getWithdrawal(id);
    const initialTries = withdrawal.confirmTries;
    await ApiStore.wallet.confirmWithdrawalRequest({
      id,
      confirmRequestDto: {code},
    });
    while (
      withdrawal.confirmTries === initialTries &&
      withdrawal.status === WithdrawalRequestStatusEnum.WaitingConfirmation
    ) {
      await sleep(500);
      withdrawal = await this.getWithdrawal(id);
    }
    return withdrawal;
  }
}

export default RealWalletStore;
