import {FC, useEffect} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/huobi/apikeys/create-key.png';
import v2 from '../../../../../assets/onboarding/huobi/apikeys/edit-key.png';
import ReadOnlyInput from '../../../../../component/design/input/ReadOnlyInput';
import {inject, observer} from 'mobx-react';
import {ExchangeConnectionStore} from '../../../../../store/ExchangeConnectionStore';
import AsyncStateWrapper from '../../../../../component/ui/AsyncStateWrapper';

interface IProps {
  exchangeConnection: ExchangeConnectionStore;
}

const SetConfigFragment: FC<IProps> = ({exchangeConnection}) => {
  useEffect(() => {
    exchangeConnection.ensureClusterIpsLoaded();
  }, [exchangeConnection]);
  return (
    <AsyncStateWrapper state={exchangeConnection.clusterIps}>
      {(ips) => (
        <>
          <Subtitle
            text={{
              id: 'fragments.onboarding.huobi.SetConfigFragment.title',
              defaultMessage:
                'Press <h>Create API</h>. Enter key name, enable <h>trade setting</h>, <h>enter ips</h> from field below and press <h>Confirm</h>',
            }}
          />
          <Spacer size={1.5} />
          <ReadOnlyInput
            value={ips.exchangeOutbound.reduce((a, b) => a + ',' + b)}
            label={{
              id: 'onboarding.ips',
              defaultMessage: 'IP Addresses',
            }}
          />
          <Spacer size={1.5} />
          <SideBySideImage first={v1} second={v2} />
        </>
      )}
    </AsyncStateWrapper>
  );
};

export default inject('exchangeConnection')(observer(SetConfigFragment)) as FC<
  Partial<IProps>
>;
