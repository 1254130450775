import {FC} from 'react';
import WelcomeBanner from '../../component/design/banner/WelcomeBanner';
import SelectExchangeButtonSet from '../../component/design/misc/SelectExchangeButtonSet';
import {inject, observer} from 'mobx-react';
import OnboardingStore from '../../store/OnboardingStore';
import {AdvanceStateDtoActionEnum, AdvanceStateDtoStageEnum} from '../../api';
import {useNavigate} from 'react-router-dom';
import Spacer from '../../component/design/utils/Spacer';
import FlexCol from '../../component/design/container/FlexCol';

interface StoreProps {
  onboarding: OnboardingStore;
}

const OnboardingSelectMarketScreen: FC<StoreProps> = ({onboarding}) => {
  const nav = useNavigate();
  return (
    <FlexCol grow={1} height={'100%'}>
      <WelcomeBanner />
      <Spacer flex={1} />
      <SelectExchangeButtonSet
        onSelect={(exchange) => {
          onboarding.advance(
            AdvanceStateDtoStageEnum.FirstScreen,
            AdvanceStateDtoActionEnum.Pass,
            exchange,
          );
          nav(`/app/onboarding/${exchange.toLowerCase()}/exchange-register/0`);
        }}
      />
    </FlexCol>
  );
};

export default inject('onboarding')(
  observer(OnboardingSelectMarketScreen),
) as FC<Partial<StoreProps>>;
