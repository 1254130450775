import React, {useMemo} from 'react';
import {MarketPair, MarketPairAccessEnum, RobotPair} from '../../../api';
import BottomSheet from '../BottomSheet';
import cls from './style.module.scss';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import Tag from '../Tag';
import CoinIcon from '../../design/graphics/CoinIcon';
import Typography from '../../design/typo/Typography';

interface Props {
  pair: RobotPair;
  onChange: (pair: RobotPair) => void;
  open: boolean;
  onDismiss: () => void;
  pairs: MarketPair[];
  onPairSelect?: (pair: MarketPair) => boolean;
}

const BUSINESS_TAG: MessageDescriptor = {
  id: 'common.plans.business',
  defaultMessage: 'Business',
};

const TradingPairSelectSheet: React.FunctionComponent<Props> = ({
  pair,
  onChange,
  open,
  onDismiss,
  pairs,
  onPairSelect,
}) => {
  const assets = useMemo(
    () => [
      ...new Set(
        pairs.filter((value) => !value.hidden).map((value) => value.asset),
      ),
    ],
    [pairs],
  );
  const moneys = useMemo(
    () => [
      ...new Set(
        pairs.filter((value) => !value.hidden).map((value) => value.money),
      ),
    ],
    [pairs],
  );
  const pairMap = useMemo(
    () =>
      new Map(
        pairs
          .filter((value) => !value.hidden)
          .map((value) => [value.pair, value]),
      ),
    [pairs],
  );
  return (
    <BottomSheet open={open} onDismiss={onDismiss}>
      <div className={cls.container}>
        <div className={cls.header}>
          <span>
            <FormattedMessage
              id={'components.TradingPairSelectSheet.asset'}
              defaultMessage={'Asset'}
            />
          </span>
          <span>
            <FormattedMessage
              id={'components.TradingPairSelectSheet.money'}
              defaultMessage={'Money'}
            />
          </span>
        </div>
        <div className={cls.content}>
          <div className={cls.coinContainer}>
            {assets.map((value) => (
              <div
                key={value}
                className={
                  cls.coin +
                  ' ' +
                  (value === pair.asset && cls.selected) +
                  ' ' +
                  (!pairMap.has(value + pair.money) && cls.disabled)
                }
                onClick={() => {
                  if (pairMap.has(value + pair.money)) {
                    const p = pairMap.get(value + pair.money);
                    if (p && onPairSelect && !onPairSelect(p)) {
                      return;
                    }
                    onChange({asset: value, money: pair.money});
                  }
                }}
              >
                <span>
                  <CoinIcon coin={value} size={16} />
                  <Typography text={value.toUpperCase()} />
                </span>
                {pairMap.has(value + pair.money) &&
                  pairMap.get(value + pair.money)?.access ==
                    MarketPairAccessEnum.BusinessSub && (
                    <Tag label={BUSINESS_TAG} className={cls.businessTag} />
                  )}
              </div>
            ))}
          </div>
          <div className={cls.coinContainer}>
            {moneys.map((value) => (
              <div
                key={value}
                className={
                  cls.coin +
                  ' ' +
                  (value === pair.money && cls.selected) +
                  ' ' +
                  (!pairMap.has(pair.asset + value) && cls.disabled)
                }
                onClick={() => {
                  if (pairMap.has(pair.asset + value)) {
                    const p = pairMap.get(pair.asset + value);
                    if (p && onPairSelect && !onPairSelect(p)) {
                      return;
                    }
                    onChange({asset: pair.asset, money: value});
                  }
                }}
              >
                <span>
                  <CoinIcon coin={value} size={16} />
                  <Typography text={value.toUpperCase()} />
                </span>
                {pairMap.has(pair.asset + value) &&
                  pairMap.get(pair.asset + value)?.access ==
                    MarketPairAccessEnum.BusinessSub && (
                    <Tag label={BUSINESS_TAG} className={cls.businessTag} />
                  )}
              </div>
            ))}
          </div>
        </div>
        <div className={cls.closeButton} onClick={onDismiss}>
          <FormattedMessage id={'common.confirm'} defaultMessage={'Confirm'} />
        </div>
      </div>
    </BottomSheet>
  );
};

export default React.memo(TradingPairSelectSheet);
