import React from 'react';
import {PlacesType, Tooltip as NTooltip} from 'react-tooltip';
import cls from './style.module.scss';

interface Props {
  children: React.ReactNode;
  target: string;
  place?: PlacesType;
}

/**
 * @deprecated
 * @param children
 * @param target
 * @param place
 * @constructor
 */
const Tooltip: React.FunctionComponent<Props> = ({children, target, place}) => (
  <NTooltip anchorId={target} place={place ?? 'bottom'} className={cls.tooltip}>
    {children}
  </NTooltip>
);

export default Tooltip;
