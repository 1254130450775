import React from 'react';
import cls from './style.module.scss';
import Loader from '../Loader';
import Icon from '../Icon';
import Avatar from '../../design/misc/Avatar';

interface Props {
  name: string;
  level: string;
  photo: string;
  onAvatarClicked?: () => void;
  changingAvatar?: boolean;
  onProfileEditClick?: () => void;
}

const ProfileItem: React.FunctionComponent<Props> = ({
  name,
  onAvatarClicked,
  changingAvatar,
  level,
  photo,
  onProfileEditClick,
}) => {
  return (
    <div className={cls.container}>
      <div className={cls.imageContainer}>
        <Avatar
          size={64}
          src={photo}
          className={cls.image}
          onClick={onAvatarClicked}
        />
        {changingAvatar && (
          <>
            <div className={cls.backdrop}></div>
            <Loader className={cls.loader} size={48} />
          </>
        )}
      </div>
      <div className={cls.textContainer}>
        <h3 className={cls.name}>{name}</h3>
        <p className={cls.id}>Level: {level}</p>
      </div>
      <div className={cls.edit} onClick={onProfileEditClick}>
        <Icon name={'pencil'} size={24} clickable />
      </div>
    </div>
  );
};

export default React.memo(ProfileItem);
