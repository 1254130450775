import React, {useCallback, useState} from 'react';
import {RobotStatus, RobotSummaryStatusEnum, RobotWarning} from '../../api';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import Spacer from '../../component/design/utils/Spacer';
import WideButton from '../../component/ui/WideButton';
import {RobotStore} from '../../store/RobotStore';
import {inject, observer} from 'mobx-react';
import OrderStatsCard from '../../component/ui/OrderStatsCard';
import BotConfigCard from '../../component/ui/BotConfigCard';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {WalletStore} from '../../store/WalletStore';
import {ifSuccessful} from '../../utils';
import ArrayUtils from '../../utils/ArrayUtils';
import GuideContainer from '../../component/design/container/GuideContainer';

interface Props {
  robot: RobotStore;
  wallet: WalletStore;
}

function parseState(
  status: RobotStatus | undefined,
  wallet: WalletStore,
): MessageDescriptor | string {
  const state = status?.state;
  const title = status?.error?.title;
  if (!state) {
    return {id: 'common.robot.unknown', defaultMessage: 'Unknown'};
  }
  if (
    (state === RobotSummaryStatusEnum.StoppedRequest ||
      state === RobotSummaryStatusEnum.NotStarted) &&
    ifSuccessful(wallet.wallet, (t) => t.balance <= 0, false)
  ) {
    return {
      id: 'common.robot.insufficientRCOBalance',
      defaultMessage: 'Insufficient RCO balance',
    };
  }
  switch (state) {
    case RobotSummaryStatusEnum.Started:
      return {id: 'common.robot.online', defaultMessage: 'Online'};
    case RobotSummaryStatusEnum.Starting:
      return {id: 'common.robot.starting', defaultMessage: 'Starting'};
    case RobotSummaryStatusEnum.Stopping:
      return {id: 'common.robot.stopping', defaultMessage: 'Stopping'};
    case RobotSummaryStatusEnum.NotStarted:
      return {id: 'common.robot.not.started', defaultMessage: 'Stopped'};
    case RobotSummaryStatusEnum.StoppedCorrection:
      return {
        id: 'common.robot.stopped.correction',
        defaultMessage: 'Stopped by correction limit',
      };
    case RobotSummaryStatusEnum.StoppedRequest:
      return {id: 'common.robot.stopped.request', defaultMessage: 'Stopped'};
    case RobotSummaryStatusEnum.NoSpace:
      return {
        id: 'common.robot.no.space',
        defaultMessage: 'Cannot find space for bot',
      };
    case RobotSummaryStatusEnum.StartFailed:
      if (title) {
        return title;
      }
      return {id: 'common.robot.start.failed', defaultMessage: 'Start failed'};
    case RobotSummaryStatusEnum.StoppedError:
      if (title) {
        return title;
      }
      return {
        id: 'common.robot.stopped.error',
        defaultMessage: 'Stopped by internal error',
      };
  }
}

function parseButtonState(
  warnings: RobotWarning[],
  state: RobotSummaryStatusEnum | undefined,
  wallet: WalletStore,
): 'none' | 'loading' | 'error' | 'warning' {
  if (!state) {
    return 'none';
  }
  if (
    (state === RobotSummaryStatusEnum.StoppedRequest ||
      state === RobotSummaryStatusEnum.NotStarted) &&
    ifSuccessful(wallet.wallet, (t) => t.balance <= 0, false)
  ) {
    return 'error';
  }
  switch (state) {
    case RobotSummaryStatusEnum.Started:
      return warnings.length > 0 ? 'warning' : 'none';
    case RobotSummaryStatusEnum.NotStarted:
    case RobotSummaryStatusEnum.StoppedRequest:
      return 'none';
    case RobotSummaryStatusEnum.Starting:
    case RobotSummaryStatusEnum.Stopping:
      return 'loading';
    case RobotSummaryStatusEnum.StartFailed:
    case RobotSummaryStatusEnum.StoppedCorrection:
    case RobotSummaryStatusEnum.StoppedError:
    case RobotSummaryStatusEnum.NoSpace:
      return 'error';
  }
}

const RobotDetailsFragment: React.FunctionComponent<Props> = ({
  robot,
  wallet,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const stateMessage = parseState(robot.currentSummary?.statusDetails, wallet);
  const [searchParams, setSearchParams] = useSearchParams();
  const [guideOpen, setGuideOpen] = useState(
    searchParams.get('guide') === 'true',
  );

  const closeGuide = useCallback(() => {
    setGuideOpen(false);
    setSearchParams((prev) => {
      prev.delete('guide');
      return prev;
    });
  }, []);

  const warning = ArrayUtils.max(
    robot.currentSummary?.warnings ?? [],
    (item) => item.priority,
  );
  return (
    <>
      <OrderStatsCard
        chart={robot.chartData}
        date={robot.chartPeriod}
        onChange={robot.setChartPeriod}
        onFooterClick={() => navigate('/app/robot/' + params.robot + '/trades')}
        moneyCurrency={robot.currentSummary?.pair?.money ?? 'USDT'}
      />
      <Spacer size={1} />
      <BotConfigCard
        config={robot.configuration}
        onClick={() => navigate('/app/robot/' + params.robot + '/edit-config')}
      />
      <Spacer size={2} />
      <WideButton
        onClick={robot.switchCurrentState}
        state={parseButtonState(
          robot.currentSummary?.warnings ?? [],
          robot.currentSummary?.statusDetails.state,
          wallet,
        )}
      >
        {warning ? (
          warning.detail ?? warning.title
        ) : typeof stateMessage === 'string' ? (
          stateMessage
        ) : (
          <FormattedMessage {...stateMessage} />
        )}
      </WideButton>
      <Spacer size={1} />

      <GuideContainer open={guideOpen} onClose={closeGuide}>
        <GuideContainer.Item
          text={{
            id: 'fragments.RobotDetailsFragment.guide.summary',
            defaultMessage:
              'Here, you can see your robot info and profit, switch robots and create new ones',
          }}
          x={'24px'}
          y={'24px'}
          w={'calc(100% - 48px)'}
          h={'22vh'}
          position={'top'}
        />
        <GuideContainer.Item
          text={{
            id: 'fragments.RobotDetailsFragment.guide.graph',
            defaultMessage:
              'This chart shows robot balance change. Also, you can see all orders by tapping at the bottom',
          }}
          x={'20px'}
          y={'calc(22vh + 24px + 16px)'}
          w={'calc(100% - 40px)'}
          h={
            'calc(100% - 22vh - 24px - 16px - 16px - 164px - 1rem - 2rem - 51px)'
          }
          position={'top'}
        />
        <GuideContainer.Item
          text={{
            id: 'fragments.RobotDetailsFragment.guide.settings',
            defaultMessage:
              'These are robot settings. Go inside to change them and to see detailed description',
          }}
          x={'24px'}
          y={
            'calc(22vh + 24px + 16px + (100% - 22vh - 24px - 16px - 164px - 1rem - 2rem - 51px))'
          }
          w={'calc(100% - 48px)'}
          h={'calc(164px)'}
          position={'bottom'}
        />
        <GuideContainer.Item
          text={{
            id: 'fragments.RobotDetailsFragment.guide.button',
            defaultMessage:
              'This button starts or stops robot. Also, it display robot status, errors and warnings',
          }}
          x={'0'}
          y={
            'calc(22vh + 24px + 16px + (100% - 22vh - 24px - 164px - 1rem - 2rem - 51px) + 164px + 1rem)'
          }
          w={'100%'}
          h={'51px'}
          position={'bottom'}
          nextText={{id: 'common.done', defaultMessage: 'Done'}}
        />
      </GuideContainer>
    </>
  );
};

export default inject(
  'robot',
  'wallet',
)(observer(RobotDetailsFragment)) as unknown as React.FC;
