import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import {useNavigate, useParams} from 'react-router-dom';
import RobotTradeListViewModel from '../../viewmodel/RobotTradeListViewModel';
import Scaffolding from '../../component/container/Scaffolding';
import IntersectionTrigger from '../../component/ui/IntersectionTrigger';
import {parseISO} from 'date-fns';
import RobotTradeCard from '../../component/ui/RobotTradeCard';

const RobotTradeListScreen: React.FunctionComponent = () => {
  const params = useParams();
  const navigate = useNavigate();
  const vm = useMemo(
    () => new RobotTradeListViewModel(params.robot),
    [params.robot],
  );
  return (
    <Scaffolding
      header={{
        id: 'nav.robotTrades',
        defaultMessage: 'Trades',
      }}
      overflow
      paddingHorizontal={1}
    >
      {vm.items.map((t, idx) =>
        idx === vm.items.length - 5 ? (
          <React.Fragment key={t.id}>
            <IntersectionTrigger onIntersected={vm.nextPage} />
            <RobotTradeCard
              {...t}
              boughtAt={t.boughtAt ? parseISO(t.boughtAt) : undefined}
              soldAt={t.soldAt ? parseISO(t.soldAt) : undefined}
              onClick={() =>
                navigate('/app/robot/' + t.robotId + '/trades/' + t.id)
              }
            />
          </React.Fragment>
        ) : (
          <RobotTradeCard
            {...t}
            key={t.id}
            boughtAt={t.boughtAt ? parseISO(t.boughtAt) : undefined}
            soldAt={t.soldAt ? parseISO(t.soldAt) : undefined}
            onClick={() =>
              navigate('/app/robot/' + t.robotId + '/trades/' + t.id)
            }
          />
        ),
      )}
    </Scaffolding>
  );
};

export default observer(RobotTradeListScreen);
