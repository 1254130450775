import React, {useMemo} from 'react';
import {WalletStore} from '../../../store/WalletStore';
import {inject, observer} from 'mobx-react';
import WalletViewModel from '../../../viewmodel/wallet/WalletViewModel';
import {useNavigate} from 'react-router-dom';
import Scaffolding from '../../../component/container/Scaffolding';
import IntersectionTrigger from '../../../component/ui/IntersectionTrigger';
import WalletTransactionItem from '../../../component/design/data/WalletTransactionItem';
import TransactionHistoryHeader from '../../../component/design/stub/TransactionHistoryHeader';
import WalletContainer from '../../../component/container/WalletContainer';
import BalanceCard from '../../../component/design/data/BalanceCard';
import {ifSuccessful, mapRefreshableValues} from '../../../utils';
import ErrorBlock from '../../../component/ui/ErrorBlock';
import IntlUtils from '../../../utils/IntlUtils';
import FilterHeader from '../../../component/design/container/FilterHeader';
import TransactionTypeFilter from '../../../component/design/data/TransactionTypeFilter';
import NoTransactionsItem from '../../../component/design/stub/NoTransactionsItem';
import CauseDistributionCard from '../../../component/design/data/CauseDistributionCard';
import PeriodFilter from '../../../component/design/data/PeriodFilter';

interface RProps {
  wallet: WalletStore;
}

const WalletScreen: React.FunctionComponent<RProps> = ({wallet}) => {
  const navigate = useNavigate();
  const vm = useMemo(() => new WalletViewModel(), []);
  return (
    <Scaffolding
      header={{
        id: 'nav.walletTransactionList',
        defaultMessage: 'My wallet',
      }}
    >
      <WalletContainer
        header={
          <FilterHeader>
            <TransactionTypeFilter
              causes={vm.transactionCauseFilter}
              onCauses={vm.setTransactionCauseFilter}
            />
            <PeriodFilter
              to={vm.to}
              from={vm.from}
              onChange={vm.setDateFilters}
            />
          </FilterHeader>
        }
        main={
          <>
            <BalanceCard
              balance={mapRefreshableValues(wallet.wallet, (v) => v.balance)}
              warning={ifSuccessful(
                wallet.wallet,
                (v) =>
                  v.balance < 1 ? IntlUtils.lowBalanceWarning() : undefined,
                undefined,
              )}
              onBuyClick={() => navigate('/app/wallet/buy')}
              onSellClick={() => navigate('/app/wallet/sell')}
              change={mapRefreshableValues(
                vm.causeDistribution,
                (t) => t.change,
              )}
              changePercent={mapRefreshableValues(
                vm.causeDistribution,
                (v) => v.changePercent,
              )}
            />
            {vm.causeDistribution.status === 'success' && (
              <CauseDistributionCard
                distribution={vm.causeDistribution.result}
              />
            )}
            {vm.causeDistribution.status === 'loading' && (
              <CauseDistributionCard.Skeleton />
            )}
          </>
        }
        list={
          <>
            <TransactionHistoryHeader />
            {vm.currentPage.status === 'loading' && (
              <>
                <WalletTransactionItem.Loader />
                <WalletTransactionItem.Loader />
                <WalletTransactionItem.Loader />
                <WalletTransactionItem.Loader />
                <WalletTransactionItem.Loader />
              </>
            )}
            {vm.currentPage.status === 'error' && (
              <ErrorBlock error={vm.currentPage.error} />
            )}
            {vm.transactions.length === 0 && <NoTransactionsItem />}
            {vm.transactions.map((t, idx) =>
              idx === vm.transactions.length - 5 ? (
                <React.Fragment key={t.id}>
                  <IntersectionTrigger onIntersected={vm.nextPage} />
                  <WalletTransactionItem
                    {...t}
                    onClick={() => navigate('/app/wallet/' + t.id)}
                  />
                </React.Fragment>
              ) : (
                <WalletTransactionItem
                  key={t.id}
                  {...t}
                  onClick={() => navigate('/app/wallet/' + t.id)}
                />
              ),
            )}
          </>
        }
      />
    </Scaffolding>
  );
};

export default inject('wallet')(observer(WalletScreen)) as React.FC<
  Partial<RProps>
>;
