import {UserInfo, WalletTransactionDetails} from '../../../../api';
import {FC} from 'react';
import KVCard from '../../container/KVCard';
import Amount from '../../typo/CoinBalance';
import MiscUtils from '../../../../utils/MiscUtils';
import MarketLogo from '../../graphics/MarketLogo';
import RobotPair from '../RobotPair';

interface Props extends WalletTransactionDetails {
  me: UserInfo;
}

const index: FC<Props> = ({
  id,
  robot,
  trade,
  amount,
  marketAddress,
  marketTransactionId,
  originalTxId,
  fromTree,
  me,
}) => (
  <KVCard>
    <KVCard.Item
      name={{id: 'common.walletTx.id', defaultMessage: 'Transaction ID'}}
      value={id}
      copyable
      ellipsis
    />
    {trade && (
      <KVCard.Item
        name={{id: 'common.trade.profit', defaultMessage: 'Profit'}}
        value={
          trade.profit !== undefined ? (
            <Amount
              amount={trade.profit}
              coin={trade.robot?.pair?.money ?? 'UNKNOWN'}
              minDigits={8}
              maxDigits={8}
            />
          ) : undefined
        }
      />
    )}
    {trade && (
      <KVCard.Item
        name={{
          id: 'common.trade.robotCommission',
          defaultMessage: 'Robot commission',
        }}
        value={
          trade.sellPrice !== undefined && trade.sellAmount !== undefined
            ? Math.round(
                (-amount /
                  (trade.sellPrice * trade.sellAmount -
                    trade.buyPrice * trade.buyAmount) /
                  (trade.sellMoneyPrice ?? 1)) *
                  100,
              ) + '%'
            : undefined
        }
      />
    )}
    {robot && robot.id.length > 0 && (
      <KVCard.Item
        name={{
          id: 'common.walletTx.robot',
          defaultMessage: 'Robot',
        }}
        value={robot.name ?? MiscUtils.capitalize(robot.market.toLowerCase())}
        link={!robot.deleted ? '/app/dashboard/robot/' + robot.id : ''}
      />
    )}
    {robot && (
      <KVCard.Item
        name={{
          id: 'common.walletTx.market',
          defaultMessage: 'Market',
        }}
        value={<MarketLogo market={robot.market} />}
      />
    )}
    {robot && (
      <KVCard.Item
        name={{
          id: 'common.walletTx.pair',
          defaultMessage: 'Trading pair',
        }}
        value={<RobotPair {...robot.pair} />}
      />
    )}
    {marketAddress && (
      <KVCard.Item
        name={{
          id: 'common.walletTx.marketAddress',
          defaultMessage: 'Market address',
        }}
        value={marketAddress}
        copyable
      />
    )}
    {marketTransactionId && (
      <KVCard.Item
        name={{
          id: 'common.walletTx.marketTransactionId',
          defaultMessage: 'Market transaction ID',
        }}
        value={marketTransactionId}
        copyable
      />
    )}
    {originalTxId && (
      <KVCard.Item
        name={{
          id: 'common.walletTx.originalTxId',
          defaultMessage: 'Original transaction ID',
        }}
        value={originalTxId}
        copyable
        ellipsis
      />
    )}
    {fromTree && fromTree.length > 0 && (
      <KVCard.FromTree tree={fromTree} me={me} />
    )}
  </KVCard>
);

export default index;
