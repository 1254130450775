import React, {useEffect, useMemo, useRef, useState} from 'react';

interface Props {
  onIntersected: () => void;
}

const IntersectionTrigger: React.FunctionComponent<Props> = ({
  onIntersected,
}) => {
  const [intersecting, setIntersecting] = useState(false);
  const ref = useRef(null);
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting),
      ),
    [],
  );
  useEffect(() => {
    if (intersecting) {
      onIntersected();
    }
  }, [intersecting]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion,@typescript-eslint/no-non-null-assertion
    observer.observe(ref.current!!);
    return () => observer.disconnect();
  }, []);
  return <div ref={ref} />;
};

export default IntersectionTrigger;
