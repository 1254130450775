import stores from '../store';
import {UnsubscribeStatus} from '../api';
import {notLoaded, wrapPromise} from '../utils';
import {makeAutoObservable, observable, runInAction} from 'mobx';

class UnsubscribeViewModel {
  status: AsyncState<UnsubscribeStatus> = notLoaded();

  constructor(readonly id: string, readonly token: string) {
    makeAutoObservable(this, {
      status: observable,
    });
    void wrapPromise(
      stores.notifications.getUnsubStatus(id, token),
      this.status,
      (v) => runInAction(() => (this.status = v)),
    );
  }

  unsubscribe(): void {
    void wrapPromise(
      (async () => {
        await stores.notifications.unsubscribe(this.id, this.token);
        return await stores.notifications.getUnsubStatus(this.id, this.token);
      })(),
      this.status,
      (v) => runInAction(() => (this.status = v)),
    );
  }
}

export default UnsubscribeViewModel;
