import React from 'react';
import ErrorBlock from '../ErrorBlock';
import LoadingBlock from '../LoadingBlock';

interface Props<T> {
  state: AsyncState<T> | RefreshableAsyncState<T>;
  children: (data: T) => React.ReactElement;
}

function AsyncStateWrapper<T>(props: Props<T>): React.ReactElement {
  if (props.state.status === 'error') {
    return <ErrorBlock error={props.state.error} />;
  } else if (
    props.state.status === 'loading' ||
    props.state.status === 'not-requested' ||
    props.state.status === 'refreshing-error'
  ) {
    return <LoadingBlock />;
  } else {
    return props.children(props.state.result);
  }
}

export default AsyncStateWrapper;
