import {FC} from 'react';
import cls from './style.module.scss';

interface Props {
  current: number;
  max: number;
}

const HeaderProgress: FC<Props> = ({current, max}) => (
  <div className={cls.container}>
    <div className={cls.track}>
      <div
        className={cls.head}
        style={{width: Math.min((Math.max(current, 0) / max) * 100, 100) + '%'}}
      />
    </div>
    <div className={cls.text}>
      {current}
      <span className={cls.max}>/{max}</span>
    </div>
  </div>
);

export default HeaderProgress;
