export const SENTRY_DSN =
  'https://76e6fcf5d3f044d2a7f730e1c58812fd@o568807.ingest.sentry.io/4503920793944064';
export const SENTRY_TRACES = 0.2;
export const SENTRY_TRACING_ORIGINS: (string | RegExp)[] = [
  new RegExp('.*\\.robowallet\\.org'),
];
export const SENTRY_MAX_STORED_EVENTS = 30;
export const GA_TAG = 'G-79JECRR2ZB';
export const API_URL =
  'development' === process.env.NODE_ENV
    ? 'http://localhost:3000'
    : 'https://api.robowallet.org';
export const MAIN_SITE_URL = 'https://robowallet.org';
export const AUTH_EXPIRY_DELAY = -5 * 60 * 1000;
export const SUMMARY_UPDATE_INTERVAL = 60 * 1000; // ms
export const NOTIFICATIONS_UPDATE_INTERVAL = 30 * 1000; // ms
export const NOTIFICATIONS_PREVIEW_DEBOUNCE = 2 * 1000; // ms
export const TOS_URL = 'https://robowallet.org/policy';
export const EMAIL_VERIFICATION_CODE_LENGTH = 6;
export const INVITE_URL = 'https://robowallet.org/?invite={code}';
export const LOGO =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/logo512.png'
    : 'https://app.robowallet.org/logo512.png';

export const COLOR_TEXT = '#FCFCFD';
export const COLOR_PRIMARY = '#3772FF';
export const COLOR_QR_FG = '#4d4d4d';
export const DISTRIBUTION_CHART_SORTMAP = new Map<string, number>();
DISTRIBUTION_CHART_SORTMAP.set('BTC', 0);
DISTRIBUTION_CHART_SORTMAP.set('USDT', 1);
DISTRIBUTION_CHART_SORTMAP.set('ETH', 2);
DISTRIBUTION_CHART_SORTMAP.set('LTC', 3);
DISTRIBUTION_CHART_SORTMAP.set('BNB', 4);
DISTRIBUTION_CHART_SORTMAP.set('DOT', 5);
DISTRIBUTION_CHART_SORTMAP.set('TRX', 6);
DISTRIBUTION_CHART_SORTMAP.set('TUSD', 7);
DISTRIBUTION_CHART_SORTMAP.set('USDC', 8);
export const DISTRIBUTION_CHART_COLORS = [
  '#F7931A',
  '#3772FF',
  '#7031ac',
  '#345d9d',
  '#bc5090',
  '#E60079',
  '#c2a633',
  '#fe0013',
  '#1b5afe',
  '#F0B90B',
];
export const PROC_ADDRESS = 'TBjP58BVrttbv2MneisRusBXcQZw8fcjC6';
export const APP_URLS = {
  support: 'https://t.me/RoboWallet_bot',
  business_subscription: undefined,
};
export const MAINTENANCE_STORAGE_KEEP_ALIVE = 24 * 60 * 60 * 1000; // in ms

export const COIN_ICON_SIZES = [16, 32, 64, 128] as const;
export const COIN_ICON_URL = (
  name: string,
  size: typeof COIN_ICON_SIZES[number],
) =>
  `https://coin-icons-${size}.robowallet.org/${name
    .toLowerCase()
    .replace('/', '_')}.png`;
