import React, {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {UserStore} from '../../../store/UserStore';

interface Props {
  user: UserStore;
}

const AuthorizedRoute: React.FunctionComponent<Props> = ({user}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (user.loadLoggedIn.type !== 'logged-in') {
      navigate('/');
    }
  }, []);

  return user.loadLoggedIn.type === 'logged-in' ? <Outlet /> : <></>;
};

export default inject('user')(observer(AuthorizedRoute)) as unknown as React.FC;
