import {FC, ReactNode} from 'react';
import cls from './style.module.scss';

interface Props {
  children: ReactNode;
  gap?: number;
  paddingHorizontal?: number;
  grow?: number;
  height?: string;
}

const FlexCol: FC<Props> = ({
  children,
  gap,
  paddingHorizontal,
  grow,
  height,
}) => (
  <div
    className={cls.container}
    style={{
      gap: gap + 'rem',
      paddingLeft: paddingHorizontal ? paddingHorizontal + 'rem' : undefined,
      paddingRight: paddingHorizontal ? paddingHorizontal + 'rem' : undefined,
      flexGrow: grow,
      height: height,
    }}
  >
    {children}
  </div>
);

export default FlexCol;
