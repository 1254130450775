import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/apikeys/setup-2fa-1.png';
import v2 from '../../../../../assets/onboarding/bybit/apikeys/setup-2fa-2.png';

const SetupGAuthFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.SetupGAuthFragment.title',
        defaultMessage:
          'Scroll down to see <h>Google Two Factor Authentication</h>, click <h>Settings</h>',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default SetupGAuthFragment;
