export default class ArrayUtils {
  static max<T>(arr: T[], fn: (item: T) => number): T | undefined {
    let max: T | undefined = undefined;
    let maxI: number | undefined = undefined;
    arr.forEach((value) => {
      const i = fn(value);
      if (maxI === undefined || i > maxI) {
        maxI = i;
        max = value;
      }
    });
    return max;
  }
}
