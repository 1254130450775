import Typography, {HText} from '../../typo/Typography';
import Icon, {IconName} from '../../graphics/Icon';
import {FC} from 'react';
import cls from './style.module.scss';

interface HeaderAction {
  icon: IconName;
  onClick?: () => void;
}

export type HeaderActions = HeaderAction[];

interface Props {
  text: HText;
  onBackClick?: () => void;
  actions?: HeaderActions;
}

const Header: FC<Props> = ({text, onBackClick, actions}) => (
  <div className={cls.container}>
    <Typography text={text} className={cls.text} />

    <div className={cls.back}>
      {onBackClick && (
        <Icon
          name={'arrow-left'}
          size={24}
          tint={'white'}
          onClick={onBackClick}
          clickable
        />
      )}
    </div>

    <div className={cls.left}>
      {actions &&
        actions.map((item) => (
          <Icon
            key={item.icon}
            name={item.icon}
            size={24}
            tint={'white'}
            onClick={item.onClick}
            clickable={!!item.onClick}
          />
        ))}
    </div>
  </div>
);

export default Header;
