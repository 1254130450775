import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {
  FieldConfig,
  FormikContextType,
  useField,
  useFormikContext,
} from 'formik';
import TextInput from '../../ui/TextInput';

interface Props {
  header?: MessageDescriptor;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  enterKeyHint?:
    | 'enter'
    | 'done'
    | 'go'
    | 'next'
    | 'previous'
    | 'search'
    | 'send';
  subtext?: MessageDescriptor;
  password?: boolean | 'fake';
  submitForm?: boolean;
  onTextChange?: (text: string, ctx: FormikContextType<unknown>) => void;
  children?: undefined;
  backgroundColor?: string;
  tooltip?: React.ReactNode;
}

function TextField({
  ...props
}: Props & FieldConfig<string>): React.ReactElement {
  const [field, meta, helpers] = useField<string>(props);
  const context = useFormikContext();
  return (
    <TextInput
      error={meta.error}
      label={props.header}
      inputMode={props.inputMode}
      enterKeyHint={props.enterKeyHint}
      value={field.value}
      onValueChange={(text) => {
        helpers.setValue(text);
        if (props.onTextChange) {
          props.onTextChange(text, context);
        }
      }}
      onBlur={() => field.onBlur(field.name)}
      sublabel={props.subtext}
      password={props.password}
      onSubmitEditing={
        props.submitForm ? () => void context.submitForm() : undefined
      }
      backgroundColor={props.backgroundColor}
      tooltip={props.tooltip}
    />
  );
}

export default React.memo(TextField);
