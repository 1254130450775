import StorageBackend from './StorageBackend';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

class AESEncryptedStorageBackendDecorator implements StorageBackend {
  constructor(
    private readonly delegate: StorageBackend,
    private readonly key: string,
  ) {}

  delete(key: string): void {
    return this.delegate.delete(key);
  }

  getString(key: string): string | undefined {
    const item = this.delegate.getString(key);
    return item === undefined
      ? undefined
      : AES.decrypt(item, this.key).toString(Utf8);
  }

  set(key: string, value: string): void {
    this.delegate.set(key, AES.encrypt(Utf8.parse(value), this.key).toString());
  }
}

export default AESEncryptedStorageBackendDecorator;
