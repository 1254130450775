import {FC, useState} from 'react';
import BottomSheet from '../BottomSheet';
import BottomSheetCard from '../../container/BottomSheetCard';
import Button from '../../button/Button';
import {format, parse, addMonths} from 'date-fns';
import {isNaN} from 'formik';
import Icon from '../../graphics/Icon';
import {FormattedMessage} from 'react-intl';
import DateInput from '../../input/DateInput';
import cls from './style.module.scss';

const MONTHS = [1, 3, 6];
const F = (date: Date) => format(date, 'yyyy-MM-dd');
const P = (date: string) => parse(date, 'yyyy-MM-dd', new Date());

interface Props {
  open: boolean;
  onSubmit: (from: Date, to: Date) => void;
  onDismiss: () => void;
  from?: Date;
  to?: Date;
}

const DatePeriodPickerBottomSheet: FC<Props> = ({
  open,
  onSubmit,
  onDismiss,
  from,
  to,
}) => {
  const [fromS, setFromS] = useState(from ? F(from) : '');
  const [toS, setToS] = useState(to ? F(to) : '');
  const [fromSInvalid, setFromSInvalid] = useState(false);
  const [toSInvalid, setToSInvalid] = useState(false);
  return (
    <BottomSheet open={open} onDismiss={onDismiss}>
      <BottomSheetCard
        mainClassName={cls.main}
        buttons={
          <>
            <Button
              type={'default'}
              text={{id: 'common.cancel', defaultMessage: 'Cancel'}}
              onClick={onDismiss}
            />
            <Button
              type={'primary'}
              text={{id: 'common.submit', defaultMessage: 'Submit'}}
              onClick={() => {
                const f = P(fromS);
                const t = P(toS);
                setFromSInvalid(isNaN(+f));
                setToSInvalid(isNaN(+t));
                if (!isNaN(+f) && !isNaN(+t)) {
                  onSubmit(f, t);
                }
              }}
            />
          </>
        }
      >
        <span className={cls.header}>
          <Icon name={'calendar'} size={24} tint={'white'} />
          <FormattedMessage
            id={'components.DatePeriodPickerBottomSheet.title'}
            defaultMessage={'Enter period'}
          />
        </span>
        <div className={cls.buttons}>
          {MONTHS.map((m) => (
            <Button
              key={m}
              text={{
                id: 'common.nMonth',
                defaultMessage: '{n, plural, \n=1 {# month}\nother {# months}}',
                values: {n: m},
              }}
              type={'default'}
              onClick={() => {
                setFromS(F(addMonths(new Date(), -m)));
                setToS(F(new Date()));
              }}
            />
          ))}
        </div>
        <div className={cls.inputs}>
          <DateInput
            value={fromS}
            onChange={setFromS}
            label={{id: 'common.from', defaultMessage: 'From'}}
            error={fromSInvalid ? true : undefined}
          />
          <DateInput
            value={toS}
            onChange={setToS}
            label={{id: 'common.to', defaultMessage: 'To'}}
            error={toSInvalid ? true : undefined}
          />
        </div>
      </BottomSheetCard>
    </BottomSheet>
  );
};

export default DatePeriodPickerBottomSheet;
