import {FC, ReactNode} from 'react';
import Feature from './feature';
import cls from './style.module.scss';

interface Props {
  children: ReactNode;
}

interface SubComponents {
  Feature: typeof Feature;
}

const FeatureList: FC<Props> & SubComponents = ({children}) => (
  <div className={cls.list}>{children}</div>
);

FeatureList.Feature = Feature;

export default FeatureList;
