import {FC} from 'react';
import StoreIcon from '../../graphics/StoreIcon';
import Typography from '../../typo/Typography';
import cls from './style.module.scss';

interface Props {
  store: 'gplay' | 'appstore';
  link: string;
}

const StoreButton: FC<Props> = ({store, link}) => (
  <a href={link} className={cls.container}>
    <StoreIcon store={store} size={32} />
    <div className={cls.texts}>
      <Typography
        text={{id: 'components.StoreButton.title', defaultMessage: 'Get it on'}}
        className={cls.get}
      />
      <Typography
        text={store === 'gplay' ? 'Google Play' : 'App Store'}
        className={cls.name}
      />
    </div>
  </a>
);

export default StoreButton;
