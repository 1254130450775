import React from 'react';
import DesktopCompatibilityStore from '../../../store/DesktopCompatibilityStore';
import {inject, observer} from 'mobx-react';
import cls from './style.module.scss';
import bg from '../../../assets/images/desktop-compat-bg.jpg';

interface Props {
  desktopCompatibility: DesktopCompatibilityStore;
  children: React.ReactNode;
}

const DesktopCompatibilityContainer: React.FunctionComponent<Props> = ({
  desktopCompatibility,
  children,
}) =>
  desktopCompatibility.shouldUseCompatibility ? (
    <div className={cls.container}>
      <img src={bg} className={cls.background} decoding={'async'} />
      <div className={cls.content}>{children}</div>
    </div>
  ) : (
    <>{children}</>
  );

export default inject('desktopCompatibility')(
  observer(DesktopCompatibilityContainer),
) as React.FC<Partial<Props>>;
