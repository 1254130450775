import {FocusEventHandler, useCallback, useState} from 'react';

export default function useIsFocused<T>(
  focus?: FocusEventHandler<T>,
  blur?: FocusEventHandler<T>,
): [boolean, FocusEventHandler<T>, FocusEventHandler<T>] {
  const [focused, setFocused] = useState(false);
  const onFocus: FocusEventHandler<T> = useCallback(
    (e) => {
      setFocused(true);
      if (focus) {
        focus(e);
      }
    },
    [focus],
  );
  const onBlur: FocusEventHandler<T> = useCallback(
    (e) => {
      setFocused(false);
      if (blur) {
        blur(e);
      }
    },
    [blur],
  );
  return [focused, onFocus, onBlur];
}
