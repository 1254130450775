import {EmailNotificationPreferences, NotificationPreferences} from '../api';
import {notLoaded, wrapPromise} from '../utils';
import {makeAutoObservable, observable, runInAction} from 'mobx';
import stores from '../store';

class EditEmailNotificationsPreferencesViewModel {
  prefs: AsyncState<EmailNotificationPreferences> = notLoaded();
  saved = false;

  constructor() {
    makeAutoObservable(this, {
      prefs: observable,
      saved: observable,
    });
    void wrapPromise(
      stores.notifications.getEmailNotificationPreferences(),
      this.prefs,
      (v) => runInAction(() => (this.prefs = v)),
    );
  }

  async savePrefs(prefs: NotificationPreferences): Promise<void> {
    if (this.prefs.status === 'success') {
      await stores.notifications.setEmailNotificationPreferences(
        this.prefs.result.id,
        prefs,
      );
      runInAction(() => {
        this.saved = true;
      });
    } else {
      throw Error('Failed to load prefs');
    }
  }
}

export default EditEmailNotificationsPreferencesViewModel;
