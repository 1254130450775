import React from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import Icon, {IconName} from '../Icon';
import cls from './style.module.scss';
import Tag from '../Tag';

interface Props {
  header?: MessageDescriptor;
  value?: MessageDescriptor | string;
  icon?: IconName;
  onClick?: () => void;
  tag?: MessageDescriptor;
}

const FakeSelect: React.FunctionComponent<Props> = ({
  header,
  value,
  icon,
  onClick,
  tag,
}) => (
  <div className={cls.container}>
    {header && (
      <label>
        <FormattedMessage {...header} />
      </label>
    )}
    <div className={cls.inputContainer} onClick={onClick}>
      <div className={cls.input + ' ' + (!value && cls.caption)}>
        <div className={cls.contentContainer}>
          {icon && <Icon name={icon} size={18} className={cls.icon} />}
          {value ? (
            typeof value === 'string' ? (
              value
            ) : (
              <FormattedMessage {...value} />
            )
          ) : (
            <FormattedMessage
              id={'component.FakeSelect.empty'}
              defaultMessage={'Select an option'}
            />
          )}
        </div>
        <div className={cls.rightContent}>
          {tag && <Tag label={tag} className={cls.tag} />}
          <Icon name={'chevron-down'} size={24} />
        </div>
      </div>
    </div>
  </div>
);

export default FakeSelect;
