import {
  WalletCauseDistribution,
  WalletTransactionItem,
  WalletTransactionItemCauseEnum,
  WalletTransactionList,
} from '../../api';
import {notLoaded, wrapPromise, wrapRefreshablePromise} from '../../utils';
import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import stores from '../../store';
import {debounce} from 'ts-debounce';
import {subMonths, format, differenceInDays, subDays} from 'date-fns';
import WalletFilterStorage from '../../storage/WalletFilterStorage';

const DEBOUNCE_MS = 400;

class WalletViewModel {
  transactionCauseFilter: WalletTransactionItemCauseEnum[] | undefined =
    undefined;
  from: Date = subMonths(new Date(), 1);
  to: Date = new Date();
  transactions: WalletTransactionItem[] = [];
  currentPage: RefreshableAsyncState<WalletTransactionList> = notLoaded();
  causeDistribution: AsyncState<WalletCauseDistribution> = notLoaded();
  nextToken?: string;

  constructor() {
    makeAutoObservable(this, {
      currentPage: observable,
      transactions: observable,
      transactionCauseFilter: observable,
      from: observable,
      to: observable,
      nextPage: action.bound,
      setTransactionCauseFilter: action.bound,
      setDateFilters: action.bound,
    });

    const dateDelta = WalletFilterStorage.get().getDateDelta();
    if (dateDelta) {
      this.from = subDays(this.to, dateDelta);
    }

    const filter = WalletFilterStorage.get().getCauseFilter();
    if (filter) {
      this.transactionCauseFilter = filter;
    }

    this.loadPage();
    this.loadCauseDistribution();
  }

  nextPage() {
    if (
      this.currentPage.status === 'success' &&
      this.nextToken !== undefined &&
      this.nextToken !== null
    ) {
      this.loadPage(this.nextToken);
    }
  }

  private loadCauseDistribution() {
    void wrapPromise(
      stores.wallet.getWalletCauseDistribution({
        causes: this.transactionCauseFilter,
        from: format(this.from, 'yyyy-MM-dd'),
        to: format(this.to, 'yyyy-MM-dd'),
      }),
      this.causeDistribution,
      (v) => runInAction(() => (this.causeDistribution = v)),
    );
  }

  private loadPage(token?: string) {
    void wrapRefreshablePromise(
      stores.wallet.loadTransactionPage({
        nextPageToken: token,
        causes: this.transactionCauseFilter,
        from: format(this.from, 'yyyy-MM-dd'),
        to: format(this.to, 'yyyy-MM-dd'),
      }),
      this.currentPage,
      (v) =>
        runInAction(() => {
          if (v.status === 'success') {
            this.nextToken = v.result.nextPageToken;
            this.transactions.push(...v.result.transactions);
          }
          this.currentPage = v;
        }),
    );
  }

  fullReload() {
    runInAction(() => {
      this.transactions = [];
      this.currentPage = notLoaded();
      this.nextToken = undefined;
      this.loadPage();
      this.loadCauseDistribution();
    });
  }

  private fullReloadDebounced = debounce(this.fullReload, DEBOUNCE_MS);

  setTransactionCauseFilter(
    filter: WalletTransactionItemCauseEnum[] | undefined,
  ) {
    this.transactionCauseFilter = filter;
    WalletFilterStorage.get().setCauseFilter(filter);
    void this.fullReloadDebounced();
  }

  setDateFilters(from: Date, to: Date) {
    this.from = from;
    this.to = to;
    console.log(differenceInDays(to, from));
    WalletFilterStorage.get().setDateDelta(differenceInDays(to, from));
    this.fullReload();
  }
}

export default WalletViewModel;
