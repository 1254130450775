import React, {FC} from 'react';
import {FormattedNumberParts} from 'react-intl';
import CurrencyUtils from '../../../../utils/CurrencyUtils';

interface Props {
  amount: number;
  coin: string;
  maxDigits?: number;
  minDigits?: number;
  coinClassName?: string;
}

const CoinBalance: FC<Props> = ({
  amount,
  coin,
  minDigits,
  maxDigits,
  coinClassName,
}) => (
  <>
    <FormattedNumberParts
      value={amount}
      maximumFractionDigits={
        maxDigits === undefined
          ? CurrencyUtils.getDisplayMaxFractionalDigits(coin)
          : maxDigits
      }
      minimumFractionDigits={
        minDigits === undefined
          ? CurrencyUtils.getDisplayMinFractionalDigits(coin)
          : minDigits
      }
    >
      {CurrencyUtils.formatBalance}
    </FormattedNumberParts>{' '}
    {coinClassName ? <span className={coinClassName}>{coin}</span> : coin}
  </>
);

export default React.memo(CoinBalance);
