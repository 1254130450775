import {Storage} from './Storage';
import StorageManager from './StorageManager';
import Logger from '../manager/Logger';
import StorageBackend from './backend/StorageBackend';
import LocalStorageBackend from './backend/LocalStorageBackend';
import {CurrentOnboardingStateCurrentStageEnum} from '../api';

class OnboardingStorage implements Storage {
  private static instance: OnboardingStorage;
  private readonly backend: StorageBackend = new LocalStorageBackend(
    'onboarding',
  );

  static get(): OnboardingStorage {
    if (!this.instance) {
      this.instance = new OnboardingStorage();
      StorageManager.addStorage('onboarding', this.instance);
      Logger.debug('OnboardingStorage', 'start', 'OnboardingStorage started');
    }
    return this.instance;
  }

  get stage(): CurrentOnboardingStateCurrentStageEnum | undefined {
    return this.backend.getString(
      'stage',
    ) as CurrentOnboardingStateCurrentStageEnum;
  }

  set stage(stage: CurrentOnboardingStateCurrentStageEnum | undefined) {
    if (stage) {
      this.backend.set('stage', stage);
    } else {
      this.backend.delete('stage');
    }
  }

  onUserLogout() {
    this.backend.delete('stage');
  }
}

export default OnboardingStorage;
