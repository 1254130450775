import React from 'react';
import {Route} from 'react-router-dom';
import bybitOnboardingRoutes from './fragment/onboarding/bybit/BybitOnboarding';
import binanceOnboardingRoutes from './fragment/onboarding/binance/BinanceOnboarding';
import huobiOnboardingRoutes from './fragment/onboarding/huobi/HuobiOnboarding';
import OnboardingSelectMarketScreen from './full/OnboardingSelectMarketScreen';

export default function onboardingRoutes(): React.ReactNode {
  return (
    <Route path={'onboarding'}>
      {bybitOnboardingRoutes()}
      {binanceOnboardingRoutes()}
      {huobiOnboardingRoutes()}
      <Route path={''} element={<OnboardingSelectMarketScreen />} />
    </Route>
  );
}
