import Logger from './Logger';

class ScreenOrientation {
  static lockPortrait(): void {
    try {
      if (screen.orientation) {
        void screen.orientation.lock('portrait').catch((e) => {
          Logger.debug(
            'ScreenOrientation',
            'lockPortrait',
            `Failed to lock screen to portrait: ${e.message}`,
          );
        });
      }
    } catch (e) {
      Logger.debug(
        'ScreenOrientation',
        'lockPortrait',
        `Failed to lock screen to portrait`,
      );
    }
  }
}

export default ScreenOrientation;
