import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import openP2P1 from '../../../../../assets/onboarding/binance/p2p/open-p2p-1.png';
import openP2P2 from '../../../../../assets/onboarding/binance/p2p/open-p2p-2.png';

const OpenP2PFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.OpenP2PFragment.title',
        defaultMessage:
          'Open <h>P2P</h> trading from main screen or from <h>bottom Trade tab</h>, then <h>P2P tab</h> on top',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={openP2P1} second={openP2P2} />
  </>
);

export default OpenP2PFragment;
