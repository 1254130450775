import React from 'react';
import {
  DISTRIBUTION_CHART_COLORS,
  DISTRIBUTION_CHART_SORTMAP,
} from '../../../config';
import cls from './style.module.scss';

interface Props {
  symbol: string;
  value: string;
}

const LegendItem: React.FunctionComponent<Props> = ({symbol, value}) => {
  const colorIdx = DISTRIBUTION_CHART_SORTMAP.get(symbol) ?? -1;
  const color = colorIdx === -1 ? '' : DISTRIBUTION_CHART_COLORS[colorIdx];
  return (
    <span className={cls.legendContainer}>
      <div
        className={cls.dot}
        style={{
          backgroundColor: color,
        }}
      />
      <span className={cls.legendText}>
        {' '}
        {symbol} — {value}
      </span>
    </span>
  );
};

export default React.memo(LegendItem);
