import React from 'react';
import cls from './style.module.scss';
import TotalBalanceRow from './TotalBalanceRow';
import CurrentBalanceRow from './CurrentBalanceRow';
import NoInfoContent from './NoInfoContent';
import UpLine from './UpLine';
import RowCarousel from '../RowCarousel';
import TotalIncomeRow from './TotalIncomeRow';

export interface CurrentBalance {
  moneyCoin: string;
  assetCoin: string;
  money: number;
  asset: number;
}

interface Props {
  name: string;
  onNameClick?: () => void;
  moneyCurrency: string;
  totalMoney: number;
  income?: number;
  incomePercent?: number;
  actions?: (open: boolean) => React.ReactNode;
  balances: CurrentBalance[];
  notifications?: 'has-new' | 'no-new';
  onNotificationsClick?: () => void;
  onlyTotalIncome?: boolean;
  alwaysShowIncome?: boolean;
}

const BalanceCard: React.FunctionComponent<Props> = ({
  name,
  onNameClick,
  moneyCurrency,
  totalMoney,
  income,
  incomePercent,
  actions,
  balances,
  notifications,
  onNotificationsClick,
  onlyTotalIncome,
  alwaysShowIncome,
}) => {
  return (
    <div className={cls.card}>
      <UpLine
        name={name}
        onNameClick={onNameClick}
        actions={actions}
        notifications={notifications}
        onNotificationsClick={onNotificationsClick}
      />
      {(balances.length === 0 || balances[0].money < 0) && !alwaysShowIncome ? (
        <></>
      ) : onlyTotalIncome ? (
        <TotalIncomeRow
          moneyCurrency={moneyCurrency}
          income={income}
          incomePercent={incomePercent}
        />
      ) : (
        <TotalBalanceRow
          totalMoney={totalMoney}
          moneyCurrency={moneyCurrency}
          income={income}
          incomePercent={incomePercent}
        />
      )}
      {balances.length === 0 || balances[0].money < 0 ? (
        <NoInfoContent small={alwaysShowIncome} />
      ) : balances.length === 1 ? (
        <CurrentBalanceRow
          asset={balances[0].asset}
          moneyCoin={balances[0].moneyCoin}
          assetCoin={balances[0].assetCoin}
          money={balances[0].money}
        />
      ) : (
        <RowCarousel delayMs={2000}>
          {balances.map((value) => (
            <CurrentBalanceRow
              key={value.moneyCoin + value.assetCoin}
              asset={value.asset}
              moneyCoin={value.moneyCoin}
              assetCoin={value.assetCoin}
              money={value.money}
            />
          ))}
        </RowCarousel>
      )}
    </div>
  );
};

export default React.memo(BalanceCard);
