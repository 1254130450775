import React from 'react';
import {useFormikContext} from 'formik';
import WideButton from '../../ui/WideButton';
import ErrorUtils from '../../../utils/ErrorUtils';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const SubmitFullscreenButton: React.FunctionComponent<Props> = ({
  children,
  className,
}) => {
  const formik = useFormikContext();
  const errorMessage = ErrorUtils.getFormikErrorMessage(formik);
  return (
    <WideButton
      className={className}
      state={
        formik.isValidating || formik.isSubmitting
          ? 'loading'
          : errorMessage !== undefined
          ? 'error'
          : 'none'
      }
      onClick={() => {
        if (!formik.isSubmitting) {
          void formik.submitForm();
        }
      }}
    >
      {errorMessage ?? children}
    </WideButton>
  );
};

export default SubmitFullscreenButton;
