import {makeAutoObservable, observable, runInAction} from 'mobx';
import {WithdrawalRequest, WithdrawalRequestStatusEnum} from '../../api';
import {notLoaded, sleep, wrapRefreshablePromise} from '../../utils';
import stores from '../../store';

const CHECK_INTERVAL = 500;

class SellRcoStatusViewModel {
  request: RefreshableAsyncState<WithdrawalRequest> = notLoaded();
  private running = true;

  constructor(private readonly id: string) {
    makeAutoObservable(this, {
      request: observable,
    });
  }

  init() {
    (async () => {
      while (this.running) {
        await wrapRefreshablePromise(
          stores.wallet.getWithdrawal(this.id),
          this.request,
          (v) =>
            runInAction(() => {
              this.request = v;
              if (v.status === 'success' || v.status === 'refreshing-success') {
                if (
                  v.result.status !== WithdrawalRequestStatusEnum.Processing &&
                  v.result.status !==
                    WithdrawalRequestStatusEnum.WaitingConfirmation &&
                  v.result.status !== WithdrawalRequestStatusEnum.Created
                ) {
                  this.running = false;
                }
                if (
                  v.result.status === WithdrawalRequestStatusEnum.Processing ||
                  v.result.status === WithdrawalRequestStatusEnum.Completed
                ) {
                  stores.wallet.loadWalletInfo();
                }
              }
            }),
        );
        await sleep(CHECK_INTERVAL);
      }
    })();
  }

  stopUpdate() {
    this.running = false;
  }
}

export default SellRcoStatusViewModel;
