import {RobotPair} from '../../../../api';
import {FC} from 'react';
import cls from './style.module.scss';
import CoinIcon from '../../graphics/CoinIcon';

type Props = RobotPair;

const index: FC<Props> = ({asset, money}) => (
  <span className={cls.container}>
    <span className={cls.asset}>
      <CoinIcon coin={asset} size={20} />
      <span>{asset.toUpperCase()}</span>
    </span>
    <span className={cls.slash}>/</span>
    <span className={cls.money}>
      <span>{money.toUpperCase()}</span>
      <CoinIcon coin={money} size={20} />
    </span>
  </span>
);

export default index;
