import React, {useEffect, useState} from 'react';
import {EmailVerificationStore} from '../../store/EmailVerificationStore';
import {UserStore} from '../../store/UserStore';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {NavigateFunction, useNavigate, useSearchParams} from 'react-router-dom';
import ErrorUtils from '../../utils/ErrorUtils';
import {inject, observer} from 'mobx-react';
import {EMAIL_VERIFICATION_CODE_LENGTH} from '../../config';
import Scaffolding from '../../component/container/Scaffolding';
import Form from '../../component/design/container/Form';
import SubmitWideButton from '../../component/form/SubmitWideButton';
import {FormattedMessage} from 'react-intl';
import CodeField from '../../component/design/field/CodeField';
import Text from '../../component/design/typo/Text';
import Spacer from '../../component/design/utils/Spacer';

interface Props {
  user: UserStore;
  emailVerification: EmailVerificationStore;
}

interface EmailVerificationFormValues {
  code: string;
}

function submit(
  user: UserStore,
  values: EmailVerificationFormValues,
  bag: FormikHelpers<EmailVerificationFormValues>,
  login: string | undefined,
  navigate: NavigateFunction,
  emailVerificationStore: EmailVerificationStore,
) {
  if (!login) {
    throw new Error('Creds not set');
  }
  return user
    .login(login, values.code.trim())
    .then(() => {
      emailVerificationStore.resetCredentials();
      navigate('/app/dashboard');
    })
    .catch((reason: Error) => {
      ErrorUtils.setFormikError(bag, reason);
      throw reason;
    });
}

const ValuesForm: React.FunctionComponent<
  FormikProps<EmailVerificationFormValues> & {shouldSubmit: boolean}
> = (props) => {
  useEffect(() => {
    if (props.shouldSubmit) {
      void props.submitForm();
    }
  }, []);

  return (
    <Scaffolding
      header={{
        id: 'nav.emailVerification',
        defaultMessage: 'Email verification',
      }}
    >
      <Form
        footer={
          <SubmitWideButton>
            <FormattedMessage id={'common.login'} defaultMessage={'Log in'} />
          </SubmitWideButton>
        }
      >
        <Text
          text={{
            id: 'screens.VerifyEmailScreen.text',
            defaultMessage:
              'A message with verification code has been sent to your email. Enter the code to continue.',
          }}
        />
        <Spacer size={1.5} />
        <CodeField
          name={'code'}
          allowedCharacters={'numeric'}
          length={EMAIL_VERIFICATION_CODE_LENGTH}
          enterKeyHint={'go'}
          submitForm
        />
      </Form>
    </Scaffolding>
  );
};

const VerifyEmailScreen: React.FunctionComponent<Props> = ({
  user,
  emailVerification,
}) => {
  const [login, setLogin] = useState<string>();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    const creds = emailVerification.takeCredentials();
    if (creds) {
      setLogin(creds.login);
    } else {
      const queryParam = params.get('login');
      if (queryParam) {
        setLogin(queryParam);
      } else {
        navigate(-1);
      }
    }
  }, [emailVerification]);

  return (
    <Formik
      validateOnMount={false}
      validateOnChange={false}
      initialValues={{
        code: params.get('code') ?? '',
      }}
      onSubmit={(values, bag) =>
        submit(user, values, bag, login, navigate, emailVerification)
      }
    >
      {(form) => <ValuesForm {...form} shouldSubmit={params.has('code')} />}
    </Formik>
  );
};

export default inject(
  'user',
  'emailVerification',
)(observer(VerifyEmailScreen)) as unknown as React.FC;
