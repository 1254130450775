import React, {FC} from 'react';
import cls from './style.module.scss';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const FlatButtonContainer: FC<Props> = ({children}) => (
  <div className={cls.container}>{children}</div>
);

export default FlatButtonContainer;
