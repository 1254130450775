import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/huobi/apikeys/verify.png';

const VerifyKeyCreationFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.huobi.VerifyKeyCreationFragment.title',
        defaultMessage:
          'Send code to your email and enter received code and 2FA',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} />
  </>
);

export default VerifyKeyCreationFragment;
