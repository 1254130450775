import {ExchangeConnectionDetailsStateEnum} from '../../../../api';
import {FC} from 'react';
import StatusHeader from '../../container/StatusHeader';

interface Props {
  state: ExchangeConnectionDetailsStateEnum;
}

const ExchangeConnectionStateHeader: FC<Props> = ({state}) => {
  if (state === ExchangeConnectionDetailsStateEnum.Fetching) {
    return (
      <StatusHeader
        color={'primary'}
        text={{
          id: 'common.fetchingInformation',
          defaultMessage: 'Fetching information...',
        }}
        icon={'reset'}
      />
    );
  } else if (state === ExchangeConnectionDetailsStateEnum.ExpiresSoon) {
    return (
      <StatusHeader
        color={'yellow'}
        text={{
          id: 'common.expiresSoon',
          defaultMessage: 'Expires soon',
        }}
        icon={'clock-mini'}
      />
    );
  } else if (
    state === ExchangeConnectionDetailsStateEnum.MinorConfigurationProblems
  ) {
    return (
      <StatusHeader
        color={'yellow'}
        text={{
          id: 'common.incorrectlyConfigured',
          defaultMessage: 'Incorrectly configured',
        }}
        icon={'link-mini-warning'}
      />
    );
  } else if (
    state === ExchangeConnectionDetailsStateEnum.CriticalConfigurationProblems
  ) {
    return (
      <StatusHeader
        color={'red'}
        text={{
          id: 'common.incorrectlyConfigured',
          defaultMessage: 'Incorrectly configured',
        }}
        icon={'link-mini-error'}
      />
    );
  } else if (state === ExchangeConnectionDetailsStateEnum.Expired) {
    return (
      <StatusHeader
        color={'red'}
        text={{
          id: 'common.expired',
          defaultMessage: 'Connection expired',
        }}
        icon={'clock-mini'}
      />
    );
  } else if (state === ExchangeConnectionDetailsStateEnum.Failed) {
    return (
      <StatusHeader
        color={'red'}
        text={{
          id: 'common.failed',
          defaultMessage: 'Connection failed',
        }}
        icon={'link-mini-error'}
      />
    );
  } else {
    return <></>;
  }
};

export default ExchangeConnectionStateHeader;
