import React from 'react';
import WideButton from '../../component/ui/WideButton';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {RobotStore} from '../../store/RobotStore';
import {ifSuccessful} from '../../utils';
import Spacer from '../../component/design/utils/Spacer';
import AssetDistributionCard from '../../component/ui/AssetDistributionCard';
import OrderStatsCard from '../../component/ui/OrderStatsCard';
import {useNavigate} from 'react-router-dom';

interface Props {
  robot: RobotStore;
}

const RobotSummaryFragment: React.FunctionComponent<Props> = ({robot}) => {
  const navigate = useNavigate();
  return (
    <>
      <OrderStatsCard
        chart={robot.chartData}
        date={robot.chartPeriod}
        onChange={robot.setChartPeriod}
        onFooterClick={() => navigate('/app/trades')}
        moneyCurrency={'USDT'}
      />
      <Spacer size={1} />
      <AssetDistributionCard info={robot.summary} />
      <Spacer size={2} />
      <WideButton>
        <FormattedMessage
          id={'fragments.RobotSummaryFragment.robotsOnline'}
          defaultMessage={'Robots online: {online}'}
          values={{
            online: ifSuccessful(robot.summary, (t) => t.workingRobotCount, 0),
          }}
        />
      </WideButton>
      <Spacer size={1} />
    </>
  );
};

export default inject('robot')(
  observer(RobotSummaryFragment),
) as unknown as React.FC;
