import {FC} from 'react';

interface Props {
  width?: number;
  height?: number;
}

const BinanceIcon: FC<Props> = ({width, height}) => (
  <svg
    width={width ?? 24}
    height={height ?? 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_770_8802)">
      <path
        d="M7.3409 10.0846L12.0023 5.4252L16.6656 10.0884L19.3764 7.37576L12.0023 0L4.62823 7.37387L7.3409 10.0846Z"
        fill="#F3BA2F"
      />
      <path
        d="M0 12.0004L2.71168 9.28883L5.42335 12.0004L2.71168 14.712L0 12.0004Z"
        fill="#F3BA2F"
      />
      <path
        d="M7.34085 13.9154L12.0022 18.5767L16.6655 13.9135L19.3782 16.6223L12.0022 24L4.62438 16.6242L7.34085 13.9154Z"
        fill="#F3BA2F"
      />
      <path
        d="M18.5766 12.0012L21.2883 9.28956L24 12.0012L21.2883 14.7128L18.5766 12.0012Z"
        fill="#F3BA2F"
      />
      <path
        d="M14.7528 11.9991L12.0022 9.2467L9.9682 11.2807L9.73314 11.5138L9.24785 11.9991L9.25165 12.0048L12.0022 14.7534L14.7547 11.9991H14.7528Z"
        fill="#F3BA2F"
      />
    </g>
    <defs>
      <clipPath id="clip0_770_8802">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BinanceIcon;
