import {FC, ReactNode} from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import {FormatXMLElementFn, PrimitiveType} from 'intl-messageformat';

type Values = Record<
  string,
  ReactNode | PrimitiveType | FormatXMLElementFn<ReactNode, ReactNode>
>;

export type HText = (MessageDescriptor & {values?: Values}) | string;

interface Props {
  text: HText;
  values?: Values;
  className?: string;
}
const Typography: FC<Props> = ({text, className, values}) => {
  let v: Values = {
    b: (chunks) => <b>{chunks}</b>,
    h: (chunks) => <span className={'text-highlighted'}>{chunks}</span>,
  };
  if (values) {
    v = {...v, ...values};
  }
  const txt = text as unknown as {values?: Values};
  if (typeof text !== 'string' && txt.values) {
    v = {...v, ...txt.values};
  }

  return (
    <span className={className}>
      {typeof text === 'string' ? (
        text
      ) : text.id ? (
        <FormattedMessage
          id={text.id}
          defaultMessage={text.defaultMessage}
          description={text.description}
          values={v}
        />
      ) : (
        (text as unknown as string)
      )}
    </span>
  );
};

export default Typography;
