import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/huobi/first/enter-email-code.png';

const EnterEmailCodeFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.huobi.EnterEmailCodeFragment.title',
        defaultMessage:
          'Verification code was sent to your email. Paste it into HTX app',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} />
  </>
);

export default EnterEmailCodeFragment;
