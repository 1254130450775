import {FC, ReactNode} from 'react';
import cls from './style.module.scss';
import Typography, {HText} from '../../typo/Typography';

interface Props {
  contentClassName?: string;
  children: ReactNode;
  selected?: boolean;
  label?: HText;
  error?: HText | true;
  onContentClick?: () => void;
}

const InputBase: FC<Props> = ({
  children,
  selected,
  label,
  error,
  onContentClick,
  contentClassName,
}) => (
  <div
    className={
      cls.root + ' ' + (selected && cls.selected) + ' ' + (error && cls.error)
    }
  >
    {label && <Typography className={cls.label} text={label} />}

    <div
      className={cls.container + ' ' + contentClassName}
      onClick={onContentClick}
    >
      {children}
    </div>

    {error && error !== true && (
      <Typography text={error} className={cls.errorLabel} />
    )}
  </div>
);

export default InputBase;
