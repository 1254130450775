import React from 'react';
import {ReactComponent as LogoSVG} from '../../../assets/images/logo.svg';
import {FormattedMessage} from 'react-intl';
import cls from './style.module.scss';

interface Props {
  withText?: boolean;
  className?: string;
}

const Logo: React.FunctionComponent<Props> = ({withText, className}) => (
  <div className={`center-vertically ${className ?? ''}`}>
    <LogoSVG />
    {withText && (
      <p className={cls.text}>
        <FormattedMessage
          id={'components.Logo.text1'}
          defaultMessage={'A simple robot'}
        />
        <br />
        <FormattedMessage
          id={'components.Logo.text2'}
          defaultMessage={'for your enjoyment.'}
        />
      </p>
    )}
  </div>
);

export default Logo;
