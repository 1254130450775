import React from 'react';
import cls from './style.module.scss';
import {ReactComponent as MaintenanceBanner} from '../../../assets/images/notification-banners/header_maintenance.svg';
import {ReactComponent as NoInternetBanner} from '../../../assets/images/notification-banners/header_no_internet.svg';
import {FormattedMessage} from 'react-intl';
import {CurrentMaintenance} from '../../../store/MaintenanceStore';
import MaintenanceUtils from '../../../utils/MaintenanceUtils';

interface Props {
  maintenance: CurrentMaintenance;
  onDismiss?: () => void;
}

const MaintenanceDialog: React.FunctionComponent<Props> = ({
  maintenance,
  onDismiss,
}) => (
  <div>
    <div className={cls.backdrop} />
    <div className={cls.modalContainer}>
      <div className={cls.modal}>
        {MaintenanceUtils.getBanner(maintenance) === 'no_internet' ? (
          <NoInternetBanner className={cls.banner} />
        ) : (
          <MaintenanceBanner className={cls.banner} />
        )}
        <h4 className={cls.title}>
          <FormattedMessage {...MaintenanceUtils.getTitle(maintenance)} />
        </h4>
        <p className={cls.text}>
          <FormattedMessage {...MaintenanceUtils.getText(maintenance)} />
        </p>
        {!MaintenanceUtils.isBlockingAccess(maintenance) && (
          <div onClick={onDismiss} className={cls.button}>
            <FormattedMessage id={'common.ok'} defaultMessage={'Ok'} />
          </div>
        )}
      </div>
    </div>
  </div>
);

export default MaintenanceDialog;
