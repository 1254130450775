import {FC} from 'react';
import GPlayStoreIcon from './GPlayStoreIcon';
import AppStoreIcon from './AppStoreIcon';

interface Props {
  store: 'gplay' | 'appstore';
  size?: number;
}

const StoreIcon: FC<Props> = ({store, size}) => {
  switch (store) {
    case 'gplay':
      return <GPlayStoreIcon width={size} height={size} />;
    case 'appstore':
      return <AppStoreIcon width={size} height={size} />;
  }
};

export default StoreIcon;
