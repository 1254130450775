import {CostContainer} from '../../../../utils/SubscriptionUtils';
import {CurrentSubscriptionDetailsPeriodEnum} from '../../../../api';
import {FC, memo} from 'react';
import cls from './style.module.scss';
import SubscriptionPeriodSelectCard from './card';

interface Props extends CostContainer {
  current: CurrentSubscriptionDetailsPeriodEnum;
  onChange: (period: CurrentSubscriptionDetailsPeriodEnum) => void;
}

const SubscriptionPeriodSelect: FC<Props> = ({
  current,
  onChange,
  costThreeMonth,
  costMonthly,
  costYearly,
}) => (
  <div className={cls.container}>
    <SubscriptionPeriodSelectCard
      period={CurrentSubscriptionDetailsPeriodEnum.Monthly}
      price={costMonthly}
      selected={current === CurrentSubscriptionDetailsPeriodEnum.Monthly}
      onClick={() => onChange(CurrentSubscriptionDetailsPeriodEnum.Monthly)}
    />
    <SubscriptionPeriodSelectCard
      period={CurrentSubscriptionDetailsPeriodEnum.ThreeMonth}
      price={costThreeMonth}
      selected={current === CurrentSubscriptionDetailsPeriodEnum.ThreeMonth}
      onClick={() => onChange(CurrentSubscriptionDetailsPeriodEnum.ThreeMonth)}
    />
    <SubscriptionPeriodSelectCard
      period={CurrentSubscriptionDetailsPeriodEnum.Yearly}
      price={costYearly}
      selected={current === CurrentSubscriptionDetailsPeriodEnum.Yearly}
      onClick={() => onChange(CurrentSubscriptionDetailsPeriodEnum.Yearly)}
    />
  </div>
);

export default memo(SubscriptionPeriodSelect);
