import Icon, {IconName} from '../../graphics/Icon';
import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import BottomSheetCard from '../../container/BottomSheetCard';
import Button from '../../button/Button';
import BottomSheet from '../BottomSheet';
import cls from './style.module.scss';

interface Props {
  open: boolean;
  type?: 'default' | 'danger';
  icon: IconName;
  title: HText;
  message: HText;
  confirmText: HText;
  dismissText?: HText;
  onConfirm: () => void;
  onDismiss: () => void;
}

const ConfirmBottomSheet: FC<Props> = ({
  open,
  type = 'default',
  icon,
  title,
  message,
  confirmText,
  dismissText = {id: 'common.cancel', defaultMessage: 'Cancel'},
  onDismiss,
  onConfirm,
}) => (
  <BottomSheet open={open} onDismiss={onDismiss}>
    <BottomSheetCard
      mainClassName={cls.main}
      buttons={
        <>
          <Button type={'default'} text={dismissText} onClick={onDismiss} />
          <Button
            type={type === 'default' ? 'primary' : 'error'}
            text={confirmText}
            onClick={onConfirm}
          />
        </>
      }
    >
      <Icon
        name={icon}
        size={48}
        tint={type === 'default' ? 'white' : 'danger'}
      />
      <Typography text={title} className={cls.title} />
      <Typography text={message} className={cls.message} />
    </BottomSheetCard>
  </BottomSheet>
);

export default ConfirmBottomSheet;
