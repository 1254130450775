import {RefObject, useCallback, useEffect, useRef} from 'react';

type MeasureFn = (text: string) => number;

export function useMeasureTextWidth(
  input: RefObject<HTMLInputElement | null>,
): MeasureFn {
  const ctx = useRef<CanvasRenderingContext2D | null>(null);
  useEffect(() => {
    const canvas = document.createElement('canvas');
    const c = canvas.getContext('2d');
    if (!c) {
      return;
    }

    c.lineWidth = 1;
    c.font = window.getComputedStyle(input.current ?? document.body)?.font;
    ctx.current = c;

    return () => {
      canvas.remove();
    };
  }, [input]);

  return useCallback((text) => {
    return ctx.current?.measureText(text)?.width ?? 0;
  }, []);
}
