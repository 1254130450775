import React from 'react';
import cls from './style.module.scss';
import Loader from '../Loader';

interface Props {
  className?: string;
}

const LoadingBlock: React.FunctionComponent<Props> = ({className}) => (
  <div className={cls.container + ' ' + (className ?? '')}>
    <Loader size={64} />
  </div>
);

export default LoadingBlock;
