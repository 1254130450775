import {Storage} from './Storage';
import StorageManager from './StorageManager';
import Logger from '../manager/Logger';
import StorageBackend from './backend/StorageBackend';
import LocalStorageBackend from './backend/LocalStorageBackend';
import {ExchangeConnectionPublicKey} from '../api';
import AESEncryptedStorageBackendDecorator from './backend/AESEncryptedStorageBackendDecorator';
import SodiumUtils from '../utils/SodiumUtils';

interface EncryptedApiKeys {
  key?: string;
  secret?: string;
  pk: ExchangeConnectionPublicKey;
}

class ApiKeysStorage implements Storage {
  private static instance: ApiKeysStorage;
  private readonly backend: StorageBackend =
    new AESEncryptedStorageBackendDecorator(
      new LocalStorageBackend('apiKeys'),
      'cVn4x6V0lhc0Gv0h86XDu4Ea',
    );

  static get(): ApiKeysStorage {
    if (!this.instance) {
      this.instance = new ApiKeysStorage();
      StorageManager.addStorage('apiKeys', this.instance);
      Logger.debug('ApiKeysStorage', 'start', 'ApiKeysStorage started');
    }
    return this.instance;
  }

  private get publicKey(): ExchangeConnectionPublicKey | undefined {
    const data = this.backend.getString('pk');
    if (data) {
      return JSON.parse(data);
    } else {
      return undefined;
    }
  }

  private set publicKey(pk: ExchangeConnectionPublicKey | undefined) {
    if (pk) {
      this.backend.set('pk', JSON.stringify(pk));
    } else {
      this.backend.delete('pk');
    }
  }

  setApiKey(key: string, pk: ExchangeConnectionPublicKey) {
    let currentPk = this.publicKey;
    if (!currentPk) {
      currentPk = pk;
      this.publicKey = pk;
    }

    this.backend.set('key', SodiumUtils.seal(key, currentPk.key));
  }

  setApiSecret(secret: string, pk: ExchangeConnectionPublicKey) {
    let currentPk = this.publicKey;
    if (!currentPk) {
      currentPk = pk;
      this.publicKey = pk;
    }

    this.backend.set('secret', SodiumUtils.seal(secret, currentPk.key));
  }

  get(): EncryptedApiKeys | undefined {
    const pk = this.publicKey;
    if (!pk) {
      return undefined;
    }
    return {
      pk,
      key: this.backend.getString('key'),
      secret: this.backend.getString('secret'),
    };
  }

  reset() {
    this.backend.delete('pk');
    this.backend.delete('key');
    this.backend.delete('secret');
  }

  onUserLogout() {
    this.backend.delete('pk');
    this.backend.delete('key');
    this.backend.delete('secret');
  }
}

export default ApiKeysStorage;
