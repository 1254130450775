import {FC, useEffect, useState} from 'react';
import cls from './style.module.scss';
import DefaultAvatar from './def';

interface Props {
  size: number;
  src: string;
  onClick?: () => void;
  className?: string;
  badge?: string;
}

const Avatar: FC<Props> = ({size, src, onClick, className}) => {
  const [photoError, setPhotoError] = useState(false);
  useEffect(() => {
    setPhotoError(false);
  }, [src]);
  return photoError ? (
    <DefaultAvatar
      src={src}
      width={size}
      height={size}
      className={cls.image + ' ' + className}
      onClick={onClick}
    />
  ) : (
    <img
      width={size}
      height={size}
      src={src}
      alt={'avatar'}
      className={cls.image + ' ' + className}
      onClick={onClick}
      onError={() => setPhotoError(true)}
    />
  );
};

export default Avatar;
