import {ExchangeConnectionStore} from '../../../store/ExchangeConnectionStore';
import {FC, useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import Scaffolding from '../../../component/container/Scaffolding';
import AsyncStateWrapper from '../../../component/ui/AsyncStateWrapper';
import ExchangeConnectionItem from '../../../component/design/data/ExchangeConnectionItem';
import List from '../../../component/design/container/List';
import {useNavigate} from 'react-router-dom';

interface RProps {
  exchangeConnection: ExchangeConnectionStore;
}

const ExchangeConnectionListScreen: FC<RProps> = ({exchangeConnection}) => {
  const nav = useNavigate();
  useEffect(() => {
    exchangeConnection.loadConnections();
  }, []);
  return (
    <Scaffolding
      header={{
        id: 'nav.exchangeConnectionList',
        defaultMessage: 'My connections',
      }}
    >
      <AsyncStateWrapper state={exchangeConnection.connections}>
        {(conns) => (
          <List>
            {conns.map((c) => (
              <ExchangeConnectionItem
                {...c}
                key={c.id}
                onClick={() => nav('/app/exchange-connections/' + c.id)}
                onEdit={() => nav('/app/exchange-connections/' + c.id)}
              />
            ))}
          </List>
        )}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default inject('exchangeConnection')(
  observer(ExchangeConnectionListScreen),
) as FC<Partial<RProps>>;
