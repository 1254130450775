import React from 'react';
import cls from './style.module.scss';
import Logo from '../../../component/ui/Logo';
import WideButton from '../../../component/ui/WideButton';
import {FormattedMessage} from 'react-intl';
import Spacer from '../../../component/design/utils/Spacer';
import SecondaryButton from '../../../component/ui/SecondaryButton';
import {MAIN_SITE_URL} from '../../../config';
import {useNavigate} from 'react-router-dom';

const WelcomeScreen: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div className={cls.container}>
      <Logo withText className={cls.logo} />
      <div className={cls.content}>
        <WideButton onClick={() => navigate('/login')}>
          <FormattedMessage
            id={'screens.WelcomeScreen.login'}
            defaultMessage={'Log in to your account'}
          />
        </WideButton>
        <Spacer size={2} />
        <SecondaryButton onClick={() => navigate('/register')}>
          <FormattedMessage
            id={'screens.WelcomeScreen.register'}
            defaultMessage={'Create new account'}
          />
        </SecondaryButton>
        <Spacer size={5} />
        <a href={MAIN_SITE_URL}>
          <FormattedMessage
            id={'screens.WelcomeScreen.footerText'}
            defaultMessage={'www.robowallet.org'}
          />
        </a>
      </div>
    </div>
  );
};

export default WelcomeScreen;
