import React from 'react';
import {
  FormattedMessage,
  FormattedNumberParts,
  MessageDescriptor,
} from 'react-intl';
import cls from './style.module.scss';
import {ReactComponent as TradeArrow} from '../../../assets/images/trade-arrow.svg';
import {format} from 'date-fns';
import CurrencyUtils from '../../../utils/CurrencyUtils';

interface Props {
  type: MessageDescriptor;
  date?: Date;
  price: number;
  amount: number;
  volume: number;
}

const TradeLine: React.FunctionComponent<Props> = ({
  type,
  date,
  price,
  amount,
  volume,
}) => (
  <div className={cls.tradeLine}>
    <span className={cls.tradeArrow}>
      <TradeArrow />
      <span>
        <FormattedMessage {...type} />
      </span>
    </span>
    <span className={cls.date}>
      {date !== undefined ? (
        <>
          {format(date, 'dd.MM.yy')}
          <br />
          {format(date, 'HH:mm')}
        </>
      ) : (
        <>
          --.--.--
          <br />
          --:--
        </>
      )}
    </span>
    <span className={cls.field}>
      <FormattedNumberParts
        value={price}
        signDisplay={'exceptZero'}
        maximumFractionDigits={8}
      >
        {CurrencyUtils.formatBalance}
      </FormattedNumberParts>{' '}
    </span>
    <span className={cls.operation}>*</span>
    <span className={cls.field}>
      <FormattedNumberParts
        value={amount}
        signDisplay={'exceptZero'}
        maximumFractionDigits={8}
      >
        {CurrencyUtils.formatBalance}
      </FormattedNumberParts>{' '}
    </span>
    <span className={cls.operation + ' ' + cls.eq}>=</span>
    <span className={cls.field + ' ' + cls.volume}>
      <FormattedNumberParts
        value={volume}
        signDisplay={'exceptZero'}
        maximumFractionDigits={8}
      >
        {CurrencyUtils.formatBalance}
      </FormattedNumberParts>{' '}
    </span>
  </div>
);

export default TradeLine;
