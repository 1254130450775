class ClipboardUtils {
  static write(data: unknown) {
    if (navigator.clipboard && window.isSecureContext && data) {
      void navigator.clipboard
        .writeText(data.toString())
        .catch((reason) => console.log(reason));
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = data as string;
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';
      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  }

  static readText(): Promise<string> {
    if (navigator.clipboard) {
      return navigator.clipboard.readText();
    } else {
      return Promise.reject(new Error('Clipboard API not supported'));
    }
  }
}

export default ClipboardUtils;
