import './axios/InternetReachablility';
import './axios/Problem';

import {InternetUnreachableError} from './axios/InternetReachablility';
import {Problem, ProblemError} from './axios/Problem';
import axios from 'axios';

axios.defaults.validateStatus = (status) => status >= 200 && status < 300;

export type {Problem};
export {InternetUnreachableError, ProblemError};
export * from './src';
