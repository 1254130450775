import axios, {AxiosError} from 'axios';

export type Problem = {[key: string]: unknown} & {
  type: string;
  title: string;
  detail: string;
};

export class ProblemError extends Error {
  readonly problem: Problem;

  get type(): string {
    return this.problem.type;
  }

  get title(): string {
    return this.problem.title;
  }

  get detail(): string {
    return this.problem.detail;
  }

  constructor(problem: Problem) {
    super(problem.title);
    this.problem = problem;
  }
}

axios.interceptors.response.use(
  (value) => value,
  (error: Error | AxiosError) => {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (typeof error.response.data === 'object') {
          throw new ProblemError(error.response.data as Problem);
        }
      }
    }
    throw error;
  },
);
