import React, {useEffect} from 'react';
import {SentryRoutes} from '../manager/Sentry';
import {inject, observer} from 'mobx-react';
import {UserStore} from '../store/UserStore';
import {
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import AuthorizedRoute from '../component/nav/AuthorizedRoute';

import WelcomeScreen from './full/WelcomeScreen/index';
import LoginScreen from './full/LoginScreen';
import RegisterScreen from './full/RegisterScreen';
import VerifyEmailScreen from './full/VerifyEmailScreen';
import DashboardFragment from './fragment/DashboardFragment';
import ProfileFragment from './fragment/ProfileFragment';
import RobotDashboardFragment from './fragment/RobotDashboardFragment';
import RobotSummaryFragment from './fragment/RobotSummaryFragment';
import RobotDetailsFragment from './fragment/RobotDetailsFragment';
import AddRobotFragment from './fragment/AddRobotFragment';
import EditRobotConfigScreen from './full/EditRobotConfigScreen';
import EditRobotNameScreen from './full/EditRobotNameScreen';
import EditRobotKeysScreen from './full/EditRobotKeysScreen';
import WalletTransactionDetailsScreen from './full/wallet/WalletTransactionDetailsScreen';
import InviteScreen from './full/InviteScreen';
import EditProfileScreen from './full/EditProfileScreen';
import TeamSearchScreen from './full/TeamSearchScreen';
import TeamScreen from './full/TeamScreen';
import RobotTradeDetailsScreen from './full/RobotTradeDetailsScreen';
import RobotTradeListScreen from './full/RobotTradeListScreen';
import SubscriptionScreen from './full/SubscriptionScreen';
import EditEmailNotificationsPreferences from './full/EditEmailNotificationsPreferencesScreen';
import UnsubscribeScreen from './full/UnsubscribeScreen';
import DesktopCompatilibityContainer from '../component/container/DesktopCompatilibityContainer';
import Background from '../component/container/Background';
import NotificationsListScreen from './full/NotificationsListScreen';
import NotificationsDetailsScreen from './full/NotificationsDetailsScreen';
import MaintenanceDialog from '../component/ui/MaintenanceDialog';
import MaintenanceStore from '../store/MaintenanceStore';
import SellRcoStartScreen from './full/wallet/sell-rco/SellRcoStartScreen';
import SellRcoConfirmScreen from './full/wallet/sell-rco/SellRcoConfirmScreen';
import SellRcoStatusScreen from './full/wallet/sell-rco/SellRcoStatusScreen';
import BuyRcoStartScreen from './full/wallet/buy-rco/BuyRcoStartScreen';
import BuyRcoTxidScreen from './full/wallet/buy-rco/BuyRcoTxidScreen';
import BuyRcoStatusScreen from './full/wallet/buy-rco/BuyRcoStatusScreen';
import WalletScreen from './full/wallet/WalletScreen';
import onboardingRoutes from './Onboarding';
import ExchangeConnectionStatusScreen from './full/exchange-connection/ExchangeConnectionStatusScreen';
import OnboardingGuardRoute from '../component/nav/OnboardingGuardRoute';
import SnackbarContainer from '../component/design/container/SnackbarContainer';
import ExchangeConnectionListScreen from './full/exchange-connection/ExchangeConnectionListScreen';
import ExchangeConnectionItemScreen from './full/exchange-connection/ExchangeConnectionItemScren';

interface Props {
  user: UserStore;
  maintenance: MaintenanceStore;
}

const Navigator: React.FunctionComponent<Props> = ({user, maintenance}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  useEffect(() => {
    void maintenance.load();

    const authCode = params.get('authCode');
    if (authCode) {
      params.delete('authCode');
      setParams(params);
    }

    void user.load(authCode).then((result) => {
      if (result.type === 'logged-in' && location.pathname === '/') {
        navigate('/app/dashboard');
      }
    });
  }, [user]);
  return user.loadLoggedIn.type === 'loading' ? (
    <></>
  ) : (
    <Background>
      <DesktopCompatilibityContainer>
        <SnackbarContainer>
          <SentryRoutes>
            <Route path={'/'} element={<WelcomeScreen />} />
            <Route path={'/login'} element={<LoginScreen />} />
            <Route path={'/register'} element={<RegisterScreen />} />
            <Route path={'/verify-email'} element={<VerifyEmailScreen />} />
            <Route path={'/unsubscribe'} element={<UnsubscribeScreen />} />
            <Route path={'/app'} element={<AuthorizedRoute />}>
              <Route element={<OnboardingGuardRoute />}>
                <Route
                  path={'robot/:robot/edit-config'}
                  element={<EditRobotConfigScreen />}
                />
                <Route
                  path={'robot/:robot/edit-name'}
                  element={<EditRobotNameScreen />}
                />
                <Route
                  path={'robot/:robot/edit-keys'}
                  element={<EditRobotKeysScreen />}
                />
                <Route
                  path={'robot/:robot/trades'}
                  element={<RobotTradeListScreen />}
                />
                <Route
                  path={'robot/:robot/trades/:id'}
                  element={<RobotTradeDetailsScreen />}
                />
                <Route path={'trades'} element={<RobotTradeListScreen />} />
                <Route path={'wallet'} element={<WalletScreen />} />
                <Route
                  path={'wallet/:transaction'}
                  element={<WalletTransactionDetailsScreen />}
                />

                <Route path={'wallet/sell'} element={<SellRcoStartScreen />} />
                <Route
                  path={'wallet/sell/:id/confirm'}
                  element={<SellRcoConfirmScreen />}
                />
                <Route
                  path={'wallet/sell/:id/status'}
                  element={<SellRcoStatusScreen />}
                />

                <Route path={'wallet/buy'} element={<BuyRcoStartScreen />} />
                <Route
                  path={'wallet/buy/enter-txid'}
                  element={<BuyRcoTxidScreen />}
                />
                <Route
                  path={'wallet/buy/:id/status'}
                  element={<BuyRcoStatusScreen />}
                />

                <Route path={'invite'} element={<InviteScreen />} />
                <Route path={'edit-profile'} element={<EditProfileScreen />} />
                <Route path={'team/search'} element={<TeamSearchScreen />} />
                <Route path={'team'} element={<TeamScreen />} />
                <Route path={'subscription'} element={<SubscriptionScreen />} />
                <Route
                  path={'notifications'}
                  element={<NotificationsListScreen />}
                />
                <Route
                  path={'notifications/:id'}
                  element={<NotificationsDetailsScreen />}
                />
                <Route
                  path={'notifications/settings/email'}
                  element={<EditEmailNotificationsPreferences />}
                />
                <Route
                  path={'exchange-connections'}
                  element={<ExchangeConnectionListScreen />}
                />
                <Route
                  path={'exchange-connections/:id'}
                  element={<ExchangeConnectionItemScreen />}
                />
                <Route
                  path={'exchange-connections/:id/status'}
                  element={<ExchangeConnectionStatusScreen />}
                />
                <Route path={'dashboard'} element={<DashboardFragment />}>
                  <Route path={''} element={<RobotDashboardFragment />}>
                    <Route index element={<RobotSummaryFragment />} />
                    <Route path={'add-robot'} element={<AddRobotFragment />} />
                    <Route
                      path={'robot/:robot'}
                      element={<RobotDetailsFragment />}
                    />
                  </Route>
                  <Route path={'profile'} element={<ProfileFragment />} />
                </Route>
              </Route>

              {onboardingRoutes()}
            </Route>
          </SentryRoutes>
        </SnackbarContainer>
        {maintenance.currentMaintenance && (
          <MaintenanceDialog
            maintenance={maintenance.currentMaintenance}
            onDismiss={maintenance.onDismiss}
          />
        )}
      </DesktopCompatilibityContainer>
    </Background>
  );
};

export default inject(
  'user',
  'maintenance',
)(observer(Navigator)) as unknown as React.FC;
