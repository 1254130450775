import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import selectTrade from '../../../../../assets/onboarding/binance/p2p/select-trade.png';
import enterAmount from '../../../../../assets/onboarding/binance/p2p/enter-amount.png';

const SelectTradeFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.SelectTradeFragment.title',
        defaultMessage:
          'Select trade from list, examine its terms, enter amount and press <h>Buy</h>. Also you can filter trades by min/max amount, fiat currency and payment type',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={selectTrade} second={enterAmount} />
  </>
);

export default SelectTradeFragment;
