import {FC} from 'react';

interface Props {
  width?: number;
  height?: number;
}

const GPlayStoreIcon: FC<Props> = ({width, height}) => (
  <svg
    width={width ?? 30}
    height={height ?? 32}
    viewBox="0 0 30 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.48347 0.490569C1.12375 0.877185 0.916992 1.47224 0.916992 2.24547V29.749C0.916992 30.5222 1.12375 31.1173 1.49692 31.4904L1.59441 31.5728L17.0053 16.162V15.8157L1.58096 0.408203L1.48347 0.490569Z"
      fill="#00D5FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.1315 21.3195L16.9912 16.1775V15.8178L22.1332 10.6758L22.2441 10.7447L28.3258 14.2007C30.0672 15.1824 30.0672 16.7994 28.3258 17.7946L22.2441 21.2506C22.2425 21.2506 22.1315 21.3195 22.1315 21.3195Z"
      fill="#FFCC01"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.244 21.25L16.9927 15.9971L1.48438 31.5054C2.05085 32.1139 3.00394 32.1828 4.06966 31.5878L22.244 21.25Z"
      fill="#F63142"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.244 10.7465L4.06966 0.422159C3.00563 -0.186341 2.05085 -0.103975 1.48438 0.504525L16.991 15.9977L22.244 10.7465Z"
      fill="#00FF80"
    />
  </svg>
);

export default GPlayStoreIcon;
