import {useEffect} from 'react';

export default function useWindowClick(
  fn: (evt: MouseEvent) => void,
  deps: unknown[],
  enabled = true,
) {
  useEffect(() => {
    if (enabled) {
      window.addEventListener('click', fn);
      return () => window.removeEventListener('click', fn);
    }
  }, [...deps, enabled]);
}
