import axios, {AxiosError} from 'axios';

export class InternetUnreachableError extends Error {
  constructor() {
    super('Internet unreachable');
  }
}

let online = navigator.onLine;

window.addEventListener('offline', () => (online = false));
window.addEventListener('online', () => (online = true));

axios.interceptors.response.use(
  (response) => response,
  (error: Error | AxiosError) => {
    if (axios.isAxiosError(error)) {
      if (!error.response && !online) {
        throw new InternetUnreachableError();
      }
    }
    throw error;
  },
);
