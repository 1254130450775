import React, {useEffect} from 'react';
import Scaffolding from '../../component/container/Scaffolding';
import {UserStore} from '../../store/UserStore';
import {inject, observer} from 'mobx-react';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import ErrorUtils from '../../utils/ErrorUtils';
import {NavigateFunction, useNavigate, useSearchParams} from 'react-router-dom';
import {FormattedMessage, IntlShape, useIntl} from 'react-intl';
import {EmailVerificationStore} from '../../store/EmailVerificationStore';
import InviteCodeStore from '../../store/InviteCodeStore';
import Form from '../../component/design/container/Form';
import SubmitWideButton from '../../component/form/SubmitWideButton';
import TextField from '../../component/design/field/TextField';
import Spacer from '../../component/design/utils/Spacer';

interface Props {
  user: UserStore;
  emailVerification: EmailVerificationStore;
  inviteCode: InviteCodeStore;
}

interface RegisterFormValues {
  email: string;
  inviteCode: string;
}

function submit(
  user: UserStore,
  values: RegisterFormValues,
  bag: FormikHelpers<RegisterFormValues>,
  emailVerification: EmailVerificationStore,
  navigate: NavigateFunction,
) {
  return user
    .register(values.email, values.inviteCode)
    .then(() => {
      emailVerification.putCredentials({
        login: values.email,
      });
      navigate('/verify-email');
    })
    .catch((reason: Error) => {
      ErrorUtils.setFormikError(bag, reason);
      throw reason;
    });
}

function validate(values: RegisterFormValues, intl: IntlShape) {
  const ret: Partial<Record<keyof RegisterFormValues, string>> = {};
  if (values.email.trim() === '') {
    ret.email = intl.formatMessage({
      id: 'form.emailEmpty',
      defaultMessage: 'Email is empty',
    });
  }
  const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
  if (!reg.test(values.email)) {
    ret.email = intl.formatMessage({
      id: 'form.emailInvalid',
      defaultMessage: 'Email is invalid',
    });
  }
  return ret;
}

const ValuesForm: React.FunctionComponent<
  FormikProps<RegisterFormValues> & {showInvite: boolean}
> = ({showInvite}) => {
  const navigate = useNavigate();

  return (
    <Scaffolding
      header={{
        id: 'nav.register',
        defaultMessage: 'Create a New Robot',
      }}
      onBackPress={() => navigate('/')}
    >
      <Form
        footer={
          <SubmitWideButton>
            <FormattedMessage
              id={'screen.RegisterScreen.button.confirm'}
              defaultMessage={'Register'}
            />
          </SubmitWideButton>
        }
      >
        <TextField
          label={{id: 'common.email', defaultMessage: 'Email address'}}
          name={'email'}
          inputMode={'email'}
          enterKeyHint={showInvite ? 'next' : 'go'}
          submitForm={!showInvite}
        />
        {showInvite && (
          <>
            <Spacer size={1} />
            <TextField
              label={{
                id: 'screen.RegisterScreen.field.inviteCode',
                defaultMessage: 'Invite Code',
              }}
              name={'inviteCode'}
              enterKeyHint={'go'}
              submitForm
            />
          </>
        )}
      </Form>
    </Scaffolding>
  );
};

const RegisterScreen: React.FunctionComponent<Props> = ({
  user,
  emailVerification,
  inviteCode,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  useEffect(() => {
    inviteCode.set(params.get('invite'));
  }, [params]);
  return (
    <Formik
      validateOnMount={false}
      validateOnChange={false}
      initialValues={{
        email: '',
        inviteCode: params.get('invite') ?? inviteCode.code ?? '',
      }}
      onSubmit={(values, bag) =>
        submit(user, values, bag, emailVerification, navigate)
      }
      validate={(values) => validate(values, intl)}
    >
      {(form) => (
        <ValuesForm
          {...form}
          showInvite={inviteCode.code === undefined && !params.has('invite')}
        />
      )}
    </Formik>
  );
};

export default inject(
  'user',
  'emailVerification',
  'inviteCode',
)(observer(RegisterScreen)) as unknown as React.FC;
