import {Storage} from './Storage';
import Logger from '../manager/Logger';

class StorageManager {
  private static storages: Map<string, Storage> = new Map<string, Storage>();

  static addStorage(name: string, storage: Storage): void {
    StorageManager.storages.set(name, storage); // if storage already exists -> override
  }

  static onUserLogout(): void {
    for (const [name, value] of this.storages.entries()) {
      Logger.debug(
        `StorageManager/${name}`,
        'onUserLogout',
        `Running '${name}' handler`,
      );
      try {
        value.onUserLogout();
      } catch (e: unknown) {
        Logger.error(
          `StorageManager/${name}`,
          'onUserLogout',
          e instanceof Error ? e.message : e?.toString() ?? 'Unknown error',
        );
      }
    }
  }
}

export default StorageManager;
