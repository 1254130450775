import {Storage} from './Storage';
import StorageManager from './StorageManager';
import Logger from '../manager/Logger';
import StorageBackend from './backend/StorageBackend';
import AESEncryptedStorageBackendDecorator from './backend/AESEncryptedStorageBackendDecorator';
import LocalStorageBackend from './backend/LocalStorageBackend';

class InviteCodeStorage implements Storage {
  private static instance: InviteCodeStorage;
  private readonly backend: StorageBackend =
    new AESEncryptedStorageBackendDecorator(
      new LocalStorageBackend('token'),
      'UUY4&mpWXoNF1vUL9^b10!0F',
    );

  static get(): InviteCodeStorage {
    if (!this.instance) {
      this.instance = new InviteCodeStorage();
      StorageManager.addStorage('inviteCode', this.instance);
      Logger.debug('InviteCodeStorage', 'start', 'InviteCodeStorage started');
    }
    return this.instance;
  }

  getInviteCode(): string | undefined {
    return this.backend.getString('inviteCode');
  }

  setInviteCode(code: string) {
    this.backend.set('inviteCode', code);
  }

  onUserLogout() {}
}

export default InviteCodeStorage;
