import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import {TreeLaneItem, TreeLanePage} from '../api';
import {notLoaded, wrapRefreshablePromise} from '../utils';
import stores from '../store';

interface HistoryItem {
  name: string;
  id: number;
}

class TeamViewModel {
  history: HistoryItem[] = [];
  tree: TreeLaneItem[] = [];
  currentPage: RefreshableAsyncState<TreeLanePage> = notLoaded();
  nextPageToken?: string;
  user: number;
  name: string;

  constructor(user: number, name: string) {
    this.user = user;
    this.name = name;
    this.history.push({id: user, name: name});
    makeAutoObservable(this, {
      history: observable,
      user: observable,
      currentPage: observable,
      tree: observable,
      nextPage: action.bound,
      goTo: action.bound,
      goBack: action.bound,
    });
    this.loadPage();
  }

  nextPage() {
    if (
      this.currentPage.status === 'success' &&
      this.nextPageToken !== undefined &&
      this.nextPageToken !== null
    ) {
      this.loadPage(this.nextPageToken);
    }
  }

  goTo(id: number) {
    const user = this.tree.find((value) => value.id === id);
    if (!user) {
      return;
    }
    this.history.push({id: user.id, name: user.name});

    this.user = user.id;
    this.name = user.name;
    this.tree = [];
    this.currentPage = notLoaded();
    this.nextPageToken = undefined;
    this.loadPage();
  }

  goBack(id: number) {
    const idx = this.history.findIndex((value) => value.id === id);
    if (idx === -1) {
      return;
    }
    this.history.splice(idx + 1);

    this.user = this.history[this.history.length - 1].id;
    this.name = this.history[this.history.length - 1].name;
    this.tree = [];
    this.currentPage = notLoaded();
    this.nextPageToken = undefined;
    this.loadPage();
  }

  private loadPage(token?: string) {
    void wrapRefreshablePromise(
      stores.referral.loadLane(this.user, token ?? undefined),
      this.currentPage,
      (v) =>
        runInAction(() => {
          if (v.status === 'success') {
            this.nextPageToken = v.result.nextPageToken;
            this.tree.push(...v.result.items);
          }
          this.currentPage = v;
        }),
    );
  }
}

export default TeamViewModel;
