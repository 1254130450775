import React, {useCallback} from 'react';
import {MessageDescriptor} from 'react-intl';
import {FieldConfig, useField} from 'formik';
import ActionSheetSelect, {Item} from '../../ui/ActionSheetSelect';

interface Props<T> {
  header?: MessageDescriptor;
  items: Item<T>[];
  children?: React.ReactNode;
  preSelect?: (item: T) => boolean;
  postSelect?: (item: T) => void;
}

function ActionSheetSelectField<T>({
  ...props
}: Props<T> & FieldConfig<T>): React.ReactElement {
  const [field, , helpers] = useField<T>(props);
  const setValue = useCallback(
    (value: T) => {
      if (props.preSelect) {
        if (!props.preSelect(value)) {
          return;
        }
      }
      helpers.setValue(value);
      if (props.postSelect) {
        props.postSelect(value);
      }
    },
    [helpers.setValue, props.preSelect],
  );
  return (
    <ActionSheetSelect
      items={props.items}
      value={field.value}
      onChange={setValue}
      header={props.header}
    />
  );
}

export default React.memo(ActionSheetSelectField);
