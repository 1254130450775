import React from 'react';
import cls from './style.module.scss';
import {HText} from '../../typo/Typography';
import StepHeader from '../StepHeader';
import HeaderProgress from '../../data/HeaderProgress';
import FlatButtonContainer from '../FlatButtonContainer';
import FlatButton from '../../button/FlatButton';
import ErrorUtils from '../../../../utils/ErrorUtils';

interface Props {
  header: HText;
  onSkip?: () => void;
  children: React.ReactNode;
  currentStep: number;
  totalSteps: number;
  onBackClick?: () => void;
  onNextClick?: () => void;
  nextButtonState?: AsyncState<void>;
}

const SteppedScaffolding: React.FunctionComponent<Props> = ({
  header,
  children,
  currentStep,
  totalSteps,
  onSkip,
  onNextClick,
  onBackClick,
  nextButtonState,
}) => (
  <div className={cls.content}>
    <StepHeader header={header} onSkip={onSkip} />
    <HeaderProgress current={currentStep + 1} max={totalSteps} />
    <div className={cls.main}>{children}</div>
    <FlatButtonContainer>
      {onBackClick && (
        <FlatButton type={'gray'} icon={'arrow-left'} onClick={onBackClick} />
      )}
      <FlatButton
        type={
          nextButtonState && nextButtonState.status === 'error'
            ? 'error'
            : 'primary'
        }
        text={
          nextButtonState && nextButtonState.status === 'error'
            ? ErrorUtils.getMessage(nextButtonState.error)
            : {id: 'common.next', defaultMessage: 'Next'}
        }
        onClick={onNextClick}
        flex={1}
        loading={nextButtonState && nextButtonState.status === 'loading'}
      />
    </FlatButtonContainer>
  </div>
);

export default SteppedScaffolding;
