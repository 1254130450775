import React, {FC} from 'react';
import {ReactComponent as Background} from './background.svg';
import cls from './style.module.scss';
import Typography from '../../typo/Typography';

const WelcomeBanner: FC = () => (
  <div className={cls.container}>
    <Background />
    <Typography
      text={{
        id: 'components.WelcomeBanner.text',
        defaultMessage: 'Welcome to RoboWallet',
      }}
      className={cls.heading}
    />
  </div>
);

export default WelcomeBanner;
