import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/apikeys/end-gauth-1.png';
import v2 from '../../../../../assets/onboarding/bybit/apikeys/end-gauth-2.png';

const EndGAuthSetupFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.EndGAuthSetupFragment.title',
        defaultMessage:
          'Copy code from Google authenticator in Bybit, press <h>Confirm</h>.',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} overlayX={'8%'} overlayY={'25%'}>
      <svg
        width="45%"
        height="42%"
        viewBox="0 0 135 153"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.47231 2.01211C3.43123 1.11006 2.66668 0.412103 1.76463 0.453181C0.862576 0.494256 0.164617 1.25881 0.205694 2.16086L3.47231 2.01211ZM134.496 141.75C135.135 141.112 135.135 140.077 134.496 139.438L124.091 129.033C123.453 128.394 122.417 128.394 121.779 129.033C121.14 129.671 121.14 130.707 121.779 131.345L131.028 140.594L121.779 149.843C121.14 150.482 121.14 151.517 121.779 152.155C122.417 152.794 123.453 152.794 124.091 152.155L134.496 141.75ZM1.839 2.08649C0.205694 2.16086 0.205751 2.16211 0.205835 2.16389C0.205894 2.16518 0.206001 2.16749 0.206123 2.17008C0.206369 2.17525 0.206718 2.18253 0.207178 2.19189C0.208096 2.21061 0.20946 2.23767 0.211319 2.27294C0.215034 2.34347 0.220739 2.44682 0.228862 2.58193C0.245106 2.85215 0.271024 3.24944 0.310061 3.76535C0.388129 4.79712 0.518695 6.30356 0.729353 8.21691C1.15061 12.0431 1.89249 17.4994 3.17624 24.043C5.74227 37.1228 10.4809 54.586 19.178 72.0685C36.602 107.093 69.9643 142.229 133.34 142.229L133.34 138.959C71.5219 138.959 39.1337 104.841 22.1057 70.612C13.5767 53.4676 8.91394 36.3039 6.38507 23.4135C5.12136 16.9721 4.39241 11.6075 3.97971 7.85905C3.77339 5.98508 3.64619 4.51578 3.57074 3.51863C3.53302 3.02008 3.50823 2.63962 3.49297 2.3857C3.48534 2.25875 3.48009 2.16343 3.47678 2.10082C3.47513 2.0695 3.47397 2.04637 3.47324 2.03154C3.47288 2.02413 3.47262 2.01879 3.47247 2.01555C3.47239 2.01393 3.47235 2.01307 3.47232 2.01225C3.4723 2.01192 3.47231 2.01211 1.839 2.08649Z"
          fill="#FF4040"
        />
      </svg>
    </SideBySideImage>
  </>
);

export default EndGAuthSetupFragment;
