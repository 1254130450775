import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import apiKeyName from '../../../../../assets/onboarding/binance/apikeys/api-key-name.png';

const SetApiKeyNameFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.SetApiKeyNameFragment.title',
        defaultMessage: 'Set API key name',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={apiKeyName} />
  </>
);

export default SetApiKeyNameFragment;
