import React from 'react';
import cls from './style.module.scss';
import {FormattedMessage, FormattedNumberParts} from 'react-intl';
import CurrencyUtils from '../../../utils/CurrencyUtils';

interface Props {
  moneyCurrency: string;
  income: number | undefined;
  incomePercent: number | undefined;
}

const TotalIncomeRow: React.FunctionComponent<Props> = ({
  moneyCurrency,
  income,
  incomePercent,
}) => (
  <span className={cls.balanceUsd}>
    <FormattedMessage
      id={'components.BalanceCard.TotalBalanceRow.heading'}
      defaultMessage={'Total income:'}
    />
    <span
      className={
        cls.balanceCost +
        ' ' +
        (income !== undefined && income >= 0 ? cls.balanceUp : cls.balanceDown)
      }
    >
      {income !== undefined ? (
        <>
          {' +'}
          <FormattedNumberParts
            value={income}
            maximumFractionDigits={CurrencyUtils.getDisplayMaxFractionalDigits(
              moneyCurrency,
            )}
          >
            {CurrencyUtils.formatBalance}
          </FormattedNumberParts>
          {' ' + moneyCurrency.toUpperCase()}
          <span
            className={
              cls.balancePercent +
              ' ' +
              (incomePercent !== undefined && incomePercent >= 0
                ? cls.balanceUp
                : cls.balanceDown)
            }
          >
            {incomePercent !== undefined && ` (≈ ${incomePercent / 100}%)`}
          </span>
        </>
      ) : (
        ''
      )}
    </span>
  </span>
);

export default React.memo(TotalIncomeRow);
