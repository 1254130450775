import {Route} from 'react-router-dom';
import OnboardingBaseFragment from '../OnboardingBaseFragment';
import stores from '../../../../store';
import {
  AdvanceStateDtoSelectedMarketEnum,
  AdvanceStateDtoStageEnum,
} from '../../../../api';
import CreateAccountFragment from './first/CreateAccountFragment';
import React from 'react';
import DownloadBinanceFragment from './first/DownloadBinanceFragment';
import CreateAccountNextFragment from './first/CreateAccountNextFragment';
import VerificationFragment from './first/VerificationFragment';
import SetPasswordFragment from './first/SetPasswordFragment';
import GoToVerifyFragment from './first/GoToVerifyFragment';
import CheckVerifyFragment from './first/CheckVerifyFragment';
import DownloadGAuthFragment from './first/DownloadGAuthFragment';
import Setup2FAFragment from './first/Setup2FAFragment';
import OpenP2PFragment from './p2p/OpenP2PFragment';
import SelectTradeFragment from './p2p/SelectTradeFragment';
import PayFragment from './p2p/PayFragment';
import PaymentCompletedFragment from './p2p/PaymentCompletedFragment';
import TransferToSpotFragment from './p2p/TransferToSpotFragment';
import GoToServicesFragment from './apikeys/GoToServicesFragment';
import GoToApiKeysFragment from './apikeys/GoToApiKeysFragment';
import CreateApiKeyFragment from './apikeys/CreateApiKeyFragment';
import SetApiKeyNameFragment from './apikeys/SetApiKeyNameFragment';
import Enter2FAFragment from './apikeys/Enter2FAFragment';
import SetConfigFragment from './apikeys/SetConfigFragment';
import CopyApiKeyFragment from './apikeys/CopyApiKeyFragment';
import CopyApiSecretFragment from './apikeys/CopyApiSecretFragment';

export default function binanceOnboardingRoutes() {
  return (
    <Route path={'binance'}>
      <Route
        path={'exchange-register'}
        element={
          <OnboardingBaseFragment
            header={{
              id: 'nav.onboarding.exRegister.title',
              defaultMessage: 'Exchange registration',
            }}
            count={9}
            nextBlockLink={'/app/onboarding/binance/p2p/0'}
            currentLink={'/app/onboarding/binance/exchange-register'}
            onExit={(a) =>
              stores.onboarding.advance(
                AdvanceStateDtoStageEnum.Exchange,
                a,
                AdvanceStateDtoSelectedMarketEnum.Binance,
              )
            }
            first
          />
        }
      >
        <Route path={'0'} element={<DownloadBinanceFragment />} />
        <Route path={'1'} element={<CreateAccountFragment />} />
        <Route path={'2'} element={<CreateAccountNextFragment />} />
        <Route path={'3'} element={<VerificationFragment />} />
        <Route path={'4'} element={<SetPasswordFragment />} />
        <Route path={'5'} element={<GoToVerifyFragment />} />
        <Route path={'6'} element={<CheckVerifyFragment />} />
        <Route path={'7'} element={<DownloadGAuthFragment />} />
        <Route path={'8'} element={<Setup2FAFragment />} />
      </Route>
      <Route
        path={'p2p'}
        element={
          <OnboardingBaseFragment
            header={{
              id: 'nav.onboarding.p2p.title',
              defaultMessage: 'Crypto acquisition',
            }}
            count={5}
            nextBlockLink={'/app/onboarding/binance/api-keys/0'}
            lastBlockLink={'/app/onboarding/binance/exchange-register/8'}
            currentLink={'/app/onboarding/binance/p2p'}
            onExit={(a) =>
              stores.onboarding.advance(
                AdvanceStateDtoStageEnum.P2P,
                a,
                AdvanceStateDtoSelectedMarketEnum.Binance,
              )
            }
          />
        }
      >
        <Route path={'0'} element={<OpenP2PFragment />} />
        <Route path={'1'} element={<SelectTradeFragment />} />
        <Route path={'2'} element={<PayFragment />} />
        <Route path={'3'} element={<PaymentCompletedFragment />} />
        <Route path={'4'} element={<TransferToSpotFragment />} />
      </Route>
      <Route
        path={'api-keys'}
        element={
          <OnboardingBaseFragment
            header={{
              id: 'nav.onboarding.agpiKeys.title',
              defaultMessage: 'Connection setup',
            }}
            count={9}
            lastBlockLink={'/app/onboarding/binance/p2p/4'}
            currentLink={'/app/onboarding/binance/api-keys'}
            nextBlockLink={'/app/dashboard'}
            onExit={(a) =>
              stores.onboarding.advance(
                AdvanceStateDtoStageEnum.ApiKeys,
                a,
                AdvanceStateDtoSelectedMarketEnum.Binance,
              )
            }
          />
        }
      >
        <Route path={'0'} element={<GoToServicesFragment />} />
        <Route path={'1'} element={<GoToApiKeysFragment />} />
        <Route path={'2'} element={<CreateApiKeyFragment />} />
        <Route path={'3'} element={<SetApiKeyNameFragment />} />
        <Route path={'4'} element={<Enter2FAFragment />} />
        <Route path={'5'} element={<SetConfigFragment />} />
        <Route path={'6'} element={<Enter2FAFragment />} />
        <Route path={'7'} element={<CopyApiKeyFragment />} />
        <Route path={'8'} element={<CopyApiSecretFragment />} />
      </Route>
    </Route>
  );
}
