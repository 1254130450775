import {FC} from 'react';

interface Props {
  width?: number;
  height?: number;
}

const BybitIcon: FC<Props> = ({width, height}) => (
  <svg
    width={width ?? 24}
    height={height ?? 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_770_8805)">
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H19C21.7614 0 24 2.23858 24 5V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V5Z"
        fill="#232737"
      />
      <path
        d="M15.8039 12.4634V7.87012H16.7247V12.4634H15.8039Z"
        fill="#F7A600"
      />
      <path
        d="M5.07576 13.8277H3.10266V9.2344H4.9964C5.91718 9.2344 6.45289 9.73781 6.45289 10.525C6.45305 10.7218 6.39863 10.9147 6.29571 11.0822C6.1928 11.2497 6.04545 11.3852 5.87015 11.4736C6.15454 11.6026 6.51903 11.893 6.51903 12.5055C6.51976 13.3634 5.91718 13.8277 5.07576 13.8277ZM4.92365 10.0348H4.02344V11.0925H4.92438C5.31533 11.0925 5.53358 10.8795 5.53358 10.5633C5.53358 10.2471 5.31459 10.0348 4.92365 10.0348ZM4.98317 11.8996H4.02344V13.0288H4.9839C5.40131 13.0288 5.59972 12.7708 5.59972 12.4612C5.59972 12.1517 5.3991 11.8989 4.98317 11.8989V11.8996Z"
        fill="white"
      />
      <path
        d="M9.32697 11.9438V13.8277H8.41207V11.9438L6.99526 9.2344H7.9954L8.87724 11.0859L9.74437 9.2344H10.7445L9.32697 11.9438Z"
        fill="white"
      />
      <path
        d="M13.354 13.8277H11.3801V9.2344H13.2739C14.1946 9.2344 14.7304 9.73781 14.7304 10.525C14.7305 10.7218 14.6761 10.9147 14.5732 11.0822C14.4703 11.2497 14.3229 11.3852 14.1476 11.4736C14.432 11.6026 14.7965 11.893 14.7965 12.5055C14.7972 13.3634 14.1946 13.8277 13.354 13.8277ZM13.2018 10.0348H12.3009V11.0925H13.2018C13.5928 11.0925 13.811 10.8795 13.811 10.5633C13.811 10.2471 13.5921 10.0348 13.2011 10.0348H13.2018ZM13.2614 11.8996H12.3009V13.0288H13.2621C13.6795 13.0288 13.8779 12.7708 13.8779 12.4612C13.8779 12.1517 13.678 11.8989 13.2621 11.8989L13.2614 11.8996Z"
        fill="white"
      />
      <path
        d="M19.6914 10.0348V13.8285H18.7735V10.0348H17.539V9.2344H20.923V10.0348H19.6914Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_770_8805">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BybitIcon;
