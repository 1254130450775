import {makeAutoObservable, observable, runInAction} from 'mobx';
import {WalletTransactionDetails} from '../../api';
import {notLoaded, wrapPromise} from '../../utils';
import stores from '../../store';

class WalletTransactionDetailsViewModel {
  details: AsyncState<WalletTransactionDetails> = notLoaded();

  constructor(readonly id: string) {
    makeAutoObservable(this, {
      details: observable,
    });
    runInAction(() => {
      void wrapPromise(
        stores.wallet.loadTransactionDetails(id),
        this.details,
        (v) => runInAction(() => (this.details = v)),
      );
    });
  }
}

export default WalletTransactionDetailsViewModel;
