import React, {useMemo} from 'react';
import {inject, observer} from 'mobx-react';
import Scaffolding from '../../component/container/Scaffolding';
import {useNavigate, useParams} from 'react-router-dom';
import NotificationDetailsViewModel from '../../viewmodel/NotificationsDetailsViewModel';
import NotificationDetailsScaffold from '../../component/container/NotificationDetailsScaffold';
import NotificationUtils from '../../utils/NotificationUtils';
import AsyncStateWrapper from '../../component/ui/AsyncStateWrapper';
import WideButton from '../../component/ui/WideButton';
import {FormattedMessage, useIntl} from 'react-intl';
import DateUtils from '../../utils/DateUtils';
import MiscUtils from '../../utils/MiscUtils';
import {FormatXMLElementFn, PrimitiveType} from 'intl-messageformat';
import {UserStore} from '../../store/UserStore';

interface Props {
  user: UserStore;
}

const NotificationsDetailsScreen: React.FunctionComponent<Props> = ({user}) => {
  const navigate = useNavigate();
  const params = useParams();
  const intl = useIntl();
  const vm = useMemo(
    () => new NotificationDetailsViewModel(params.id as string),
    [params.id],
  );
  const details = vm.details;
  return (
    <Scaffolding
      header={{
        id: 'nav.notification',
        defaultMessage: 'Notification',
      }}
      bottomTabs={
        vm.button && (
          <WideButton
            onClick={() => {
              if (vm.button) {
                navigate(vm.button.url);
              }
            }}
          >
            <FormattedMessage {...vm.button.message} />
          </WideButton>
        )
      }
    >
      <AsyncStateWrapper state={user.user}>
        {(user) => (
          <AsyncStateWrapper state={details}>
            {(details) => {
              const data: Record<
                string,
                PrimitiveType | FormatXMLElementFn<string, string>
              > = {
                ...MiscUtils.flatten({
                  ...details.data,
                  referralInfo: details.referralInfo,
                }),
                ts: (parts) => DateUtils.formatFullTimestamp(parts.join('')),
              };
              return (
                <NotificationDetailsScaffold
                  banner={NotificationUtils.getBanner(details.type)}
                  header={intl.formatMessage(vm.header, data)}
                  to={user.name ?? user.email}
                  footer={vm.footer.map((value) =>
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    intl.formatMessage(value, data),
                  )}
                >
                  {vm.content?.type === 'text' && (
                    <NotificationDetailsScaffold.Text
                      {...vm.content.text}
                      values={data}
                    />
                  )}
                  {vm.content?.type === 'money' && (
                    <NotificationDetailsScaffold.Money
                      money={details.data[vm.content.target] as number}
                      symbol={vm.content.symbol}
                    />
                  )}
                  {vm.content?.type === 'referral' && (
                    <NotificationDetailsScaffold.Profile
                      name={details.referralInfo?.name ?? ''}
                      photo={details.referralInfo?.photoUrl ?? ''}
                    />
                  )}
                </NotificationDetailsScaffold>
              );
            }}
          </AsyncStateWrapper>
        )}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default inject('user')(observer(NotificationsDetailsScreen)) as React.FC<
  Partial<Props>
>;
