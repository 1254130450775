import * as NSentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {
  SENTRY_DSN,
  SENTRY_MAX_STORED_EVENTS,
  SENTRY_TRACES,
  SENTRY_TRACING_ORIGINS,
} from '../config';
import {differenceInSeconds} from 'date-fns';
import {CaptureConsole} from '@sentry/integrations';
import {useEffect} from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {UserInfo} from '../api';

NSentry.init({
  dsn: SENTRY_DSN,
  autoSessionTracking: true,
  tracesSampleRate: SENTRY_TRACES,
  tracesSampler: (samplingContext) => {
    if (samplingContext.parentSampled) {
      return true;
    }
    if (
      samplingContext.transactionContext.endTimestamp &&
      samplingContext.transactionContext.startTimestamp
    ) {
      const timeSec = differenceInSeconds(
        samplingContext.transactionContext.startTimestamp,
        samplingContext.transactionContext.endTimestamp,
      );
      if (timeSec > 0) {
        return true;
      }
    }
    return SENTRY_TRACES;
  },
  environment: 'development' === process.env.NODE_ENV ? 'dev' : 'production',
  transport: NSentry.makeBrowserOfflineTransport(NSentry.makeFetchTransport),
  transportOptions: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxQueueSize: SENTRY_MAX_STORED_EVENTS,
  },
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: SENTRY_TRACING_ORIGINS,
      routingInstrumentation: NSentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new CaptureConsole(),
    new NSentry.Replay({
      maskAllInputs: true,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: process.env.NODE_ENV === 'production',
});

class Sentry {
  static setUser(user: UserInfo) {
    NSentry.setUser({
      id: user.id.toString(10),
      email: user.email,
      username: user.name,
    });
  }
  static logInfo(component: string, method: string, message: string): void {
    NSentry.addBreadcrumb({
      message,
      level: 'info',
      data: {component, method},
    });
  }

  static infoEvent(component: string, method: string, message: string): void {
    NSentry.captureMessage(message, {
      level: 'info',
      tags: {component, method},
    });
  }

  static warnEvent(component: string, method: string, message: string): void {
    NSentry.captureMessage(message, {
      level: 'warning',
      tags: {component, method},
    });
  }

  static logError(component: string, method: string, message: string): void {
    NSentry.captureMessage(message, {
      level: 'error',
      tags: {component, method},
    });
  }
}

const SentryRoutes = NSentry.withSentryReactRouterV6Routing(Routes);
export {SentryRoutes};

export default Sentry;
