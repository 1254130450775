class MiscUtils {
  static flatten(
    object: object,
    parent: string | undefined = undefined,
    res: Record<string, unknown> = {},
  ): Record<string, unknown> {
    for (const key in object) {
      const propName = parent ? parent + '_' + key : key;
      const v = (object as Record<string, unknown>)[key] as unknown;
      if (v && typeof v == 'object') {
        MiscUtils.flatten(v as Record<string, unknown>, propName, res);
      } else {
        res[propName] = v;
      }
    }
    return res;
  }

  static capitalize(str: string): string {
    if (str.length === 0) {
      return str;
    }
    if (str.length === 1) {
      return str.toUpperCase();
    }
    return str[0].toUpperCase() + str.substring(1);
  }
}

export default MiscUtils;
