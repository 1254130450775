import React from 'react';
import cls from './style.module.scss';

interface Item<T extends number | string> {
  id: T;
  name: string;
}

interface Props<T extends number | string> {
  items: Item<T>[];
  onClick?: (id: T) => void;
  alwaysShow?: boolean;
  lastElementDisabled?: boolean;
}

function Breadcrumbs<T extends number | string>(
  props: Props<T>,
): React.ReactElement {
  const onClick = props.onClick;
  if (!props.alwaysShow && props.items.length < 2) {
    return <></>;
  }
  return (
    <div className={cls.container}>
      {props.items.map((i, idx, list) => (
        <span
          onClick={
            (!props.lastElementDisabled || idx + 1 !== list.length) && onClick
              ? () => onClick(i.id)
              : undefined
          }
          key={i.id}
          className={cls.item}
        >
          <span className={cls.text}>{i.name}</span>
          {idx + 1 !== list.length && (
            <svg
              width="7"
              height="19"
              viewBox="0 0 7 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={cls.line}
            >
              <line x1="0.525658" y1="18.8419" x2="6.52566" y2="0.841886" />
            </svg>
          )}
        </span>
      ))}
    </div>
  );
}

export default Breadcrumbs;
