import {FC, ReactNode} from 'react';
import cls from './style.module.scss';

interface Props {
  children: ReactNode;
  gap?: number;
  stretch?: boolean;
}

const FlexRow: FC<Props> = ({children, gap, stretch}) => (
  <div
    className={cls.container + ' ' + (stretch && cls.stretch)}
    style={{gap: gap + 'rem'}}
  >
    {children}
  </div>
);

export default FlexRow;
