import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/huobi/apikeys/trading-settings.png';
import v2 from '../../../../../assets/onboarding/huobi/apikeys/api-management.png';

const OpenApiManagementFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.huobi.OpenApiManagementFragment.title',
        defaultMessage:
          'Click on <h>trading settings</h>, then on <h>API Management</h>',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default OpenApiManagementFragment;
