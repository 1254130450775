import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/p2p/buy-init-1.png';
import v2 from '../../../../../assets/onboarding/bybit/p2p/buy-init-2.png';

const BuyInitFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.BuyInitFragment.title',
        defaultMessage:
          'Enter amount and press <h>Buy</h>. Check data correctness and press <h>Go to Pay</h> button.',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default BuyInitFragment;
