import React, {useCallback, useState} from 'react';
import {MessageDescriptor} from 'react-intl';
import {FieldConfig, useField} from 'formik';
import {MarketPair, RobotPair} from '../../../api';
import FakeSelect from '../../ui/FakeSelect';
import TradingPairSelectSheet from '../../ui/TradingPairSelectSheet';

interface Props {
  header?: MessageDescriptor;
  children?: React.ReactNode;
  pairs: MarketPair[];
  tag?: MessageDescriptor;
  onPairSelect?: (pair: MarketPair) => boolean;
}

function ActionSheetSelectField({
  ...props
}: Props & FieldConfig<RobotPair>): React.ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [field, , helpers] = useField<RobotPair>(props);
  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);
  const closeDialog = useCallback(() => setDialogOpen(false), []);
  return (
    <>
      <FakeSelect
        header={props.header}
        value={field.value.asset + ' / ' + field.value.money}
        onClick={openDialog}
        tag={props.tag}
      />
      <TradingPairSelectSheet
        pair={field.value}
        onChange={helpers.setValue}
        open={dialogOpen}
        onDismiss={closeDialog}
        pairs={props.pairs}
        onPairSelect={props.onPairSelect}
      />
    </>
  );
}

export default React.memo(ActionSheetSelectField);
