import Icon, {IconName, IconTint} from '../../graphics/Icon';
import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import cls from './style.module.scss';

type Color = 'red' | 'green' | 'default';

interface Props {
  icon?: IconName;
  iconTint?: IconTint;
  iconRoundColor?: Color;
  text: HText;
  textColor?: Color;
  rightIcon?: IconName;
  rightIconTint?: IconTint;
  onClick?: () => void;
}

function color2tint(color: Color): IconTint {
  switch (color) {
    case 'green':
      return 'green';
    case 'default':
      return 'white';
    case 'red':
      return 'red';
  }
}

const Item: FC<Props> = ({
  icon,
  iconTint,
  iconRoundColor,
  rightIcon,
  rightIconTint,
  text,
  textColor,
  onClick,
}) => (
  <div onClick={onClick} className={cls.item}>
    {icon && iconRoundColor && (
      <div className={cls.roundContainer + ' ' + cls[iconRoundColor]}>
        <Icon
          name={icon}
          size={16}
          tint={iconTint ?? color2tint(iconRoundColor)}
        />
      </div>
    )}
    {icon && !iconRoundColor && <Icon name={icon} size={24} tint={iconTint} />}

    <Typography
      text={text}
      className={cls.text + ' ' + (textColor && cls[textColor])}
    />

    {rightIcon && <Icon name={rightIcon} size={24} tint={rightIconTint} />}
  </div>
);

export default Item;
