import {FC} from 'react';
import KVCard from '../../container/KVCard';
import {
  CurrentSubscriptionDetails,
  SubscriptionInfoStatusEnum,
} from '../../../../api';
import SubscriptionStatus from '../SubscriptionStatus';
import {observer} from 'mobx-react';
import SubscriptionUtils from '../../../../utils/SubscriptionUtils';
import {format, parseISO} from 'date-fns';
import Switch from '../../input/Switch';
import Typography from '../../typo/Typography';

interface Props {
  status: SubscriptionInfoStatusEnum;
  details: CurrentSubscriptionDetails;
  onAutoRenewChanged?: (value: boolean) => void;
  onEditBilling?: () => void;
}

const SubscriptionStatusCard: FC<Props> = ({
  status,
  details,
  onAutoRenewChanged,
  onEditBilling,
}) => (
  <KVCard>
    <KVCard.Item
      name={{
        id: 'components.SubscriptionStatus.status',
        defaultMessage: 'Status',
      }}
      value={<SubscriptionStatus status={status} />}
    />
    <KVCard.Item
      name={{
        id: 'components.SubscriptionStatus.billingPeriod',
        defaultMessage: 'Billing period',
      }}
      value={
        <Typography text={SubscriptionUtils.formatPeriod(details.period)} />
      }
      rightIcon={'edit'}
      onRightIconClick={onEditBilling}
    />
    <KVCard.Item
      name={{
        id: 'components.SubscriptionStatus.totalCost',
        defaultMessage: 'Total cost',
      }}
      value={
        <Typography
          text={SubscriptionUtils.formatCost(details.period, details)}
        />
      }
    />
    <KVCard.Item
      name={{
        id: 'components.SubscriptionStatus.lastInvoice',
        defaultMessage: 'Last invoice',
      }}
      value={format(parseISO(details.lastRenewalAt), 'dd.MM.yyyy')}
    />
    <KVCard.Item
      name={{
        id: 'components.SubscriptionStatus.subscriptionExpires',
        defaultMessage: 'Subscription expires',
      }}
      value={format(parseISO(details.nextRenewalAt), 'dd.MM.yyyy')}
    />
    <KVCard.Item
      name={{
        id: 'components.SubscriptionStatus.autorenew',
        defaultMessage: 'Automatic renewal',
      }}
      value={
        <Switch
          defaultChecked={details.autoRenew}
          onChanged={onAutoRenewChanged}
        />
      }
    />
  </KVCard>
);

export default observer(SubscriptionStatusCard);
