import {FC} from 'react';
import Scaffolding from '../../../../component/container/Scaffolding';
import Form from '../../../../component/design/container/Form';
import WideButton from '../../../../component/ui/WideButton';
import {FormattedMessage} from 'react-intl';
import FlexCol from '../../../../component/design/container/FlexCol';
import Title from '../../../../component/design/typo/Title';
import ReadOnlyInput from '../../../../component/design/input/ReadOnlyInput';
import {PROC_ADDRESS} from '../../../../config';
import ProcQR from '../../../../assets/images/proc-qr.svg';
import QRCode from '../../../../component/design/graphics/QRCode';
import {useNavigate} from 'react-router-dom';

const BuyRcoStartScreen: FC = () => {
  const nav = useNavigate();
  return (
    <Scaffolding
      header={{
        id: 'nav.buyRCO',
        defaultMessage: 'Buy RCO',
      }}
    >
      <Form
        overflow
        footer={
          <WideButton
            onClick={() => nav('/app/wallet/buy/enter-txid', {replace: true})}
          >
            <FormattedMessage
              id={'common.continue'}
              defaultMessage={'Continue'}
            />
          </WideButton>
        }
      >
        <FlexCol gap={1.5}>
          <Title
            text={{
              id: 'screens.BuyRcoStartScreen.text',
              defaultMessage:
                'To buy <h>RCO</h>, Transfer <h>USDT</h> in <h>TRON network (TRC20)</h> to this account (1 USDT = 1 RCO)',
            }}
          />
          <ReadOnlyInput
            value={PROC_ADDRESS}
            label={{
              id: 'common.walletAddress',
              defaultMessage: 'Wallet address (TRC20 network)',
            }}
          />
          <QRCode
            image={ProcQR}
            alt={PROC_ADDRESS}
            label={{
              id: 'screens.BuyRcoStartScreen.qrLabel',
              defaultMessage: 'or scan this QR code',
            }}
          />
        </FlexCol>
      </Form>
    </Scaffolding>
  );
};

export default BuyRcoStartScreen;
