import {FC, useEffect, useMemo} from 'react';
import {inject, observer} from 'mobx-react';
import AsyncStateWrapper from '../../../../component/ui/AsyncStateWrapper';
import {useNavigate, useParams} from 'react-router-dom';
import WideButton from '../../../../component/ui/WideButton';
import {FormattedMessage} from 'react-intl';
import WithdrawalRequestCard from '../../../../component/design/data/WithdrawalRequestCard';
import {WalletStore} from '../../../../store/WalletStore';
import SellRcoStatusViewModel from '../../../../viewmodel/wallet/SellRcoStatusViewModel';
import Status from '../../../../component/design/container/Status';
import Illustration from '../../../../component/design/graphics/Illustration';
import WithdrawalUtils from '../../../../utils/WithdrawalUtils';
import IntlUtils from '../../../../utils/IntlUtils';

interface RProps {
  wallet: WalletStore;
}

const SellRcoStatusScreen: FC<RProps> = ({wallet}) => {
  const navigate = useNavigate();
  const params = useParams();
  const vm = useMemo(
    () => new SellRcoStatusViewModel(params.id ?? ''),
    [params.id],
  );
  useEffect(() => {
    wallet.initWithdrawal();
    vm.init();
    return () => vm.stopUpdate();
  }, [vm]);

  const req = vm.request;
  return (
    <AsyncStateWrapper state={wallet.withdrawalRequestConfig}>
      {(cfg) => (
        <AsyncStateWrapper state={req}>
          {(req) => {
            const status = WithdrawalUtils.simplifyStatus(req.status);
            return (
              <Status
                illustration={
                  status === 'loading' ? (
                    <Illustration.Progress />
                  ) : status === 'error' ? (
                    <Illustration.Failure />
                  ) : (
                    <Illustration.Success />
                  )
                }
                title={IntlUtils.withdrawalTitle(status)}
                description={
                  status === 'error'
                    ? req.error ?? 'Unknown error'
                    : IntlUtils.withdrawalDescription(status)
                }
                card={<WithdrawalRequestCard {...req} cfg={cfg} />}
                footer={
                  status !== 'loading' && (
                    <WideButton onClick={() => navigate(-1)}>
                      <FormattedMessage
                        id={'common.ok'}
                        defaultMessage={'Ok'}
                      />
                    </WideButton>
                  )
                }
              />
            );
          }}
        </AsyncStateWrapper>
      )}
    </AsyncStateWrapper>
  );
};

export default inject('wallet')(observer(SellRcoStatusScreen)) as FC<
  Partial<RProps>
>;
