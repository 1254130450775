import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/huobi/p2p/paid-1.png';
import v2 from '../../../../../assets/onboarding/huobi/p2p/paid-2.png';

const PaidP2PFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.huobi.PaidP2PFragment.title',
        defaultMessage:
          'Pay requested amount to specified bank account, After that, press <h>Paid</h>. When money arrives, you will receive your crypto',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default PaidP2PFragment;
