import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import setPassword from '../../../../../assets/onboarding/binance/first/set-password.png';

const SetPasswordFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.SetPasswordFragment.title',
        defaultMessage: 'Set your password according to displayed rules',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={setPassword} />
  </>
);

export default SetPasswordFragment;
