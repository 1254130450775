import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import createAcc1 from '../../../../../assets/onboarding/binance/first/create-account-1.png';
import createAcc2 from '../../../../../assets/onboarding/binance/first/create-account-2.png';

const CreateAccountFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.CreateAccountFragment.title',
        defaultMessage:
          'Click on top banner, then on <h>Create a Binance Account</h>',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={createAcc1} second={createAcc2} />
  </>
);

export default CreateAccountFragment;
