/* tslint:disable */
/* eslint-disable */
/**
 * Robowallet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Access token
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * Access token value
     * @type {string}
     * @memberof AccessToken
     */
    'access_token': string;
    /**
     * Token type
     * @type {string}
     * @memberof AccessToken
     */
    'token_type': string;
    /**
     * Access token TTL
     * @type {number}
     * @memberof AccessToken
     */
    'expires_in': number;
    /**
     * Refresh token
     * @type {string}
     * @memberof AccessToken
     */
    'refresh_token': string;
}
/**
 * 
 * @export
 * @interface AcquisitionRequest
 */
export interface AcquisitionRequest {
    /**
     * 
     * @type {string}
     * @memberof AcquisitionRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AcquisitionRequest
     */
    'status': AcquisitionRequestStatusEnum;
}

export const AcquisitionRequestStatusEnum = {
    Created: 'CREATED',
    FailedNotFound: 'FAILED_NOT_FOUND',
    Success: 'SUCCESS'
} as const;

export type AcquisitionRequestStatusEnum = typeof AcquisitionRequestStatusEnum[keyof typeof AcquisitionRequestStatusEnum];

/**
 * 
 * @export
 * @interface AdvanceStateDto
 */
export interface AdvanceStateDto {
    /**
     * 
     * @type {string}
     * @memberof AdvanceStateDto
     */
    'stage': AdvanceStateDtoStageEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvanceStateDto
     */
    'action': AdvanceStateDtoActionEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvanceStateDto
     */
    'selectedMarket'?: AdvanceStateDtoSelectedMarketEnum;
}

export const AdvanceStateDtoStageEnum = {
    FirstScreen: 'FIRST_SCREEN',
    Exchange: 'EXCHANGE',
    P2P: 'P2P',
    ApiKeys: 'API_KEYS',
    Completed: 'COMPLETED'
} as const;

export type AdvanceStateDtoStageEnum = typeof AdvanceStateDtoStageEnum[keyof typeof AdvanceStateDtoStageEnum];
export const AdvanceStateDtoActionEnum = {
    Pass: 'PASS',
    Skip: 'SKIP'
} as const;

export type AdvanceStateDtoActionEnum = typeof AdvanceStateDtoActionEnum[keyof typeof AdvanceStateDtoActionEnum];
export const AdvanceStateDtoSelectedMarketEnum = {
    Binance: 'BINANCE',
    Huobi: 'HUOBI',
    Bybit: 'BYBIT'
} as const;

export type AdvanceStateDtoSelectedMarketEnum = typeof AdvanceStateDtoSelectedMarketEnum[keyof typeof AdvanceStateDtoSelectedMarketEnum];

/**
 * 
 * @export
 * @interface AdviceIpWhitelistAdvice
 */
export interface AdviceIpWhitelistAdvice {
    /**
     * 
     * @type {string}
     * @memberof AdviceIpWhitelistAdvice
     */
    'advice': AdviceIpWhitelistAdviceAdviceEnum;
    /**
     * 
     * @type {string}
     * @memberof AdviceIpWhitelistAdvice
     */
    'level': AdviceIpWhitelistAdviceLevelEnum;
}

export const AdviceIpWhitelistAdviceAdviceEnum = {
    Add: 'ADD',
    Delete: 'DELETE'
} as const;

export type AdviceIpWhitelistAdviceAdviceEnum = typeof AdviceIpWhitelistAdviceAdviceEnum[keyof typeof AdviceIpWhitelistAdviceAdviceEnum];
export const AdviceIpWhitelistAdviceLevelEnum = {
    Critical: 'CRITICAL',
    Warning: 'WARNING'
} as const;

export type AdviceIpWhitelistAdviceLevelEnum = typeof AdviceIpWhitelistAdviceLevelEnum[keyof typeof AdviceIpWhitelistAdviceLevelEnum];

/**
 * 
 * @export
 * @interface AdvicePermissionAdvice
 */
export interface AdvicePermissionAdvice {
    /**
     * 
     * @type {string}
     * @memberof AdvicePermissionAdvice
     */
    'advice': AdvicePermissionAdviceAdviceEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvicePermissionAdvice
     */
    'level': AdvicePermissionAdviceLevelEnum;
}

export const AdvicePermissionAdviceAdviceEnum = {
    Deactivate: 'DEACTIVATE',
    Activate: 'ACTIVATE'
} as const;

export type AdvicePermissionAdviceAdviceEnum = typeof AdvicePermissionAdviceAdviceEnum[keyof typeof AdvicePermissionAdviceAdviceEnum];
export const AdvicePermissionAdviceLevelEnum = {
    Critical: 'CRITICAL',
    Warning: 'WARNING'
} as const;

export type AdvicePermissionAdviceLevelEnum = typeof AdvicePermissionAdviceLevelEnum[keyof typeof AdvicePermissionAdviceLevelEnum];

/**
 * 
 * @export
 * @interface AutoRenewFailedNotification
 */
export interface AutoRenewFailedNotification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof AutoRenewFailedNotification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {string}
     * @memberof AutoRenewFailedNotification
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface AutoRenewWillFailNotification
 */
export interface AutoRenewWillFailNotification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof AutoRenewWillFailNotification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {string}
     * @memberof AutoRenewWillFailNotification
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface CauseChange
 */
export interface CauseChange {
    /**
     * 
     * @type {string}
     * @memberof CauseChange
     */
    'cause': CauseChangeCauseEnum;
    /**
     * 
     * @type {number}
     * @memberof CauseChange
     */
    'change': number;
}

export const CauseChangeCauseEnum = {
    DepositBonusRegistration: 'DEPOSIT_BONUS_REGISTRATION',
    WithdrawRobotCommission: 'WITHDRAW_ROBOT_COMMISSION',
    DepositManual: 'DEPOSIT_MANUAL',
    DepositAcquisition: 'DEPOSIT_ACQUISITION',
    MoneyToBonusTransfer: 'MONEY_TO_BONUS_TRANSFER',
    DepositReferralCommission: 'DEPOSIT_REFERRAL_COMMISSION',
    WithdrawBusinessSubscription: 'WITHDRAW_BUSINESS_SUBSCRIPTION',
    WithdrawUser: 'WITHDRAW_USER'
} as const;

export type CauseChangeCauseEnum = typeof CauseChangeCauseEnum[keyof typeof CauseChangeCauseEnum];

/**
 * 
 * @export
 * @interface ClusterIpList
 */
export interface ClusterIpList {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClusterIpList
     */
    'exchangeOutbound': Array<string>;
}
/**
 * 
 * @export
 * @interface ConfirmRequestDto
 */
export interface ConfirmRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ConfirmRequestDto
     */
    'code': string;
}
/**
 * Request to acquire crypto
 * @export
 * @interface CreateAcquisitionRequest
 */
export interface CreateAcquisitionRequest {
    /**
     * Tx ID
     * @type {string}
     * @memberof CreateAcquisitionRequest
     */
    'transactionId': string;
}
/**
 * 
 * @export
 * @interface CreateConnectionRequest
 */
export interface CreateConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'exchange': CreateConnectionRequestExchangeEnum;
    /**
     * Encrypted and coded into Base64
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'apiKey'?: string;
    /**
     * Encrypted and coded into Base64
     * @type {string}
     * @memberof CreateConnectionRequest
     */
    'apiSecret'?: string;
    /**
     * 
     * @type {ExchangeConnectionPublicKeyRef}
     * @memberof CreateConnectionRequest
     */
    'publicKey': ExchangeConnectionPublicKeyRef;
}

export const CreateConnectionRequestExchangeEnum = {
    Bybit: 'BYBIT',
    Binance: 'BINANCE',
    Huobi: 'HUOBI'
} as const;

export type CreateConnectionRequestExchangeEnum = typeof CreateConnectionRequestExchangeEnum[keyof typeof CreateConnectionRequestExchangeEnum];

/**
 * 
 * @export
 * @interface CreateRequestDto
 */
export interface CreateRequestDto {
    /**
     * 
     * @type {number}
     * @memberof CreateRequestDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateRequestDto
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRequestDto
     */
    'address': string;
}
/**
 * Request to acquire crypto
 * @export
 * @interface CryptoAcquisitionRequest
 */
export interface CryptoAcquisitionRequest {
    /**
     * Tx ID
     * @type {string}
     * @memberof CryptoAcquisitionRequest
     */
    'transactionId': string;
    /**
     * Sending address
     * @type {string}
     * @memberof CryptoAcquisitionRequest
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface CurrentMaintenanceDto
 */
export interface CurrentMaintenanceDto {
    /**
     * 
     * @type {Maintenance}
     * @memberof CurrentMaintenanceDto
     */
    'maintenance'?: Maintenance;
}
/**
 * 
 * @export
 * @interface CurrentOnboardingState
 */
export interface CurrentOnboardingState {
    /**
     * 
     * @type {string}
     * @memberof CurrentOnboardingState
     */
    'currentStage': CurrentOnboardingStateCurrentStageEnum;
    /**
     * 
     * @type {string}
     * @memberof CurrentOnboardingState
     */
    'selectedMarket'?: CurrentOnboardingStateSelectedMarketEnum;
}

export const CurrentOnboardingStateCurrentStageEnum = {
    FirstScreen: 'FIRST_SCREEN',
    Exchange: 'EXCHANGE',
    P2P: 'P2P',
    ApiKeys: 'API_KEYS',
    Completed: 'COMPLETED'
} as const;

export type CurrentOnboardingStateCurrentStageEnum = typeof CurrentOnboardingStateCurrentStageEnum[keyof typeof CurrentOnboardingStateCurrentStageEnum];
export const CurrentOnboardingStateSelectedMarketEnum = {
    Binance: 'BINANCE',
    Huobi: 'HUOBI',
    Bybit: 'BYBIT'
} as const;

export type CurrentOnboardingStateSelectedMarketEnum = typeof CurrentOnboardingStateSelectedMarketEnum[keyof typeof CurrentOnboardingStateSelectedMarketEnum];

/**
 * Current subscription details
 * @export
 * @interface CurrentSubscriptionDetails
 */
export interface CurrentSubscriptionDetails {
    /**
     * Subscription cost per year
     * @type {number}
     * @memberof CurrentSubscriptionDetails
     */
    'costYearly': number;
    /**
     * Subscription cost per month
     * @type {number}
     * @memberof CurrentSubscriptionDetails
     */
    'costMonthly': number;
    /**
     * Subscription cost per 3 month
     * @type {number}
     * @memberof CurrentSubscriptionDetails
     */
    'costThreeMonth': number;
    /**
     * Is auto-renewal enabled or disabled?
     * @type {boolean}
     * @memberof CurrentSubscriptionDetails
     */
    'autoRenew': boolean;
    /**
     * When subscription was last renewed
     * @type {string}
     * @memberof CurrentSubscriptionDetails
     */
    'lastRenewalAt': string;
    /**
     * When subscription will be renewed
     * @type {string}
     * @memberof CurrentSubscriptionDetails
     */
    'nextRenewalAt': string;
    /**
     * New renewal period
     * @type {string}
     * @memberof CurrentSubscriptionDetails
     */
    'period': CurrentSubscriptionDetailsPeriodEnum;
}

export const CurrentSubscriptionDetailsPeriodEnum = {
    Monthly: 'MONTHLY',
    ThreeMonth: 'THREE_MONTH',
    Yearly: 'YEARLY'
} as const;

export type CurrentSubscriptionDetailsPeriodEnum = typeof CurrentSubscriptionDetailsPeriodEnum[keyof typeof CurrentSubscriptionDetailsPeriodEnum];

/**
 * 
 * @export
 * @interface DepositBalanceNotification
 */
export interface DepositBalanceNotification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof DepositBalanceNotification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {number}
     * @memberof DepositBalanceNotification
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof DepositBalanceNotification
     */
    'walletTransactionId': string;
    /**
     * 
     * @type {number}
     * @memberof DepositBalanceNotification
     */
    'balanceAfter': number;
    /**
     * 
     * @type {string}
     * @memberof DepositBalanceNotification
     */
    'marketAddress': string;
    /**
     * 
     * @type {string}
     * @memberof DepositBalanceNotification
     */
    'marketTransactionId': string;
    /**
     * 
     * @type {string}
     * @memberof DepositBalanceNotification
     */
    'marketId': string;
    /**
     * 
     * @type {number}
     * @memberof DepositBalanceNotification
     */
    'processingId': number;
    /**
     * 
     * @type {string}
     * @memberof DepositBalanceNotification
     */
    'timestamp': string;
}
/**
 * Edit current subscription
 * @export
 * @interface EditCurrentSubscription
 */
export interface EditCurrentSubscription {
    /**
     * Enable or disable auto-renew
     * @type {boolean}
     * @memberof EditCurrentSubscription
     */
    'autoRenew'?: boolean;
    /**
     * Set renewal period (applies after next checkout)
     * @type {string}
     * @memberof EditCurrentSubscription
     */
    'period'?: EditCurrentSubscriptionPeriodEnum;
}

export const EditCurrentSubscriptionPeriodEnum = {
    Monthly: 'MONTHLY',
    ThreeMonth: 'THREE_MONTH',
    Yearly: 'YEARLY'
} as const;

export type EditCurrentSubscriptionPeriodEnum = typeof EditCurrentSubscriptionPeriodEnum[keyof typeof EditCurrentSubscriptionPeriodEnum];

/**
 * DTO to edit email notification preferences
 * @export
 * @interface EditEmailNotificationPreferences
 */
export interface EditEmailNotificationPreferences {
    /**
     * 
     * @type {NotificationPreferences}
     * @memberof EditEmailNotificationPreferences
     */
    'notificationPreferences': NotificationPreferences;
}
/**
 * 
 * @export
 * @interface EditExchangeConnectionRequest
 */
export interface EditExchangeConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof EditExchangeConnectionRequest
     */
    'name'?: string;
}
/**
 * Params to authenticate user with email and password
 * @export
 * @interface EmailAuthenticationParamsDto
 */
export interface EmailAuthenticationParamsDto {
    /**
     * User email
     * @type {string}
     * @memberof EmailAuthenticationParamsDto
     */
    'email': string;
    /**
     * Email code
     * @type {string}
     * @memberof EmailAuthenticationParamsDto
     */
    'code'?: string;
}
/**
 * Authorization code DTO for email
 * @export
 * @interface EmailAuthorizationCodeDto
 */
export interface EmailAuthorizationCodeDto {
    /**
     * Auth code
     * @type {string}
     * @memberof EmailAuthorizationCodeDto
     */
    'code': string;
}
/**
 * Email preferences
 * @export
 * @interface EmailNotificationPreferences
 */
export interface EmailNotificationPreferences {
    /**
     * Preferences ID
     * @type {string}
     * @memberof EmailNotificationPreferences
     */
    'id': string;
    /**
     * Email to send notifications to
     * @type {string}
     * @memberof EmailNotificationPreferences
     */
    'email': string;
    /**
     * 
     * @type {NotificationPreferences}
     * @memberof EmailNotificationPreferences
     */
    'notificationPreferences': NotificationPreferences;
    /**
     * Created at
     * @type {string}
     * @memberof EmailNotificationPreferences
     */
    'createdAt': string;
}
/**
 * List of EmailNotificationPreferences
 * @export
 * @interface EmailNotificationPreferencesList
 */
export interface EmailNotificationPreferencesList {
    /**
     * 
     * @type {Array<EmailNotificationPreferences>}
     * @memberof EmailNotificationPreferencesList
     */
    'preferences': Array<EmailNotificationPreferences>;
}
/**
 * 
 * @export
 * @interface ExchangeConnectionDetails
 */
export interface ExchangeConnectionDetails {
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionDetails
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionDetails
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionDetails
     */
    'exchange': ExchangeConnectionDetailsExchangeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionDetails
     */
    'state': ExchangeConnectionDetailsStateEnum;
    /**
     * 
     * @type {ExchangeConnectionError}
     * @memberof ExchangeConnectionDetails
     */
    'error'?: ExchangeConnectionError;
    /**
     * 
     * @type {ExchangeConnectionStatus}
     * @memberof ExchangeConnectionDetails
     */
    'status'?: ExchangeConnectionStatus;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionDetails
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionDetails
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionDetails
     */
    'lastFetchedAt'?: string;
}

export const ExchangeConnectionDetailsExchangeEnum = {
    Bybit: 'BYBIT',
    Binance: 'BINANCE',
    Huobi: 'HUOBI'
} as const;

export type ExchangeConnectionDetailsExchangeEnum = typeof ExchangeConnectionDetailsExchangeEnum[keyof typeof ExchangeConnectionDetailsExchangeEnum];
export const ExchangeConnectionDetailsStateEnum = {
    Ok: 'OK',
    ExpiresSoon: 'EXPIRES_SOON',
    MinorConfigurationProblems: 'MINOR_CONFIGURATION_PROBLEMS',
    CriticalConfigurationProblems: 'CRITICAL_CONFIGURATION_PROBLEMS',
    Expired: 'EXPIRED',
    Failed: 'FAILED',
    Fetching: 'FETCHING'
} as const;

export type ExchangeConnectionDetailsStateEnum = typeof ExchangeConnectionDetailsStateEnum[keyof typeof ExchangeConnectionDetailsStateEnum];

/**
 * 
 * @export
 * @interface ExchangeConnectionError
 */
export interface ExchangeConnectionError {
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionError
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionError
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionError
     */
    'detail'?: string;
}
/**
 * 
 * @export
 * @interface ExchangeConnectionItem
 */
export interface ExchangeConnectionItem {
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionItem
     */
    'exchange': ExchangeConnectionItemExchangeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionItem
     */
    'expiresAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionItem
     */
    'state': ExchangeConnectionItemStateEnum;
}

export const ExchangeConnectionItemExchangeEnum = {
    Bybit: 'BYBIT',
    Binance: 'BINANCE',
    Huobi: 'HUOBI'
} as const;

export type ExchangeConnectionItemExchangeEnum = typeof ExchangeConnectionItemExchangeEnum[keyof typeof ExchangeConnectionItemExchangeEnum];
export const ExchangeConnectionItemStateEnum = {
    Ok: 'OK',
    ExpiresSoon: 'EXPIRES_SOON',
    MinorConfigurationProblems: 'MINOR_CONFIGURATION_PROBLEMS',
    CriticalConfigurationProblems: 'CRITICAL_CONFIGURATION_PROBLEMS',
    Expired: 'EXPIRED',
    Failed: 'FAILED',
    Fetching: 'FETCHING'
} as const;

export type ExchangeConnectionItemStateEnum = typeof ExchangeConnectionItemStateEnum[keyof typeof ExchangeConnectionItemStateEnum];

/**
 * 
 * @export
 * @interface ExchangeConnectionList
 */
export interface ExchangeConnectionList {
    /**
     * 
     * @type {Array<ExchangeConnectionItem>}
     * @memberof ExchangeConnectionList
     */
    'connections': Array<ExchangeConnectionItem>;
}
/**
 * 
 * @export
 * @interface ExchangeConnectionPermission
 */
export interface ExchangeConnectionPermission {
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionPermission
     */
    'knownPermission'?: ExchangeConnectionPermissionKnownPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionPermission
     */
    'permissionName': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangeConnectionPermission
     */
    'enabled': boolean;
    /**
     * 
     * @type {AdvicePermissionAdvice}
     * @memberof ExchangeConnectionPermission
     */
    'advice'?: AdvicePermissionAdvice;
}

export const ExchangeConnectionPermissionKnownPermissionEnum = {
    Read: 'READ',
    Margin: 'MARGIN',
    Spot: 'SPOT',
    Withdrawal: 'WITHDRAWAL',
    Futures: 'FUTURES'
} as const;

export type ExchangeConnectionPermissionKnownPermissionEnum = typeof ExchangeConnectionPermissionKnownPermissionEnum[keyof typeof ExchangeConnectionPermissionKnownPermissionEnum];

/**
 * 
 * @export
 * @interface ExchangeConnectionPublicKey
 */
export interface ExchangeConnectionPublicKey {
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionPublicKey
     */
    'key': string;
    /**
     * 
     * @type {number}
     * @memberof ExchangeConnectionPublicKey
     */
    'rev': number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionPublicKey
     */
    'uid': string;
}
/**
 * 
 * @export
 * @interface ExchangeConnectionPublicKeyRef
 */
export interface ExchangeConnectionPublicKeyRef {
    /**
     * 
     * @type {number}
     * @memberof ExchangeConnectionPublicKeyRef
     */
    'rev': number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionPublicKeyRef
     */
    'uid': string;
}
/**
 * 
 * @export
 * @interface ExchangeConnectionStatus
 */
export interface ExchangeConnectionStatus {
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionStatus
     */
    'apiKey': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionStatus
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<ExchangeConnectionPermission>}
     * @memberof ExchangeConnectionStatus
     */
    'permissions': Array<ExchangeConnectionPermission>;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionStatus
     */
    'note': string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionStatus
     */
    'ipWhitelistMode': ExchangeConnectionStatusIpWhitelistModeEnum;
    /**
     * 
     * @type {Array<IpWhitelistItem>}
     * @memberof ExchangeConnectionStatus
     */
    'ipWhitelist': Array<IpWhitelistItem>;
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionStatus
     */
    'expiresAt'?: string;
}

export const ExchangeConnectionStatusIpWhitelistModeEnum = {
    Unrestricted: 'UNRESTRICTED',
    Unsupported: 'UNSUPPORTED',
    List: 'LIST'
} as const;

export type ExchangeConnectionStatusIpWhitelistModeEnum = typeof ExchangeConnectionStatusIpWhitelistModeEnum[keyof typeof ExchangeConnectionStatusIpWhitelistModeEnum];

/**
 * App feature flags
 * @export
 * @interface FeatureFlag
 */
export interface FeatureFlag {
    /**
     * Feature flag name
     * @type {string}
     * @memberof FeatureFlag
     */
    'name': string;
    /**
     * Is feature flag enabled?
     * @type {boolean}
     * @memberof FeatureFlag
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface GlobalSubscriptionInfo
 */
export interface GlobalSubscriptionInfo {
    /**
     * 
     * @type {SubscriptionTypeInfo}
     * @memberof GlobalSubscriptionInfo
     */
    'business': SubscriptionTypeInfo;
}
/**
 * 
 * @export
 * @interface IpWhitelistItem
 */
export interface IpWhitelistItem {
    /**
     * 
     * @type {string}
     * @memberof IpWhitelistItem
     */
    'ip': string;
    /**
     * 
     * @type {boolean}
     * @memberof IpWhitelistItem
     */
    'real': boolean;
    /**
     * 
     * @type {AdviceIpWhitelistAdvice}
     * @memberof IpWhitelistItem
     */
    'advice'?: AdviceIpWhitelistAdvice;
}
/**
 * 
 * @export
 * @interface LowBalanceNotification
 */
export interface LowBalanceNotification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof LowBalanceNotification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {number}
     * @memberof LowBalanceNotification
     */
    'balance': number;
    /**
     * 
     * @type {string}
     * @memberof LowBalanceNotification
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface LowReferralBalanceNotification
 */
export interface LowReferralBalanceNotification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof LowReferralBalanceNotification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {UserIdentification}
     * @memberof LowReferralBalanceNotification
     */
    'referral': UserIdentification;
    /**
     * 
     * @type {number}
     * @memberof LowReferralBalanceNotification
     */
    'balance': number;
    /**
     * 
     * @type {string}
     * @memberof LowReferralBalanceNotification
     */
    'timestamp': string;
}
/**
 * Maintenance
 * @export
 * @interface Maintenance
 */
export interface Maintenance {
    /**
     * 
     * @type {string}
     * @memberof Maintenance
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Maintenance
     */
    'subsystem': MaintenanceSubsystemEnum;
    /**
     * 
     * @type {string}
     * @memberof Maintenance
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof Maintenance
     */
    'to': string;
    /**
     * 
     * @type {boolean}
     * @memberof Maintenance
     */
    'blockAccess': boolean;
    /**
     * 
     * @type {string}
     * @memberof Maintenance
     */
    'urgency': MaintenanceUrgencyEnum;
}

export const MaintenanceSubsystemEnum = {
    Robots: 'ROBOTS',
    Backend: 'BACKEND'
} as const;

export type MaintenanceSubsystemEnum = typeof MaintenanceSubsystemEnum[keyof typeof MaintenanceSubsystemEnum];
export const MaintenanceUrgencyEnum = {
    Scheduled: 'SCHEDULED',
    Urgent: 'URGENT'
} as const;

export type MaintenanceUrgencyEnum = typeof MaintenanceUrgencyEnum[keyof typeof MaintenanceUrgencyEnum];

/**
 * 
 * @export
 * @interface Market
 */
export interface Market {
    /**
     * 
     * @type {string}
     * @memberof Market
     */
    'name': MarketNameEnum;
    /**
     * 
     * @type {string}
     * @memberof Market
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof Market
     */
    'access': MarketAccessEnum;
    /**
     * 
     * @type {Array<MarketPair>}
     * @memberof Market
     */
    'pairs': Array<MarketPair>;
    /**
     * 
     * @type {string}
     * @memberof Market
     */
    'defaultPair': string;
    /**
     * 
     * @type {boolean}
     * @memberof Market
     */
    'hidden': boolean;
}

export const MarketNameEnum = {
    Binance: 'BINANCE',
    Huobi: 'HUOBI',
    Bybit: 'BYBIT'
} as const;

export type MarketNameEnum = typeof MarketNameEnum[keyof typeof MarketNameEnum];
export const MarketAccessEnum = {
    Everyone: 'EVERYONE',
    BusinessSub: 'BUSINESS_SUB'
} as const;

export type MarketAccessEnum = typeof MarketAccessEnum[keyof typeof MarketAccessEnum];

/**
 * 
 * @export
 * @interface MarketList
 */
export interface MarketList {
    /**
     * 
     * @type {Array<Market>}
     * @memberof MarketList
     */
    'markets': Array<Market>;
}
/**
 * 
 * @export
 * @interface MarketPair
 */
export interface MarketPair {
    /**
     * 
     * @type {string}
     * @memberof MarketPair
     */
    'pair': string;
    /**
     * 
     * @type {string}
     * @memberof MarketPair
     */
    'asset': string;
    /**
     * 
     * @type {string}
     * @memberof MarketPair
     */
    'money': string;
    /**
     * 
     * @type {string}
     * @memberof MarketPair
     */
    'access': MarketPairAccessEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MarketPair
     */
    'hidden': boolean;
}

export const MarketPairAccessEnum = {
    Everyone: 'EVERYONE',
    BusinessSub: 'BUSINESS_SUB'
} as const;

export type MarketPairAccessEnum = typeof MarketPairAccessEnum[keyof typeof MarketPairAccessEnum];

/**
 * 
 * @export
 * @interface NewReferralNotification
 */
export interface NewReferralNotification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof NewReferralNotification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {number}
     * @memberof NewReferralNotification
     */
    'referralId': number;
    /**
     * 
     * @type {string}
     * @memberof NewReferralNotification
     */
    'referralEmail': string;
    /**
     * 
     * @type {string}
     * @memberof NewReferralNotification
     */
    'timestamp': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewReferralNotification
     */
    'referralStack'?: Array<string>;
}
/**
 * Data
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof Notification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'walletTransactionId': string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'balanceAfter': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'marketAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'marketTransactionId': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'marketId': string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'processingId': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'timestamp': string;
    /**
     * 
     * @type {UserIdentification}
     * @memberof Notification
     */
    'referral': UserIdentification;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'earnedRco': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'transactionId': string;
    /**
     * 
     * @type {RobotInfo}
     * @memberof Notification
     */
    'robot': RobotInfo;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'earnedMoney': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'tradeId': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'type': NotificationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'referralId': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'referralEmail': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Notification
     */
    'referralStack'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'cause': NotificationCauseEnum;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'errorMessage'?: string;
}

export const NotificationTypeEnum = {
    Business: 'BUSINESS'
} as const;

export type NotificationTypeEnum = typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum];
export const NotificationCauseEnum = {
    Error: 'ERROR',
    InsufficientRco: 'INSUFFICIENT_RCO',
    ExpiredSubscription: 'EXPIRED_SUBSCRIPTION',
    Correction: 'CORRECTION'
} as const;

export type NotificationCauseEnum = typeof NotificationCauseEnum[keyof typeof NotificationCauseEnum];

/**
 * 
 * @export
 * @interface NotificationDetails
 */
export interface NotificationDetails {
    /**
     * ID
     * @type {string}
     * @memberof NotificationDetails
     */
    'id': string;
    /**
     * Type
     * @type {string}
     * @memberof NotificationDetails
     */
    'type': NotificationDetailsTypeEnum;
    /**
     * Timestamp
     * @type {string}
     * @memberof NotificationDetails
     */
    'timestamp': string;
    /**
     * Title
     * @type {string}
     * @memberof NotificationDetails
     */
    'title': string;
    /**
     * Description
     * @type {string}
     * @memberof NotificationDetails
     */
    'description': string;
    /**
     * Is notification acked?
     * @type {boolean}
     * @memberof NotificationDetails
     */
    'acked': boolean;
    /**
     * 
     * @type {Notification}
     * @memberof NotificationDetails
     */
    'data': Notification;
    /**
     * 
     * @type {PublicUserInfo}
     * @memberof NotificationDetails
     */
    'referralInfo'?: PublicUserInfo;
}

export const NotificationDetailsTypeEnum = {
    DepositBalance: 'DEPOSIT_BALANCE',
    ReferralCommission: 'REFERRAL_COMMISSION',
    TradeClosed: 'TRADE_CLOSED',
    SuccessfulSubscription: 'SUCCESSFUL_SUBSCRIPTION',
    SuccessfulReferralSubscription: 'SUCCESSFUL_REFERRAL_SUBSCRIPTION',
    LowBalance: 'LOW_BALANCE',
    LowReferralBalance: 'LOW_REFERRAL_BALANCE',
    NewReferral: 'NEW_REFERRAL',
    RobotStopped: 'ROBOT_STOPPED',
    AutoRenewFailed: 'AUTO_RENEW_FAILED',
    AutoRenewWillFail: 'AUTO_RENEW_WILL_FAIL'
} as const;

export type NotificationDetailsTypeEnum = typeof NotificationDetailsTypeEnum[keyof typeof NotificationDetailsTypeEnum];

/**
 * Notification item
 * @export
 * @interface NotificationItem
 */
export interface NotificationItem {
    /**
     * ID
     * @type {string}
     * @memberof NotificationItem
     */
    'id': string;
    /**
     * Type
     * @type {string}
     * @memberof NotificationItem
     */
    'type': NotificationItemTypeEnum;
    /**
     * Timestamp
     * @type {string}
     * @memberof NotificationItem
     */
    'timestamp': string;
    /**
     * Title
     * @type {string}
     * @memberof NotificationItem
     */
    'title': string;
    /**
     * Description
     * @type {string}
     * @memberof NotificationItem
     */
    'description': string;
    /**
     * Is notification acked?
     * @type {boolean}
     * @memberof NotificationItem
     */
    'acked': boolean;
}

export const NotificationItemTypeEnum = {
    DepositBalance: 'DEPOSIT_BALANCE',
    ReferralCommission: 'REFERRAL_COMMISSION',
    TradeClosed: 'TRADE_CLOSED',
    SuccessfulSubscription: 'SUCCESSFUL_SUBSCRIPTION',
    SuccessfulReferralSubscription: 'SUCCESSFUL_REFERRAL_SUBSCRIPTION',
    LowBalance: 'LOW_BALANCE',
    LowReferralBalance: 'LOW_REFERRAL_BALANCE',
    NewReferral: 'NEW_REFERRAL',
    RobotStopped: 'ROBOT_STOPPED',
    AutoRenewFailed: 'AUTO_RENEW_FAILED',
    AutoRenewWillFail: 'AUTO_RENEW_WILL_FAIL'
} as const;

export type NotificationItemTypeEnum = typeof NotificationItemTypeEnum[keyof typeof NotificationItemTypeEnum];

/**
 * Notification list
 * @export
 * @interface NotificationList
 */
export interface NotificationList {
    /**
     * Items
     * @type {Array<NotificationItem>}
     * @memberof NotificationList
     */
    'items': Array<NotificationItem>;
    /**
     * 
     * @type {string}
     * @memberof NotificationList
     */
    'nextPageToken'?: string;
}
/**
 * Notifications preferences
 * @export
 * @interface NotificationPreferences
 */
export interface NotificationPreferences {
    /**
     * Global toggle
     * @type {boolean}
     * @memberof NotificationPreferences
     */
    'notificationsEnabled': boolean;
    /**
     * \'Deposit balance\' toggle
     * @type {boolean}
     * @memberof NotificationPreferences
     */
    'balanceDepositEnabled': boolean;
    /**
     * \'Low balance\' toggle
     * @type {boolean}
     * @memberof NotificationPreferences
     */
    'lowBalanceEnabled': boolean;
    /**
     * \'Low referral balance\' toggle
     * @type {boolean}
     * @memberof NotificationPreferences
     */
    'lowReferralBalanceEnabled': boolean;
    /**
     * \'New referral\' toggle
     * @type {boolean}
     * @memberof NotificationPreferences
     */
    'newReferralEnabled': boolean;
    /**
     * \'Referral commission\' toggle
     * @type {boolean}
     * @memberof NotificationPreferences
     */
    'referralCommissionEnabled': boolean;
    /**
     * \'Successful referral subscription\' toggle
     * @type {boolean}
     * @memberof NotificationPreferences
     */
    'successfulReferralSubscriptionEnabled': boolean;
    /**
     * \'Successful subscription\' toggle
     * @type {boolean}
     * @memberof NotificationPreferences
     */
    'successfulSubscriptionEnabled': boolean;
    /**
     * \'Trade closed\' toggle
     * @type {boolean}
     * @memberof NotificationPreferences
     */
    'tradeClosedEnabled': boolean;
    /**
     * \'Robot stopped\' toggle
     * @type {boolean}
     * @memberof NotificationPreferences
     */
    'robotStoppedEnabled': boolean;
}
/**
 * 
 * @export
 * @interface NotificationStoragePreview
 */
export interface NotificationStoragePreview {
    /**
     * Has new notifications?
     * @type {boolean}
     * @memberof NotificationStoragePreview
     */
    'hasNewNotifications': boolean;
}
/**
 * Originator tree
 * @export
 * @interface PublicUserInfo
 */
export interface PublicUserInfo {
    /**
     * User id
     * @type {number}
     * @memberof PublicUserInfo
     */
    'id': number;
    /**
     * User name
     * @type {string}
     * @memberof PublicUserInfo
     */
    'name': string;
    /**
     * User photo
     * @type {string}
     * @memberof PublicUserInfo
     */
    'photoUrl': string;
}
/**
 * 
 * @export
 * @interface ReferralCommissionNotification
 */
export interface ReferralCommissionNotification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof ReferralCommissionNotification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {UserIdentification}
     * @memberof ReferralCommissionNotification
     */
    'referral': UserIdentification;
    /**
     * 
     * @type {number}
     * @memberof ReferralCommissionNotification
     */
    'earnedRco': number;
    /**
     * 
     * @type {string}
     * @memberof ReferralCommissionNotification
     */
    'transactionId': string;
    /**
     * 
     * @type {number}
     * @memberof ReferralCommissionNotification
     */
    'processingId': number;
    /**
     * 
     * @type {string}
     * @memberof ReferralCommissionNotification
     */
    'timestamp': string;
}
/**
 * Items
 * @export
 * @interface ReferralSearchItem
 */
export interface ReferralSearchItem {
    /**
     * User id
     * @type {number}
     * @memberof ReferralSearchItem
     */
    'id': number;
    /**
     * User name
     * @type {string}
     * @memberof ReferralSearchItem
     */
    'name': string;
    /**
     * User photo url
     * @type {string}
     * @memberof ReferralSearchItem
     */
    'photoUrl': string;
    /**
     * User depth relative to me
     * @type {number}
     * @memberof ReferralSearchItem
     */
    'relativeDepth': number;
    /**
     * User income
     * @type {number}
     * @memberof ReferralSearchItem
     */
    'income': number;
    /**
     * User income percent
     * @type {number}
     * @memberof ReferralSearchItem
     */
    'incomePercent': number;
}
/**
 * 
 * @export
 * @interface ReferralSearchPage
 */
export interface ReferralSearchPage {
    /**
     * Items
     * @type {Array<ReferralSearchItem>}
     * @memberof ReferralSearchPage
     */
    'items': Array<ReferralSearchItem>;
    /**
     * Next page token
     * @type {string}
     * @memberof ReferralSearchPage
     */
    'nextPageToken'?: string;
}
/**
 * Info to register user with email
 * @export
 * @interface RegisterWithEmailParams
 */
export interface RegisterWithEmailParams {
    /**
     * E-mail
     * @type {string}
     * @memberof RegisterWithEmailParams
     */
    'email': string;
    /**
     * Invite code
     * @type {string}
     * @memberof RegisterWithEmailParams
     */
    'inviteCode'?: string;
}
/**
 * 
 * @export
 * @interface RobotBalance
 */
export interface RobotBalance {
    /**
     * 
     * @type {number}
     * @memberof RobotBalance
     */
    'money': number;
    /**
     * 
     * @type {number}
     * @memberof RobotBalance
     */
    'asset': number;
    /**
     * 
     * @type {number}
     * @memberof RobotBalance
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof RobotBalance
     */
    'moneyPrice': number;
}
/**
 * 
 * @export
 * @interface RobotConfig
 */
export interface RobotConfig {
    /**
     * Order qty, divided by 10^8
     * @type {number}
     * @memberof RobotConfig
     */
    'order': number;
    /**
     * Byu metric, divided by 10^8
     * @type {number}
     * @memberof RobotConfig
     */
    'buy': number;
    /**
     * Sell metric, divided by 10^8
     * @type {number}
     * @memberof RobotConfig
     */
    'sell': number;
    /**
     * Emergency stop market change, divided by 10^8
     * @type {number}
     * @memberof RobotConfig
     */
    'correction10m': number;
    /**
     * Robot deposit
     * @type {number}
     * @memberof RobotConfig
     */
    'deposit': number;
    /**
     * Sell order timeout, in hours
     * @type {number}
     * @memberof RobotConfig
     */
    'sellOrderTimeout': number;
}
/**
 * 
 * @export
 * @interface RobotCreateRequest
 */
export interface RobotCreateRequest {
    /**
     * Robot market
     * @type {string}
     * @memberof RobotCreateRequest
     * @deprecated
     */
    'market': RobotCreateRequestMarketEnum;
    /**
     * API key for this market
     * @type {string}
     * @memberof RobotCreateRequest
     * @deprecated
     */
    'apiKey': string;
    /**
     * Encrypted API secret for this market
     * @type {string}
     * @memberof RobotCreateRequest
     * @deprecated
     */
    'apiSecret': string;
    /**
     * Exchange connection ref
     * @type {string}
     * @memberof RobotCreateRequest
     */
    'exchangeConnection': string;
    /**
     * Robot name
     * @type {string}
     * @memberof RobotCreateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {RobotPair}
     * @memberof RobotCreateRequest
     */
    'pair': RobotPair;
}

export const RobotCreateRequestMarketEnum = {
    Binance: 'BINANCE',
    Huobi: 'HUOBI',
    Bybit: 'BYBIT'
} as const;

export type RobotCreateRequestMarketEnum = typeof RobotCreateRequestMarketEnum[keyof typeof RobotCreateRequestMarketEnum];

/**
 * 
 * @export
 * @interface RobotEditDto
 */
export interface RobotEditDto {
    /**
     * Change name to current value
     * @type {string}
     * @memberof RobotEditDto
     */
    'name'?: string;
    /**
     * Change API key
     * @type {string}
     * @memberof RobotEditDto
     */
    'apiKey'?: string;
    /**
     * Change API secret
     * @type {string}
     * @memberof RobotEditDto
     */
    'apiSecret'?: string;
}
/**
 * Robot error, if any
 * @export
 * @interface RobotError
 */
export interface RobotError {
    /**
     * Robot error type (URL)
     * @type {string}
     * @memberof RobotError
     */
    'type': string;
    /**
     * Robot error message
     * @type {string}
     * @memberof RobotError
     */
    'title': string;
}
/**
 * Robot, which made that trade
 * @export
 * @interface RobotInfo
 */
export interface RobotInfo {
    /**
     * Robot ID
     * @type {string}
     * @memberof RobotInfo
     */
    'id': string;
    /**
     * Robot name
     * @type {string}
     * @memberof RobotInfo
     */
    'name'?: string;
    /**
     * Robot market
     * @type {string}
     * @memberof RobotInfo
     */
    'market': RobotInfoMarketEnum;
    /**
     * 
     * @type {RobotPair}
     * @memberof RobotInfo
     */
    'pair': RobotPair;
    /**
     * Is robot deleted?
     * @type {boolean}
     * @memberof RobotInfo
     */
    'deleted': boolean;
}

export const RobotInfoMarketEnum = {
    Binance: 'BINANCE',
    Huobi: 'HUOBI',
    Bybit: 'BYBIT'
} as const;

export type RobotInfoMarketEnum = typeof RobotInfoMarketEnum[keyof typeof RobotInfoMarketEnum];

/**
 * Robot pair
 * @export
 * @interface RobotPair
 */
export interface RobotPair {
    /**
     * Coin used as money
     * @type {string}
     * @memberof RobotPair
     */
    'money': string;
    /**
     * Coin used as asset
     * @type {string}
     * @memberof RobotPair
     */
    'asset': string;
}
/**
 * 
 * @export
 * @interface RobotStatus
 */
export interface RobotStatus {
    /**
     * Robot state
     * @type {string}
     * @memberof RobotStatus
     */
    'state': RobotStatusStateEnum;
    /**
     * 
     * @type {RobotError}
     * @memberof RobotStatus
     */
    'error'?: RobotError;
}

export const RobotStatusStateEnum = {
    NotStarted: 'NOT_STARTED',
    Starting: 'STARTING',
    Started: 'STARTED',
    StartFailed: 'START_FAILED',
    Stopping: 'STOPPING',
    StoppedRequest: 'STOPPED_REQUEST',
    StoppedError: 'STOPPED_ERROR',
    StoppedCorrection: 'STOPPED_CORRECTION',
    NoSpace: 'NO_SPACE'
} as const;

export type RobotStatusStateEnum = typeof RobotStatusStateEnum[keyof typeof RobotStatusStateEnum];

/**
 * 
 * @export
 * @interface RobotStoppedNotification
 */
export interface RobotStoppedNotification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof RobotStoppedNotification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {RobotInfo}
     * @memberof RobotStoppedNotification
     */
    'robot': RobotInfo;
    /**
     * 
     * @type {string}
     * @memberof RobotStoppedNotification
     */
    'cause': RobotStoppedNotificationCauseEnum;
    /**
     * 
     * @type {string}
     * @memberof RobotStoppedNotification
     */
    'errorMessage'?: string;
}

export const RobotStoppedNotificationCauseEnum = {
    Error: 'ERROR',
    InsufficientRco: 'INSUFFICIENT_RCO',
    ExpiredSubscription: 'EXPIRED_SUBSCRIPTION',
    Correction: 'CORRECTION'
} as const;

export type RobotStoppedNotificationCauseEnum = typeof RobotStoppedNotificationCauseEnum[keyof typeof RobotStoppedNotificationCauseEnum];

/**
 * 
 * @export
 * @interface RobotSummary
 */
export interface RobotSummary {
    /**
     * 
     * @type {string}
     * @memberof RobotSummary
     */
    'id': string;
    /**
     * 
     * @type {RobotBalance}
     * @memberof RobotSummary
     */
    'balance'?: RobotBalance;
    /**
     * 
     * @type {string}
     * @memberof RobotSummary
     */
    'market': RobotSummaryMarketEnum;
    /**
     * 
     * @type {string}
     * @memberof RobotSummary
     */
    'status': RobotSummaryStatusEnum;
    /**
     * 
     * @type {RobotStatus}
     * @memberof RobotSummary
     */
    'statusDetails': RobotStatus;
    /**
     * 
     * @type {RobotPair}
     * @memberof RobotSummary
     */
    'pair': RobotPair;
    /**
     * 
     * @type {Array<RobotWarning>}
     * @memberof RobotSummary
     */
    'warnings': Array<RobotWarning>;
    /**
     * 
     * @type {string}
     * @memberof RobotSummary
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotSummary
     */
    'exchangeConnection': string;
}

export const RobotSummaryMarketEnum = {
    Binance: 'BINANCE',
    Huobi: 'HUOBI',
    Bybit: 'BYBIT'
} as const;

export type RobotSummaryMarketEnum = typeof RobotSummaryMarketEnum[keyof typeof RobotSummaryMarketEnum];
export const RobotSummaryStatusEnum = {
    NotStarted: 'NOT_STARTED',
    Starting: 'STARTING',
    Started: 'STARTED',
    StartFailed: 'START_FAILED',
    Stopping: 'STOPPING',
    StoppedRequest: 'STOPPED_REQUEST',
    StoppedError: 'STOPPED_ERROR',
    StoppedCorrection: 'STOPPED_CORRECTION',
    NoSpace: 'NO_SPACE'
} as const;

export type RobotSummaryStatusEnum = typeof RobotSummaryStatusEnum[keyof typeof RobotSummaryStatusEnum];

/**
 * 
 * @export
 * @interface RobotSummaryInfo
 */
export interface RobotSummaryInfo {
    /**
     * 
     * @type {Array<RobotSummary>}
     * @memberof RobotSummaryInfo
     */
    'robotSummaries': Array<RobotSummary>;
    /**
     * 
     * @type {RobotBalance}
     * @memberof RobotSummaryInfo
     */
    'aggregated': RobotBalance;
    /**
     * 
     * @type {number}
     * @memberof RobotSummaryInfo
     */
    'aggregatedUsd': number;
    /**
     * 
     * @type {number}
     * @memberof RobotSummaryInfo
     */
    'workingRobotCount': number;
}
/**
 * 
 * @export
 * @interface RobotTradeChart
 */
export interface RobotTradeChart {
    /**
     * Chart points
     * @type {Array<RobotTradeChartPoint>}
     * @memberof RobotTradeChart
     */
    'points': Array<RobotTradeChartPoint>;
    /**
     * Closed order count for selected month
     * @type {number}
     * @memberof RobotTradeChart
     */
    'closedCount': number;
    /**
     * Open order count for selected month
     * @type {number}
     * @memberof RobotTradeChart
     */
    'openCount': number;
    /**
     * Sum of trade deltas
     * @type {number}
     * @memberof RobotTradeChart
     */
    'deltaSum': number;
    /**
     * Percent change of balance in month (2 frac. digits)
     * @type {number}
     * @memberof RobotTradeChart
     */
    'deltaPercent': number;
}
/**
 * Chart points
 * @export
 * @interface RobotTradeChartPoint
 */
export interface RobotTradeChartPoint {
    /**
     * When trade happened
     * @type {string}
     * @memberof RobotTradeChartPoint
     */
    'timestamp': string;
    /**
     * Money balance after trade
     * @type {number}
     * @memberof RobotTradeChartPoint
     */
    'money': number;
}
/**
 * Robot trade, linked to transaction
 * @export
 * @interface RobotTradeDetails
 */
export interface RobotTradeDetails {
    /**
     * Trade ID
     * @type {string}
     * @memberof RobotTradeDetails
     */
    'id': string;
    /**
     * Buy ID on market
     * @type {string}
     * @memberof RobotTradeDetails
     */
    'buyId': string;
    /**
     * Buy price
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'buyPrice': number;
    /**
     * Buy amount
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'buyAmount': number;
    /**
     * Buy volume
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'buyVolume': number;
    /**
     * Buy order complete timestamp
     * @type {string}
     * @memberof RobotTradeDetails
     */
    'boughtTimestamp'?: string;
    /**
     * Bought asset balance
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'boughtAssetBalance'?: number;
    /**
     * Bought money balance
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'boughtMoneyBalance'?: number;
    /**
     * Sell ID on market
     * @type {string}
     * @memberof RobotTradeDetails
     */
    'sellId'?: string;
    /**
     * Sell price
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'sellPrice'?: number;
    /**
     * Sell amount
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'sellAmount'?: number;
    /**
     * Sell volume
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'sellVolume'?: number;
    /**
     * Sell money price
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'sellMoneyPrice'?: number;
    /**
     * Sell order complete timestamp
     * @type {string}
     * @memberof RobotTradeDetails
     */
    'soldTimestamp'?: string;
    /**
     * Sold asset balance
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'soldAssetBalance'?: number;
    /**
     * Sold money balance
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'soldMoneyBalance'?: number;
    /**
     * Profit
     * @type {number}
     * @memberof RobotTradeDetails
     */
    'profit'?: number;
    /**
     * Buy order create timestamp, when trade was initiated
     * @type {string}
     * @memberof RobotTradeDetails
     */
    'createdAt': string;
    /**
     * Trade state
     * @type {string}
     * @memberof RobotTradeDetails
     */
    'state': RobotTradeDetailsStateEnum;
    /**
     * 
     * @type {RobotInfo}
     * @memberof RobotTradeDetails
     */
    'robot'?: RobotInfo;
}

export const RobotTradeDetailsStateEnum = {
    BuyInProgress: 'BUY_IN_PROGRESS',
    SellInProgress: 'SELL_IN_PROGRESS',
    SellError: 'SELL_ERROR',
    Completed: 'COMPLETED'
} as const;

export type RobotTradeDetailsStateEnum = typeof RobotTradeDetailsStateEnum[keyof typeof RobotTradeDetailsStateEnum];

/**
 * Robot trade list item
 * @export
 * @interface RobotTradeItem
 */
export interface RobotTradeItem {
    /**
     * ID
     * @type {string}
     * @memberof RobotTradeItem
     */
    'id': string;
    /**
     * Robot, which made that trade
     * @type {string}
     * @memberof RobotTradeItem
     */
    'robotId': string;
    /**
     * State
     * @type {string}
     * @memberof RobotTradeItem
     */
    'state': RobotTradeItemStateEnum;
    /**
     * 
     * @type {RobotPair}
     * @memberof RobotTradeItem
     */
    'pair': RobotPair;
    /**
     * Potential or acquired (depending on state) profit
     * @type {number}
     * @memberof RobotTradeItem
     */
    'profit'?: number;
    /**
     * When buy was created
     * @type {string}
     * @memberof RobotTradeItem
     */
    'boughtAt'?: string;
    /**
     * Buy price
     * @type {number}
     * @memberof RobotTradeItem
     */
    'buyPrice': number;
    /**
     * Buy amount
     * @type {number}
     * @memberof RobotTradeItem
     */
    'buyAmount': number;
    /**
     * Buy volume (amount * price)
     * @type {number}
     * @memberof RobotTradeItem
     */
    'buyVolume': number;
    /**
     * When sell was created
     * @type {string}
     * @memberof RobotTradeItem
     */
    'soldAt'?: string;
    /**
     * Sell price
     * @type {number}
     * @memberof RobotTradeItem
     */
    'soldAmount'?: number;
    /**
     * Sell amount
     * @type {number}
     * @memberof RobotTradeItem
     */
    'soldPrice'?: number;
    /**
     * Sell volume (amount * price)
     * @type {number}
     * @memberof RobotTradeItem
     */
    'soldVolume'?: number;
}

export const RobotTradeItemStateEnum = {
    BuyInProgress: 'BUY_IN_PROGRESS',
    SellInProgress: 'SELL_IN_PROGRESS',
    SellError: 'SELL_ERROR',
    Completed: 'COMPLETED'
} as const;

export type RobotTradeItemStateEnum = typeof RobotTradeItemStateEnum[keyof typeof RobotTradeItemStateEnum];

/**
 * 
 * @export
 * @interface RobotTradeList
 */
export interface RobotTradeList {
    /**
     * Items
     * @type {Array<RobotTradeItem>}
     * @memberof RobotTradeList
     */
    'items': Array<RobotTradeItem>;
    /**
     * Token to next page
     * @type {string}
     * @memberof RobotTradeList
     */
    'nextPageToken'?: string;
}
/**
 * 
 * @export
 * @interface RobotWarning
 */
export interface RobotWarning {
    /**
     * 
     * @type {string}
     * @memberof RobotWarning
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof RobotWarning
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof RobotWarning
     */
    'detail'?: string;
    /**
     * 
     * @type {number}
     * @memberof RobotWarning
     */
    'priority': number;
}
/**
 * 
 * @export
 * @interface SubscribeBusinessRequest
 */
export interface SubscribeBusinessRequest {
    /**
     * 
     * @type {string}
     * @memberof SubscribeBusinessRequest
     */
    'period'?: SubscribeBusinessRequestPeriodEnum;
}

export const SubscribeBusinessRequestPeriodEnum = {
    Monthly: 'MONTHLY',
    ThreeMonth: 'THREE_MONTH',
    Yearly: 'YEARLY'
} as const;

export type SubscribeBusinessRequestPeriodEnum = typeof SubscribeBusinessRequestPeriodEnum[keyof typeof SubscribeBusinessRequestPeriodEnum];

/**
 * Subscription info
 * @export
 * @interface SubscriptionInfo
 */
export interface SubscriptionInfo {
    /**
     * Subscription type
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'type': SubscriptionInfoTypeEnum;
    /**
     * Subscription status
     * @type {string}
     * @memberof SubscriptionInfo
     */
    'status': SubscriptionInfoStatusEnum;
    /**
     * 
     * @type {CurrentSubscriptionDetails}
     * @memberof SubscriptionInfo
     */
    'currentSubscriptionDetails'?: CurrentSubscriptionDetails;
}

export const SubscriptionInfoTypeEnum = {
    None: 'NONE',
    Business: 'BUSINESS',
    Dev: 'DEV'
} as const;

export type SubscriptionInfoTypeEnum = typeof SubscriptionInfoTypeEnum[keyof typeof SubscriptionInfoTypeEnum];
export const SubscriptionInfoStatusEnum = {
    Active: 'ACTIVE',
    Expired: 'EXPIRED'
} as const;

export type SubscriptionInfoStatusEnum = typeof SubscriptionInfoStatusEnum[keyof typeof SubscriptionInfoStatusEnum];

/**
 * 
 * @export
 * @interface SubscriptionTypeInfo
 */
export interface SubscriptionTypeInfo {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionTypeInfo
     */
    'yearlyCost': number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionTypeInfo
     */
    'monthlyCost': number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionTypeInfo
     */
    'threeMonthCost': number;
}
/**
 * 
 * @export
 * @interface SuccessfulReferralSubscriptionNotification
 */
export interface SuccessfulReferralSubscriptionNotification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof SuccessfulReferralSubscriptionNotification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {UserIdentification}
     * @memberof SuccessfulReferralSubscriptionNotification
     */
    'referral': UserIdentification;
    /**
     * 
     * @type {string}
     * @memberof SuccessfulReferralSubscriptionNotification
     */
    'type': SuccessfulReferralSubscriptionNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SuccessfulReferralSubscriptionNotification
     */
    'timestamp': string;
}

export const SuccessfulReferralSubscriptionNotificationTypeEnum = {
    Business: 'BUSINESS'
} as const;

export type SuccessfulReferralSubscriptionNotificationTypeEnum = typeof SuccessfulReferralSubscriptionNotificationTypeEnum[keyof typeof SuccessfulReferralSubscriptionNotificationTypeEnum];

/**
 * 
 * @export
 * @interface SuccessfulSubscriptionNotification
 */
export interface SuccessfulSubscriptionNotification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof SuccessfulSubscriptionNotification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {string}
     * @memberof SuccessfulSubscriptionNotification
     */
    'type': SuccessfulSubscriptionNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SuccessfulSubscriptionNotification
     */
    'timestamp': string;
}

export const SuccessfulSubscriptionNotificationTypeEnum = {
    Business: 'BUSINESS'
} as const;

export type SuccessfulSubscriptionNotificationTypeEnum = typeof SuccessfulSubscriptionNotificationTypeEnum[keyof typeof SuccessfulSubscriptionNotificationTypeEnum];

/**
 * Team count for selected user
 * @export
 * @interface TeamCount
 */
export interface TeamCount {
    /**
     * Team count
     * @type {number}
     * @memberof TeamCount
     */
    'count': number;
    /**
     * My level
     * @type {number}
     * @memberof TeamCount
     */
    'myLevel': number;
}
/**
 * Total income for selected user
 * @export
 * @interface TotalIncome
 */
export interface TotalIncome {
    /**
     * Total income
     * @type {number}
     * @memberof TotalIncome
     */
    'income': number;
    /**
     * Income percent
     * @type {number}
     * @memberof TotalIncome
     */
    'percent': number;
}
/**
 * 
 * @export
 * @interface TradeClosedNotification
 */
export interface TradeClosedNotification {
    /**
     * 
     * @type {UserIdentification}
     * @memberof TradeClosedNotification
     */
    'target': UserIdentification;
    /**
     * 
     * @type {RobotInfo}
     * @memberof TradeClosedNotification
     */
    'robot': RobotInfo;
    /**
     * 
     * @type {number}
     * @memberof TradeClosedNotification
     */
    'earnedMoney': number;
    /**
     * 
     * @type {string}
     * @memberof TradeClosedNotification
     */
    'tradeId': string;
    /**
     * 
     * @type {string}
     * @memberof TradeClosedNotification
     */
    'timestamp': string;
}
/**
 * Items
 * @export
 * @interface TreeLaneItem
 */
export interface TreeLaneItem {
    /**
     * User id
     * @type {number}
     * @memberof TreeLaneItem
     */
    'id': number;
    /**
     * User name
     * @type {string}
     * @memberof TreeLaneItem
     */
    'name': string;
    /**
     * User photo url
     * @type {string}
     * @memberof TreeLaneItem
     */
    'photoUrl': string;
    /**
     * User team count
     * @type {number}
     * @memberof TreeLaneItem
     */
    'teamCount': number;
    /**
     * Node depth based on actor (current authenticated user) depth
     * @type {number}
     * @memberof TreeLaneItem
     */
    'relativeDepth': number;
    /**
     * Random member photo
     * @type {string}
     * @memberof TreeLaneItem
     */
    'randomMemberPhotoUrl'?: string;
    /**
     * Tree income
     * @type {number}
     * @memberof TreeLaneItem
     */
    'treeIncome': number;
    /**
     * Tree income percent
     * @type {number}
     * @memberof TreeLaneItem
     */
    'treeIncomePercent': number;
    /**
     * User level
     * @type {number}
     * @memberof TreeLaneItem
     */
    'level': number;
}
/**
 * 
 * @export
 * @interface TreeLanePage
 */
export interface TreeLanePage {
    /**
     * Items
     * @type {Array<TreeLaneItem>}
     * @memberof TreeLanePage
     */
    'items': Array<TreeLaneItem>;
    /**
     * 
     * @type {TeamCount}
     * @memberof TreeLanePage
     */
    'teamCount': TeamCount;
    /**
     * 
     * @type {TotalIncome}
     * @memberof TreeLanePage
     */
    'totalIncome': TotalIncome;
    /**
     * Selected user photo url
     * @type {string}
     * @memberof TreeLanePage
     */
    'userPhotoUrl': string;
    /**
     * Current user level
     * @type {number}
     * @memberof TreeLanePage
     */
    'myLevel': number;
    /**
     * Next page token
     * @type {string}
     * @memberof TreeLanePage
     */
    'nextPageToken'?: string;
}
/**
 * 
 * @export
 * @interface UnsubscribeStatus
 */
export interface UnsubscribeStatus {
    /**
     * Is user subscribed/notifications enabled?
     * @type {boolean}
     * @memberof UnsubscribeStatus
     */
    'subscribed': boolean;
}
/**
 * 
 * @export
 * @interface UpdateApiKeysRequest
 */
export interface UpdateApiKeysRequest {
    /**
     * Encrypted and coded into Base64
     * @type {string}
     * @memberof UpdateApiKeysRequest
     */
    'apiKey'?: string;
    /**
     * Encrypted and coded into Base64
     * @type {string}
     * @memberof UpdateApiKeysRequest
     */
    'apiSecret'?: string;
    /**
     * 
     * @type {ExchangeConnectionPublicKeyRef}
     * @memberof UpdateApiKeysRequest
     */
    'publicKey': ExchangeConnectionPublicKeyRef;
}
/**
 * 
 * @export
 * @interface UserEdit
 */
export interface UserEdit {
    /**
     * User full name
     * @type {string}
     * @memberof UserEdit
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserIdentification
 */
export interface UserIdentification {
    /**
     * 
     * @type {number}
     * @memberof UserIdentification
     */
    'id': number;
}
/**
 * User info
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * User id
     * @type {number}
     * @memberof UserInfo
     */
    'id': number;
    /**
     * E-mail
     * @type {string}
     * @memberof UserInfo
     */
    'email': string;
    /**
     * User full name
     * @type {string}
     * @memberof UserInfo
     */
    'name'?: string;
    /**
     * Photo URL
     * @type {string}
     * @memberof UserInfo
     */
    'photo': string;
}
/**
 * 
 * @export
 * @interface UserLink
 */
export interface UserLink {
    /**
     * 
     * @type {string}
     * @memberof UserLink
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserLink
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof UserLink
     */
    'service': UserLinkServiceEnum;
    /**
     * 
     * @type {string}
     * @memberof UserLink
     */
    'type': UserLinkTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserLink
     */
    'displayName'?: string;
}

export const UserLinkServiceEnum = {
    Telegram: 'TELEGRAM',
    Discord: 'DISCORD',
    Facebook: 'FACEBOOK',
    Twitter: 'TWITTER',
    Instagram: 'INSTAGRAM',
    Website: 'WEBSITE',
    Whatsapp: 'WHATSAPP',
    Linkedin: 'LINKEDIN',
    Vk: 'VK'
} as const;

export type UserLinkServiceEnum = typeof UserLinkServiceEnum[keyof typeof UserLinkServiceEnum];
export const UserLinkTypeEnum = {
    PersonalContact: 'PERSONAL_CONTACT',
    Group: 'GROUP'
} as const;

export type UserLinkTypeEnum = typeof UserLinkTypeEnum[keyof typeof UserLinkTypeEnum];

/**
 * 
 * @export
 * @interface UserLinkCreateDto
 */
export interface UserLinkCreateDto {
    /**
     * 
     * @type {string}
     * @memberof UserLinkCreateDto
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof UserLinkCreateDto
     */
    'service'?: UserLinkCreateDtoServiceEnum;
    /**
     * 
     * @type {string}
     * @memberof UserLinkCreateDto
     */
    'type': UserLinkCreateDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserLinkCreateDto
     */
    'displayName'?: string;
}

export const UserLinkCreateDtoServiceEnum = {
    Telegram: 'TELEGRAM',
    Discord: 'DISCORD',
    Facebook: 'FACEBOOK',
    Twitter: 'TWITTER',
    Instagram: 'INSTAGRAM',
    Website: 'WEBSITE',
    Whatsapp: 'WHATSAPP',
    Linkedin: 'LINKEDIN',
    Vk: 'VK'
} as const;

export type UserLinkCreateDtoServiceEnum = typeof UserLinkCreateDtoServiceEnum[keyof typeof UserLinkCreateDtoServiceEnum];
export const UserLinkCreateDtoTypeEnum = {
    PersonalContact: 'PERSONAL_CONTACT',
    Group: 'GROUP'
} as const;

export type UserLinkCreateDtoTypeEnum = typeof UserLinkCreateDtoTypeEnum[keyof typeof UserLinkCreateDtoTypeEnum];

/**
 * 
 * @export
 * @interface UserLinkEditDto
 */
export interface UserLinkEditDto {
    /**
     * 
     * @type {string}
     * @memberof UserLinkEditDto
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof UserLinkEditDto
     */
    'displayName'?: string;
}
/**
 * 
 * @export
 * @interface UserLinkListDto
 */
export interface UserLinkListDto {
    /**
     * 
     * @type {Array<UserLink>}
     * @memberof UserLinkListDto
     */
    'links': Array<UserLink>;
}
/**
 * 
 * @export
 * @interface VersionResult
 */
export interface VersionResult {
    /**
     * Acton for current version
     * @type {string}
     * @memberof VersionResult
     */
    'action': VersionResultActionEnum;
    /**
     * Next app version, if has any
     * @type {string}
     * @memberof VersionResult
     */
    'nextVersion'?: string;
    /**
     * App feature flags
     * @type {Array<FeatureFlag>}
     * @memberof VersionResult
     */
    'featureFlags': Array<FeatureFlag>;
}

export const VersionResultActionEnum = {
    None: 'NONE',
    UpgradeWarning: 'UPGRADE_WARNING',
    UpgradeRequired: 'UPGRADE_REQUIRED'
} as const;

export type VersionResultActionEnum = typeof VersionResultActionEnum[keyof typeof VersionResultActionEnum];

/**
 * 
 * @export
 * @interface WalletCauseDistribution
 */
export interface WalletCauseDistribution {
    /**
     * 
     * @type {number}
     * @memberof WalletCauseDistribution
     */
    'change': number;
    /**
     * 
     * @type {number}
     * @memberof WalletCauseDistribution
     */
    'changePercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof WalletCauseDistribution
     */
    'totalIncome': number;
    /**
     * 
     * @type {number}
     * @memberof WalletCauseDistribution
     */
    'totalCost': number;
    /**
     * 
     * @type {Array<CauseChange>}
     * @memberof WalletCauseDistribution
     */
    'details': Array<CauseChange>;
}
/**
 * Information about user wallet
 * @export
 * @interface WalletInfo
 */
export interface WalletInfo {
    /**
     * Wallet balance
     * @type {number}
     * @memberof WalletInfo
     */
    'balance': number;
    /**
     * Wallet money balance
     * @type {number}
     * @memberof WalletInfo
     */
    'money': number;
    /**
     * Wallet bonus balance
     * @type {number}
     * @memberof WalletInfo
     */
    'bonus': number;
}
/**
 * 
 * @export
 * @interface WalletTransactionDetails
 */
export interface WalletTransactionDetails {
    /**
     * Transaction ID
     * @type {string}
     * @memberof WalletTransactionDetails
     */
    'id': string;
    /**
     * Transaction cause
     * @type {string}
     * @memberof WalletTransactionDetails
     */
    'cause': WalletTransactionDetailsCauseEnum;
    /**
     * Transaction state
     * @type {string}
     * @memberof WalletTransactionDetails
     */
    'state': WalletTransactionDetailsStateEnum;
    /**
     * When transaction was created
     * @type {string}
     * @memberof WalletTransactionDetails
     */
    'createdAt': string;
    /**
     * Transaction amount
     * @type {number}
     * @memberof WalletTransactionDetails
     */
    'amount': number;
    /**
     * Wallet balance after current transaction
     * @type {number}
     * @memberof WalletTransactionDetails
     */
    'balanceAfterTransaction': number;
    /**
     * 
     * @type {RobotInfo}
     * @memberof WalletTransactionDetails
     */
    'robot'?: RobotInfo;
    /**
     * 
     * @type {RobotTradeDetails}
     * @memberof WalletTransactionDetails
     */
    'trade'?: RobotTradeDetails;
    /**
     * Market address, linked to transaction
     * @type {string}
     * @memberof WalletTransactionDetails
     */
    'marketAddress'?: string;
    /**
     * Market Tx ID, linked to transaction
     * @type {string}
     * @memberof WalletTransactionDetails
     */
    'marketTransactionId'?: string;
    /**
     * Original transaction ID
     * @type {string}
     * @memberof WalletTransactionDetails
     */
    'originalTxId'?: string;
    /**
     * 
     * @type {PublicUserInfo}
     * @memberof WalletTransactionDetails
     */
    'from'?: PublicUserInfo;
    /**
     * Originator tree
     * @type {Array<PublicUserInfo>}
     * @memberof WalletTransactionDetails
     */
    'fromTree'?: Array<PublicUserInfo>;
}

export const WalletTransactionDetailsCauseEnum = {
    DepositBonusRegistration: 'DEPOSIT_BONUS_REGISTRATION',
    WithdrawRobotCommission: 'WITHDRAW_ROBOT_COMMISSION',
    DepositManual: 'DEPOSIT_MANUAL',
    DepositAcquisition: 'DEPOSIT_ACQUISITION',
    MoneyToBonusTransfer: 'MONEY_TO_BONUS_TRANSFER',
    DepositReferralCommission: 'DEPOSIT_REFERRAL_COMMISSION',
    WithdrawBusinessSubscription: 'WITHDRAW_BUSINESS_SUBSCRIPTION',
    WithdrawUser: 'WITHDRAW_USER'
} as const;

export type WalletTransactionDetailsCauseEnum = typeof WalletTransactionDetailsCauseEnum[keyof typeof WalletTransactionDetailsCauseEnum];
export const WalletTransactionDetailsStateEnum = {
    InProgress: 'IN_PROGRESS',
    Failed: 'FAILED',
    Successful: 'SUCCESSFUL'
} as const;

export type WalletTransactionDetailsStateEnum = typeof WalletTransactionDetailsStateEnum[keyof typeof WalletTransactionDetailsStateEnum];

/**
 * Wallet transaction item
 * @export
 * @interface WalletTransactionItem
 */
export interface WalletTransactionItem {
    /**
     * Transaction ID
     * @type {string}
     * @memberof WalletTransactionItem
     */
    'id': string;
    /**
     * Transaction cause
     * @type {string}
     * @memberof WalletTransactionItem
     */
    'cause': WalletTransactionItemCauseEnum;
    /**
     * Transaction state
     * @type {string}
     * @memberof WalletTransactionItem
     */
    'state': WalletTransactionItemStateEnum;
    /**
     * When transaction was created
     * @type {string}
     * @memberof WalletTransactionItem
     */
    'createdAt': string;
    /**
     * Transaction amount
     * @type {number}
     * @memberof WalletTransactionItem
     */
    'amount': number;
    /**
     * Wallet balance after current transaction
     * @type {number}
     * @memberof WalletTransactionItem
     */
    'balanceAfterTransaction': number;
}

export const WalletTransactionItemCauseEnum = {
    DepositBonusRegistration: 'DEPOSIT_BONUS_REGISTRATION',
    WithdrawRobotCommission: 'WITHDRAW_ROBOT_COMMISSION',
    DepositManual: 'DEPOSIT_MANUAL',
    DepositAcquisition: 'DEPOSIT_ACQUISITION',
    MoneyToBonusTransfer: 'MONEY_TO_BONUS_TRANSFER',
    DepositReferralCommission: 'DEPOSIT_REFERRAL_COMMISSION',
    WithdrawBusinessSubscription: 'WITHDRAW_BUSINESS_SUBSCRIPTION',
    WithdrawUser: 'WITHDRAW_USER'
} as const;

export type WalletTransactionItemCauseEnum = typeof WalletTransactionItemCauseEnum[keyof typeof WalletTransactionItemCauseEnum];
export const WalletTransactionItemStateEnum = {
    InProgress: 'IN_PROGRESS',
    Failed: 'FAILED',
    Successful: 'SUCCESSFUL'
} as const;

export type WalletTransactionItemStateEnum = typeof WalletTransactionItemStateEnum[keyof typeof WalletTransactionItemStateEnum];

/**
 * 
 * @export
 * @interface WalletTransactionList
 */
export interface WalletTransactionList {
    /**
     * Transactions
     * @type {Array<WalletTransactionItem>}
     * @memberof WalletTransactionList
     */
    'transactions': Array<WalletTransactionItem>;
    /**
     * Token to next page
     * @type {string}
     * @memberof WalletTransactionList
     */
    'nextPageToken'?: string;
}
/**
 * 
 * @export
 * @interface WithdrawalNetwork
 */
export interface WithdrawalNetwork {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalNetwork
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalNetwork
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawalNetwork
     */
    'isDefault': boolean;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalNetwork
     */
    'addressRegex'?: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalNetwork
     */
    'fee': number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalNetwork
     */
    'minAmount': number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalNetwork
     */
    'maxAmount': number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalNetwork
     */
    'amountMultiple': number;
}
/**
 * 
 * @export
 * @interface WithdrawalRequest
 */
export interface WithdrawalRequest {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalRequest
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalRequest
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalRequest
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalRequest
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalRequest
     */
    'status': WithdrawalRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalRequest
     */
    'error'?: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalRequest
     */
    'confirmTries': number;
}

export const WithdrawalRequestStatusEnum = {
    Created: 'CREATED',
    WaitingConfirmation: 'WAITING_CONFIRMATION',
    Failed: 'FAILED',
    Denied: 'DENIED',
    Expired: 'EXPIRED',
    Processing: 'PROCESSING',
    Completed: 'COMPLETED'
} as const;

export type WithdrawalRequestStatusEnum = typeof WithdrawalRequestStatusEnum[keyof typeof WithdrawalRequestStatusEnum];

/**
 * 
 * @export
 * @interface WithdrawalRequestConfig
 */
export interface WithdrawalRequestConfig {
    /**
     * 
     * @type {number}
     * @memberof WithdrawalRequestConfig
     */
    'minAmount': number;
    /**
     * 
     * @type {Array<WithdrawalNetwork>}
     * @memberof WithdrawalRequestConfig
     */
    'networks': Array<WithdrawalNetwork>;
}

/**
 * AppVersionApi - axios parameter creator
 * @export
 */
export const AppVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get version action
         * @param {string} client Client ID
         * @param {number} version Client version code
         * @param {string} branch Client branch
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionAction: async (client: string, version: number, branch: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('getVersionAction', 'client', client)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getVersionAction', 'version', version)
            // verify required parameter 'branch' is not null or undefined
            assertParamExists('getVersionAction', 'branch', branch)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVersionAction', 'id', id)
            const localVarPath = `/api/v2/app-version/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (branch !== undefined) {
                localVarQueryParameter['branch'] = branch;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppVersionApi - functional programming interface
 * @export
 */
export const AppVersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppVersionApiAxiosParamCreator(configuration)
    return {
        /**
         * Get version action
         * @param {string} client Client ID
         * @param {number} version Client version code
         * @param {string} branch Client branch
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersionAction(client: string, version: number, branch: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersionAction(client, version, branch, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppVersionApi - factory interface
 * @export
 */
export const AppVersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppVersionApiFp(configuration)
    return {
        /**
         * Get version action
         * @param {string} client Client ID
         * @param {number} version Client version code
         * @param {string} branch Client branch
         * @param {string} id Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionAction(client: string, version: number, branch: string, id: string, options?: any): AxiosPromise<VersionResult> {
            return localVarFp.getVersionAction(client, version, branch, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getVersionAction operation in AppVersionApi.
 * @export
 * @interface AppVersionApiGetVersionActionRequest
 */
export interface AppVersionApiGetVersionActionRequest {
    /**
     * Client ID
     * @type {string}
     * @memberof AppVersionApiGetVersionAction
     */
    readonly client: string

    /**
     * Client version code
     * @type {number}
     * @memberof AppVersionApiGetVersionAction
     */
    readonly version: number

    /**
     * Client branch
     * @type {string}
     * @memberof AppVersionApiGetVersionAction
     */
    readonly branch: string

    /**
     * Device ID
     * @type {string}
     * @memberof AppVersionApiGetVersionAction
     */
    readonly id: string
}

/**
 * AppVersionApi - object-oriented interface
 * @export
 * @class AppVersionApi
 * @extends {BaseAPI}
 */
export class AppVersionApi extends BaseAPI {
    /**
     * Get version action
     * @param {AppVersionApiGetVersionActionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppVersionApi
     */
    public getVersionAction(requestParameters: AppVersionApiGetVersionActionRequest, options?: AxiosRequestConfig) {
        return AppVersionApiFp(this.configuration).getVersionAction(requestParameters.client, requestParameters.version, requestParameters.branch, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * OAuth 2.0 \'get token\' endpoint
         * @param {string} grantType Grant type
         * @param {string} [code] Authorization code (required for authorization_code grant type)
         * @param {string} [refreshToken] Refresh token (required for refresh_token grant type)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (grantType: string, code?: string, refreshToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('getToken', 'grantType', grantType)
            const localVarPath = `/api/v1/oauth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (grantType !== undefined) {
                localVarQueryParameter['grant_type'] = grantType;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (refreshToken !== undefined) {
                localVarQueryParameter['refresh_token'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Login user with email and password
         * @param {EmailAuthenticationParamsDto} emailAuthenticationParamsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithEmail: async (emailAuthenticationParamsDto: EmailAuthenticationParamsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAuthenticationParamsDto' is not null or undefined
            assertParamExists('loginWithEmail', 'emailAuthenticationParamsDto', emailAuthenticationParamsDto)
            const localVarPath = `/api/v1/auth/email/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailAuthenticationParamsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterWithEmailParams} registerWithEmailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWithEmail: async (registerWithEmailParams: RegisterWithEmailParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerWithEmailParams' is not null or undefined
            assertParamExists('registerWithEmail', 'registerWithEmailParams', registerWithEmailParams)
            const localVarPath = `/api/v1/auth/email/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerWithEmailParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * OAuth 2.0 \'get token\' endpoint
         * @param {string} grantType Grant type
         * @param {string} [code] Authorization code (required for authorization_code grant type)
         * @param {string} [refreshToken] Refresh token (required for refresh_token grant type)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(grantType: string, code?: string, refreshToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(grantType, code, refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Login user with email and password
         * @param {EmailAuthenticationParamsDto} emailAuthenticationParamsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithEmail(emailAuthenticationParamsDto: EmailAuthenticationParamsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailAuthorizationCodeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithEmail(emailAuthenticationParamsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterWithEmailParams} registerWithEmailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerWithEmail(registerWithEmailParams: RegisterWithEmailParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerWithEmail(registerWithEmailParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * OAuth 2.0 \'get token\' endpoint
         * @param {string} grantType Grant type
         * @param {string} [code] Authorization code (required for authorization_code grant type)
         * @param {string} [refreshToken] Refresh token (required for refresh_token grant type)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken(grantType: string, code?: string, refreshToken?: string, options?: any): AxiosPromise<AccessToken> {
            return localVarFp.getToken(grantType, code, refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Login user with email and password
         * @param {EmailAuthenticationParamsDto} emailAuthenticationParamsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithEmail(emailAuthenticationParamsDto: EmailAuthenticationParamsDto, options?: any): AxiosPromise<EmailAuthorizationCodeDto> {
            return localVarFp.loginWithEmail(emailAuthenticationParamsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterWithEmailParams} registerWithEmailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWithEmail(registerWithEmailParams: RegisterWithEmailParams, options?: any): AxiosPromise<void> {
            return localVarFp.registerWithEmail(registerWithEmailParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getToken operation in AuthApi.
 * @export
 * @interface AuthApiGetTokenRequest
 */
export interface AuthApiGetTokenRequest {
    /**
     * Grant type
     * @type {string}
     * @memberof AuthApiGetToken
     */
    readonly grantType: string

    /**
     * Authorization code (required for authorization_code grant type)
     * @type {string}
     * @memberof AuthApiGetToken
     */
    readonly code?: string

    /**
     * Refresh token (required for refresh_token grant type)
     * @type {string}
     * @memberof AuthApiGetToken
     */
    readonly refreshToken?: string
}

/**
 * Request parameters for loginWithEmail operation in AuthApi.
 * @export
 * @interface AuthApiLoginWithEmailRequest
 */
export interface AuthApiLoginWithEmailRequest {
    /**
     * 
     * @type {EmailAuthenticationParamsDto}
     * @memberof AuthApiLoginWithEmail
     */
    readonly emailAuthenticationParamsDto: EmailAuthenticationParamsDto
}

/**
 * Request parameters for registerWithEmail operation in AuthApi.
 * @export
 * @interface AuthApiRegisterWithEmailRequest
 */
export interface AuthApiRegisterWithEmailRequest {
    /**
     * 
     * @type {RegisterWithEmailParams}
     * @memberof AuthApiRegisterWithEmail
     */
    readonly registerWithEmailParams: RegisterWithEmailParams
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * OAuth 2.0 \'get token\' endpoint
     * @param {AuthApiGetTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getToken(requestParameters: AuthApiGetTokenRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getToken(requestParameters.grantType, requestParameters.code, requestParameters.refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Login user with email and password
     * @param {AuthApiLoginWithEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginWithEmail(requestParameters: AuthApiLoginWithEmailRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginWithEmail(requestParameters.emailAuthenticationParamsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiRegisterWithEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public registerWithEmail(requestParameters: AuthApiRegisterWithEmailRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).registerWithEmail(requestParameters.registerWithEmailParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExchangeConnectionApi - axios parameter creator
 * @export
 */
export const ExchangeConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create exchange connection
         * @param {CreateConnectionRequest} createConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchangeConnection: async (createConnectionRequest: CreateConnectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createConnectionRequest' is not null or undefined
            assertParamExists('createExchangeConnection', 'createConnectionRequest', createConnectionRequest)
            const localVarPath = `/api/v1/exchange-connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit exchange connection
         * @param {string} id 
         * @param {EditExchangeConnectionRequest} editExchangeConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editExchangeConnection: async (id: string, editExchangeConnectionRequest: EditExchangeConnectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editExchangeConnection', 'id', id)
            // verify required parameter 'editExchangeConnectionRequest' is not null or undefined
            assertParamExists('editExchangeConnection', 'editExchangeConnectionRequest', editExchangeConnectionRequest)
            const localVarPath = `/api/v1/exchange-connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editExchangeConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get robot cluster IPs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterIps: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/exchange-connections/cluster-ips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get exchange connection
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeConnection: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExchangeConnection', 'id', id)
            const localVarPath = `/api/v1/exchange-connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get public key to encrypt exchange connection creds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeConnectionPublicKey: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/exchange-connections/public-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get exchange connection list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeConnections: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/exchange-connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Refresh connection, fetch current exchange info
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshExchangeConnection: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refreshExchangeConnection', 'id', id)
            const localVarPath = `/api/v1/exchange-connections/{id}/refresh`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update exchange connection API keys
         * @param {string} id 
         * @param {UpdateApiKeysRequest} updateApiKeysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchangeConnectionApiKeys: async (id: string, updateApiKeysRequest: UpdateApiKeysRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExchangeConnectionApiKeys', 'id', id)
            // verify required parameter 'updateApiKeysRequest' is not null or undefined
            assertParamExists('updateExchangeConnectionApiKeys', 'updateApiKeysRequest', updateApiKeysRequest)
            const localVarPath = `/api/v1/exchange-connections/{id}/update-api-keys`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateApiKeysRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangeConnectionApi - functional programming interface
 * @export
 */
export const ExchangeConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangeConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * Create exchange connection
         * @param {CreateConnectionRequest} createConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExchangeConnection(createConnectionRequest: CreateConnectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeConnectionDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExchangeConnection(createConnectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit exchange connection
         * @param {string} id 
         * @param {EditExchangeConnectionRequest} editExchangeConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editExchangeConnection(id: string, editExchangeConnectionRequest: EditExchangeConnectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editExchangeConnection(id, editExchangeConnectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get robot cluster IPs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterIps(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterIpList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterIps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get exchange connection
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExchangeConnection(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeConnectionDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExchangeConnection(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get public key to encrypt exchange connection creds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExchangeConnectionPublicKey(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeConnectionPublicKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExchangeConnectionPublicKey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get exchange connection list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExchangeConnections(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeConnectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExchangeConnections(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Refresh connection, fetch current exchange info
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshExchangeConnection(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshExchangeConnection(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update exchange connection API keys
         * @param {string} id 
         * @param {UpdateApiKeysRequest} updateApiKeysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExchangeConnectionApiKeys(id: string, updateApiKeysRequest: UpdateApiKeysRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeConnectionDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExchangeConnectionApiKeys(id, updateApiKeysRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExchangeConnectionApi - factory interface
 * @export
 */
export const ExchangeConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangeConnectionApiFp(configuration)
    return {
        /**
         * Create exchange connection
         * @param {CreateConnectionRequest} createConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchangeConnection(createConnectionRequest: CreateConnectionRequest, options?: any): AxiosPromise<ExchangeConnectionDetails> {
            return localVarFp.createExchangeConnection(createConnectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit exchange connection
         * @param {string} id 
         * @param {EditExchangeConnectionRequest} editExchangeConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editExchangeConnection(id: string, editExchangeConnectionRequest: EditExchangeConnectionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.editExchangeConnection(id, editExchangeConnectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get robot cluster IPs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterIps(options?: any): AxiosPromise<ClusterIpList> {
            return localVarFp.getClusterIps(options).then((request) => request(axios, basePath));
        },
        /**
         * Get exchange connection
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeConnection(id: string, options?: any): AxiosPromise<ExchangeConnectionDetails> {
            return localVarFp.getExchangeConnection(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get public key to encrypt exchange connection creds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeConnectionPublicKey(options?: any): AxiosPromise<ExchangeConnectionPublicKey> {
            return localVarFp.getExchangeConnectionPublicKey(options).then((request) => request(axios, basePath));
        },
        /**
         * Get exchange connection list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeConnections(options?: any): AxiosPromise<ExchangeConnectionList> {
            return localVarFp.getExchangeConnections(options).then((request) => request(axios, basePath));
        },
        /**
         * Refresh connection, fetch current exchange info
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshExchangeConnection(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.refreshExchangeConnection(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update exchange connection API keys
         * @param {string} id 
         * @param {UpdateApiKeysRequest} updateApiKeysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchangeConnectionApiKeys(id: string, updateApiKeysRequest: UpdateApiKeysRequest, options?: any): AxiosPromise<ExchangeConnectionDetails> {
            return localVarFp.updateExchangeConnectionApiKeys(id, updateApiKeysRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createExchangeConnection operation in ExchangeConnectionApi.
 * @export
 * @interface ExchangeConnectionApiCreateExchangeConnectionRequest
 */
export interface ExchangeConnectionApiCreateExchangeConnectionRequest {
    /**
     * 
     * @type {CreateConnectionRequest}
     * @memberof ExchangeConnectionApiCreateExchangeConnection
     */
    readonly createConnectionRequest: CreateConnectionRequest
}

/**
 * Request parameters for editExchangeConnection operation in ExchangeConnectionApi.
 * @export
 * @interface ExchangeConnectionApiEditExchangeConnectionRequest
 */
export interface ExchangeConnectionApiEditExchangeConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionApiEditExchangeConnection
     */
    readonly id: string

    /**
     * 
     * @type {EditExchangeConnectionRequest}
     * @memberof ExchangeConnectionApiEditExchangeConnection
     */
    readonly editExchangeConnectionRequest: EditExchangeConnectionRequest
}

/**
 * Request parameters for getExchangeConnection operation in ExchangeConnectionApi.
 * @export
 * @interface ExchangeConnectionApiGetExchangeConnectionRequest
 */
export interface ExchangeConnectionApiGetExchangeConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionApiGetExchangeConnection
     */
    readonly id: string
}

/**
 * Request parameters for refreshExchangeConnection operation in ExchangeConnectionApi.
 * @export
 * @interface ExchangeConnectionApiRefreshExchangeConnectionRequest
 */
export interface ExchangeConnectionApiRefreshExchangeConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionApiRefreshExchangeConnection
     */
    readonly id: string
}

/**
 * Request parameters for updateExchangeConnectionApiKeys operation in ExchangeConnectionApi.
 * @export
 * @interface ExchangeConnectionApiUpdateExchangeConnectionApiKeysRequest
 */
export interface ExchangeConnectionApiUpdateExchangeConnectionApiKeysRequest {
    /**
     * 
     * @type {string}
     * @memberof ExchangeConnectionApiUpdateExchangeConnectionApiKeys
     */
    readonly id: string

    /**
     * 
     * @type {UpdateApiKeysRequest}
     * @memberof ExchangeConnectionApiUpdateExchangeConnectionApiKeys
     */
    readonly updateApiKeysRequest: UpdateApiKeysRequest
}

/**
 * ExchangeConnectionApi - object-oriented interface
 * @export
 * @class ExchangeConnectionApi
 * @extends {BaseAPI}
 */
export class ExchangeConnectionApi extends BaseAPI {
    /**
     * Create exchange connection
     * @param {ExchangeConnectionApiCreateExchangeConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeConnectionApi
     */
    public createExchangeConnection(requestParameters: ExchangeConnectionApiCreateExchangeConnectionRequest, options?: AxiosRequestConfig) {
        return ExchangeConnectionApiFp(this.configuration).createExchangeConnection(requestParameters.createConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit exchange connection
     * @param {ExchangeConnectionApiEditExchangeConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeConnectionApi
     */
    public editExchangeConnection(requestParameters: ExchangeConnectionApiEditExchangeConnectionRequest, options?: AxiosRequestConfig) {
        return ExchangeConnectionApiFp(this.configuration).editExchangeConnection(requestParameters.id, requestParameters.editExchangeConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get robot cluster IPs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeConnectionApi
     */
    public getClusterIps(options?: AxiosRequestConfig) {
        return ExchangeConnectionApiFp(this.configuration).getClusterIps(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get exchange connection
     * @param {ExchangeConnectionApiGetExchangeConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeConnectionApi
     */
    public getExchangeConnection(requestParameters: ExchangeConnectionApiGetExchangeConnectionRequest, options?: AxiosRequestConfig) {
        return ExchangeConnectionApiFp(this.configuration).getExchangeConnection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get public key to encrypt exchange connection creds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeConnectionApi
     */
    public getExchangeConnectionPublicKey(options?: AxiosRequestConfig) {
        return ExchangeConnectionApiFp(this.configuration).getExchangeConnectionPublicKey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get exchange connection list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeConnectionApi
     */
    public getExchangeConnections(options?: AxiosRequestConfig) {
        return ExchangeConnectionApiFp(this.configuration).getExchangeConnections(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Refresh connection, fetch current exchange info
     * @param {ExchangeConnectionApiRefreshExchangeConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeConnectionApi
     */
    public refreshExchangeConnection(requestParameters: ExchangeConnectionApiRefreshExchangeConnectionRequest, options?: AxiosRequestConfig) {
        return ExchangeConnectionApiFp(this.configuration).refreshExchangeConnection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update exchange connection API keys
     * @param {ExchangeConnectionApiUpdateExchangeConnectionApiKeysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeConnectionApi
     */
    public updateExchangeConnectionApiKeys(requestParameters: ExchangeConnectionApiUpdateExchangeConnectionApiKeysRequest, options?: AxiosRequestConfig) {
        return ExchangeConnectionApiFp(this.configuration).updateExchangeConnectionApiKeys(requestParameters.id, requestParameters.updateApiKeysRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get current maintenances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentMaintenance: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/maintenances/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceApiAxiosParamCreator(configuration)
    return {
        /**
         * Get current maintenances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentMaintenance(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentMaintenanceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentMaintenance(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceApiFp(configuration)
    return {
        /**
         * Get current maintenances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentMaintenance(options?: any): AxiosPromise<CurrentMaintenanceDto> {
            return localVarFp.getCurrentMaintenance(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
    /**
     * Get current maintenances
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public getCurrentMaintenance(options?: AxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).getCurrentMaintenance(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Ack all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ackAllNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/storage/ack-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ack notifications
         * @param {Array<string>} id Notifications IDS to ack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ackNotifications: async (id: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ackNotifications', 'id', id)
            const localVarPath = `/api/v1/notifications/storage/ack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit email notification preference
         * @param {string} id 
         * @param {EditEmailNotificationPreferences} editEmailNotificationPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEmailNotificationPreference: async (id: string, editEmailNotificationPreferences: EditEmailNotificationPreferences, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editEmailNotificationPreference', 'id', id)
            // verify required parameter 'editEmailNotificationPreferences' is not null or undefined
            assertParamExists('editEmailNotificationPreference', 'editEmailNotificationPreferences', editEmailNotificationPreferences)
            const localVarPath = `/api/v1/notifications/email/preferences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editEmailNotificationPreferences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get email notification preference by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailNotificationPreferenceById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmailNotificationPreferenceById', 'id', id)
            const localVarPath = `/api/v1/notifications/email/preferences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get email notification preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailNotificationPreferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/email/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notification storage preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifcationStoragePreview: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/storage/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notification details
         * @param {string} id Notification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationDetails: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNotificationDetails', 'id', id)
            const localVarPath = `/api/v1/notifications/storage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notifications page
         * @param {string} [nextPageToken] Token to next page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (nextPageToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/storage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nextPageToken !== undefined) {
                localVarQueryParameter['nextPageToken'] = nextPageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get email notification unsubscribe status
         * @param {string} token 
         * @param {string} prefs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnsubscribeStatus: async (token: string, prefs: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getUnsubscribeStatus', 'token', token)
            // verify required parameter 'prefs' is not null or undefined
            assertParamExists('getUnsubscribeStatus', 'prefs', prefs)
            const localVarPath = `/api/v1/notifications/email/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (prefs !== undefined) {
                localVarQueryParameter['prefs'] = prefs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unsubscribe from emails
         * @param {string} token 
         * @param {string} prefs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromEmails: async (token: string, prefs: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('unsubscribeFromEmails', 'token', token)
            // verify required parameter 'prefs' is not null or undefined
            assertParamExists('unsubscribeFromEmails', 'prefs', prefs)
            const localVarPath = `/api/v1/notifications/email/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (prefs !== undefined) {
                localVarQueryParameter['prefs'] = prefs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Ack all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ackAllNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ackAllNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ack notifications
         * @param {Array<string>} id Notifications IDS to ack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ackNotifications(id: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ackNotifications(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit email notification preference
         * @param {string} id 
         * @param {EditEmailNotificationPreferences} editEmailNotificationPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editEmailNotificationPreference(id: string, editEmailNotificationPreferences: EditEmailNotificationPreferences, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editEmailNotificationPreference(id, editEmailNotificationPreferences, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get email notification preference by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailNotificationPreferenceById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailNotificationPreferences>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailNotificationPreferenceById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get email notification preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailNotificationPreferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailNotificationPreferencesList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailNotificationPreferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get notification storage preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifcationStoragePreview(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationStoragePreview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifcationStoragePreview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get notification details
         * @param {string} id Notification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationDetails(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get notifications page
         * @param {string} [nextPageToken] Token to next page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(nextPageToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(nextPageToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get email notification unsubscribe status
         * @param {string} token 
         * @param {string} prefs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnsubscribeStatus(token: string, prefs: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnsubscribeStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnsubscribeStatus(token, prefs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unsubscribe from emails
         * @param {string} token 
         * @param {string} prefs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsubscribeFromEmails(token: string, prefs: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsubscribeFromEmails(token, prefs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * Ack all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ackAllNotifications(options?: any): AxiosPromise<void> {
            return localVarFp.ackAllNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * Ack notifications
         * @param {Array<string>} id Notifications IDS to ack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ackNotifications(id: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.ackNotifications(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit email notification preference
         * @param {string} id 
         * @param {EditEmailNotificationPreferences} editEmailNotificationPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEmailNotificationPreference(id: string, editEmailNotificationPreferences: EditEmailNotificationPreferences, options?: any): AxiosPromise<void> {
            return localVarFp.editEmailNotificationPreference(id, editEmailNotificationPreferences, options).then((request) => request(axios, basePath));
        },
        /**
         * Get email notification preference by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailNotificationPreferenceById(id: string, options?: any): AxiosPromise<EmailNotificationPreferences> {
            return localVarFp.getEmailNotificationPreferenceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get email notification preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailNotificationPreferences(options?: any): AxiosPromise<EmailNotificationPreferencesList> {
            return localVarFp.getEmailNotificationPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * Get notification storage preview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifcationStoragePreview(options?: any): AxiosPromise<NotificationStoragePreview> {
            return localVarFp.getNotifcationStoragePreview(options).then((request) => request(axios, basePath));
        },
        /**
         * Get notification details
         * @param {string} id Notification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationDetails(id: string, options?: any): AxiosPromise<NotificationDetails> {
            return localVarFp.getNotificationDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get notifications page
         * @param {string} [nextPageToken] Token to next page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(nextPageToken?: string, options?: any): AxiosPromise<NotificationList> {
            return localVarFp.getNotifications(nextPageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get email notification unsubscribe status
         * @param {string} token 
         * @param {string} prefs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnsubscribeStatus(token: string, prefs: string, options?: any): AxiosPromise<UnsubscribeStatus> {
            return localVarFp.getUnsubscribeStatus(token, prefs, options).then((request) => request(axios, basePath));
        },
        /**
         * Unsubscribe from emails
         * @param {string} token 
         * @param {string} prefs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromEmails(token: string, prefs: string, options?: any): AxiosPromise<void> {
            return localVarFp.unsubscribeFromEmails(token, prefs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for ackNotifications operation in NotificationsApi.
 * @export
 * @interface NotificationsApiAckNotificationsRequest
 */
export interface NotificationsApiAckNotificationsRequest {
    /**
     * Notifications IDS to ack
     * @type {Array<string>}
     * @memberof NotificationsApiAckNotifications
     */
    readonly id: Array<string>
}

/**
 * Request parameters for editEmailNotificationPreference operation in NotificationsApi.
 * @export
 * @interface NotificationsApiEditEmailNotificationPreferenceRequest
 */
export interface NotificationsApiEditEmailNotificationPreferenceRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiEditEmailNotificationPreference
     */
    readonly id: string

    /**
     * 
     * @type {EditEmailNotificationPreferences}
     * @memberof NotificationsApiEditEmailNotificationPreference
     */
    readonly editEmailNotificationPreferences: EditEmailNotificationPreferences
}

/**
 * Request parameters for getEmailNotificationPreferenceById operation in NotificationsApi.
 * @export
 * @interface NotificationsApiGetEmailNotificationPreferenceByIdRequest
 */
export interface NotificationsApiGetEmailNotificationPreferenceByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiGetEmailNotificationPreferenceById
     */
    readonly id: string
}

/**
 * Request parameters for getNotificationDetails operation in NotificationsApi.
 * @export
 * @interface NotificationsApiGetNotificationDetailsRequest
 */
export interface NotificationsApiGetNotificationDetailsRequest {
    /**
     * Notification ID
     * @type {string}
     * @memberof NotificationsApiGetNotificationDetails
     */
    readonly id: string
}

/**
 * Request parameters for getNotifications operation in NotificationsApi.
 * @export
 * @interface NotificationsApiGetNotificationsRequest
 */
export interface NotificationsApiGetNotificationsRequest {
    /**
     * Token to next page
     * @type {string}
     * @memberof NotificationsApiGetNotifications
     */
    readonly nextPageToken?: string
}

/**
 * Request parameters for getUnsubscribeStatus operation in NotificationsApi.
 * @export
 * @interface NotificationsApiGetUnsubscribeStatusRequest
 */
export interface NotificationsApiGetUnsubscribeStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiGetUnsubscribeStatus
     */
    readonly token: string

    /**
     * 
     * @type {string}
     * @memberof NotificationsApiGetUnsubscribeStatus
     */
    readonly prefs: string
}

/**
 * Request parameters for unsubscribeFromEmails operation in NotificationsApi.
 * @export
 * @interface NotificationsApiUnsubscribeFromEmailsRequest
 */
export interface NotificationsApiUnsubscribeFromEmailsRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiUnsubscribeFromEmails
     */
    readonly token: string

    /**
     * 
     * @type {string}
     * @memberof NotificationsApiUnsubscribeFromEmails
     */
    readonly prefs: string
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * Ack all notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public ackAllNotifications(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).ackAllNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ack notifications
     * @param {NotificationsApiAckNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public ackNotifications(requestParameters: NotificationsApiAckNotificationsRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).ackNotifications(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit email notification preference
     * @param {NotificationsApiEditEmailNotificationPreferenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public editEmailNotificationPreference(requestParameters: NotificationsApiEditEmailNotificationPreferenceRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).editEmailNotificationPreference(requestParameters.id, requestParameters.editEmailNotificationPreferences, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get email notification preference by id
     * @param {NotificationsApiGetEmailNotificationPreferenceByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getEmailNotificationPreferenceById(requestParameters: NotificationsApiGetEmailNotificationPreferenceByIdRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getEmailNotificationPreferenceById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get email notification preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getEmailNotificationPreferences(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getEmailNotificationPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notification storage preview
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifcationStoragePreview(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotifcationStoragePreview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notification details
     * @param {NotificationsApiGetNotificationDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotificationDetails(requestParameters: NotificationsApiGetNotificationDetailsRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotificationDetails(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notifications page
     * @param {NotificationsApiGetNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifications(requestParameters: NotificationsApiGetNotificationsRequest = {}, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotifications(requestParameters.nextPageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get email notification unsubscribe status
     * @param {NotificationsApiGetUnsubscribeStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getUnsubscribeStatus(requestParameters: NotificationsApiGetUnsubscribeStatusRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getUnsubscribeStatus(requestParameters.token, requestParameters.prefs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unsubscribe from emails
     * @param {NotificationsApiUnsubscribeFromEmailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public unsubscribeFromEmails(requestParameters: NotificationsApiUnsubscribeFromEmailsRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).unsubscribeFromEmails(requestParameters.token, requestParameters.prefs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OnboardingApi - axios parameter creator
 * @export
 */
export const OnboardingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Advance onboarding, set result to selected stage
         * @param {AdvanceStateDto} advanceStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advanceOnboarding: async (advanceStateDto: AdvanceStateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advanceStateDto' is not null or undefined
            assertParamExists('advanceOnboarding', 'advanceStateDto', advanceStateDto)
            const localVarPath = `/api/v1/onboarding/advance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advanceStateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current onboarding state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnboardingState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/onboarding/current-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnboardingApi - functional programming interface
 * @export
 */
export const OnboardingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OnboardingApiAxiosParamCreator(configuration)
    return {
        /**
         * Advance onboarding, set result to selected stage
         * @param {AdvanceStateDto} advanceStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advanceOnboarding(advanceStateDto: AdvanceStateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advanceOnboarding(advanceStateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get current onboarding state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOnboardingState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentOnboardingState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOnboardingState(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OnboardingApi - factory interface
 * @export
 */
export const OnboardingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OnboardingApiFp(configuration)
    return {
        /**
         * Advance onboarding, set result to selected stage
         * @param {AdvanceStateDto} advanceStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advanceOnboarding(advanceStateDto: AdvanceStateDto, options?: any): AxiosPromise<void> {
            return localVarFp.advanceOnboarding(advanceStateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current onboarding state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnboardingState(options?: any): AxiosPromise<CurrentOnboardingState> {
            return localVarFp.getOnboardingState(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for advanceOnboarding operation in OnboardingApi.
 * @export
 * @interface OnboardingApiAdvanceOnboardingRequest
 */
export interface OnboardingApiAdvanceOnboardingRequest {
    /**
     * 
     * @type {AdvanceStateDto}
     * @memberof OnboardingApiAdvanceOnboarding
     */
    readonly advanceStateDto: AdvanceStateDto
}

/**
 * OnboardingApi - object-oriented interface
 * @export
 * @class OnboardingApi
 * @extends {BaseAPI}
 */
export class OnboardingApi extends BaseAPI {
    /**
     * Advance onboarding, set result to selected stage
     * @param {OnboardingApiAdvanceOnboardingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public advanceOnboarding(requestParameters: OnboardingApiAdvanceOnboardingRequest, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).advanceOnboarding(requestParameters.advanceStateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current onboarding state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public getOnboardingState(options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).getOnboardingState(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReferralApi - axios parameter creator
 * @export
 */
export const ReferralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get first lane
         * @param {number} id 
         * @param {string} [nextPageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstLane: async (id: number, nextPageToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFirstLane', 'id', id)
            const localVarPath = `/api/v1/referral/user/{id}/first-lane`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nextPageToken !== undefined) {
                localVarQueryParameter['nextPageToken'] = nextPageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get team count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/referral/team-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get total income
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalIncome: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/referral/total-income`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search referral
         * @param {string} query 
         * @param {string} [nextPageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchReferral: async (query: string, nextPageToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchReferral', 'query', query)
            const localVarPath = `/api/v1/referral/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (nextPageToken !== undefined) {
                localVarQueryParameter['nextPageToken'] = nextPageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferralApi - functional programming interface
 * @export
 */
export const ReferralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferralApiAxiosParamCreator(configuration)
    return {
        /**
         * Get first lane
         * @param {number} id 
         * @param {string} [nextPageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFirstLane(id: number, nextPageToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreeLanePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFirstLane(id, nextPageToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get team count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get total income
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalIncome(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalIncome>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalIncome(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search referral
         * @param {string} query 
         * @param {string} [nextPageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchReferral(query: string, nextPageToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralSearchPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchReferral(query, nextPageToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferralApi - factory interface
 * @export
 */
export const ReferralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferralApiFp(configuration)
    return {
        /**
         * Get first lane
         * @param {number} id 
         * @param {string} [nextPageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstLane(id: number, nextPageToken?: string, options?: any): AxiosPromise<TreeLanePage> {
            return localVarFp.getFirstLane(id, nextPageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get team count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamCount(options?: any): AxiosPromise<TeamCount> {
            return localVarFp.getTeamCount(options).then((request) => request(axios, basePath));
        },
        /**
         * Get total income
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalIncome(options?: any): AxiosPromise<TotalIncome> {
            return localVarFp.getTotalIncome(options).then((request) => request(axios, basePath));
        },
        /**
         * Search referral
         * @param {string} query 
         * @param {string} [nextPageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchReferral(query: string, nextPageToken?: string, options?: any): AxiosPromise<ReferralSearchPage> {
            return localVarFp.searchReferral(query, nextPageToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getFirstLane operation in ReferralApi.
 * @export
 * @interface ReferralApiGetFirstLaneRequest
 */
export interface ReferralApiGetFirstLaneRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferralApiGetFirstLane
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof ReferralApiGetFirstLane
     */
    readonly nextPageToken?: string
}

/**
 * Request parameters for searchReferral operation in ReferralApi.
 * @export
 * @interface ReferralApiSearchReferralRequest
 */
export interface ReferralApiSearchReferralRequest {
    /**
     * 
     * @type {string}
     * @memberof ReferralApiSearchReferral
     */
    readonly query: string

    /**
     * 
     * @type {string}
     * @memberof ReferralApiSearchReferral
     */
    readonly nextPageToken?: string
}

/**
 * ReferralApi - object-oriented interface
 * @export
 * @class ReferralApi
 * @extends {BaseAPI}
 */
export class ReferralApi extends BaseAPI {
    /**
     * Get first lane
     * @param {ReferralApiGetFirstLaneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public getFirstLane(requestParameters: ReferralApiGetFirstLaneRequest, options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).getFirstLane(requestParameters.id, requestParameters.nextPageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get team count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public getTeamCount(options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).getTeamCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get total income
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public getTotalIncome(options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).getTotalIncome(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search referral
     * @param {ReferralApiSearchReferralRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public searchReferral(requestParameters: ReferralApiSearchReferralRequest, options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).searchReferral(requestParameters.query, requestParameters.nextPageToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RobotApi - axios parameter creator
 * @export
 */
export const RobotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create robot
         * @param {RobotCreateRequest} robotCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRobot: async (robotCreateRequest: RobotCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'robotCreateRequest' is not null or undefined
            assertParamExists('createRobot', 'robotCreateRequest', robotCreateRequest)
            const localVarPath = `/api/v1/robots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(robotCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete robot
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRobot: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRobot', 'id', id)
            const localVarPath = `/api/v1/robots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Disable robot
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableRobot: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('disableRobot', 'id', id)
            const localVarPath = `/api/v1/robots/{id}/disable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit robot
         * @param {string} id 
         * @param {RobotEditDto} robotEditDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRobot: async (id: string, robotEditDto: RobotEditDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editRobot', 'id', id)
            // verify required parameter 'robotEditDto' is not null or undefined
            assertParamExists('editRobot', 'robotEditDto', robotEditDto)
            const localVarPath = `/api/v1/robots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(robotEditDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable robot
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableRobot: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enableRobot', 'id', id)
            const localVarPath = `/api/v1/robots/{id}/enable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all trade list
         * @param {string} [nextPageToken] Next page token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTrades: async (nextPageToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/robots/trades`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nextPageToken !== undefined) {
                localVarQueryParameter['nextPageToken'] = nextPageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get robot config
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRobotConfig: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRobotConfig', 'id', id)
            const localVarPath = `/api/v1/robots/{id}/config`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get robot markers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRobotMarkets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/robots/markets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get robot trade history chart
         * @param {string} id 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRobotTradeHistoryChart: async (id: string, month: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRobotTradeHistoryChart', 'id', id)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getRobotTradeHistoryChart', 'month', month)
            const localVarPath = `/api/v1/robots/{id}/trade-history-chart`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get robot trade list
         * @param {string} id Robot id
         * @param {string} [nextPageToken] Next page token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRobotTrades: async (id: string, nextPageToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRobotTrades', 'id', id)
            const localVarPath = `/api/v1/robots/{id}/trades`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nextPageToken !== undefined) {
                localVarQueryParameter['nextPageToken'] = nextPageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all robot info and aggregated report for assets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRobots: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/robots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary trade history chart
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummaryTradeHistoryChart: async (month: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getSummaryTradeHistoryChart', 'month', month)
            const localVarPath = `/api/v1/robots/trade-history-chart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get robot trade details
         * @param {string} id Robot id
         * @param {string} trade Trade id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeDetails: async (id: string, trade: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTradeDetails', 'id', id)
            // verify required parameter 'trade' is not null or undefined
            assertParamExists('getTradeDetails', 'trade', trade)
            const localVarPath = `/api/v1/robots/{id}/trades/{trade}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"trade"}}`, encodeURIComponent(String(trade)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set robot config
         * @param {string} id 
         * @param {RobotConfig} robotConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRobotConfig: async (id: string, robotConfig: RobotConfig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setRobotConfig', 'id', id)
            // verify required parameter 'robotConfig' is not null or undefined
            assertParamExists('setRobotConfig', 'robotConfig', robotConfig)
            const localVarPath = `/api/v1/robots/{id}/config`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(robotConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RobotApi - functional programming interface
 * @export
 */
export const RobotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RobotApiAxiosParamCreator(configuration)
    return {
        /**
         * Create robot
         * @param {RobotCreateRequest} robotCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRobot(robotCreateRequest: RobotCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRobot(robotCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete robot
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRobot(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRobot(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Disable robot
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableRobot(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableRobot(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit robot
         * @param {string} id 
         * @param {RobotEditDto} robotEditDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editRobot(id: string, robotEditDto: RobotEditDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editRobot(id, robotEditDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable robot
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableRobot(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableRobot(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all trade list
         * @param {string} [nextPageToken] Next page token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTrades(nextPageToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotTradeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTrades(nextPageToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get robot config
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRobotConfig(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRobotConfig(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get robot markers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRobotMarkets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRobotMarkets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get robot trade history chart
         * @param {string} id 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRobotTradeHistoryChart(id: string, month: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotTradeChart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRobotTradeHistoryChart(id, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get robot trade list
         * @param {string} id Robot id
         * @param {string} [nextPageToken] Next page token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRobotTrades(id: string, nextPageToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotTradeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRobotTrades(id, nextPageToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all robot info and aggregated report for assets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRobots(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotSummaryInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRobots(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get summary trade history chart
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSummaryTradeHistoryChart(month: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotTradeChart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSummaryTradeHistoryChart(month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get robot trade details
         * @param {string} id Robot id
         * @param {string} trade Trade id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradeDetails(id: string, trade: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotTradeDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradeDetails(id, trade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set robot config
         * @param {string} id 
         * @param {RobotConfig} robotConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRobotConfig(id: string, robotConfig: RobotConfig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setRobotConfig(id, robotConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RobotApi - factory interface
 * @export
 */
export const RobotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RobotApiFp(configuration)
    return {
        /**
         * Create robot
         * @param {RobotCreateRequest} robotCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRobot(robotCreateRequest: RobotCreateRequest, options?: any): AxiosPromise<RobotSummary> {
            return localVarFp.createRobot(robotCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete robot
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRobot(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRobot(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Disable robot
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableRobot(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.disableRobot(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit robot
         * @param {string} id 
         * @param {RobotEditDto} robotEditDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRobot(id: string, robotEditDto: RobotEditDto, options?: any): AxiosPromise<void> {
            return localVarFp.editRobot(id, robotEditDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable robot
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableRobot(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.enableRobot(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all trade list
         * @param {string} [nextPageToken] Next page token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTrades(nextPageToken?: string, options?: any): AxiosPromise<RobotTradeList> {
            return localVarFp.getAllTrades(nextPageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get robot config
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRobotConfig(id: string, options?: any): AxiosPromise<RobotConfig> {
            return localVarFp.getRobotConfig(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get robot markers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRobotMarkets(options?: any): AxiosPromise<MarketList> {
            return localVarFp.getRobotMarkets(options).then((request) => request(axios, basePath));
        },
        /**
         * Get robot trade history chart
         * @param {string} id 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRobotTradeHistoryChart(id: string, month: string, options?: any): AxiosPromise<RobotTradeChart> {
            return localVarFp.getRobotTradeHistoryChart(id, month, options).then((request) => request(axios, basePath));
        },
        /**
         * Get robot trade list
         * @param {string} id Robot id
         * @param {string} [nextPageToken] Next page token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRobotTrades(id: string, nextPageToken?: string, options?: any): AxiosPromise<RobotTradeList> {
            return localVarFp.getRobotTrades(id, nextPageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all robot info and aggregated report for assets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRobots(options?: any): AxiosPromise<RobotSummaryInfo> {
            return localVarFp.getRobots(options).then((request) => request(axios, basePath));
        },
        /**
         * Get summary trade history chart
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummaryTradeHistoryChart(month: string, options?: any): AxiosPromise<RobotTradeChart> {
            return localVarFp.getSummaryTradeHistoryChart(month, options).then((request) => request(axios, basePath));
        },
        /**
         * Get robot trade details
         * @param {string} id Robot id
         * @param {string} trade Trade id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeDetails(id: string, trade: string, options?: any): AxiosPromise<RobotTradeDetails> {
            return localVarFp.getTradeDetails(id, trade, options).then((request) => request(axios, basePath));
        },
        /**
         * Set robot config
         * @param {string} id 
         * @param {RobotConfig} robotConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRobotConfig(id: string, robotConfig: RobotConfig, options?: any): AxiosPromise<void> {
            return localVarFp.setRobotConfig(id, robotConfig, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRobot operation in RobotApi.
 * @export
 * @interface RobotApiCreateRobotRequest
 */
export interface RobotApiCreateRobotRequest {
    /**
     * 
     * @type {RobotCreateRequest}
     * @memberof RobotApiCreateRobot
     */
    readonly robotCreateRequest: RobotCreateRequest
}

/**
 * Request parameters for deleteRobot operation in RobotApi.
 * @export
 * @interface RobotApiDeleteRobotRequest
 */
export interface RobotApiDeleteRobotRequest {
    /**
     * 
     * @type {string}
     * @memberof RobotApiDeleteRobot
     */
    readonly id: string
}

/**
 * Request parameters for disableRobot operation in RobotApi.
 * @export
 * @interface RobotApiDisableRobotRequest
 */
export interface RobotApiDisableRobotRequest {
    /**
     * 
     * @type {string}
     * @memberof RobotApiDisableRobot
     */
    readonly id: string
}

/**
 * Request parameters for editRobot operation in RobotApi.
 * @export
 * @interface RobotApiEditRobotRequest
 */
export interface RobotApiEditRobotRequest {
    /**
     * 
     * @type {string}
     * @memberof RobotApiEditRobot
     */
    readonly id: string

    /**
     * 
     * @type {RobotEditDto}
     * @memberof RobotApiEditRobot
     */
    readonly robotEditDto: RobotEditDto
}

/**
 * Request parameters for enableRobot operation in RobotApi.
 * @export
 * @interface RobotApiEnableRobotRequest
 */
export interface RobotApiEnableRobotRequest {
    /**
     * 
     * @type {string}
     * @memberof RobotApiEnableRobot
     */
    readonly id: string
}

/**
 * Request parameters for getAllTrades operation in RobotApi.
 * @export
 * @interface RobotApiGetAllTradesRequest
 */
export interface RobotApiGetAllTradesRequest {
    /**
     * Next page token
     * @type {string}
     * @memberof RobotApiGetAllTrades
     */
    readonly nextPageToken?: string
}

/**
 * Request parameters for getRobotConfig operation in RobotApi.
 * @export
 * @interface RobotApiGetRobotConfigRequest
 */
export interface RobotApiGetRobotConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof RobotApiGetRobotConfig
     */
    readonly id: string
}

/**
 * Request parameters for getRobotTradeHistoryChart operation in RobotApi.
 * @export
 * @interface RobotApiGetRobotTradeHistoryChartRequest
 */
export interface RobotApiGetRobotTradeHistoryChartRequest {
    /**
     * 
     * @type {string}
     * @memberof RobotApiGetRobotTradeHistoryChart
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof RobotApiGetRobotTradeHistoryChart
     */
    readonly month: string
}

/**
 * Request parameters for getRobotTrades operation in RobotApi.
 * @export
 * @interface RobotApiGetRobotTradesRequest
 */
export interface RobotApiGetRobotTradesRequest {
    /**
     * Robot id
     * @type {string}
     * @memberof RobotApiGetRobotTrades
     */
    readonly id: string

    /**
     * Next page token
     * @type {string}
     * @memberof RobotApiGetRobotTrades
     */
    readonly nextPageToken?: string
}

/**
 * Request parameters for getSummaryTradeHistoryChart operation in RobotApi.
 * @export
 * @interface RobotApiGetSummaryTradeHistoryChartRequest
 */
export interface RobotApiGetSummaryTradeHistoryChartRequest {
    /**
     * 
     * @type {string}
     * @memberof RobotApiGetSummaryTradeHistoryChart
     */
    readonly month: string
}

/**
 * Request parameters for getTradeDetails operation in RobotApi.
 * @export
 * @interface RobotApiGetTradeDetailsRequest
 */
export interface RobotApiGetTradeDetailsRequest {
    /**
     * Robot id
     * @type {string}
     * @memberof RobotApiGetTradeDetails
     */
    readonly id: string

    /**
     * Trade id
     * @type {string}
     * @memberof RobotApiGetTradeDetails
     */
    readonly trade: string
}

/**
 * Request parameters for setRobotConfig operation in RobotApi.
 * @export
 * @interface RobotApiSetRobotConfigRequest
 */
export interface RobotApiSetRobotConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof RobotApiSetRobotConfig
     */
    readonly id: string

    /**
     * 
     * @type {RobotConfig}
     * @memberof RobotApiSetRobotConfig
     */
    readonly robotConfig: RobotConfig
}

/**
 * RobotApi - object-oriented interface
 * @export
 * @class RobotApi
 * @extends {BaseAPI}
 */
export class RobotApi extends BaseAPI {
    /**
     * Create robot
     * @param {RobotApiCreateRobotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public createRobot(requestParameters: RobotApiCreateRobotRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).createRobot(requestParameters.robotCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete robot
     * @param {RobotApiDeleteRobotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public deleteRobot(requestParameters: RobotApiDeleteRobotRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).deleteRobot(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Disable robot
     * @param {RobotApiDisableRobotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public disableRobot(requestParameters: RobotApiDisableRobotRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).disableRobot(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit robot
     * @param {RobotApiEditRobotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public editRobot(requestParameters: RobotApiEditRobotRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).editRobot(requestParameters.id, requestParameters.robotEditDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable robot
     * @param {RobotApiEnableRobotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public enableRobot(requestParameters: RobotApiEnableRobotRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).enableRobot(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all trade list
     * @param {RobotApiGetAllTradesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public getAllTrades(requestParameters: RobotApiGetAllTradesRequest = {}, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).getAllTrades(requestParameters.nextPageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get robot config
     * @param {RobotApiGetRobotConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public getRobotConfig(requestParameters: RobotApiGetRobotConfigRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).getRobotConfig(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get robot markers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public getRobotMarkets(options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).getRobotMarkets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get robot trade history chart
     * @param {RobotApiGetRobotTradeHistoryChartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public getRobotTradeHistoryChart(requestParameters: RobotApiGetRobotTradeHistoryChartRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).getRobotTradeHistoryChart(requestParameters.id, requestParameters.month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get robot trade list
     * @param {RobotApiGetRobotTradesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public getRobotTrades(requestParameters: RobotApiGetRobotTradesRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).getRobotTrades(requestParameters.id, requestParameters.nextPageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all robot info and aggregated report for assets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public getRobots(options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).getRobots(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get summary trade history chart
     * @param {RobotApiGetSummaryTradeHistoryChartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public getSummaryTradeHistoryChart(requestParameters: RobotApiGetSummaryTradeHistoryChartRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).getSummaryTradeHistoryChart(requestParameters.month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get robot trade details
     * @param {RobotApiGetTradeDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public getTradeDetails(requestParameters: RobotApiGetTradeDetailsRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).getTradeDetails(requestParameters.id, requestParameters.trade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set robot config
     * @param {RobotApiSetRobotConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public setRobotConfig(requestParameters: RobotApiSetRobotConfigRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).setRobotConfig(requestParameters.id, requestParameters.robotConfig, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Edit subscription
         * @param {EditCurrentSubscription} editCurrentSubscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubscription: async (editCurrentSubscription: EditCurrentSubscription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editCurrentSubscription' is not null or undefined
            assertParamExists('editSubscription', 'editCurrentSubscription', editCurrentSubscription)
            const localVarPath = `/api/v1/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editCurrentSubscription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get subscription info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get subscription info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionTypesInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/subscription-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Subscribe to business version
         * @param {SubscribeBusinessRequest} [subscribeBusinessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeBusiness: async (subscribeBusinessRequest?: SubscribeBusinessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/subscription/subscribe-business`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribeBusinessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * Edit subscription
         * @param {EditCurrentSubscription} editCurrentSubscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editSubscription(editCurrentSubscription: EditCurrentSubscription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editSubscription(editCurrentSubscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get subscription info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get subscription info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionTypesInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalSubscriptionInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionTypesInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Subscribe to business version
         * @param {SubscribeBusinessRequest} [subscribeBusinessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribeBusiness(subscribeBusinessRequest?: SubscribeBusinessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribeBusiness(subscribeBusinessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionApiFp(configuration)
    return {
        /**
         * Edit subscription
         * @param {EditCurrentSubscription} editCurrentSubscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubscription(editCurrentSubscription: EditCurrentSubscription, options?: any): AxiosPromise<SubscriptionInfo> {
            return localVarFp.editSubscription(editCurrentSubscription, options).then((request) => request(axios, basePath));
        },
        /**
         * Get subscription info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionInfo(options?: any): AxiosPromise<SubscriptionInfo> {
            return localVarFp.getSubscriptionInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Get subscription info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionTypesInfo(options?: any): AxiosPromise<GlobalSubscriptionInfo> {
            return localVarFp.getSubscriptionTypesInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Subscribe to business version
         * @param {SubscribeBusinessRequest} [subscribeBusinessRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeBusiness(subscribeBusinessRequest?: SubscribeBusinessRequest, options?: any): AxiosPromise<SubscriptionInfo> {
            return localVarFp.subscribeBusiness(subscribeBusinessRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for editSubscription operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiEditSubscriptionRequest
 */
export interface SubscriptionApiEditSubscriptionRequest {
    /**
     * 
     * @type {EditCurrentSubscription}
     * @memberof SubscriptionApiEditSubscription
     */
    readonly editCurrentSubscription: EditCurrentSubscription
}

/**
 * Request parameters for subscribeBusiness operation in SubscriptionApi.
 * @export
 * @interface SubscriptionApiSubscribeBusinessRequest
 */
export interface SubscriptionApiSubscribeBusinessRequest {
    /**
     * 
     * @type {SubscribeBusinessRequest}
     * @memberof SubscriptionApiSubscribeBusiness
     */
    readonly subscribeBusinessRequest?: SubscribeBusinessRequest
}

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * Edit subscription
     * @param {SubscriptionApiEditSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public editSubscription(requestParameters: SubscriptionApiEditSubscriptionRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).editSubscription(requestParameters.editCurrentSubscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get subscription info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getSubscriptionInfo(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).getSubscriptionInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get subscription info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getSubscriptionTypesInfo(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).getSubscriptionTypesInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Subscribe to business version
     * @param {SubscriptionApiSubscribeBusinessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscribeBusiness(requestParameters: SubscriptionApiSubscribeBusinessRequest = {}, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).subscribeBusiness(requestParameters.subscribeBusinessRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create user link
         * @param {UserLinkCreateDto} userLinkCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserLink: async (userLinkCreateDto: UserLinkCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userLinkCreateDto' is not null or undefined
            assertParamExists('createUserLink', 'userLinkCreateDto', userLinkCreateDto)
            const localVarPath = `/api/v1/user-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLinkCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete user photo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhoto: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete user link
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserLink: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserLink', 'id', id)
            const localVarPath = `/api/v1/user-links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit user info
         * @param {UserEdit} userEdit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser: async (userEdit: UserEdit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userEdit' is not null or undefined
            assertParamExists('editUser', 'userEdit', userEdit)
            const localVarPath = `/api/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userEdit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit user link
         * @param {string} id 
         * @param {UserLinkEditDto} userLinkEditDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserLink: async (id: string, userLinkEditDto: UserLinkEditDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editUserLink', 'id', id)
            // verify required parameter 'userLinkEditDto' is not null or undefined
            assertParamExists('editUserLink', 'userLinkEditDto', userLinkEditDto)
            const localVarPath = `/api/v1/user-links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLinkEditDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current user links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLinks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set user photo
         * @param {File} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPhoto: async (photo: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'photo' is not null or undefined
            assertParamExists('setPhoto', 'photo', photo)
            const localVarPath = `/api/v1/user/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Create user link
         * @param {UserLinkCreateDto} userLinkCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserLink(userLinkCreateDto: UserLinkCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserLink(userLinkCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete user photo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePhoto(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePhoto(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete user link
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserLink(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserLink(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit user info
         * @param {UserEdit} userEdit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editUser(userEdit: UserEdit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editUser(userEdit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit user link
         * @param {string} id 
         * @param {UserLinkEditDto} userLinkEditDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editUserLink(id: string, userLinkEditDto: UserLinkEditDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editUserLink(id, userLinkEditDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get current user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get current user links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLinks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLinkListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLinks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set user photo
         * @param {File} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPhoto(photo: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPhoto(photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Create user link
         * @param {UserLinkCreateDto} userLinkCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserLink(userLinkCreateDto: UserLinkCreateDto, options?: any): AxiosPromise<UserLink> {
            return localVarFp.createUserLink(userLinkCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete user photo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhoto(options?: any): AxiosPromise<void> {
            return localVarFp.deletePhoto(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete user link
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserLink(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserLink(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit user info
         * @param {UserEdit} userEdit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser(userEdit: UserEdit, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.editUser(userEdit, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit user link
         * @param {string} id 
         * @param {UserLinkEditDto} userLinkEditDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserLink(id: string, userLinkEditDto: UserLinkEditDto, options?: any): AxiosPromise<UserLink> {
            return localVarFp.editUserLink(id, userLinkEditDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Get current user links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLinks(options?: any): AxiosPromise<UserLinkListDto> {
            return localVarFp.getUserLinks(options).then((request) => request(axios, basePath));
        },
        /**
         * Set user photo
         * @param {File} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPhoto(photo: File, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.setPhoto(photo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createUserLink operation in UserApi.
 * @export
 * @interface UserApiCreateUserLinkRequest
 */
export interface UserApiCreateUserLinkRequest {
    /**
     * 
     * @type {UserLinkCreateDto}
     * @memberof UserApiCreateUserLink
     */
    readonly userLinkCreateDto: UserLinkCreateDto
}

/**
 * Request parameters for deleteUserLink operation in UserApi.
 * @export
 * @interface UserApiDeleteUserLinkRequest
 */
export interface UserApiDeleteUserLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiDeleteUserLink
     */
    readonly id: string
}

/**
 * Request parameters for editUser operation in UserApi.
 * @export
 * @interface UserApiEditUserRequest
 */
export interface UserApiEditUserRequest {
    /**
     * 
     * @type {UserEdit}
     * @memberof UserApiEditUser
     */
    readonly userEdit: UserEdit
}

/**
 * Request parameters for editUserLink operation in UserApi.
 * @export
 * @interface UserApiEditUserLinkRequest
 */
export interface UserApiEditUserLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiEditUserLink
     */
    readonly id: string

    /**
     * 
     * @type {UserLinkEditDto}
     * @memberof UserApiEditUserLink
     */
    readonly userLinkEditDto: UserLinkEditDto
}

/**
 * Request parameters for setPhoto operation in UserApi.
 * @export
 * @interface UserApiSetPhotoRequest
 */
export interface UserApiSetPhotoRequest {
    /**
     * 
     * @type {File}
     * @memberof UserApiSetPhoto
     */
    readonly photo: File
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Create user link
     * @param {UserApiCreateUserLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUserLink(requestParameters: UserApiCreateUserLinkRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUserLink(requestParameters.userLinkCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete user photo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deletePhoto(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deletePhoto(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete user link
     * @param {UserApiDeleteUserLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserLink(requestParameters: UserApiDeleteUserLinkRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserLink(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit user info
     * @param {UserApiEditUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public editUser(requestParameters: UserApiEditUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).editUser(requestParameters.userEdit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit user link
     * @param {UserApiEditUserLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public editUserLink(requestParameters: UserApiEditUserLinkRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).editUserLink(requestParameters.id, requestParameters.userLinkEditDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current user links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserLinks(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserLinks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set user photo
     * @param {UserApiSetPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public setPhoto(requestParameters: UserApiSetPhotoRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).setPhoto(requestParameters.photo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WalletApi - axios parameter creator
 * @export
 */
export const WalletApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Acquire crypto payment
         * @param {CryptoAcquisitionRequest} cryptoAcquisitionRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        acquireCrypto: async (cryptoAcquisitionRequest: CryptoAcquisitionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cryptoAcquisitionRequest' is not null or undefined
            assertParamExists('acquireCrypto', 'cryptoAcquisitionRequest', cryptoAcquisitionRequest)
            const localVarPath = `/api/v1/wallet/acquire-crypto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cryptoAcquisitionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirm withdrawal request
         * @param {string} id 
         * @param {ConfirmRequestDto} confirmRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWithdrawalRequest: async (id: string, confirmRequestDto: ConfirmRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('confirmWithdrawalRequest', 'id', id)
            // verify required parameter 'confirmRequestDto' is not null or undefined
            assertParamExists('confirmWithdrawalRequest', 'confirmRequestDto', confirmRequestDto)
            const localVarPath = `/api/v1/wallet/withdrawal-request/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create acquisition request
         * @param {CreateAcquisitionRequest} createAcquisitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAcquisitionRequest: async (createAcquisitionRequest: CreateAcquisitionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAcquisitionRequest' is not null or undefined
            assertParamExists('createAcquisitionRequest', 'createAcquisitionRequest', createAcquisitionRequest)
            const localVarPath = `/api/v1/wallet/acquisition-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAcquisitionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create withdrawal request
         * @param {CreateRequestDto} createRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalRequest: async (createRequestDto: CreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequestDto' is not null or undefined
            assertParamExists('createWithdrawalRequest', 'createRequestDto', createRequestDto)
            const localVarPath = `/api/v1/wallet/withdrawal-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get acquisition request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcquisitionRequest: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAcquisitionRequest', 'id', id)
            const localVarPath = `/api/v1/wallet/acquisition-request/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get transaction details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionDetails: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTransactionDetails', 'id', id)
            const localVarPath = `/api/v1/wallet/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get wallet cause distribution and basic change analytics
         * @param {string} [from] Start date (inclusive)
         * @param {string} [to] End date (inclusive)
         * @param {Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>} [causes] Filter transaction by cause
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCauseDistribution: async (from?: string, to?: string, causes?: Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/wallet/analytics/cause-distribution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (causes) {
                localVarQueryParameter['causes'] = causes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current user wallet info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current user wallet transaction page
         * @param {string} [nextPageToken] Next page token
         * @param {string} [from] Start date (inclusive)
         * @param {string} [to] End date (inclusive)
         * @param {Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>} [causes] Filter transaction by cause
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTransactions: async (nextPageToken?: string, from?: string, to?: string, causes?: Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/wallet/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nextPageToken !== undefined) {
                localVarQueryParameter['nextPageToken'] = nextPageToken;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (causes) {
                localVarQueryParameter['causes'] = causes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get withdrawal request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalRequest: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWithdrawalRequest', 'id', id)
            const localVarPath = `/api/v1/wallet/withdrawal-request/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get withdrawal request config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalRequestConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/wallet/withdrawal-request-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletApi - functional programming interface
 * @export
 */
export const WalletApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletApiAxiosParamCreator(configuration)
    return {
        /**
         * Acquire crypto payment
         * @param {CryptoAcquisitionRequest} cryptoAcquisitionRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async acquireCrypto(cryptoAcquisitionRequest: CryptoAcquisitionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acquireCrypto(cryptoAcquisitionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Confirm withdrawal request
         * @param {string} id 
         * @param {ConfirmRequestDto} confirmRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmWithdrawalRequest(id: string, confirmRequestDto: ConfirmRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmWithdrawalRequest(id, confirmRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create acquisition request
         * @param {CreateAcquisitionRequest} createAcquisitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAcquisitionRequest(createAcquisitionRequest: CreateAcquisitionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcquisitionRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAcquisitionRequest(createAcquisitionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create withdrawal request
         * @param {CreateRequestDto} createRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithdrawalRequest(createRequestDto: CreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawalRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithdrawalRequest(createRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get acquisition request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAcquisitionRequest(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcquisitionRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAcquisitionRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get transaction details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionDetails(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletTransactionDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get wallet cause distribution and basic change analytics
         * @param {string} [from] Start date (inclusive)
         * @param {string} [to] End date (inclusive)
         * @param {Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>} [causes] Filter transaction by cause
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletCauseDistribution(from?: string, to?: string, causes?: Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletCauseDistribution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletCauseDistribution(from, to, causes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get current user wallet info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get current user wallet transaction page
         * @param {string} [nextPageToken] Next page token
         * @param {string} [from] Start date (inclusive)
         * @param {string} [to] End date (inclusive)
         * @param {Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>} [causes] Filter transaction by cause
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletTransactions(nextPageToken?: string, from?: string, to?: string, causes?: Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletTransactionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletTransactions(nextPageToken, from, to, causes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get withdrawal request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawalRequest(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawalRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWithdrawalRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get withdrawal request config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawalRequestConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawalRequestConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.withdrawalRequestConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WalletApi - factory interface
 * @export
 */
export const WalletApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletApiFp(configuration)
    return {
        /**
         * Acquire crypto payment
         * @param {CryptoAcquisitionRequest} cryptoAcquisitionRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        acquireCrypto(cryptoAcquisitionRequest: CryptoAcquisitionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.acquireCrypto(cryptoAcquisitionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirm withdrawal request
         * @param {string} id 
         * @param {ConfirmRequestDto} confirmRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWithdrawalRequest(id: string, confirmRequestDto: ConfirmRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.confirmWithdrawalRequest(id, confirmRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Create acquisition request
         * @param {CreateAcquisitionRequest} createAcquisitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAcquisitionRequest(createAcquisitionRequest: CreateAcquisitionRequest, options?: any): AxiosPromise<AcquisitionRequest> {
            return localVarFp.createAcquisitionRequest(createAcquisitionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create withdrawal request
         * @param {CreateRequestDto} createRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalRequest(createRequestDto: CreateRequestDto, options?: any): AxiosPromise<WithdrawalRequest> {
            return localVarFp.createWithdrawalRequest(createRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Get acquisition request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcquisitionRequest(id: string, options?: any): AxiosPromise<AcquisitionRequest> {
            return localVarFp.getAcquisitionRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get transaction details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionDetails(id: string, options?: any): AxiosPromise<WalletTransactionDetails> {
            return localVarFp.getTransactionDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get wallet cause distribution and basic change analytics
         * @param {string} [from] Start date (inclusive)
         * @param {string} [to] End date (inclusive)
         * @param {Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>} [causes] Filter transaction by cause
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCauseDistribution(from?: string, to?: string, causes?: Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>, options?: any): AxiosPromise<WalletCauseDistribution> {
            return localVarFp.getWalletCauseDistribution(from, to, causes, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current user wallet info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletInfo(options?: any): AxiosPromise<WalletInfo> {
            return localVarFp.getWalletInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Get current user wallet transaction page
         * @param {string} [nextPageToken] Next page token
         * @param {string} [from] Start date (inclusive)
         * @param {string} [to] End date (inclusive)
         * @param {Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>} [causes] Filter transaction by cause
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTransactions(nextPageToken?: string, from?: string, to?: string, causes?: Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>, options?: any): AxiosPromise<WalletTransactionList> {
            return localVarFp.getWalletTransactions(nextPageToken, from, to, causes, options).then((request) => request(axios, basePath));
        },
        /**
         * Get withdrawal request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalRequest(id: string, options?: any): AxiosPromise<WithdrawalRequest> {
            return localVarFp.getWithdrawalRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get withdrawal request config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalRequestConfig(options?: any): AxiosPromise<WithdrawalRequestConfig> {
            return localVarFp.withdrawalRequestConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for acquireCrypto operation in WalletApi.
 * @export
 * @interface WalletApiAcquireCryptoRequest
 */
export interface WalletApiAcquireCryptoRequest {
    /**
     * 
     * @type {CryptoAcquisitionRequest}
     * @memberof WalletApiAcquireCrypto
     */
    readonly cryptoAcquisitionRequest: CryptoAcquisitionRequest
}

/**
 * Request parameters for confirmWithdrawalRequest operation in WalletApi.
 * @export
 * @interface WalletApiConfirmWithdrawalRequestRequest
 */
export interface WalletApiConfirmWithdrawalRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof WalletApiConfirmWithdrawalRequest
     */
    readonly id: string

    /**
     * 
     * @type {ConfirmRequestDto}
     * @memberof WalletApiConfirmWithdrawalRequest
     */
    readonly confirmRequestDto: ConfirmRequestDto
}

/**
 * Request parameters for createAcquisitionRequest operation in WalletApi.
 * @export
 * @interface WalletApiCreateAcquisitionRequestRequest
 */
export interface WalletApiCreateAcquisitionRequestRequest {
    /**
     * 
     * @type {CreateAcquisitionRequest}
     * @memberof WalletApiCreateAcquisitionRequest
     */
    readonly createAcquisitionRequest: CreateAcquisitionRequest
}

/**
 * Request parameters for createWithdrawalRequest operation in WalletApi.
 * @export
 * @interface WalletApiCreateWithdrawalRequestRequest
 */
export interface WalletApiCreateWithdrawalRequestRequest {
    /**
     * 
     * @type {CreateRequestDto}
     * @memberof WalletApiCreateWithdrawalRequest
     */
    readonly createRequestDto: CreateRequestDto
}

/**
 * Request parameters for getAcquisitionRequest operation in WalletApi.
 * @export
 * @interface WalletApiGetAcquisitionRequestRequest
 */
export interface WalletApiGetAcquisitionRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof WalletApiGetAcquisitionRequest
     */
    readonly id: string
}

/**
 * Request parameters for getTransactionDetails operation in WalletApi.
 * @export
 * @interface WalletApiGetTransactionDetailsRequest
 */
export interface WalletApiGetTransactionDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof WalletApiGetTransactionDetails
     */
    readonly id: string
}

/**
 * Request parameters for getWalletCauseDistribution operation in WalletApi.
 * @export
 * @interface WalletApiGetWalletCauseDistributionRequest
 */
export interface WalletApiGetWalletCauseDistributionRequest {
    /**
     * Start date (inclusive)
     * @type {string}
     * @memberof WalletApiGetWalletCauseDistribution
     */
    readonly from?: string

    /**
     * End date (inclusive)
     * @type {string}
     * @memberof WalletApiGetWalletCauseDistribution
     */
    readonly to?: string

    /**
     * Filter transaction by cause
     * @type {Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>}
     * @memberof WalletApiGetWalletCauseDistribution
     */
    readonly causes?: Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>
}

/**
 * Request parameters for getWalletTransactions operation in WalletApi.
 * @export
 * @interface WalletApiGetWalletTransactionsRequest
 */
export interface WalletApiGetWalletTransactionsRequest {
    /**
     * Next page token
     * @type {string}
     * @memberof WalletApiGetWalletTransactions
     */
    readonly nextPageToken?: string

    /**
     * Start date (inclusive)
     * @type {string}
     * @memberof WalletApiGetWalletTransactions
     */
    readonly from?: string

    /**
     * End date (inclusive)
     * @type {string}
     * @memberof WalletApiGetWalletTransactions
     */
    readonly to?: string

    /**
     * Filter transaction by cause
     * @type {Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>}
     * @memberof WalletApiGetWalletTransactions
     */
    readonly causes?: Array<'DEPOSIT_BONUS_REGISTRATION' | 'WITHDRAW_ROBOT_COMMISSION' | 'DEPOSIT_MANUAL' | 'DEPOSIT_ACQUISITION' | 'MONEY_TO_BONUS_TRANSFER' | 'DEPOSIT_REFERRAL_COMMISSION' | 'WITHDRAW_BUSINESS_SUBSCRIPTION' | 'WITHDRAW_USER'>
}

/**
 * Request parameters for getWithdrawalRequest operation in WalletApi.
 * @export
 * @interface WalletApiGetWithdrawalRequestRequest
 */
export interface WalletApiGetWithdrawalRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof WalletApiGetWithdrawalRequest
     */
    readonly id: string
}

/**
 * WalletApi - object-oriented interface
 * @export
 * @class WalletApi
 * @extends {BaseAPI}
 */
export class WalletApi extends BaseAPI {
    /**
     * Acquire crypto payment
     * @param {WalletApiAcquireCryptoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public acquireCrypto(requestParameters: WalletApiAcquireCryptoRequest, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).acquireCrypto(requestParameters.cryptoAcquisitionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirm withdrawal request
     * @param {WalletApiConfirmWithdrawalRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public confirmWithdrawalRequest(requestParameters: WalletApiConfirmWithdrawalRequestRequest, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).confirmWithdrawalRequest(requestParameters.id, requestParameters.confirmRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create acquisition request
     * @param {WalletApiCreateAcquisitionRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public createAcquisitionRequest(requestParameters: WalletApiCreateAcquisitionRequestRequest, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).createAcquisitionRequest(requestParameters.createAcquisitionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create withdrawal request
     * @param {WalletApiCreateWithdrawalRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public createWithdrawalRequest(requestParameters: WalletApiCreateWithdrawalRequestRequest, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).createWithdrawalRequest(requestParameters.createRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get acquisition request
     * @param {WalletApiGetAcquisitionRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getAcquisitionRequest(requestParameters: WalletApiGetAcquisitionRequestRequest, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).getAcquisitionRequest(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get transaction details
     * @param {WalletApiGetTransactionDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getTransactionDetails(requestParameters: WalletApiGetTransactionDetailsRequest, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).getTransactionDetails(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get wallet cause distribution and basic change analytics
     * @param {WalletApiGetWalletCauseDistributionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getWalletCauseDistribution(requestParameters: WalletApiGetWalletCauseDistributionRequest = {}, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).getWalletCauseDistribution(requestParameters.from, requestParameters.to, requestParameters.causes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current user wallet info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getWalletInfo(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).getWalletInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current user wallet transaction page
     * @param {WalletApiGetWalletTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getWalletTransactions(requestParameters: WalletApiGetWalletTransactionsRequest = {}, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).getWalletTransactions(requestParameters.nextPageToken, requestParameters.from, requestParameters.to, requestParameters.causes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get withdrawal request
     * @param {WalletApiGetWithdrawalRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getWithdrawalRequest(requestParameters: WalletApiGetWithdrawalRequestRequest, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).getWithdrawalRequest(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get withdrawal request config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public withdrawalRequestConfig(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).withdrawalRequestConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


