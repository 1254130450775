import {
  EditProfileForm,
  LoggedInState,
  LoginState,
  UserStore,
} from '../UserStore';
import {
  ifSuccessful,
  notLoaded,
  wrapPromise,
  wrapRefreshablePromise,
} from '../../utils';
import {ProblemError, UserInfo} from '../../api';
import ApiStore from '../ApiStore';
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import StorageManager from '../../storage/StorageManager';
import stores from '../index';
import Logger from '../../manager/Logger';
import Sentry from '../../manager/Sentry';

class RealUserStore implements UserStore {
  isLoggingIn: LoginState = {type: 'not-started'};
  isLoggingOut: LoginState = {type: 'not-started'};
  loadLoggedIn: LoggedInState = {type: 'loading'};
  user: RefreshableAsyncState<UserInfo> = notLoaded();
  changingAvatar = false;
  private loadLock = false;

  constructor() {
    makeAutoObservable(this, {
      isLoggingIn: observable,
      isLoggingOut: observable,
      loadLoggedIn: observable,
      changingAvatar: observable,
      user: observable,
      avatar: computed,
      load: action.bound,
      login: action.bound,
      logout: action.bound,
      resetLogoutError: action.bound,
    });
  }

  get avatar(): string {
    return ifSuccessful(
      this.user,
      (t) => (t.photo ?? '<<invalid>>') + '?cachebreak=' + new Date().getTime(),
      '<<invalid>>',
    );
  }

  async load(authCode?: string | null): Promise<LoggedInState> {
    if (this.loadLock) {
      return new Promise(() => {});
    }
    this.loadLock = true;
    try {
      if (authCode) {
        Logger.debug('RealUserStore', 'load', 'Starting auth code login');
        const access = await ApiStore.auth.getToken({
          grantType: 'authorization_code',
          code: authCode,
        });
        ApiStore.init(access.data);
      } else {
        Logger.debug('RealUserStore', 'load', 'Starting refresh');
        await ApiStore.refresh();
      }
      stores.emailVerification.resetCredentials();
      this.loadUser();
      runInAction(() => (this.loadLoggedIn = {type: 'logged-in'}));
      return {type: 'logged-in'};
    } catch (e: unknown) {
      runInAction(() => (this.loadLoggedIn = {type: 'not-logged-in'}));
      return {type: 'not-logged-in'};
    }
  }

  private setUser(user: RefreshableAsyncState<UserInfo>) {
    if (user.status === 'success' || user.status === 'refreshing-success') {
      Sentry.setUser(user.result);
    }
    runInAction(() => (this.user = user));
  }

  private loadUser() {
    runInAction(() => {
      void wrapRefreshablePromise(
        ApiStore.user.getUser().then((value) => value.data),
        this.user,
        (v) => this.setUser(v),
      );
    });
    stores.wallet.init();
    stores.referral.init();
    stores.subscription.init();
    stores.notifications.init();
    setTimeout(() => stores.robot.load(), 500);
  }

  async login(login: string, emailCode: string): Promise<void> {
    const code = await ApiStore.auth.loginWithEmail({
      emailAuthenticationParamsDto: {
        email: login,
        code: emailCode,
      },
    });
    const access = await ApiStore.auth.getToken({
      grantType: 'authorization_code',
      code: code.data.code,
    });
    ApiStore.init(access.data);
    this.loadUser();
    runInAction(() => (this.loadLoggedIn = {type: 'logged-in'}));
    return Promise.resolve(undefined);
  }

  logout(): Promise<void> {
    ApiStore.reset();
    StorageManager.onUserLogout();
    return Promise.resolve(undefined);
  }

  resetLogoutError(): void {}

  sendCode(login: string): Promise<void> {
    return ApiStore.auth
      .loginWithEmail({
        emailAuthenticationParamsDto: {
          email: login,
        },
      })
      .then(() => {})
      .catch((reason) => {
        if (reason instanceof ProblemError) {
          if (
            reason.type ===
            'https://spring.alesharik.com/docs/error/auth/verification-code-required'
          ) {
            return;
          }
        }
        throw reason;
      });
  }

  async register(email: string, inviteCode: string): Promise<void> {
    const invCode = inviteCode.trim();
    await ApiStore.auth.registerWithEmail({
      registerWithEmailParams: {
        email: email,
        inviteCode: invCode.length === 0 ? undefined : invCode,
      },
    });
  }

  async deleteUser(): Promise<void> {
    await ApiStore.user.deleteUser();
    ApiStore.reset();
    StorageManager.onUserLogout();
  }

  changeAvatar(avatar: File): void {
    void wrapPromise(
      (async () => {
        await ApiStore.user.setPhoto({photo: avatar});
        await runInAction(async () => {
          await wrapRefreshablePromise(
            ApiStore.user.getUser().then((value) => value.data),
            this.user,
            (v) => this.setUser(v),
          );
        });
      })(),
      notLoaded(),
      (v) =>
        runInAction(() => {
          this.changingAvatar = v.status === 'loading';
        }),
    );
  }

  async editProfile(form: EditProfileForm): Promise<void> {
    await ApiStore.user.editUser({userEdit: {name: form.name ?? ''}});
    await runInAction(async () => {
      await wrapRefreshablePromise(
        ApiStore.user.getUser().then((value) => value.data),
        this.user,
        (v) => this.setUser(v),
      );
    });
  }
}

export default RealUserStore;
