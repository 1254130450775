import React from 'react';
import {IntlProvider} from 'react-intl';
import {Provider} from 'mobx-react';
import EN from './assets/locales/en.json';
import RU from './assets/locales/ru.json';
import stores from './store';
import {BrowserRouter} from 'react-router-dom';
import Navigator from './screen/Navigator';
import {SkeletonTheme} from 'react-loading-skeleton';

function loadMessages(locale: string): Record<string, string> {
  switch (locale) {
    case '_dissabled_ru':
      return RU;
    default:
      return EN;
  }
}

function App() {
  const locale = 'en';
  return (
    <IntlProvider
      messages={loadMessages(locale)}
      defaultLocale={'en'}
      locale={'en'}
    >
      <Provider {...stores}>
        <BrowserRouter>
          <SkeletonTheme baseColor={'#222426'} highlightColor={'#393B40'}>
            <Navigator />
          </SkeletonTheme>
        </BrowserRouter>
      </Provider>
    </IntlProvider>
  );
}

export default App;
