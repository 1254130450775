import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import gauthCode from '../../../../../assets/onboarding/binance/apikeys/gauth-code.png';
import securityVerification from '../../../../../assets/onboarding/binance/apikeys/security-verification.png';

const Enter2FAFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.Enter2FAFragment.title',
        defaultMessage:
          'Enter 2FA code from Google Authenticator into Binance App',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage
      first={gauthCode}
      second={securityVerification}
      overlayX={'7.2%'}
      overlayY={'23.8%'}
    >
      <svg
        width="44%"
        height="10%"
        viewBox="0 0 143 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.07325 4.51279C3.20446 3.83067 1.94721 3.982 1.26509 4.85079C0.582979 5.71958 0.734307 6.97683 1.6031 7.65895L4.07325 4.51279ZM142.453 6.51936C142.692 5.44099 142.012 4.37295 140.933 4.13384L123.36 0.23727C122.282 -0.00184301 121.214 0.678519 120.975 1.75689C120.735 2.83527 121.416 3.90331 122.494 4.14242L138.115 7.60604L134.651 23.2266C134.412 24.305 135.092 25.3731 136.171 25.6122C137.249 25.8513 138.317 25.1709 138.556 24.0925L142.453 6.51936ZM2.83817 6.08587C1.6031 7.65895 1.60416 7.65979 1.60547 7.66081C1.60622 7.6614 1.60776 7.6626 1.60926 7.66378C1.61226 7.66612 1.6162 7.6692 1.62108 7.67299C1.63084 7.68059 1.64436 7.69108 1.66162 7.70439C1.69613 7.73101 1.7456 7.76893 1.80983 7.81758C1.93829 7.91488 2.12585 8.05513 2.37105 8.23389C2.86142 8.59138 3.58255 9.10299 4.52288 9.73307C6.40323 10.993 9.16192 12.7279 12.7064 14.6518C19.7924 18.498 30.0374 23.1095 42.6974 26.1845C68.062 32.3453 103.051 32.3137 141.575 7.77323L139.426 4.39959C101.95 28.2721 68.1082 28.2402 43.6415 22.2975C31.3859 19.3207 21.4653 14.8547 14.6145 11.1363C11.1906 9.27782 8.53818 7.60863 6.74949 6.41009C5.85528 5.81092 5.17741 5.32968 4.72741 5.00162C4.50243 4.83761 4.33445 4.71192 4.22493 4.62896C4.17017 4.58749 4.13003 4.5567 4.10469 4.53715C4.09202 4.52738 4.08305 4.52042 4.0778 4.51633C4.07518 4.51429 4.07349 4.51297 4.07273 4.51237C4.07235 4.51208 4.07248 4.51218 4.07229 4.51203C4.07265 4.51232 4.07325 4.51279 2.83817 6.08587Z"
          fill="#FF4040"
        />
      </svg>
    </SideBySideImage>
  </>
);

export default Enter2FAFragment;
