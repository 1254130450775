import React from 'react';
import Card from '../Card';
import cls from './style.module.scss';
import {FormattedMessage, FormattedNumberParts} from 'react-intl';
import {SubscriptionInfo, TeamCount, WalletInfo} from '../../../api';
import {observer} from 'mobx-react';
import CurrencyUtils from '../../../utils/CurrencyUtils';
import AsyncStateWrapper from '../AsyncStateWrapper';
import SubscriptionUtils from '../../../utils/SubscriptionUtils';

interface Props {
  subscription: RefreshableAsyncState<SubscriptionInfo>;
  wallet: RefreshableAsyncState<WalletInfo>;
  team: RefreshableAsyncState<TeamCount>;
  onWalletClick?: () => void;
  onTeamClick?: () => void;
  onSubscriptionClick?: () => void;
}

const CommandCard: React.FunctionComponent<Props> = ({
  wallet,
  team,
  subscription,
  onWalletClick,
  onTeamClick,
  onSubscriptionClick,
}) => (
  <Card className={cls.card}>
    <AsyncStateWrapper state={team}>
      {(team) => (
        <AsyncStateWrapper state={wallet}>
          {(wallet) => (
            <AsyncStateWrapper state={subscription}>
              {(sub) => (
                <>
                  <div className={cls.item} onClick={onSubscriptionClick}>
                    <p className={cls.title}>
                      <FormattedMessage
                        id={'components.UserRatingCard.subscription'}
                        defaultMessage={'Subscription'}
                      />
                    </p>
                    <h6 className={cls.value}>
                      <FormattedMessage
                        {...SubscriptionUtils.formatType(sub.type)}
                      />
                    </h6>
                  </div>
                  <div className={cls.item} onClick={onWalletClick}>
                    <p className={cls.title}>
                      <FormattedMessage
                        id={'components.UserRatingCard.money'}
                        defaultMessage={'Money'}
                      />
                    </p>
                    <h6 className={cls.value}>
                      <FormattedNumberParts
                        value={wallet.balance}
                        maximumFractionDigits={2}
                      >
                        {CurrencyUtils.formatBalance}
                      </FormattedNumberParts>
                      {' RCO'}
                    </h6>
                  </div>
                  <div className={cls.item} onClick={onTeamClick}>
                    <p className={cls.title}>
                      <FormattedMessage
                        id={'components.UserRatingCard.command'}
                        defaultMessage={'Command'}
                      />
                    </p>
                    <h6 className={cls.value}>{team.count}</h6>
                  </div>
                </>
              )}
            </AsyncStateWrapper>
          )}
        </AsyncStateWrapper>
      )}
    </AsyncStateWrapper>
  </Card>
);

export default observer(CommandCard);
