import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import {ReferralSearchItem, ReferralSearchPage} from '../api';
import {notLoaded, wrapRefreshablePromise} from '../utils';
import stores from '../store';
import {debounce} from 'ts-debounce';

class TeamSearchViewModel {
  items: ReferralSearchItem[] = [];
  currentPage: RefreshableAsyncState<ReferralSearchPage> = notLoaded();
  nextToken?: string;
  query = '';

  constructor() {
    makeAutoObservable(this, {
      currentPage: observable,
      items: observable,
      query: observable,
      nextPage: action.bound,
      setQuery: action.bound,
    });
    this.loadPage();
  }

  nextPage() {
    if (
      this.currentPage.status === 'success' &&
      this.nextToken !== undefined &&
      this.nextToken !== null
    ) {
      this.loadPage(this.nextToken);
    }
  }

  setQuery(query: string) {
    this.query = query;
    this.reloadByQuery();
  }

  reloadByQuery = debounce(() => {
    runInAction(() => {
      this.nextToken = undefined;
      this.items = [];
      this.currentPage = notLoaded();
      this.loadPage();
    });
  }, 250);

  private loadPage(token?: string) {
    void wrapRefreshablePromise(
      stores.referral.searchTransactionsPage(this.query, token ?? undefined),
      this.currentPage,
      (v) =>
        runInAction(() => {
          if (v.status === 'success') {
            this.nextToken = v.result.nextPageToken;
            this.items.push(...v.result.items);
          }
          this.currentPage = v;
        }),
    );
  }
}

export default TeamSearchViewModel;
