import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/first/verify-identity-1.png';
import v2 from '../../../../../assets/onboarding/bybit/first/verify-identity-2.png';

const IdentityVerify1Fragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.IdentityVerify1.title',
        defaultMessage: 'Next, verify your identity',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default IdentityVerify1Fragment;
