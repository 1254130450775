import React, {FC} from 'react';
import {FormattedNumberParts} from 'react-intl';
import CurrencyUtils from '../../../../utils/CurrencyUtils';

interface Props {
  amount: number;
  withoutCurrency?: boolean;
}

const RcoAmount: FC<Props> = ({amount, withoutCurrency}) => (
  <>
    <FormattedNumberParts
      value={amount}
      signDisplay={'exceptZero'}
      maximumFractionDigits={8}
    >
      {CurrencyUtils.formatAmount}
    </FormattedNumberParts>
    {withoutCurrency ? '' : ' RCO'}
  </>
);

export default React.memo(RcoAmount);
