import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/huobi/p2p/enter-p2p.png';
import v2 from '../../../../../assets/onboarding/huobi/p2p/select-trade.png';

const OpenP2PFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.huobi.OpenP2PFragment.title',
        defaultMessage:
          '<b>You must pass L1 verification, or p2p will be unavailable</b>\n\nOpen <h>Trade</h> button tab, then click on <h>Fiat</h> tab. Find best P2P trade that suits your payment method and amount, press <h>Buy</h>',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default OpenP2PFragment;
