import React from 'react';
import IconsSVG from '../../../assets/sprites/icons.svg';
import cls from './style.module.scss';
import {ReactComponent as HomeSelected} from '../../../assets/icons/home-selected.svg';
import {ReactComponent as ProfileSelected} from '../../../assets/icons/profile-selected.svg';

export type IconName =
  | 'arrow-left'
  | 'eye-closed'
  | 'home'
  | 'home-selected'
  | 'profile'
  | 'profile-selected'
  | 'chevron-right'
  | 'chevron-left'
  | 'chevron-down'
  | 'plus'
  | 'key-outline'
  | 'upload'
  | 'download'
  | 'copy'
  | 'link'
  | 'copy-dark'
  | 'pencil'
  | 'share-dark'
  | 'search'
  | 'qr-code'
  | 'clock'
  | 'error'
  | 'info'
  | 'check-all'
  | 'bell-outline'
  | 'bell-badged-outline'
  | 'dollar-circle-outline'
  | 'check-circle-outline'
  | 'account-supervisor-circle-outline'
  | 'alert-outline'
  | 'help-circle-outline'
  | 'alert-circle'
  | 'check-circle'
  | 'close-circle'
  | 'dots-horizontal'
  | 'binance'
  | 'huobi'
  | 'bybit';

interface Props {
  name: IconName;
  size: number;
  className?: string;
  clickable?: boolean;
  onClick?: () => void;
}

const Icon: React.FunctionComponent<Props> = ({
  name,
  size,
  className,
  clickable,
  onClick,
}) => {
  const elemCls = [];
  if (clickable) elemCls.push(cls.clickable);
  if (className !== undefined) elemCls.push(className);

  if (name === 'home-selected') {
    return (
      <HomeSelected
        className={elemCls.join(' ')}
        width={size}
        height={size}
        onClick={onClick}
      />
    );
  }
  if (name === 'profile-selected') {
    return (
      <ProfileSelected
        className={elemCls.join(' ')}
        width={size}
        height={size}
        onClick={onClick}
      />
    );
  }

  return (
    <svg
      className={elemCls.join(' ')}
      width={size}
      height={size}
      onClick={onClick}
    >
      <use xlinkHref={`${IconsSVG}#${name}`} />
    </svg>
  );
};

export default Icon;
