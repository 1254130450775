import axios, {AxiosError} from 'axios';

export class ServiceUnavailableError extends Error {}

axios.interceptors.response.use(
  (value) => value,
  (error: Error | AxiosError) => {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 502) {
          throw new ServiceUnavailableError();
        }
      } else if (error.message === 'Network Error') {
        // cors
        throw new ServiceUnavailableError();
      }
    }
    throw error;
  },
);
