import React from 'react';
import cls from './style.module.scss';
import Avatar from '../../design/misc/Avatar';

interface Props {
  src: string;
  onClick?: () => void;
  className?: string;
  badge?: string;
  size: number;
}

/**
 * @deprecated
 */
const ProfilePhoto: React.FunctionComponent<Props> = ({
  src,
  onClick,
  className,
  badge,
  size,
}) => {
  return badge ? (
    <div className={cls.container}>
      <Avatar size={size} src={src} className={className} onClick={onClick} />
      <span className={cls.badge}>{badge}</span>
    </div>
  ) : (
    <Avatar size={size} src={src} className={className} onClick={onClick} />
  );
};

export default ProfilePhoto;
