import {makeAutoObservable, observable, runInAction} from 'mobx';
import {RobotConfig} from '../api';
import {notLoaded, wrapPromise} from '../utils';
import store from '../store';
import ApiStore from '../store/ApiStore';

class EditRobotConfigViewModel {
  initialConfig: AsyncState<RobotConfig> = notLoaded();

  constructor(readonly id: string) {
    makeAutoObservable(this, {
      initialConfig: observable,
    });
    if (
      store.robot.currentRobotId === id &&
      store.robot.configuration.status === 'success'
    ) {
      this.initialConfig = store.robot.configuration;
    } else {
      void wrapPromise(
        ApiStore.robot.getRobotConfig({id}).then((value) => value.data),
        this.initialConfig,
        (v) => runInAction(() => (this.initialConfig = v)),
      );
    }
  }
}

export default EditRobotConfigViewModel;
