import React from 'react';
import cls from './style.module.scss';
import {FormattedNumberParts} from 'react-intl';
import CurrencyUtils from '../../../utils/CurrencyUtils';
import CoinIcon from '../../design/graphics/CoinIcon';

interface Props {
  asset: number;
  money: number;
  assetCoin: string;
  moneyCoin: string;
}

const CurrentBalanceRow: React.FunctionComponent<Props> = ({
  asset,
  money,
  assetCoin,
  moneyCoin,
}) => (
  <span className={cls.mainRow}>
    <span className={cls.balanceCrypto + ' ' + cls.row}>
      <FormattedNumberParts
        value={asset}
        minimumFractionDigits={CurrencyUtils.getDisplayMinFractionalDigits(
          assetCoin,
        )}
        maximumFractionDigits={CurrencyUtils.getDisplayMaxFractionalDigits(
          assetCoin,
        )}
      >
        {CurrencyUtils.formatBalance}
      </FormattedNumberParts>
      <CoinIcon size={32} coin={assetCoin} />
    </span>
    <span className={cls.slash}>/</span>
    <span className={cls.balanceCrypto + ' ' + cls.rowRight}>
      <CoinIcon size={32} coin={moneyCoin} />
      <FormattedNumberParts
        value={money}
        maximumFractionDigits={CurrencyUtils.getDisplayMaxFractionalDigits(
          moneyCoin,
        )}
        minimumFractionDigits={CurrencyUtils.getDisplayMinFractionalDigits(
          moneyCoin,
        )}
      >
        {CurrencyUtils.formatBalance}
      </FormattedNumberParts>
    </span>
  </span>
);

export default React.memo(CurrentBalanceRow);
