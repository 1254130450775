import Deposit from './deposit';
import Failure from './failure';
import Progress from './progress';
import Success from './success';
import Transfer from './transfer';
import Withdrawal from './withdrawal';
import {FC} from 'react';

interface SubComponents {
  Deposit: typeof Deposit;
  Failure: typeof Failure;
  Progress: typeof Progress;
  Success: typeof Success;
  Transfer: typeof Transfer;
  Withdrawal: typeof Withdrawal;
}

const Illustration: FC & SubComponents = () => <></>;

Illustration.Deposit = Deposit;
Illustration.Failure = Failure;
Illustration.Progress = Progress;
Illustration.Success = Success;
Illustration.Transfer = Transfer;
Illustration.Withdrawal = Withdrawal;

export default Illustration;
