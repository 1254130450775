import {FieldConfig, useField, useFormikContext} from 'formik';
import TextInput, {Props as InputProps} from '../../input/TextInput';
import {memo} from 'react';

interface Props extends Omit<Omit<InputProps, 'value'>, 'onChange'> {
  submitForm?: boolean;
}

function TextField({
  ...props
}: Props & FieldConfig<string>): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [field, meta, helpers] = useField<string>(props);
  const context = useFormikContext();
  return (
    <TextInput
      {...props}
      error={meta.error}
      value={field.value}
      onChange={(text) => helpers.setValue(text)}
      onBlur={() => field.onBlur(field.name)}
      onSubmitEditing={
        props.submitForm ? () => void context.submitForm() : undefined
      }
    />
  );
}

export default memo(TextField);
