import OnboardingStore from '../OnboardingStore';
import {
  AdvanceStateDtoActionEnum,
  AdvanceStateDtoStageEnum,
  CurrentOnboardingState,
  CurrentOnboardingStateCurrentStageEnum,
} from '../../api';
import {notLoaded, wrapPromise} from '../../utils';
import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import OnboardingStorage from '../../storage/OnboardingStorage';
import ApiStore from '../ApiStore';
import Logger from '../../manager/Logger';

export default class RealOnboardingStore implements OnboardingStore {
  currentStage: AsyncState<CurrentOnboardingStateCurrentStageEnum> =
    notLoaded();
  shouldSkipOnboardingLogic: boolean;

  constructor() {
    makeAutoObservable(this, {
      currentStage: observable,
      shouldSkipOnboardingLogic: observable,
      init: action,
      advance: action,
    });
    this.shouldSkipOnboardingLogic =
      OnboardingStorage.get().stage ===
      CurrentOnboardingStateCurrentStageEnum.Completed;
  }

  async init(): Promise<CurrentOnboardingState> {
    if (this.shouldSkipOnboardingLogic) {
      this.currentStage = {
        status: 'success',
        result: CurrentOnboardingStateCurrentStageEnum.Completed,
      };
      return {
        currentStage: CurrentOnboardingStateCurrentStageEnum.Completed,
        selectedMarket: undefined,
      };
    }
    return new Promise((resolve) => {
      runInAction(() => {
        void wrapPromise(
          ApiStore.onboarding.getOnboardingState().then((value) => {
            resolve(value.data);
            return value.data.currentStage;
          }),
          this.currentStage,
          (v) =>
            runInAction(() => {
              this.currentStage = v;
              if (v.status === 'success') {
                OnboardingStorage.get().stage = v.result;
              }
            }),
        );
      });
    });
  }
  advance(
    current: AdvanceStateDtoStageEnum,
    action: AdvanceStateDtoActionEnum,
  ): void {
    ApiStore.onboarding
      .advanceOnboarding({advanceStateDto: {action, stage: current}})
      .catch((e) => Logger.error('RealOnboardingStore', 'advance', e));
    const next = this.getNext(current);
    if (next) {
      this.currentStage = {status: 'success', result: next};
      OnboardingStorage.get().stage = next;
    }
  }

  private getNext(
    current: AdvanceStateDtoStageEnum,
  ): CurrentOnboardingStateCurrentStageEnum | undefined {
    switch (current) {
      case 'EXCHANGE':
        return CurrentOnboardingStateCurrentStageEnum.P2P;
      case 'P2P':
        return CurrentOnboardingStateCurrentStageEnum.ApiKeys;
      case 'API_KEYS':
        return CurrentOnboardingStateCurrentStageEnum.Completed;
      case 'COMPLETED':
        return undefined;
    }
  }
}
