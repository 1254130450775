import Typography from '../../typo/Typography';
import {FC} from 'react';
import {CurrentSubscriptionDetailsPeriodEnum} from '../../../../api';
import Radio from '../../input/Radio';
import SubscriptionUtils from '../../../../utils/SubscriptionUtils';
import cls from './style.module.scss';

interface Props {
  period: CurrentSubscriptionDetailsPeriodEnum;
  price: number;
  selected: boolean;
  onClick?: () => void;
}

const SubscriptionPeriodSelectCard: FC<Props> = ({
  period,
  price,
  selected,
  onClick,
}) => (
  <div
    className={cls.card + ' ' + (selected && cls.selected)}
    onClick={onClick}
  >
    <Radio checked={selected} />
    <div className={cls.content}>
      <Typography
        text={SubscriptionUtils.formatPeriod(period)}
        className={cls.header}
      />
      {SubscriptionUtils.getMonthlyPrice(period, price) !== price && (
        <Typography
          text={SubscriptionUtils.formatCurrentCost(period, price)}
          className={cls.desc}
        />
      )}
    </div>
    <Typography
      text={{
        id: 'component.SubscriptionPeriodSelectCard.monthlyPrice',
        defaultMessage: '{price} RCO / month',
      }}
      className={cls.price}
      values={{price: SubscriptionUtils.getMonthlyPrice(period, price)}}
    />
  </div>
);

export default SubscriptionPeriodSelectCard;
