import React, {useRef} from 'react';
import Scaffolding from '../../component/container/Scaffolding';
import ProfileItem from '../../component/ui/ProfileItem';
import {UserStore} from '../../store/UserStore';
import {inject, observer} from 'mobx-react';
import AsyncStateWrapper from '../../component/ui/AsyncStateWrapper';
import Spacer from '../../component/design/utils/Spacer';
import CommandCard from '../../component/ui/CommandCard';
import UserActions from '../../component/ui/UserActions';
import {useNavigate} from 'react-router-dom';
import {APP_URLS} from '../../config';
import {WalletStore} from '../../store/WalletStore';
import {ReferralStore} from '../../store/ReferralStore';
import SubscriptionStore from '../../store/SubscriptionStore';
import DesktopCompatibilityStore from '../../store/DesktopCompatibilityStore';
import ReferralUtils from '../../utils/ReferralUtils';
import {ifSuccessful} from '../../utils';

interface Props {
  user: UserStore;
  wallet: WalletStore;
  referral: ReferralStore;
  subscription: SubscriptionStore;
  desktopCompatibility: DesktopCompatibilityStore;
}

const ProfileFragment: React.FunctionComponent<Props> = ({
  user,
  wallet,
  referral,
  subscription,
  desktopCompatibility,
}) => {
  const navigate = useNavigate();
  const avatarRef = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _1 = wallet.wallet;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _2 = referral.teamCount;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _3 = subscription.info;

  return (
    <Scaffolding paddingHorizontal={1.25}>
      <AsyncStateWrapper state={user.user}>
        {(data) => (
          <>
            <Spacer size={2} />
            <input
              type="file"
              accept="image/*;capture=camera"
              className={'display-none'}
              ref={avatarRef}
              onChange={(e) => {
                if (e.currentTarget.files && e.currentTarget.files.length > 0) {
                  user.changeAvatar(e.currentTarget.files[0]);
                }
              }}
            />
            <ProfileItem
              onAvatarClicked={() => avatarRef.current?.click()}
              name={data.name ?? data.email}
              level={ifSuccessful(
                referral.teamCount,
                (t) => ReferralUtils.getLevelBadge(t.myLevel) ?? 'S',
                '',
              )}
              photo={user.avatar}
              changingAvatar={user.changingAvatar}
              onProfileEditClick={() => navigate('/app/edit-profile')}
            />
            <Spacer size={2.5} />
            <CommandCard
              wallet={wallet.wallet}
              team={referral.teamCount}
              subscription={subscription.info}
              onWalletClick={() => navigate('/app/wallet')}
              onTeamClick={() => navigate('/app/team')}
              onSubscriptionClick={() => navigate('/app/subscription')}
            />
            <Spacer flex={1} />
            <UserActions>
              {/*<UserActions.LinkAction*/}
              {/*  name={{*/}
              {/*    id: 'fragment.ProfileFragment.training',*/}
              {/*    defaultMessage: 'See tutorial',*/}
              {/*  }}*/}
              {/*  link={APP_URLS['tutorial']}*/}
              {/*/>*/}
              {desktopCompatibility.canUseCompatibility && (
                <UserActions.SwitchAction
                  name={{
                    id: 'fragment.ProfileFragment.desktopCompat',
                    defaultMessage: 'Desktop mode',
                  }}
                  checked={desktopCompatibility.compatibilityModeEnabled}
                  onValueChange={
                    desktopCompatibility.setCompatibilityModeEnabled
                  }
                />
              )}
              {/*<UserActions.InternalLinkAction*/}
              {/*  name={{*/}
              {/*    id: 'fragment.ProfileFragment.exchangeConnections',*/}
              {/*    defaultMessage: 'My connections',*/}
              {/*  }}*/}
              {/*  link={'/app/exchange-connections'}*/}
              {/*/>*/}
              <UserActions.InternalLinkAction
                name={{
                  id: 'fragment.ProfileFragment.notifications',
                  defaultMessage: 'Notification settings',
                }}
                link={'/app/notifications/settings/email'}
              />
              <UserActions.LinkAction
                name={{
                  id: 'fragment.ProfileFragment.support',
                  defaultMessage: 'Message support',
                }}
                link={APP_URLS['support']}
              />
              <UserActions.DangerAction
                name={{
                  id: 'fragment.ProfileFragment.logout',
                  defaultMessage: 'Logout',
                }}
                onClick={() =>
                  void user.logout().then(() => {
                    navigate('/');
                  })
                }
              />
            </UserActions>
            <Spacer size={2} />
          </>
        )}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default inject(
  'user',
  'wallet',
  'referral',
  'subscription',
  'desktopCompatibility',
)(observer(ProfileFragment)) as unknown as React.FC;
