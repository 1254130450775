import {createContext, useContext} from 'react';

interface GuideCtx {
  current: number;
  max: number;
  onNext?: () => void;
  onPrev?: () => void;
  onClose?: () => void;
}

export const Ctx = createContext<GuideCtx>({
  current: 0,
  max: 0,
  onClose: () => {},
  onNext: () => {},
  onPrev: () => {},
});

export function useCtx(): GuideCtx {
  return useContext(Ctx);
}
