import {RobotTradeDetailsStateEnum, RobotTradeItemStateEnum} from '../api';
import {MessageDescriptor} from 'react-intl';

class RobotTradeUtils {
  static formatState(
    status: RobotTradeDetailsStateEnum | RobotTradeItemStateEnum,
  ): MessageDescriptor {
    switch (status) {
      case 'BUY_IN_PROGRESS':
        return {
          id: 'common.robotTrade.status.buyInProgress',
          defaultMessage: 'Buying...',
        };
      case 'COMPLETED':
        return {
          id: 'common.robotTrade.status.completed',
          defaultMessage: 'Completed',
        };
      case 'SELL_ERROR':
        return {
          id: 'common.robotTrade.status.sellError',
          defaultMessage: 'Sell error',
        };
      case 'SELL_IN_PROGRESS':
        return {
          id: 'common.robotTrade.status.sellInProgress',
          defaultMessage: 'Selling...',
        };
    }
  }

  static stateTextClassName(
    state: RobotTradeDetailsStateEnum | RobotTradeItemStateEnum,
  ): string {
    switch (state) {
      case 'BUY_IN_PROGRESS':
      case 'SELL_IN_PROGRESS':
        return 'text-progress';
      case 'SELL_ERROR':
        return 'text-fail';
      case 'COMPLETED':
        return 'text-success';
    }
  }
}

export default RobotTradeUtils;
