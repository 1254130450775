import SubscriptionStore from '../../store/SubscriptionStore';
import {FC, useState} from 'react';
import {inject, observer} from 'mobx-react';
import Scaffolding from '../../component/container/Scaffolding';
import SubscriptionLogo from '../../component/design/graphics/SubscriptionLogo';
import AsyncStateWrapper from '../../component/ui/AsyncStateWrapper';
import {
  CurrentSubscriptionDetailsPeriodEnum,
  ProblemError,
  SubscriptionInfoStatusEnum,
} from '../../api';
import SubscriptionStatusCard from '../../component/design/data/SubscriptionStatusCard';
import Button from '../../component/design/button/Button';
import FlexCol from '../../component/design/container/FlexCol';
import {useSnackbar} from '../../component/design/container/SnackbarContainer';
import ErrorUtils from '../../utils/ErrorUtils';
import {useNavigate} from 'react-router-dom';
import ConfirmBottomSheet from '../../component/design/sheet/ConfirmBottomSheet';
import {notLoaded, wrapPromise} from '../../utils';
import SelectSubscriptionPeriodBottomSheet from '../../component/design/sheet/SelectSubscriptionPeriodBottomSheet';
import SubscriptionPeriodSelect from '../../component/design/data/SubscriptionPeriodSelect';
import Spacer from '../../component/design/utils/Spacer';
import {useStateErrorAutoReset} from '../../hooks/useErrorAutoReset';
import FeatureList from '../../component/design/container/FeatureList';

interface StoreProps {
  subscription: SubscriptionStore;
}

const SubscriptionScreen: FC<StoreProps> = ({subscription}) => {
  const snackbar = useSnackbar();
  const nav = useNavigate();
  const [insufficientCoversheetOpen, setInsufficientCoversheetOpen] =
    useState(false);
  const [editBillingSheetOpen, setEditBillingSheetOpen] = useState(false);
  const [subscribeStatus, setSubscribeStatus] = useState<AsyncState<void>>(
    notLoaded(),
  );
  const [editBillingState, setEditBillingState] = useState<AsyncState<void>>(
    notLoaded(),
  );
  const [newSubPeriod, setNewSubPeriod] =
    useState<CurrentSubscriptionDetailsPeriodEnum>(
      CurrentSubscriptionDetailsPeriodEnum.Monthly,
    );
  const subscribeBtnError = useStateErrorAutoReset(subscribeStatus);
  const pricing = subscription.pricingInfo;

  const subscribe = () => {
    void wrapPromise(
      subscription.subscribeBusiness().catch((reason: Error) => {
        if (
          reason instanceof ProblemError &&
          reason.type ===
            'https://robowallet.org/docs/error/wallet/negative-money-balance'
        ) {
          setInsufficientCoversheetOpen(true);
        } else {
          snackbar.showError(
            ErrorUtils.getMessage(reason),
            'long',
            'sub-resub',
          );
          throw reason;
        }
      }),
      subscribeStatus,
      (v) => setSubscribeStatus(v),
    );
  };

  return (
    <Scaffolding
      header={{
        id: 'nav.subscriptionInfo',
        defaultMessage: 'My plan',
      }}
    >
      <AsyncStateWrapper state={subscription.info}>
        {(sub) =>
          sub.currentSubscriptionDetails ? (
            <FlexCol gap={1.5} paddingHorizontal={1}>
              <SubscriptionLogo subscription={'BUSINESS'} />
              <SubscriptionStatusCard
                status={sub.status}
                details={sub.currentSubscriptionDetails}
                onEditBilling={() => {
                  setEditBillingState(notLoaded());
                  setEditBillingSheetOpen(true);
                }}
                onAutoRenewChanged={(a) =>
                  subscription.setAutoRenew(a, (e) =>
                    snackbar.showError(
                      ErrorUtils.getMessage(e),
                      'long',
                      'sub-auto-renew',
                    ),
                  )
                }
              />
              {sub.status === SubscriptionInfoStatusEnum.Expired && (
                <Button
                  text={{
                    id: 'screens.SubscriptionScreen.renew',
                    defaultMessage: 'Renew subscription',
                  }}
                  type={subscribeBtnError ? 'error' : 'primary'}
                  icon={'rotate'}
                  onClick={subscribe}
                  loading={subscribeStatus.status === 'loading'}
                />
              )}

              <SelectSubscriptionPeriodBottomSheet
                details={sub.currentSubscriptionDetails}
                onDismiss={() => setEditBillingSheetOpen(false)}
                open={editBillingSheetOpen}
                onConfirm={(period) =>
                  wrapPromise(
                    subscription.setPeriod(period),
                    editBillingState,
                    (v) => setEditBillingState(v),
                  ).then(() => {
                    setEditBillingSheetOpen(false);
                    snackbar.showInfo(
                      {
                        id: 'screens.SubscriptionScreen.billingChanged',
                        defaultMessage: 'Billing period changed',
                      },
                      'short',
                    );
                  })
                }
                changeState={editBillingState}
              />
            </FlexCol>
          ) : (
            <FlexCol gap={1.5} paddingHorizontal={1} grow={1}>
              <AsyncStateWrapper state={pricing}>
                {(pricing) => (
                  <>
                    <SubscriptionLogo subscription={'BUSINESS'} />
                    <SubscriptionPeriodSelect
                      costYearly={pricing.business.yearlyCost}
                      costThreeMonth={pricing.business.threeMonthCost}
                      costMonthly={pricing.business.monthlyCost}
                      current={newSubPeriod}
                      onChange={setNewSubPeriod}
                    />
                    <FeatureList>
                      <FeatureList.Feature
                        text={{
                          id: 'subscriptions.business.features.lessCommission',
                          defaultMessage:
                            'Trade commission is <h>2 times lower</h>',
                        }}
                      />
                      <FeatureList.Feature
                        text={{
                          id: 'subscriptions.business.features.extendedSettings',
                          defaultMessage: 'Advanced trading robot settings',
                        }}
                      />
                      <FeatureList.Feature
                        text={{
                          id: 'subscriptions.business.features.addPairs',
                          defaultMessage:
                            '<h>All trading pairs</h> are available',
                        }}
                      />
                      <FeatureList.Feature
                        text={{
                          id: 'subscriptions.business.features.referral',
                          defaultMessage:
                            'Access to <h>higher tiers</h> of referral program',
                        }}
                      />
                    </FeatureList>
                    <Spacer flex={1} />
                    <div>
                      <Button
                        text={{
                          id: 'screens.SubscriptionScreen.subscribe',
                          defaultMessage: 'Subscribe',
                        }}
                        type={subscribeBtnError ? 'error' : 'primary'}
                        loading={subscribeStatus.status === 'loading'}
                        onClick={subscribe}
                      />
                    </div>
                    <Spacer size={0.5} />
                  </>
                )}
              </AsyncStateWrapper>
            </FlexCol>
          )
        }
      </AsyncStateWrapper>
      <ConfirmBottomSheet
        open={insufficientCoversheetOpen}
        icon={'attention'}
        title={{
          id: 'screens.SubscriptionScreen.insufficientRco',
          defaultMessage: 'Insufficient RCO',
        }}
        message={{
          id: 'screens.SubscriptionScreen.insufficientRcoDesc',
          defaultMessage:
            'Insufficient RCO to buy business subscription. You should buy required RCO amount first',
        }}
        confirmText={{
          id: 'screens.SubscriptionScreen.buyRco',
          defaultMessage: 'Buy RCO',
        }}
        onConfirm={() => {
          nav('/app/wallet/buy');
          setInsufficientCoversheetOpen(false);
        }}
        onDismiss={() => setInsufficientCoversheetOpen(false)}
      />
    </Scaffolding>
  );
};

export default inject('subscription')(observer(SubscriptionScreen)) as FC<
  Partial<StoreProps>
>;
