import React, {useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import OnboardingStore from '../../../store/OnboardingStore';
import {
  CurrentOnboardingStateCurrentStageEnum,
  CurrentOnboardingStateSelectedMarketEnum,
} from '../../../api';
import LoadingBlock from '../../ui/LoadingBlock';
import ErrorBlock from '../../ui/ErrorBlock';

interface Props {
  onboarding: OnboardingStore;
}

const OnboardingGuardRoute: React.FunctionComponent<Props> = ({onboarding}) => {
  const nav = useNavigate();
  const location = useLocation();
  const isOnOnboardingPages =
    location.pathname.startsWith('/app/onboarding') ||
    location.pathname.startsWith('/app/exchange-connections');
  useEffect(() => {
    void onboarding.init().then((value) => {
      if (isOnOnboardingPages) {
        return;
      }
      if (
        !value.selectedMarket &&
        (value.currentStage ===
          CurrentOnboardingStateCurrentStageEnum.Exchange ||
          value.currentStage ===
            CurrentOnboardingStateCurrentStageEnum.FirstScreen)
      ) {
        nav(`/app/onboarding`);
      }
      const ex = (
        value.selectedMarket ?? CurrentOnboardingStateSelectedMarketEnum.Bybit
      ).toLowerCase();
      switch (value.currentStage) {
        case CurrentOnboardingStateCurrentStageEnum.Exchange:
          nav(`/app/onboarding/${ex}/exchange-register/0`);
          break;
        case CurrentOnboardingStateCurrentStageEnum.P2P:
          nav(`/app/onboarding/${ex}/p2p/0`);
          break;
        case CurrentOnboardingStateCurrentStageEnum.ApiKeys:
          nav(`/app/onboarding/${ex}/api-keys/0`);
          break;
      }
    });
  }, []);

  if (isOnOnboardingPages || onboarding.shouldSkipOnboardingLogic) {
    return <Outlet />;
  }

  if (
    onboarding.currentStage.status === 'success' &&
    onboarding.currentStage.result ===
      CurrentOnboardingStateCurrentStageEnum.Completed
  ) {
    return <Outlet />;
  }
  if (onboarding.currentStage.status === 'loading') {
    return <LoadingBlock />;
  }
  if (onboarding.currentStage.status === 'error') {
    return <ErrorBlock error={onboarding.currentStage.error} />;
  }
  return <></>;
};

export default inject('onboarding')(
  observer(OnboardingGuardRoute),
) as unknown as React.FC;
