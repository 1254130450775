import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import chat from '../../../../../assets/onboarding/binance/p2p/chat.png';
import orderCompleted from '../../../../../assets/onboarding/binance/p2p/order-completed.png';

const PaymentCompletedFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.PaymentCompletedFragment.title',
        defaultMessage:
          'You can contact seller by using chat at top right. After seller receives money and releases funds, you will receive your crypto',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={chat} second={orderCompleted} />
  </>
);

export default PaymentCompletedFragment;
