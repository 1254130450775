import React, {FC} from 'react';
import WithTooltip from '../../misc/Tooltip/wrapper';
import Typography, {HText} from '../../typo/Typography';
import Icon from '../../graphics/Icon';
import RcoBalance from '../../typo/RcoBalance';
import Button from '../../button/Button';
import cls from './style.module.scss';
import WarningBanner from '../../banner/WarningBanner';
import {ifSuccessful} from '../../../../utils';
import Skeleton from 'react-loading-skeleton';
import Badge from '../../container/Badge';
import RcoAmount from '../../typo/RcoAmount';

interface Props {
  tooltip?: React.ReactNode;
  balance: AsyncState<number> | RefreshableAsyncState<number>;
  warning?: HText;
  onSellClick?: () => void;
  onBuyClick?: () => void;
  change: RefreshableAsyncState<number>;
  changePercent: RefreshableAsyncState<number | undefined | null>;
}

const BalanceCard: FC<Props> = ({
  balance,
  warning,
  onSellClick,
  onBuyClick,
  tooltip,
  change,
  changePercent,
}) => (
  <div className={cls.container}>
    <span className={cls.main}>
      <WithTooltip tooltip={tooltip} className={cls.heading}>
        <Typography
          text={{id: 'common.myBalance', defaultMessage: 'My balance'}}
          className={cls.label}
        />
        {tooltip && <Icon name={'question'} size={16} />}
      </WithTooltip>
      {ifSuccessful(
        balance,
        (balance) => (
          <span className={cls.balance}>
            <RcoBalance amount={balance} withoutCurrency />
            <span className={cls.currency}> RCO</span>
          </span>
        ),
        () => (
          <span className={cls.balance}>
            <Skeleton height={40} borderRadius={6} />
          </span>
        ),
      )}
      {change.status === 'success' || change.status === 'refreshing-success' ? (
        <Badge color={change.result > 0 ? 'green' : 'red'}>
          <RcoAmount amount={change.result} />
          {(changePercent.status === 'success' ||
            changePercent.status === 'refreshing-success') &&
          changePercent.result !== undefined &&
          changePercent.result !== null
            ? ' (' + changePercent.result.toFixed(1) + '%)'
            : ''}
        </Badge>
      ) : (
        <Skeleton borderRadius={6} />
      )}
    </span>
    {warning && <WarningBanner text={warning} />}
    <div className={cls.buttons}>
      <Button
        text={{id: 'common.sellRCO', defaultMessage: 'Sell RCO'}}
        type={'default'}
        onClick={onSellClick}
      />
      <Button
        text={{id: 'common.buyRCO', defaultMessage: 'Buy RCO'}}
        type={'primary'}
        onClick={onBuyClick}
      />
    </div>
  </div>
);

export default BalanceCard;
