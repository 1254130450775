import {CurrentMaintenance} from '../store/MaintenanceStore';
import {MessageDescriptor} from 'react-intl';

class MaintenanceUtils {
  static getTitle(maintenance: CurrentMaintenance): MessageDescriptor {
    if (maintenance === 'NO_INTERNET') {
      return {
        id: 'common.error.internetUnreachable.title',
        defaultMessage: 'Internet unreachable',
      };
    } else {
      return {
        id: 'common.maintenance.title',
        defaultMessage: 'Maintenance undergoing',
      };
    }
  }

  static getText(maintenance: CurrentMaintenance): MessageDescriptor {
    if (maintenance === 'NO_INTERNET') {
      return {
        id: 'common.error.internetUnreachable.title',
        defaultMessage: 'Please, check your internet connection',
      };
    } else {
      if (maintenance === 'SERVICE_UNAVAILABLE' || maintenance.blockAccess) {
        return {
          id: 'common.maintenanceBlockAccess.title',
          defaultMessage: 'Application is updating now, please wait',
        };
      } else {
        return {
          id: 'common.maintenanceOptional.title',
          defaultMessage:
            'Updates are underway, application may behave unstably',
        };
      }
    }
  }

  static isBlockingAccess(maintenance: CurrentMaintenance): boolean {
    return (
      maintenance === 'SERVICE_UNAVAILABLE' ||
      maintenance === 'NO_INTERNET' ||
      maintenance.blockAccess
    );
  }

  static getBanner(
    maintenance: CurrentMaintenance,
  ): 'no_internet' | 'maintenance' {
    if (maintenance === 'NO_INTERNET') {
      return 'no_internet';
    } else {
      return 'maintenance';
    }
  }
}

export default MaintenanceUtils;
