class ReferralUtils {
  static getLevelBadge(level: number): string | undefined {
    if (level == 0) {
      return undefined;
    } else if (level == 1) {
      return 'S+';
    } else {
      return 'X' + (level - 1);
    }
  }
}

export default ReferralUtils;
