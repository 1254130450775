import {FC, memo, useState} from 'react';
import {COIN_ICON_SIZES, COIN_ICON_URL} from '../../../../config';
import Sentry from '../../../../manager/Sentry';
import cls from './style.module.scss';

interface Props {
  coin: string;
  size: number;
  className?: string;
}

const CoinIcon: FC<Props> = ({coin, size, className}) => {
  const [error, setError] = useState(false);
  const arr = COIN_ICON_SIZES.filter((sz) => sz >= size);
  const sz = arr.length === 0 ? 16 : arr[0];
  const url = COIN_ICON_URL(error ? 'btc' : coin, sz);

  return (
    <img
      src={url}
      width={size}
      height={size}
      className={cls.coin + ' ' + className}
      alt={coin}
      onError={() => {
        Sentry.warnEvent(
          'CoinIcon',
          'render',
          'Failed to find icon for symbol' + coin,
        );
        setError(true);
      }}
    />
  );
};

export default memo(CoinIcon);
