import {FC} from 'react';

const HuobiLogo: FC = () => (
  <svg
    width="64"
    height="24"
    viewBox="0 0 64 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_423_7472)">
      <path
        d="M23.7578 10.5035H18.3755V5.78553C18.3755 5.39778 18.061 5.08325 17.6732 5.08325H15.8511V18.0523C15.8511 18.44 16.1657 18.7545 16.5534 18.7545H18.3755V12.7052H23.7605V18.0496C23.7551 18.4373 24.0642 18.7573 24.4519 18.7627H26.2849V5.78553C26.2849 5.39778 25.9704 5.08325 25.5826 5.08325H23.7578V10.5035ZM34.4465 8.47532V14.9747C34.4519 15.5658 34.1428 16.1163 33.633 16.42C32.2068 17.2442 30.729 16.2112 30.729 14.8554V9.17759C30.729 8.78985 30.4145 8.47532 30.0267 8.47532H28.2046V15.0886C28.2101 17.2198 29.9346 18.9443 32.0658 18.9552H33.1016C35.2355 18.9498 36.9654 17.2198 36.9709 15.0859V9.17759C36.9709 8.78985 36.6563 8.47532 36.2686 8.47532H34.4465Z"
        fill="white"
      />
      <path
        d="M63.8362 18.7626V9.55442C63.8362 9.16668 63.5216 8.85215 63.1339 8.85215H61.3118V18.0549C61.3091 18.4427 61.6209 18.7599 62.0086 18.7626H63.8362Z"
        fill="#2CA6E0"
      />
      <path
        d="M63.8747 7.01015C64.1721 6.29209 63.8311 5.46886 63.1131 5.17143C62.395 4.87399 61.5718 5.21498 61.2743 5.93304C60.9769 6.6511 61.3179 7.47432 62.0359 7.77176C62.754 8.0692 63.5772 7.72822 63.8747 7.01015Z"
        fill="#2CA6E0"
      />
      <path
        d="M43.4838 8.46982C40.8402 8.46982 38.6764 10.3597 38.6764 13.0034V14.4324C38.6764 17.0761 40.8402 18.9551 43.4838 18.9551C46.1275 18.9551 48.2913 17.0761 48.2913 14.4324V13.0034C48.294 10.3597 46.1303 8.46982 43.4838 8.46982ZM45.7561 14.6032C45.7561 15.8532 44.7338 16.6856 43.4811 16.6856C42.2284 16.6856 41.2089 15.8532 41.2089 14.6032V12.819C41.2089 11.569 42.2311 10.7366 43.4811 10.7366C44.7311 10.7366 45.7561 11.569 45.7561 12.819V14.6032Z"
        fill="white"
      />
      <path
        d="M54.8042 8.46966C54.0125 8.46424 53.2316 8.6432 52.5185 8.98756V5.79886C52.5185 5.41111 52.2039 5.09658 51.8162 5.09658H49.9941V14.4322C49.9941 17.0759 52.1578 18.955 54.8015 18.955C57.4452 18.955 59.609 17.0759 59.609 14.4322V13.0033C59.6117 10.3596 57.4534 8.46966 54.8042 8.46966ZM57.0792 14.603C57.0792 15.853 56.0569 16.6855 54.8042 16.6855C53.5542 16.6855 52.532 15.853 52.532 14.603V12.8189C52.532 11.5689 53.5542 10.7365 54.8042 10.7365C56.0542 10.7365 57.0792 11.5689 57.0792 12.8189V14.603Z"
        fill="#2CA6E0"
      />
      <path
        d="M8.22112 6.20561C8.22112 3.45075 6.87894 1.0782 5.85671 0.308141C5.85671 0.308141 5.77808 0.264757 5.78621 0.375928C5.70215 5.69857 2.98254 7.14107 1.48851 9.08249C-1.96049 13.5673 1.24448 18.4832 4.51453 19.3915C6.33664 19.9013 4.09153 18.4913 3.80141 15.5168C3.4462 11.9268 8.22112 9.18282 8.22112 6.20561Z"
        fill="white"
      />
      <path
        d="M9.78825 8.01952C9.76656 8.00596 9.73673 7.99512 9.71775 8.03037C9.66081 8.7001 8.97751 10.1318 8.10984 11.4495C5.16788 15.9126 6.84358 18.0656 7.78717 19.2234C8.33489 19.8931 7.78717 19.2234 9.15376 18.5374C10.843 17.5124 11.9385 15.7391 12.1011 13.7706C12.3696 10.5981 10.4661 8.59978 9.78825 8.01952Z"
        fill="#2CA6E0"
      />
    </g>
    <defs>
      <clipPath id="clip0_423_7472">
        <rect width="64" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HuobiLogo;
