import {WalletTransactionItemCauseEnum} from '../../../../api';
import {FC} from 'react';
import Icon from '../../graphics/Icon';
import Typography from '../../typo/Typography';
import WalletTransactionUtils from '../../../../utils/WalletTransactionUtils';
import DateTime from '../../typo/DateTime';
import RcoAmount from '../../typo/RcoAmount';
import RcoBalance from '../../typo/RcoBalance';
import cls from './style.module.scss';
import Loader from './loading';

interface Props {
  amount: number;
  cause: WalletTransactionItemCauseEnum;
  createdAt: string | Date;
  balanceAfterTransaction: number;
  onClick?: () => void;
}

interface SubComponents {
  Loader: typeof Loader;
}

const WalletTransactionItem: FC<Props> & SubComponents = ({
  amount,
  cause,
  createdAt,
  balanceAfterTransaction,
  onClick,
}) => (
  <div className={cls.container} onClick={onClick}>
    <div className={cls.icon + ' ' + (amount > 0 ? cls.up : cls.down)}>
      <Icon
        name={amount > 0 ? 'arrow-down' : 'arrow-up'}
        size={24}
        tint={amount > 0 ? 'green' : 'red'}
      />
    </div>
    <div className={cls.content}>
      <Typography
        className={cls.cause}
        text={WalletTransactionUtils.formatCause(cause)}
      />
      <span className={cls.createdAt}>
        <DateTime value={createdAt} />
      </span>
    </div>
    <div className={cls.balances}>
      <span className={cls.amount + ' ' + (amount > 0 ? cls.up : cls.down)}>
        <RcoAmount amount={amount} />
      </span>
      <span className={cls.balance}>
        <RcoBalance amount={balanceAfterTransaction} />
      </span>
    </div>
  </div>
);

WalletTransactionItem.Loader = Loader;

export default WalletTransactionItem;
