import React, {useMemo} from 'react';
import Scaffolding from '../../../component/container/Scaffolding';
import {observer} from 'mobx-react';
import TeamSearchViewModel from '../../../viewmodel/TeamSearchViewModel';
import TextInput from '../../../component/ui/TextInput';
import cls from './style.module.scss';
import {FormattedMessage} from 'react-intl';
import AsyncStateWrapper from '../../../component/ui/AsyncStateWrapper';
import ReferralSearchItem from '../../../component/ui/ReferralSearchItem';
import IntersectionTrigger from '../../../component/ui/IntersectionTrigger';
import {useNavigate} from 'react-router-dom';

const TeamSearchScreen: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const vm = useMemo(() => new TeamSearchViewModel(), []);
  return (
    <Scaffolding
      header={{
        id: 'nav.teamSearch',
        defaultMessage: 'My business',
      }}
      actions={[{icon: 'qr-code', onClick: () => navigate('/app/invite')}]}
    >
      <div className={cls.firstRow}>
        <TextInput
          value={vm.query}
          onValueChange={vm.setQuery}
          icon={'search'}
        />
        <span className={cls.cancel} onClick={() => vm.setQuery('')}>
          <FormattedMessage id={'common.cancel'} defaultMessage={'Cancel'} />
        </span>
      </div>
      <div className={cls.content}>
        <AsyncStateWrapper state={vm.currentPage}>
          {() => (
            <>
              {vm.items.map((item, idx, items) =>
                idx === items.length - 5 ? (
                  <>
                    <IntersectionTrigger onIntersected={vm.nextPage} />
                    <ReferralSearchItem
                      photo={item.photoUrl}
                      name={item.name}
                      depth={item.relativeDepth}
                      income={item.income}
                      incomePercent={item.incomePercent}
                      key={item.id}
                    />
                  </>
                ) : (
                  <ReferralSearchItem
                    photo={item.photoUrl}
                    name={item.name}
                    depth={item.relativeDepth}
                    income={item.income}
                    incomePercent={item.incomePercent}
                    key={item.id}
                  />
                ),
              )}
            </>
          )}
        </AsyncStateWrapper>
      </div>
    </Scaffolding>
  );
};

export default observer(TeamSearchScreen);
