import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import cls from './style.module.scss';

interface Props {
  name: HText;
  value: string | undefined;
}

const Item: FC<Props> = ({name, value}) => (
  <div className={cls.item}>
    <Typography text={name} className={cls.label} />
    <span className={cls.value}>
      {value || (
        <Typography
          className={cls.nf}
          text={{id: 'common.notSet', defaultMessage: 'Not set'}}
        />
      )}
    </span>
  </div>
);

export default Item;
