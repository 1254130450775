import React from 'react';
import cls from './style.module.scss';
import {FormattedNumberParts} from 'react-intl';
import CurrencyUtils from '../../../utils/CurrencyUtils';

interface Props {
  money: number;
  symbol: string;
}

const Money: React.FunctionComponent<Props> = ({money, symbol}) => (
  <span
    className={
      cls.text +
      ' ' +
      (money > 0 && 'text-balance-up') +
      ' ' +
      (money < 0 && 'text-balance-down')
    }
  >
    <FormattedNumberParts
      value={money}
      maximumFractionDigits={8}
      minimumFractionDigits={CurrencyUtils.getDisplayMinFractionalDigits(
        symbol,
      )}
    >
      {CurrencyUtils.formatAmount}
    </FormattedNumberParts>
    {` ${symbol}`}
  </span>
);

export default Money;
