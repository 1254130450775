import {BaseInputProps} from '../types';
import {FC, useRef} from 'react';
import InputBase from '../InputBase';
import useHText from '../../../../hooks/useHText';
import useIsFocused from '../../../../hooks/useIsFocused';
import useHandleSubmitEditing from '../../../../hooks/useHandleSubmitEditing';
import cls from './style.module.scss';
import {format} from 'date-fns';

interface Props extends BaseInputProps {
  from?: Date;
  to?: Date;
  value: string;
  onChange: (date: string) => void;
}

const DateInput: FC<Props> = ({
  to,
  from,
  onChange,
  value,
  onBlur,
  placeholder,
  error,
  enterKeyHint,
  onFocus,
  disabled,
  onSubmitEditing,
  label,
}) => {
  const hText = useHText();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focused, focus, blur] = useIsFocused(onFocus, onBlur);
  const submitHandle = useHandleSubmitEditing(onSubmitEditing);

  return (
    <InputBase
      contentClassName={cls.content}
      label={label}
      error={error}
      selected={focused}
      onContentClick={() => inputRef.current?.focus()}
    >
      <input
        type={'date'}
        className={cls.input}
        ref={inputRef}
        value={value}
        placeholder={hText(placeholder)}
        onChange={
          !disabled && onChange
            ? (e) => onChange(e.currentTarget.value)
            : undefined
        }
        onBlur={blur}
        onFocus={focus}
        onKeyUp={submitHandle}
        enterKeyHint={enterKeyHint}
        pattern={'\\d{2}-\\d{2}-\\d{4}'}
        min={from ? format(from, 'YYYY-MM-dd') : undefined}
        max={to ? format(to, 'YYYY-MM-dd') : undefined}
      />
    </InputBase>
  );
};

export default DateInput;
