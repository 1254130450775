import Icon, {IconName} from '../../graphics/Icon';
import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import cls from './style.module.scss';

interface Props {
  icon?: IconName;
  text: HText;
  applied?: boolean;
  onClick?: () => void;
}

const FilterButton: FC<Props> = ({icon, text, applied, onClick}) => (
  <div className={cls.container} onClick={onClick}>
    <Icon name={icon ?? 'filter'} size={16} />
    <Typography text={text} className={cls.text} />
    <div className={cls.applied + ' ' + (applied && cls.show)} />
  </div>
);

export default FilterButton;
