import {RobotTradeDetails} from '../../../../api';
import {FC} from 'react';
import KVCard from '../../container/KVCard';
import MiscUtils from '../../../../utils/MiscUtils';
import MarketLogo from '../../graphics/MarketLogo';
import RobotPair from '../RobotPair';
import DateTime from '../../typo/DateTime';

const RobotTradeCard: FC<RobotTradeDetails> = ({id, robot, createdAt}) => (
  <KVCard>
    <KVCard.Item
      name={{id: 'common.trade.id', defaultMessage: 'Trade ID'}}
      value={id}
      copyable
      ellipsis
    />
    {robot && (
      <KVCard.Item
        name={{
          id: 'common.walletTx.pair',
          defaultMessage: 'Trading pair',
        }}
        value={<RobotPair {...robot.pair} />}
      />
    )}
    {robot && robot.id.length > 0 && (
      <KVCard.Item
        name={{
          id: 'common.walletTx.robot',
          defaultMessage: 'Robot',
        }}
        value={robot.name ?? MiscUtils.capitalize(robot.market.toLowerCase())}
        link={!robot.deleted ? '/app/dashboard/robot/' + robot.id : ''}
      />
    )}
    {robot && (
      <KVCard.Item
        name={{
          id: 'common.walletTx.market',
          defaultMessage: 'Market',
        }}
        value={<MarketLogo market={robot.market} />}
      />
    )}
    <KVCard.Item
      name={{id: 'common.trade.createdAt', defaultMessage: 'Trade started'}}
      value={<DateTime value={createdAt} />}
    />
  </KVCard>
);

export default RobotTradeCard;
