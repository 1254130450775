import Typography, {HText} from '../../typo/Typography';
import Icon, {IconName} from '../../graphics/Icon';
import {FC, ReactNode} from 'react';
import cls from './style.module.scss';
import Loader from '../../../ui/Loader';

interface Props {
  text: HText;
  icon?: IconName | ((size: number) => ReactNode);
  disabled?: boolean;
  type: 'primary' | 'default' | 'error';
  small?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const Button: FC<Props> = ({
  text,
  icon,
  disabled,
  loading,
  type,
  small,
  onClick,
}) => {
  let typeStyle = '';
  if (type === 'primary') {
    typeStyle = cls.primary;
  } else if (type === 'default') {
    typeStyle = cls.default;
  } else if (type === 'error') {
    typeStyle = cls.error;
  }

  return (
    <div className={cls.container}>
      <div
        onClick={disabled || loading ? undefined : onClick}
        className={
          cls.button +
          ' ' +
          typeStyle +
          ' ' +
          (disabled && cls.disabled) +
          ' ' +
          (loading && cls.loading) +
          ' ' +
          (small && cls.small)
        }
      >
        {icon &&
          !small &&
          (typeof icon === 'function' ? (
            icon(24)
          ) : (
            <Icon name={icon} size={24} tint={'white'} />
          ))}
        <Typography text={text} />
      </div>
      {loading && (
        <div className={cls.loader}>
          {' '}
          <Loader size={24} />
        </div>
      )}
    </div>
  );
};

export default Button;
