import NotificationsStore from '../NotificationsStore';
import {
  EmailNotificationPreferences,
  NotificationDetails,
  NotificationItem,
  NotificationList,
  NotificationPreferences,
  UnsubscribeStatus,
} from '../../api';
import ApiStore from '../ApiStore';
import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import {
  NOTIFICATIONS_PREVIEW_DEBOUNCE,
  NOTIFICATIONS_UPDATE_INTERVAL,
} from '../../config';
import {debounce} from 'ts-debounce';

class RealNotificationsStore implements NotificationsStore {
  hasNewNotifications = false;

  constructor() {
    makeAutoObservable(this, {
      hasNewNotifications: observable,
      loadNotificationPreview: action.bound,
    });
    setInterval(
      () => runInAction(() => this.loadNotificationPreview()),
      NOTIFICATIONS_UPDATE_INTERVAL,
    );
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.loadNotificationPreview();
      }
    });
  }

  init() {
    this.loadNotificationPreview();
  }

  loadNotificationPreview() {
    void ApiStore.notifications
      .getNotifcationStoragePreview()
      .then((value) =>
        runInAction(
          () => (this.hasNewNotifications = value.data.hasNewNotifications),
        ),
      );
  }

  private loadNotificationsPreviewDebounced = debounce(
    this.loadNotificationPreview,
    NOTIFICATIONS_PREVIEW_DEBOUNCE,
  );

  getEmailNotificationPreferences(): Promise<EmailNotificationPreferences> {
    return ApiStore.notifications
      .getEmailNotificationPreferences()
      .then((value) => value.data.preferences[0]);
  }

  async setEmailNotificationPreferences(
    id: string,
    prefs: NotificationPreferences,
  ): Promise<void> {
    await ApiStore.notifications.editEmailNotificationPreference({
      id: id,
      editEmailNotificationPreferences: {
        notificationPreferences: prefs,
      },
    });
  }

  getUnsubStatus(id: string, token: string): Promise<UnsubscribeStatus> {
    return ApiStore.notifications
      .getUnsubscribeStatus({token, prefs: id})
      .then((value) => value.data);
  }

  async unsubscribe(id: string, token: string): Promise<void> {
    await ApiStore.notifications.unsubscribeFromEmails({prefs: id, token});
  }

  getPage(nextPageToken: string | undefined): Promise<NotificationList> {
    return ApiStore.notifications
      .getNotifications({nextPageToken})
      .then((value) => value.data);
  }
  ackItems(items: NotificationItem[]): void {
    const ids = items.filter((value) => !value.acked).map((value) => value.id);
    if (ids.length > 0) {
      void ApiStore.notifications.ackNotifications({id: ids});
      void this.loadNotificationsPreviewDebounced();
    }
  }

  getDetails(id: string): Promise<NotificationDetails> {
    return ApiStore.notifications
      .getNotificationDetails({id})
      .then((value) => value.data);
  }

  ackAll(): Promise<void> {
    return ApiStore.notifications.ackAllNotifications().then(() => {
      this.loadNotificationPreview();
    });
  }
}

export default RealNotificationsStore;
