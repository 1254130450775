import React, {useState} from 'react';
import BottomActionSheet from '../BottomActionSheet';
import cls from './style.module.scss';
import {FormattedMessage} from 'react-intl';
import Icon from '../Icon';

interface Props {
  name: string;
  onNameClick?: () => void;
  actions?: (open: boolean) => React.ReactNode;
  notifications?: 'has-new' | 'no-new';
  onNotificationsClick?: () => void;
}

const UpLine: React.FunctionComponent<Props> = ({
  name,
  onNameClick,
  actions,
  notifications,
  onNotificationsClick,
}) => {
  const [actionsOpen, setActionsOpen] = useState(false);

  return (
    <>
      {actions && (
        <BottomActionSheet
          open={actionsOpen}
          onDismiss={() => setActionsOpen(false)}
        >
          {actions(actionsOpen)}
        </BottomActionSheet>
      )}
      <div className={cls.upLine}>
        <p className={cls.name} onClick={onNameClick}>
          <FormattedMessage
            id={'component.BalanceCard.balance'}
            defaultMessage={'{name} Balance'}
            values={{name}}
          />
        </p>
        {actions && (
          <Icon
            name={'dots-horizontal'}
            size={24}
            clickable
            onClick={() => setActionsOpen(true)}
          />
        )}
        {notifications && (
          <Icon
            name={
              notifications === 'has-new'
                ? 'bell-badged-outline'
                : 'bell-outline'
            }
            size={24}
            clickable
            onClick={onNotificationsClick}
          />
        )}
      </div>
    </>
  );
};

export default UpLine;
