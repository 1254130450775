import Typography, {HText} from '../../typo/Typography';
import {FC, ReactNode} from 'react';
import cls from './style.module.scss';
import Icon, {IconName} from '../../graphics/Icon';
import ClipboardUtils from '../../../../utils/ClipboardUtils';
import {useNavigate} from 'react-router-dom';
import {useSnackbar} from '../SnackbarContainer';

export interface Props {
  name: HText;
  value: string | ReactNode;
  ellipsis?: boolean;
  copyable?: boolean;
  link?: string;
  rightIcon?: IconName;
  onRightIconClick?: () => void;
}

const Item: FC<Props> = ({
  name,
  value,
  ellipsis,
  copyable,
  link,
  rightIcon,
  onRightIconClick,
}) => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  return (
    <div className={cls.item}>
      <Typography text={name} className={cls.name} />
      <span
        className={
          cls.val +
          ' ' +
          ((value === undefined || value === null) && cls.noValue) +
          ' ' +
          (ellipsis && cls.ellipsis)
        }
      >
        {value === undefined || value === null ? '...' : (value as string)}
      </span>
      {copyable && (
        <Icon
          name={'copy'}
          size={24}
          tint={'default'}
          onClick={() => {
            ClipboardUtils.write(value);
            snackbar.showInfo(
              {id: 'common.textCopied', defaultMessage: 'Copied to clipboard'},
              'short',
              'copied',
            );
          }}
          clickable
        />
      )}
      {link && (
        <Icon
          name={'new-tab'}
          size={24}
          tint={'default'}
          onClick={() => navigate(link)}
          clickable
        />
      )}
      {rightIcon && (
        <Icon
          name={rightIcon}
          size={24}
          tint={'primary'}
          onClick={onRightIconClick}
          clickable
        />
      )}
    </div>
  );
};

export default Item;
