import React from 'react';
import cls from './style.module.scss';
import Avatar from '../../design/misc/Avatar';

interface Props {
  photo: string;
  name: string;
}

const Profile: React.FunctionComponent<Props> = ({photo, name}) => (
  <div className={cls.profile}>
    <Avatar size={64} src={photo} className={cls.photo} />
    <span className={cls.name}>{name}</span>
  </div>
);

export default Profile;
