import {FC, ReactNode} from 'react';
import cls from './style.module.scss';

interface Props {
  color: 'red' | 'green' | 'yellow' | 'yellow-bg' | 'red-bg';
  small?: boolean;
  children: ReactNode;
}

const Badge: FC<Props> = ({color, small, children}) => (
  <span
    className={
      cls.badge +
      ' ' +
      (small && cls.small) +
      ' ' +
      (color === 'red' && cls.red) +
      ' ' +
      (color === 'green' && cls.green) +
      ' ' +
      (color === 'yellow' && cls.yellow) +
      ' ' +
      (color === 'yellow-bg' && cls.yellowBg) +
      ' ' +
      (color === 'red-bg' && cls.redBg)
    }
  >
    {children}
  </span>
);

export default Badge;
