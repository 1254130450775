import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import Icon from '../../graphics/Icon';
import cls from './style.module.scss';

export type SnackbarType = 'info' | 'warning' | 'error';

interface Props {
  type: SnackbarType;
  text: HText;
  onClose?: () => void;
}

const Snackbar: FC<Props> = ({type, text, onClose}) => (
  <div
    className={
      cls.container +
      ' ' +
      (type === 'warning' && cls.warning) +
      ' ' +
      (type === 'error' && cls.error)
    }
  >
    <Icon
      name={type === 'info' ? 'info' : 'attention'}
      size={24}
      tint={'white'}
    />
    <Typography text={text} className={cls.text} />
    <Icon name={'close'} size={24} tint={'white'} onClick={onClose} clickable />
  </div>
);

export default Snackbar;
