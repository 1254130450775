import {RobotTradeItem, RobotTradeList} from '../api';
import {notLoaded, wrapRefreshablePromise} from '../utils';
import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import stores from '../store';

class WalletTransactionListViewModel {
  items: RobotTradeItem[] = [];
  currentPage: RefreshableAsyncState<RobotTradeList> = notLoaded();
  nextToken?: string;

  constructor(readonly robot: string | undefined) {
    makeAutoObservable(this, {
      currentPage: observable,
      items: observable,
      nextPage: action.bound,
    });

    this.loadPage();
  }

  nextPage() {
    if (
      this.currentPage.status === 'success' &&
      this.nextToken !== undefined &&
      this.nextToken !== null
    ) {
      this.loadPage(this.nextToken);
    }
  }

  private loadPage(token?: string) {
    const promise =
      this.robot !== undefined
        ? stores.robot.getRobotTradesPage(this.robot, token ?? undefined)
        : stores.robot.getAllTradesPage(token ?? undefined);
    void wrapRefreshablePromise(promise, this.currentPage, (v) =>
      runInAction(() => {
        if (v.status === 'success') {
          this.nextToken = v.result.nextPageToken;
          this.items.push(...v.result.items);
        }
        this.currentPage = v;
      }),
    );
  }
}

export default WalletTransactionListViewModel;
