import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/apikeys/gauth-1.png';
import v2 from '../../../../../assets/onboarding/bybit/apikeys/gauth-2.png';

const StartGAuthSetupFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.StartGAuthSetupFragment.title',
        defaultMessage:
          'Open Google authenticator, press <h>Add a code</h>, then <h>Enter a setup key</h>.',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default StartGAuthSetupFragment;
