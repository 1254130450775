import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import cls from './style.module.scss';
import {format} from 'date-fns';
import Badge from '../Badge';

interface Props {
  name: HText;
  value: Date;
  modifier?: 'soon' | 'expired';
}

const ExpiresItem: FC<Props> = ({name, value, modifier}) => (
  <div className={cls.item}>
    <Typography text={name} className={cls.label} />
    <span className={cls.value}>{format(value, 'dd LLL yyyy, HH:mm')}</span>
    {modifier === 'soon' && (
      <Badge color={'yellow-bg'} small>
        <Typography text={{id: 'common.soon', defaultMessage: 'Soon'}} />
      </Badge>
    )}
    {modifier === 'expired' && (
      <Badge color={'red-bg'} small>
        <Typography
          text={{
            id: 'common.connectionExpired',
            defaultMessage: 'Connection expired',
          }}
        />
      </Badge>
    )}
  </div>
);

export default ExpiresItem;
