import {
  AdviceIpWhitelistAdviceAdviceEnum,
  AdvicePermissionAdviceAdviceEnum,
  ExchangeConnectionDetailsStateEnum,
  ExchangeConnectionItemStateEnum,
  ExchangeConnectionPermission,
  ExchangeConnectionPermissionKnownPermissionEnum,
} from '../api';
import {HText} from '../component/design/typo/Typography';

type SimpleStatus = 'loading' | 'error' | 'success';

export default class ExchangeConnectionUtils {
  static simplifyState(
    state: ExchangeConnectionDetailsStateEnum | ExchangeConnectionItemStateEnum,
  ): SimpleStatus {
    switch (state) {
      case 'CRITICAL_CONFIGURATION_PROBLEMS':
      case 'MINOR_CONFIGURATION_PROBLEMS':
      case 'EXPIRES_SOON':
      case 'OK':
        return 'success';
      case 'EXPIRED':
      case 'FAILED':
        return 'error';
      case 'FETCHING':
        return 'loading';
    }
  }

  static localizePermission(perm: ExchangeConnectionPermission): HText {
    if (!perm.knownPermission) {
      return perm.permissionName;
    }
    switch (perm.knownPermission) {
      case ExchangeConnectionPermissionKnownPermissionEnum.Read:
        return {id: 'common.ec.permissions.read', defaultMessage: 'Read data'};
      case ExchangeConnectionPermissionKnownPermissionEnum.Futures:
        return {
          id: 'common.ec.permissions.futures',
          defaultMessage: 'Futures trading',
        };
      case ExchangeConnectionPermissionKnownPermissionEnum.Spot:
        return {
          id: 'common.ec.permissions.spot',
          defaultMessage: 'Spot trading',
        };
      case ExchangeConnectionPermissionKnownPermissionEnum.Withdrawal:
        return {
          id: 'common.ec.permissions.withdrawal',
          defaultMessage: 'Asset withdrawals',
        };
      case ExchangeConnectionPermissionKnownPermissionEnum.Margin:
        return {
          id: 'common.ec.permissions.margin',
          defaultMessage: 'Margin trading',
        };
    }
  }

  static localizeAdvice(
    advice:
      | AdvicePermissionAdviceAdviceEnum
      | AdviceIpWhitelistAdviceAdviceEnum,
  ): HText {
    switch (advice) {
      case AdvicePermissionAdviceAdviceEnum.Activate:
        return {
          id: 'common.ec.advice.activate',
          defaultMessage: 'Need activation',
        };
      case AdvicePermissionAdviceAdviceEnum.Deactivate:
        return {
          id: 'common.ec.advice.activate',
          defaultMessage: 'Should be disabled',
        };
      case AdviceIpWhitelistAdviceAdviceEnum.Add:
        return {
          id: 'common.ec.advice.add',
          defaultMessage: 'Should be added',
        };
      case AdviceIpWhitelistAdviceAdviceEnum.Delete:
        return {
          id: 'common.ec.advice.deleted',
          defaultMessage: 'Should be deleted',
        };
    }
  }
}
