import React from 'react';
import {RobotPair, RobotTradeItemStateEnum} from '../../../api';
import cls from './style.module.scss';
import RobotTradeUtils from '../../../utils/RobotTradeUtils';
import {FormattedMessage, FormattedNumberParts, useIntl} from 'react-intl';
import CurrencyUtils from '../../../utils/CurrencyUtils';

interface Props {
  profit?: number;
  state: RobotTradeItemStateEnum;
  pair: RobotPair;
}

function statusTextClassName(state: RobotTradeItemStateEnum): string {
  switch (state) {
    case 'BUY_IN_PROGRESS':
    case 'SELL_IN_PROGRESS':
      return 'text-progress';
    case 'SELL_ERROR':
      return 'text-fail';
    case 'COMPLETED':
      return cls.completed;
  }
}

const FirstLine: React.FunctionComponent<Props> = ({profit, state, pair}) => {
  const intl = useIntl();
  return (
    <div className={cls.firstLine}>
      <span className={cls.pair}>{pair.asset + ' / ' + pair.money}</span>
      <span className={cls.status + ' ' + statusTextClassName(state)}>
        {'•  ' + intl.formatMessage(RobotTradeUtils.formatState(state))}
      </span>
      <span className={cls.flex} />
      {profit && (
        <>
          <span className={cls.profitLabel}>
            <FormattedMessage
              id={'components.RobotTradeCard.profit'}
              defaultMessage={'Profit = '}
            />
          </span>
          <span className={cls.profitCard}>
            <FormattedNumberParts
              value={profit}
              signDisplay={'exceptZero'}
              maximumFractionDigits={8}
            >
              {CurrencyUtils.formatAmount}
            </FormattedNumberParts>{' '}
            {pair.money}
          </span>
        </>
      )}
    </div>
  );
};

export default React.memo(FirstLine);
