import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/huobi/p2p/select-amount.png';
import v2 from '../../../../../assets/onboarding/huobi/p2p/pay.png';

const PayP2PFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.huobi.PayP2PFragment.title',
        defaultMessage:
          'Enter desired amount and press <h>Order</h>. Then, review order details and if they are correct, press <h>Pay</h>',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default PayP2PFragment;
