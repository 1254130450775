import {FC} from 'react';

interface Props {
  className?: string;
}

const Progress: FC<Props> = ({className}) => (
  <svg
    className={className}
    width="300"
    height="200"
    viewBox="0 0 300 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_687_8798)">
      <g opacity="0.4">
        <path
          d="M223.867 99.9998C223.867 140.796 190.795 173.867 149.999 173.867C109.203 173.867 76.1317 140.796 76.1317 99.9998C76.1317 59.2039 109.203 26.1322 149.999 26.1322C190.795 26.1322 223.867 59.2039 223.867 99.9998Z"
          stroke="url(#paint0_radial_687_8798)"
        />
        <path
          d="M248.489 99.9998C248.489 154.394 204.394 198.49 149.999 198.49C95.6047 198.49 51.5092 154.394 51.5092 99.9998C51.5092 45.6052 95.6047 1.50971 149.999 1.50971C204.394 1.50971 248.489 45.6052 248.489 99.9998Z"
          stroke="url(#paint1_radial_687_8798)"
        />
        <path
          d="M273.112 99.9998C273.112 167.993 217.992 223.112 149.999 223.112C82.006 223.112 26.8866 167.993 26.8866 99.9998C26.8866 32.0066 82.006 -23.1128 149.999 -23.1128C217.992 -23.1128 273.112 32.0066 273.112 99.9998Z"
          stroke="url(#paint2_radial_687_8798)"
        />
        <path
          d="M297.735 99.9998C297.735 181.592 231.592 247.735 150 247.735C68.4079 247.735 2.26465 181.592 2.26465 99.9998C2.26465 18.4079 68.4079 -47.7354 150 -47.7354C231.592 -47.7354 297.735 18.4079 297.735 99.9998Z"
          stroke="url(#paint3_radial_687_8798)"
        />
      </g>
      <g filter="url(#filter0_dd_687_8798)">
        <circle cx="150" cy="100" r="50" fill="url(#paint4_linear_687_8798)" />
        <circle
          cx="150"
          cy="100"
          r="49.5"
          stroke="url(#paint5_linear_687_8798)"
          strokeOpacity="0.35"
        />
        <circle
          cx="150"
          cy="100"
          r="49.5"
          stroke="#3772FF"
          strokeOpacity="0.35"
        />
      </g>
      <path
        d="M144.979 89.8009C146.673 90.9054 148.065 92.4149 149.028 94.1929C149.992 95.9708 150.497 97.9611 150.498 99.9835C150.498 95.7382 152.676 92.003 155.973 89.8309C157.672 88.7091 158.916 87.0191 159.483 85.0636L141.499 85.0036C142.072 87.0137 143.308 88.6788 144.949 89.7859L144.979 89.8009Z"
        fill="url(#paint6_linear_687_8798)"
      />
      <path
        d="M160.038 94.1811C161.727 92.9587 163.102 91.3535 164.051 89.497C165 87.6405 165.495 85.5855 165.497 83.5005V82.0004C167.154 82.0004 168.497 80.6571 168.497 79.0004C168.497 77.3434 167.154 76 165.497 76H135.5C133.843 76 132.5 77.3434 132.5 79.0004C132.5 80.6571 133.843 82.0004 135.5 82.0004V83.5005C135.5 85.5782 135.991 87.6265 136.934 89.478C137.876 91.3296 139.243 92.9319 140.923 94.1541C141.927 94.783 142.772 95.6352 143.392 96.6442C144.012 97.6533 144.391 98.792 144.499 99.9715C144.372 101.178 143.982 102.342 143.355 103.38C142.728 104.419 141.881 105.307 140.872 105.981C139.23 107.202 137.892 108.786 136.961 110.609C136.029 112.431 135.531 114.444 135.503 116.491V118C133.846 118 132.503 119.343 132.503 121C132.503 122.657 133.846 124 135.503 124H165.5C167.157 124 168.5 122.657 168.5 121C168.5 119.343 167.157 118 165.5 118V116.5C165.476 114.464 164.985 112.46 164.065 110.644C163.145 108.828 161.821 107.247 160.194 106.023C159.177 105.356 158.32 104.475 157.682 103.44C157.044 102.405 156.642 101.242 156.504 100.035C156.598 98.8566 156.964 97.7166 157.572 96.7037C158.181 95.6908 159.015 94.8325 160.011 94.1961L160.038 94.1811ZM153.498 100.002C153.599 101.634 154.06 103.224 154.848 104.657C155.635 106.09 156.731 107.331 158.055 108.291C159.38 109.219 160.472 110.443 161.243 111.865C162.014 113.288 162.443 114.87 162.497 116.488V118.003H138.499V116.503C138.549 114.895 138.97 113.321 139.729 111.904C140.489 110.487 141.566 109.265 142.876 108.333C144.212 107.378 145.32 106.138 146.119 104.703C146.918 103.268 147.388 101.673 147.496 100.035C147.412 98.4384 146.964 96.8827 146.185 95.4872C145.406 94.0916 144.317 92.8935 143.002 91.985C141.62 91.0428 140.487 89.7783 139.702 88.3007C138.917 86.8231 138.503 85.1767 138.497 83.5035V82.0034H162.494V83.5035C162.492 85.164 162.086 86.7991 161.314 88.2689C160.541 89.7387 159.424 90.9993 158.058 91.943C156.733 92.8434 155.633 94.0361 154.842 95.4292C154.051 96.8222 153.59 98.3783 153.495 99.9775L153.498 100.002Z"
        fill="url(#paint7_linear_687_8798)"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_687_8798"
        x="52"
        y="2"
        width="196"
        height="196"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.447059 0 0 0 0 1 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_687_8798"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="24" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.447059 0 0 0 0 1 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_687_8798"
          result="effect2_dropShadow_687_8798"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_687_8798"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_687_8798"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(150 100) rotate(-90) scale(100.5 161.95)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_687_8798"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(150 100) rotate(-90) scale(100.5 161.95)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_687_8798"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(150 100) rotate(-90) scale(100.5 161.95)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_687_8798"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(150 100) rotate(-90) scale(100.5 161.95)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_687_8798"
        x1="115"
        y1="65"
        x2="186"
        y2="136"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A3366" />
        <stop offset="0.512367" stopColor="#264D99" />
        <stop offset="1" stopColor="#1A3366" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_687_8798"
        x1="100"
        y1="50"
        x2="178.174"
        y2="164.856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_687_8798"
        x1="132.5"
        y1="76"
        x2="172.646"
        y2="120.238"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_687_8798"
        x1="132.5"
        y1="76"
        x2="172.646"
        y2="120.238"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
      <clipPath id="clip0_687_8798">
        <rect width="300" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Progress;
