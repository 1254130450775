import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import cls from './style.module.scss';
import Icon from '../../graphics/Icon';

interface Props {
  text: HText;
}

const WarningBanner: FC<Props> = ({text}) => (
  <div className={cls.container}>
    <Icon name={'attention'} size={24} tint={'yellow'} />
    <Typography text={text} className={cls.text} />
  </div>
);

export default WarningBanner;
