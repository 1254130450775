import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import AppCard from '../../../../../component/design/data/AppCard';
import FlexRow from '../../../../../component/design/container/FlexRow';
import StoreButton from '../../../../../component/design/button/StoreButton';
import {App} from '../../../../../utils/AppUtils';

const DownloadGAuthFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.DownloadGAuthFragment.title',
        defaultMessage: 'To secure your account, install Google Authenticator',
      }}
    />
    <Spacer size={1.5} />
    <AppCard app={App.GAuth} />
    <Spacer size={1.5} />
    <FlexRow gap={1} stretch>
      <StoreButton
        store={'gplay'}
        link={
          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
        }
      />
      <StoreButton
        store={'appstore'}
        link={'https://apps.apple.com/us/app/google-authenticator/id388497605'}
      />
    </FlexRow>
  </>
);

export default DownloadGAuthFragment;
