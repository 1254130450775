import React, {useId, useState} from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import cls from './style.module.scss';
import Icon, {IconName} from '../Icon';
import ClipboardUtils from '../../../utils/ClipboardUtils';
import ShareUtils from '../../../utils/ShareUtils';
import Tooltip from '../Tooltip';

interface Props {
  value: string;
  onValueChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onSubmitEditing?: () => void;
  label?: MessageDescriptor;
  sublabel?: MessageDescriptor;
  error?: string;
  password?: boolean | 'fake';
  disabled?: boolean;
  copyable?: boolean;
  shareable?: 'url';
  shareText?: string;
  shareTitle?: string;
  shareValue?: string;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  enterKeyHint?:
    | 'enter'
    | 'done'
    | 'go'
    | 'next'
    | 'previous'
    | 'search'
    | 'send';
  icon?: IconName;
  textarea?: boolean;
  cols?: number;
  backgroundColor?: string;
  tooltip?: React.ReactNode;
}

const TextInput: React.FunctionComponent<Props> = ({
  value,
  onValueChange,
  onBlur,
  onFocus,
  label,
  error,
  password,
  inputMode,
  enterKeyHint,
  onSubmitEditing,
  sublabel,
  disabled,
  copyable,
  shareable,
  shareText,
  shareTitle,
  shareValue,
  icon,
  textarea,
  cols,
  backgroundColor,
  tooltip,
}) => {
  const id = useId();
  const [showPassword, setShowPassword] = useState(false);
  const canShare =
    shareable === 'url' && ShareUtils.canShareUrl(value, shareText, shareTitle);
  return (
    <div className={cls.container} id={id}>
      {label && (
        <label>
          <FormattedMessage {...label} />
          {tooltip && <Icon name={'info'} size={16} />}
        </label>
      )}
      <div className={cls.inputContainer}>
        {textarea ? (
          <textarea
            style={backgroundColor ? {backgroundColor} : undefined}
            cols={cols}
            className={
              (icon && cls.withIcon) +
              ' ' +
              ((copyable || shareable || password) && cls.withRightIcon)
            }
            disabled={disabled}
            inputMode={inputMode}
            value={value}
            onInput={
              onValueChange
                ? (event) => onValueChange(event.currentTarget.value)
                : undefined
            }
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyUp={(event) => {
              if (event.key === 'Enter' && onSubmitEditing) {
                onSubmitEditing();
              }
            }}
          />
        ) : (
          <input
            style={backgroundColor ? {backgroundColor} : undefined}
            className={
              (icon && cls.withIcon) +
              ' ' +
              ((copyable || shareable || password) && cls.withRightIcon) +
              ' ' +
              (password === 'fake' && !showPassword && cls.fakePassword)
            }
            disabled={disabled}
            type={
              password === 'fake' || !password || showPassword
                ? 'text'
                : 'password'
            }
            onCopy={(e) => {
              if (password === 'fake') {
                e.preventDefault();
              }
            }}
            onCut={(e) => {
              if (password === 'fake') {
                e.preventDefault();
              }
            }}
            onSelect={(e) => {
              if (password === 'fake') {
                e.preventDefault();
              }
            }}
            inputMode={inputMode}
            enterKeyHint={enterKeyHint}
            value={value}
            onInput={
              onValueChange
                ? (event) => onValueChange(event.currentTarget.value)
                : undefined
            }
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyUp={(event) => {
              if (event.key === 'Enter' && onSubmitEditing) {
                onSubmitEditing();
              }
            }}
          />
        )}
        {icon && <Icon name={icon} size={24} className={cls.icon} />}
        {password && (
          <Icon
            name={'eye-closed'}
            size={24}
            className={cls.passwordEye}
            clickable
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
        {shareable && (
          <Icon
            name={'share-dark'}
            size={24}
            className={cls.copy}
            clickable
            onClick={() =>
              ShareUtils.shareUrl(shareValue ?? value, shareText, shareTitle)
            }
          />
        )}
        {(!shareable || !canShare) && copyable && (
          <Icon
            name={'copy-dark'}
            size={24}
            className={cls.copy}
            clickable
            onClick={() => ClipboardUtils.write(value)}
          />
        )}
      </div>
      {error ? (
        <p className={cls.sublabel + ' ' + cls.error}>{error}</p>
      ) : (
        sublabel && (
          <p className={cls.sublabel}>
            <FormattedMessage {...sublabel} />
          </p>
        )
      )}
      {tooltip && <Tooltip target={id}>{tooltip}</Tooltip>}
    </div>
  );
};

export default TextInput;
