import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/apikeys/select-search.png';
import v2 from '../../../../../assets/onboarding/bybit/apikeys/select-api-management.png';

const EnterApiManagementFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.EnterApiManagementFragment.title',
        defaultMessage:
          'Select search, enter <h>API</h>, then select <h>API Management</h> option.',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default EnterApiManagementFragment;
