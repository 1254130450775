import DesktopCompatibilityStore from '../DesktopCompatibilityStore';
import {
  action,
  autorun,
  computed,
  IReactionDisposer,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import Logger from '../../manager/Logger';
import DesktopCompatibilityStorage from '../../storage/DesktopCompatibilityStorage';

const ASPECT_RATIO_THRESHOLD = 1.1;

class RealDesktopCompatibilityStore implements DesktopCompatibilityStore {
  private static reaction: IReactionDisposer | undefined;

  canUseCompatibility = false;
  compatibilityModeEnabled = true;

  constructor() {
    const enabled = DesktopCompatibilityStorage.get().getCompatibilityEnabled();
    if (enabled !== undefined) {
      this.compatibilityModeEnabled = enabled;
    }

    makeAutoObservable(this, {
      canUseCompatibility: observable,
      compatibilityModeEnabled: observable,
      shouldUseCompatibility: computed,
      setCompatibilityModeEnabled: action.bound,
    });
    runInAction(() => {
      const aspectRatio = window.innerWidth / window.innerHeight;
      if (aspectRatio >= ASPECT_RATIO_THRESHOLD) {
        this.canUseCompatibility = true;
        Logger.debug(
          'RealDesktopCompatibilityStorage',
          'ctor',
          'Desktop compat mode advised',
        );
      }
    });
    window.addEventListener('resize', () => {
      runInAction(() => {
        const aspectRatio = window.innerWidth / window.innerHeight;
        if (
          aspectRatio < ASPECT_RATIO_THRESHOLD &&
          this.shouldUseCompatibility
        ) {
          this.canUseCompatibility = false;
          Logger.debug(
            'RealDesktopCompatibilityStorage',
            'ctor',
            'Desktop compat mode disabled',
          );
        }
      });
    });

    if (RealDesktopCompatibilityStore.reaction) {
      RealDesktopCompatibilityStore.reaction();
    }
    RealDesktopCompatibilityStore.reaction = autorun(() => {
      if (this.shouldUseCompatibility) {
        document.body.dataset.compat = 'desktop';
      } else {
        delete document.body.dataset.compat;
      }
    });
  }

  get shouldUseCompatibility(): boolean {
    return this.canUseCompatibility && this.compatibilityModeEnabled;
  }

  setCompatibilityModeEnabled(enabled: boolean) {
    this.compatibilityModeEnabled = enabled;
    DesktopCompatibilityStorage.get().setCompatibilityEnabled(enabled);
  }
}

export default RealDesktopCompatibilityStore;
