import {
  Market,
  MarketAccessEnum,
  MarketList,
  MarketNameEnum,
  RobotCreateRequestMarketEnum,
  RobotInfoMarketEnum,
  RobotPair,
  RobotSummaryMarketEnum,
} from '../api';
import {MessageDescriptor} from 'react-intl';
import {IconName} from '../component/ui/Icon';

class MarketUtils {
  static MARKETS: MarketNameEnum[] = [
    MarketNameEnum.Binance,
    MarketNameEnum.Bybit,
    MarketNameEnum.Huobi,
  ];

  static formatMarket(
    market:
      | RobotInfoMarketEnum
      | RobotSummaryMarketEnum
      | RobotCreateRequestMarketEnum
      | MarketNameEnum,
  ): MessageDescriptor {
    switch (market) {
      case 'BINANCE':
        return {
          id: 'common.market.binance',
          defaultMessage: 'Binance',
        };
      case 'HUOBI':
        return {
          id: 'common.market.huobi',
          defaultMessage: 'HTX',
        };
      case 'BYBIT':
        return {
          id: 'common.market.bybit',
          defaultMessage: 'Bybit',
        };
    }
  }

  static nameToCreateRequest(
    market: MarketNameEnum,
  ): RobotCreateRequestMarketEnum {
    switch (market) {
      case MarketNameEnum.Huobi:
        return RobotCreateRequestMarketEnum.Huobi;
      case MarketNameEnum.Binance:
        return RobotCreateRequestMarketEnum.Binance;
      case MarketNameEnum.Bybit:
        return RobotCreateRequestMarketEnum.Bybit;
    }
  }

  static getIcon(market: MarketNameEnum): IconName {
    switch (market) {
      case MarketNameEnum.Huobi:
        return 'huobi';
      case MarketNameEnum.Binance:
        return 'binance';
      case MarketNameEnum.Bybit:
        return 'bybit';
    }
  }

  static getDefaultMarket(markets: MarketList): Market {
    const firstForEveryone = markets.markets.find(
      (v) => v.access === MarketAccessEnum.Everyone,
    );
    if (firstForEveryone) {
      return firstForEveryone;
    } else {
      return markets.markets[0];
    }
  }

  static getDefaultPair(market: Market): RobotPair {
    const pair = market.pairs.find(
      (value) => value.pair === market.defaultPair,
    );
    if (pair) {
      return {
        money: pair.money,
        asset: pair.asset,
      };
    } else {
      return {
        money: market.pairs[0].money,
        asset: market.pairs[0].asset,
      };
    }
  }
}

export default MarketUtils;
