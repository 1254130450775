import React from 'react';
import {DISTRIBUTION_CHART_SORTMAP} from '../config';

class CurrencyUtils {
  static formatBalance(parts: Intl.NumberFormatPart[]): React.ReactElement {
    return (
      <>
        {parts.map((value) => {
          switch (value.type) {
            case 'minusSign':
              return '-';
            case 'decimal':
              return ',';
            case 'integer':
              return value.value;
            case 'group':
              return '';
            case 'fraction':
              return value.value;
            default:
              return undefined;
          }
        })}
      </>
    );
  }

  static formatAmount(parts: Intl.NumberFormatPart[]): React.ReactElement {
    return (
      <>
        {parts.map((value) => {
          switch (value.type) {
            case 'minusSign':
              return '-';
            case 'plusSign':
              return '+';
            case 'decimal':
              return ',';
            case 'integer':
              return value.value;
            case 'group':
              return '';
            case 'fraction':
              return value.value;
            default:
              return undefined;
          }
        })}
      </>
    );
  }

  static compareByDistributionChartColor(coin: string, other: string): number {
    const coinIdx = DISTRIBUTION_CHART_SORTMAP.get(coin);
    const otherIdx = DISTRIBUTION_CHART_SORTMAP.get(other);
    if (coinIdx === undefined && otherIdx === undefined) {
      return 0;
    } else if (coinIdx === undefined) {
      return 1;
    } else if (otherIdx === undefined) {
      return -1;
    } else {
      return coinIdx - otherIdx;
    }
  }

  static getDisplayMaxFractionalDigits(coin: string) {
    if (coin.toUpperCase() === 'USDT' || coin.toUpperCase() == 'TUSD') {
      return 2;
    } else if (coin.toUpperCase() === 'TRX') {
      return 2;
    } else {
      return 8;
    }
  }

  static getDisplayMinFractionalDigits(coin: string) {
    if (coin.toUpperCase() === 'USDT' || coin.toUpperCase() == 'TUSD') {
      return 0;
    } else if (coin.toUpperCase() === 'TRX') {
      return 0;
    } else {
      return 8;
    }
  }
}

export default CurrencyUtils;
