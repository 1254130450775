import {FieldConfig, useField, useFormikContext} from 'formik';
import {memo} from 'react';
import CodeInput, {Props as InputProps} from '../../input/CodeInput';

interface Props extends Omit<InputProps, 'onChange'> {
  submitForm?: boolean;
}

function CodeField({
  ...props
}: Props & FieldConfig<string>): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [, meta, helpers] = useField<string>(props);
  const context = useFormikContext();
  return (
    <CodeInput
      {...props}
      error={meta.error}
      onChange={(text) => helpers.setValue(text)}
      onSubmitEditing={
        props.submitForm ? () => void context.submitForm() : undefined
      }
    />
  );
}

export default memo(CodeField);
