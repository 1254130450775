import React, {FC} from 'react';
import Icon from '../../graphics/Icon';
import Typography from '../../typo/Typography';
import cls from './style.module.scss';

const TransactionHistoryHeader: FC = () => (
  <div className={cls.container}>
    <Icon name={'clock'} size={16} tint={'gray500'} />
    <Typography
      className={cls.text}
      text={{
        id: 'components.TransactionHistoryHeader.header',
        defaultMessage: 'Transaction history',
      }}
    />
  </div>
);

export default TransactionHistoryHeader;
