import {KeyboardEventHandler, useCallback} from 'react';

export default function useHandleSubmitEditing<T>(
  handler?: () => void,
): KeyboardEventHandler<T> {
  return useCallback(
    (e) => {
      if (e.key === 'Enter' && handler) {
        handler();
      }
    },
    [handler],
  );
}
