import StorageBackend from './backend/StorageBackend';
import LocalStorageBackend from './backend/LocalStorageBackend';
import StorageManager from './StorageManager';
import Logger from '../manager/Logger';

class DesktopCompatibilityStorage {
  private static instance: DesktopCompatibilityStorage;
  private readonly backend: StorageBackend = new LocalStorageBackend(
    'desktop_compat',
  );

  static get(): DesktopCompatibilityStorage {
    if (!this.instance) {
      this.instance = new DesktopCompatibilityStorage();
      StorageManager.addStorage('desktop_compat', this.instance);
      Logger.debug(
        'DesktopCompatibilityStorage',
        'start',
        'DesktopCompatibilityStorage started',
      );
    }
    return this.instance;
  }

  getCompatibilityEnabled(): boolean | undefined {
    const result = this.backend.getString('enabled');
    if (result === undefined) {
      return undefined;
    }
    return result === 'true';
  }

  setCompatibilityEnabled(enabled: boolean) {
    this.backend.set('enabled', enabled ? 'true' : 'false');
  }

  onUserLogout() {}
}

export default DesktopCompatibilityStorage;
