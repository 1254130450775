import React from 'react';
import {inject, observer} from 'mobx-react';
import {RobotStore} from '../../store/RobotStore';
import {useNavigate, useParams} from 'react-router-dom';
import {Formik, FormikProps} from 'formik';
import {useIntl} from 'react-intl';
import ErrorUtils from '../../utils/ErrorUtils';
import Scaffolding from '../../component/container/Scaffolding';
import Form from '../../component/container/Form';
import TextField from '../../component/form/TextField';

interface Props {
  robot: RobotStore;
}

interface RobotNameForm {
  name: string;
}

const ValuesForm: React.FunctionComponent<FormikProps<RobotNameForm>> = () => (
  <Form
    submitLabel={{
      id: 'screen.EditRobotNameScreen.saveName',
      defaultMessage: 'Save name',
    }}
  >
    <TextField
      header={{
        id: 'screen.EditRobotNameScreen.field.name',
        defaultMessage: 'Bot name',
      }}
      name={'name'}
      inputMode={'text'}
      enterKeyHint={'send'}
      submitForm
    />
  </Form>
);

const EditRobotNameScreen: React.FunctionComponent<Props> = ({robot}) => {
  const params = useParams();
  const intl = useIntl();
  const nav = useNavigate();
  return (
    <Scaffolding
      header={{
        id: 'nav.changeRobotNameScreen',
        defaultMessage: 'Change robot name',
      }}
    >
      <Formik
        validateOnMount={false}
        validateOnChange={false}
        initialValues={{
          name: robot.getName(params.robot as string) ?? '',
        }}
        validate={(values) => {
          const ret: Partial<Record<keyof RobotNameForm, string>> = {};
          if (values.name === undefined || values.name.length === 0) {
            ret.name = intl.formatMessage({
              id: 'form.fieldEmpty',
              defaultMessage: 'Field cannot be empty',
            });
          }
          if (values.name !== undefined && values.name.trim().length > 30) {
            ret.name = intl.formatMessage(
              {
                id: 'form.maxChars',
                defaultMessage:
                  'Field cannot contain more than {max} characters',
              },
              {max: 30},
            );
          }
          Object.keys(ret).forEach((key) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ret[key] === undefined ? delete ret[key] : {},
          );
          return ret;
        }}
        onSubmit={(values, bag) => {
          return robot
            .setName(params.robot as string, values.name.trim())
            .then(() => nav(-1))
            .catch((reason: Error) => {
              ErrorUtils.setFormikError(bag, reason);
              throw reason;
            });
        }}
      >
        {(form) => <ValuesForm {...form} />}
      </Formik>
    </Scaffolding>
  );
};

export default inject('robot')(
  observer(EditRobotNameScreen),
) as unknown as React.FC;
