import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/p2p/open-p2p-1.png';
import v2 from '../../../../../assets/onboarding/bybit/p2p/open-p2p-2.png';

const OpenP2PFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.OpenP2PFragment.title',
        defaultMessage: 'On main page, press <h>Buy crypto</h> → <h>P2P</h>',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default OpenP2PFragment;
