import Typography, {HText} from '../../typo/Typography';
import {CSSProperties, FC} from 'react';
import Icon from '../../graphics/Icon';
import cls from './style.module.scss';

interface Props {
  text: HText;
  current: number;
  max: number;
  onNext?: () => void;
  onPrev?: () => void;
  onClose?: () => void;
  position: 'top' | 'bottom';
  nextText?: HText;
  className?: string;
  style?: CSSProperties;
}

const GuidePopup: FC<Props> = ({
  text,
  current,
  max,
  onPrev,
  onClose,
  position,
  onNext,
  className,
  nextText,
  style,
}) => (
  <div className={cls.container + ' ' + className} style={style}>
    {position === 'top' && (
      <svg
        width="64"
        height="11"
        viewBox="0 0 64 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 1.39876e-06L26.2426 5.75736C23.5261 8.47388 19.8417 10 16 10L16 11L48 11L48 10C44.1583 10 40.4739 8.47388 37.7574 5.75737L32 1.39876e-06Z"
          fill="#3772FF"
        />
      </svg>
    )}
    <div className={cls.content}>
      <div className={cls.first}>
        <Typography text={text} className={cls.text} />
        <Icon
          name={'close'}
          size={24}
          tint={'white'}
          onClick={onClose}
          clickable
        />
      </div>
      <div className={cls.second}>
        <div className={cls.pos}>
          <span className={cls.currentPos}>{current}</span>/ {max}
        </div>
        <div className={cls.buttons}>
          <div
            className={cls.button + ' ' + (!onPrev && cls.disabled)}
            onClick={onPrev}
          >
            <Typography text={{id: 'common.prev', defaultMessage: 'Prev'}} />
          </div>
          <div
            className={cls.button + ' ' + (!onNext && cls.disabled)}
            onClick={onNext}
          >
            <Typography
              text={nextText ?? {id: 'common.next', defaultMessage: 'Next'}}
            />
          </div>
        </div>
      </div>
    </div>
    {position === 'bottom' && (
      <svg
        width="64"
        height="11"
        viewBox="0 0 64 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 11L26.2426 5.24264C23.5261 2.52612 19.8417 0.999999 16 0.999999L16 -8.9018e-07L48 0L48 1C44.1583 1 40.4739 2.52612 37.7574 5.24263L32 11Z"
          fill="#3772FF"
        />
      </svg>
    )}
  </div>
);

export default GuidePopup;
