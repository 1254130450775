import React from 'react';

interface Props {
  size?: number;
  flex?: number;
}

const Spacer: React.FunctionComponent<Props> = ({size, flex}) => (
  <span
    style={{
      height: size !== undefined ? `${size}rem` : undefined,
      maxHeight: size !== undefined ? `${size}rem` : undefined,
      flex: flex !== undefined ? flex.toString(10) : flex,
    }}
  />
);

export default Spacer;
