import React from 'react';
import {inject, observer} from 'mobx-react';
import {RobotStore} from '../../store/RobotStore';
import {useNavigate, useParams} from 'react-router-dom';
import {Formik, FormikProps} from 'formik';
import {useIntl} from 'react-intl';
import ErrorUtils from '../../utils/ErrorUtils';
import Scaffolding from '../../component/container/Scaffolding';
import Form from '../../component/container/Form';
import TextField from '../../component/form/TextField';
import Spacer from '../../component/design/utils/Spacer';
import RobotUtils from '../../utils/RobotUtils';

interface Props {
  robot: RobotStore;
}

interface RobotKeysForm {
  apiKey: string;
  apiSecret: string;
}

const ValuesForm: React.FunctionComponent<FormikProps<RobotKeysForm>> = () => (
  <Form
    submitLabel={{
      id: 'screen.EditRobotKeysScreen.saveKeys',
      defaultMessage: 'Save keys and restart',
    }}
  >
    <TextField
      name={'apiKey'}
      header={{
        id: 'form.apiKey',
        defaultMessage: 'Api key',
      }}
      password={'fake'}
    />
    <Spacer size={1} />
    <TextField
      name={'apiSecret'}
      header={{
        id: 'form.apiSecret',
        defaultMessage: 'Api secret',
      }}
      password={'fake'}
    />
  </Form>
);

const EditRobotKeysScreen: React.FunctionComponent<Props> = ({robot}) => {
  const params = useParams();
  const intl = useIntl();
  const nav = useNavigate();
  return (
    <Scaffolding
      header={{
        id: 'nav.changeRobotKeysScreen',
        defaultMessage: 'Change robot keys',
      }}
    >
      <Formik
        validateOnMount={false}
        validateOnChange={false}
        initialValues={{
          apiKey: '',
          apiSecret: '',
        }}
        validate={(values) => RobotUtils.checkApiKeysOnForm(intl, values)}
        onSubmit={(values, bag) => {
          return robot
            .setApiKeys(params.robot as string, values.apiKey, values.apiSecret)
            .then(() => nav(-1))
            .catch((reason: Error) => {
              ErrorUtils.setFormikError(bag, reason);
              throw reason;
            });
        }}
      >
        {(form) => <ValuesForm {...form} />}
      </Formik>
    </Scaffolding>
  );
};

export default inject('robot')(
  observer(EditRobotKeysScreen),
) as unknown as React.FC;
