import {IntlShape} from 'react-intl';

interface ApiKeysForm {
  apiKey: string;
  apiSecret: string;
}

class RobotUtils {
  static checkApiKeysOnForm(
    intl: IntlShape,
    values: ApiKeysForm,
  ): Partial<Record<keyof ApiKeysForm, string>> {
    const ret: Partial<Record<keyof ApiKeysForm, string>> = {};
    if (values.apiSecret === '') {
      ret.apiSecret = intl.formatMessage({
        id: 'form.apiSecretEmpty',
        defaultMessage: 'API secret cannot be empty',
      });
    }
    if (values.apiKey === '') {
      ret.apiKey = intl.formatMessage({
        id: 'form.apiKeyEmpty',
        defaultMessage: 'API key cannot be empty',
      });
    }
    if (values.apiKey === values.apiSecret) {
      ret.apiKey = intl.formatMessage({
        id: 'form.apiKeysMatch',
        defaultMessage: 'API key cannot match API secret',
      });
    }
    return ret;
  }
}

export default RobotUtils;
