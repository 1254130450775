import React, {FC, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import ExchangeConnectionStatusViewModel from '../../../viewmodel/exchange-connection/ExchangeConnectionStatusViewModel';
import AsyncStateWrapper from '../../../component/ui/AsyncStateWrapper';
import Illustration from '../../../component/design/graphics/Illustration';
import ExchangeConnectionUtils from '../../../utils/ExchangeConnectionUtils';
import Status from '../../../component/design/container/Status';
import IntlUtils from '../../../utils/IntlUtils';
import FlatButtonContainer from '../../../component/design/container/FlatButtonContainer';
import FlatButton from '../../../component/design/button/FlatButton';
import ErrorUtils from '../../../utils/ErrorUtils';
import Scaffolding from '../../../component/container/Scaffolding';

export type ExchangeConnectionStatusScreenMode = 'onboarding';

const ExchangeConnectionStatusScreen: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [query] = useSearchParams();
  const mode = query.get('mode') as ExchangeConnectionStatusScreenMode;
  const vm = useMemo(
    () =>
      new ExchangeConnectionStatusViewModel(
        params.id ?? '',
        mode,
        (robotId) => {
          if (robotId) {
            navigate('/app/dashboard/robot/' + robotId + '?guide=true');
          } else {
            navigate(-1);
          }
        },
      ),
    [params.id],
  );
  useEffect(() => {
    vm.init();
    return () => vm.stopUpdate();
  }, [vm]);

  const createRobotRequest = vm.createRobotRequest;
  return (
    <Scaffolding>
      <AsyncStateWrapper state={vm.request}>
        {(req) => {
          const state = ExchangeConnectionUtils.simplifyState(req.state);
          return (
            <Status
              illustration={
                state === 'loading' ||
                createRobotRequest.status === 'loading' ? (
                  <Illustration.Progress />
                ) : state === 'error' ||
                  createRobotRequest.status === 'error' ? (
                  <Illustration.Failure />
                ) : (
                  <Illustration.Success />
                )
              }
              title={IntlUtils.exchangeConnectionTitle(state)}
              description={
                createRobotRequest.status === 'error'
                  ? ErrorUtils.getMessage(createRobotRequest.error)
                  : state === 'error'
                  ? req.error?.detail ?? req.error?.title ?? 'Unknown error'
                  : IntlUtils.exchangeConnectionDescription(
                      createRobotRequest.status === 'loading'
                        ? 'creating-robot'
                        : state,
                    )
              }
              footer={
                state === 'error' ? (
                  <FlatButtonContainer>
                    <FlatButton
                      type={'gray'}
                      icon={'arrow-left'}
                      onClick={() => navigate(-2)}
                    />
                    <FlatButton
                      type={'primary'}
                      text={{
                        id: 'common.retryConnection',
                        defaultMessage: 'Retry connection',
                      }}
                      onClick={() => vm.refresh()}
                      flex={1}
                    />
                  </FlatButtonContainer>
                ) : createRobotRequest.status === 'error' ? (
                  <FlatButtonContainer>
                    <FlatButton
                      type={'gray'}
                      text={{
                        id: 'common.skip',
                        defaultMessage: 'Skip',
                      }}
                      onClick={() => navigate('/app/dashboard')}
                    />
                    <FlatButton
                      type={'primary'}
                      text={{
                        id: 'common.retry',
                        defaultMessage: 'Retry',
                      }}
                      onClick={() => vm.retryRobotCreate()}
                      flex={1}
                    />
                  </FlatButtonContainer>
                ) : undefined
              }
            />
          );
        }}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default observer(ExchangeConnectionStatusScreen);
