import {RobotTradeDetailsStateEnum} from '../../../../api';
import {FC} from 'react';
import Icon from '../../graphics/Icon';
import Typography from '../../typo/Typography';
import RobotTradeUtils from '../../../../utils/RobotTradeUtils';
import cls from './style.module.scss';

interface Props {
  state: RobotTradeDetailsStateEnum;
}

const index: FC<Props> = ({state}) => (
  <span className={cls.container}>
    <Icon
      name={
        state === RobotTradeDetailsStateEnum.Completed
          ? 'checkmark'
          : state === RobotTradeDetailsStateEnum.SellError
          ? 'close'
          : 'clock-mini'
      }
      size={24}
      tint={
        state === RobotTradeDetailsStateEnum.Completed
          ? 'green'
          : state === RobotTradeDetailsStateEnum.SellError
          ? 'red'
          : 'default'
      }
    />
    <span
      className={
        cls.text +
        ' ' +
        (state === RobotTradeDetailsStateEnum.Completed && cls.success) +
        ' ' +
        (state === RobotTradeDetailsStateEnum.SellError && cls.error)
      }
    >
      <Typography text={RobotTradeUtils.formatState(state)} />
    </span>
  </span>
);

export default index;
