import {Credentials, EmailVerificationStore} from '../EmailVerificationStore';
import AESEncryptedStorageBackendDecorator from '../../storage/backend/AESEncryptedStorageBackendDecorator';
import LocalStorageBackend from '../../storage/backend/LocalStorageBackend';

class RealEmailVerificationStore implements EmailVerificationStore {
  private readonly storage = new AESEncryptedStorageBackendDecorator(
    new LocalStorageBackend('creds'),
    'Z2g9ULG6i#Bdq$XTphnw2L8P',
  );

  putCredentials(credentials: Credentials): void {
    this.storage.set('credentials', JSON.stringify(credentials));
  }

  resetCredentials(): void {
    this.storage.delete('credentials');
  }

  takeCredentials(): Credentials | undefined {
    const creds = this.storage.getString('credentials');
    if (creds) {
      return JSON.parse(creds) as Credentials;
    } else {
      return undefined;
    }
  }
}

export default RealEmailVerificationStore;
