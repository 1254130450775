import {makeAutoObservable, observable, runInAction} from 'mobx';
import {AcquisitionRequest, AcquisitionRequestStatusEnum} from '../../api';
import {notLoaded, sleep, wrapRefreshablePromise} from '../../utils';
import stores from '../../store';

const CHECK_INTERVAL = 500;

class SellRcoStatusViewModel {
  request: RefreshableAsyncState<AcquisitionRequest> = notLoaded();
  private running = true;

  constructor(private readonly id: string) {
    makeAutoObservable(this, {
      request: observable,
    });
  }

  init() {
    (async () => {
      while (this.running) {
        wrapRefreshablePromise(
          stores.wallet.getAcquisitionRequest(this.id),
          this.request,
          (v) =>
            runInAction(() => {
              this.request = v;
              if (v.status === 'success' || v.status === 'refreshing-success') {
                if (
                  v.result.status ===
                    AcquisitionRequestStatusEnum.FailedNotFound ||
                  v.result.status === AcquisitionRequestStatusEnum.Success
                ) {
                  this.running = false;
                }
                if (v.result.status === AcquisitionRequestStatusEnum.Success) {
                  stores.wallet.loadWalletInfo();
                }
              }
            }),
        ).catch(() => {});
        await sleep(CHECK_INTERVAL);
      }
    })();
  }

  stopUpdate() {
    this.running = false;
  }
}

export default SellRcoStatusViewModel;
