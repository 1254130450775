import {FC, ReactNode} from 'react';
import cls from './style.module.scss';

interface Props {
  first: string;
  second?: string;
  children?: ReactNode;
  overlayX?: string;
  overlayY?: string;
}

const SideBySideImage: FC<Props> = ({
  first,
  second,
  children,
  overlayY,
  overlayX,
}) => (
  <div className={cls.container}>
    <img src={first} alt={''} />
    {second ? <img src={second} alt={''} /> : <div />}
    <div
      className={cls.overlay}
      style={{
        paddingTop: overlayY,
        paddingLeft: overlayX,
        width: `calc(100% + ${overlayX})`,
        height: `calc(100% + ${overlayY})`,
      }}
    >
      {children}
    </div>
  </div>
);

export default SideBySideImage;
