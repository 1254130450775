import React, {useMemo} from 'react';
import {inject, observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import {UserStore} from '../../../store/UserStore';
import {ifSuccessful} from '../../../utils';
import TeamViewModel from '../../../viewmodel/TeamViewModel';
import Scaffolding from '../../../component/container/Scaffolding';
import ProfilePhoto from '../../../component/ui/ProfilePhoto';
import AsyncStateWrapper from '../../../component/ui/AsyncStateWrapper';
import TeamCard from '../../../component/ui/TeamCard';
import cls from './style.module.scss';
import {FormattedMessage} from 'react-intl';
import IntersectionTrigger from '../../../component/ui/IntersectionTrigger';
import TeamTreeItem from '../../../component/ui/TeamTreeItem';
import Breadcrumbs from '../../../component/ui/Breadcrumbs';
import ReferralUtils from '../../../utils/ReferralUtils';

interface Props {
  user: UserStore;
}

const TeamScreen: React.FunctionComponent<Props> = ({user}) => {
  const navigate = useNavigate();
  const vm = useMemo(
    () =>
      new TeamViewModel(
        ifSuccessful(user.user, (t) => t.id, -1),
        ifSuccessful(user.user, (t) => t.name ?? t.email, ''),
      ),
    [user.user],
  );

  return (
    <Scaffolding
      header={{id: 'nav.team', defaultMessage: 'My business'}}
      overflow
      actions={[
        {
          icon: 'search',
          onClick: () => navigate('/app/team/search'),
        },
        {
          icon: 'qr-code',
          onClick: () => navigate('/app/invite'),
        },
      ]}
    >
      <Breadcrumbs items={vm.history} onClick={vm.goBack} lastElementDisabled />
      <AsyncStateWrapper state={vm.currentPage}>
        {(data) => (
          <>
            <span className={cls.first}>
              <ProfilePhoto
                src={data.userPhotoUrl}
                className={cls.photo}
                badge={ReferralUtils.getLevelBadge(data.myLevel)}
                size={80}
              />
              <TeamCard team={data.teamCount} income={data.totalIncome} />
            </span>
            {vm.tree.length === 0 && (
              <span className={cls.notFound}>
                <span className={'caption'}>
                  <FormattedMessage
                    id={'screens.TeamScreen.noTeam'}
                    defaultMessage={'No team members found'}
                  />
                </span>
              </span>
            )}
            {vm.tree.map((item, idx) => (
              <React.Fragment key={item.id}>
                {idx === vm.tree.length - 5 && (
                  <IntersectionTrigger onIntersected={vm.nextPage} />
                )}
                <TeamTreeItem {...item} onUserClick={() => vm.goTo(item.id)} />
              </React.Fragment>
            ))}
          </>
        )}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default inject('user')(observer(TeamScreen)) as unknown as React.FC;
