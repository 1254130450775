import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import Icon from '../../graphics/Icon';
import cls from './style.module.scss';
import Badge from '../Badge';

interface Props {
  enabled: boolean;
  name: HText;
  adviceLevel?: 'warning' | 'error';
  advice?: HText;
}

const Permission: FC<Props> = ({enabled, name, advice, adviceLevel}) => (
  <div className={cls.permission}>
    <Icon
      name={enabled ? 'checkmark' : 'close'}
      size={24}
      tint={enabled ? 'green' : 'default'}
    />
    <Typography
      text={name}
      className={cls.name + ' ' + (enabled && cls.enabled)}
    />
    {adviceLevel && advice && (
      <Badge color={adviceLevel === 'error' ? 'red' : 'yellow'}>
        <Icon
          name={'attention'}
          size={16}
          tint={adviceLevel === 'error' ? 'red' : 'yellow'}
        />
        <Typography
          text={advice ?? ''}
          className={
            adviceLevel === 'error' ? cls.adviceerror : cls.advicewarning
          }
        />
      </Badge>
    )}
  </div>
);

export default Permission;
