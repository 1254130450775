import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/apikeys/setup-2fa.png';

const SetupGAuthStartFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.SetupGAuthStartFragment.title',
        defaultMessage:
          'If popup appears, select <h>Set Up</h>. If not, skip 2FA setup steps',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} />
  </>
);

export default SetupGAuthStartFragment;
