import {computed, makeAutoObservable, observable, runInAction} from 'mobx';
import {NotificationDetails} from '../api';
import {ifSuccessful, notLoaded, wrapPromise} from '../utils';
import stores from '../store';
import NotificationUtils, {
  NotificationButton,
  NotificationContent,
} from '../utils/NotificationUtils';
import {MessageDescriptor} from 'react-intl';

class NotificationDetailsViewModel {
  details: AsyncState<NotificationDetails> = notLoaded();

  constructor(readonly id: string) {
    makeAutoObservable(this, {
      details: observable,
      button: computed,
      footer: computed,
      header: computed,
      content: computed,
    });
    runInAction(() => {
      void wrapPromise(stores.notifications.getDetails(id), this.details, (v) =>
        runInAction(() => (this.details = v)),
      );
    });
  }

  get button(): NotificationButton | undefined {
    return ifSuccessful(
      this.details,
      (t) => NotificationUtils.getButton(t.type, t.data),
      undefined,
    );
  }

  get footer(): MessageDescriptor[] {
    return ifSuccessful(
      this.details,
      (t) => NotificationUtils.getFooter(t.type, t.data),
      [],
    );
  }

  get header(): MessageDescriptor {
    return ifSuccessful(
      this.details,
      (t) => NotificationUtils.getHeader(t.type),
      {id: 'empty', defaultMessage: ''},
    );
  }

  get content(): NotificationContent | undefined {
    return ifSuccessful(
      this.details,
      (t) => NotificationUtils.getContent(t.type, t.data),
      undefined,
    );
  }
}

export default NotificationDetailsViewModel;
