import {ProblemError} from '../api';
import {FormikContextType, FormikHelpers} from 'formik';

class Errors {
  static setFormikError<T>(bag: FormikHelpers<T>, error: Error) {
    bag.setFieldError('__PROMISE__', error as unknown as string);
  }

  static getFormikErrorMessage(
    ctx: FormikContextType<unknown>,
  ): string | undefined {
    const errors = ctx.errors as Record<string, Error>;
    const err = errors.__PROMISE__;
    if (err instanceof ProblemError) {
      return err.title;
    } else {
      return err?.message;
    }
  }

  static getTitle(error: Error): string {
    if (error instanceof ProblemError) {
      return error.title;
    } else {
      return '';
    }
  }

  static getDetail(error: Error): string {
    if (error instanceof ProblemError) {
      return error.detail;
    } else {
      return '';
    }
  }

  static getMessage(error: Error): string {
    if (error instanceof ProblemError) {
      return error.detail ?? error.title;
    } else {
      return error.message;
    }
  }
}

export default Errors;
