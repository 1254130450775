import {WithdrawalRequestStatusEnum} from '../api';

type SimpleStatus = 'loading' | 'error' | 'success';

export default class WithdrawalUtils {
  static simplifyStatus(status: WithdrawalRequestStatusEnum): SimpleStatus {
    switch (status) {
      case WithdrawalRequestStatusEnum.Completed:
        return 'success';
      case WithdrawalRequestStatusEnum.Denied:
      case WithdrawalRequestStatusEnum.Expired:
      case WithdrawalRequestStatusEnum.Failed:
        return 'error';
      default:
        return 'loading';
    }
  }
}
