import React, {FC} from 'react';
import Typography, {HText} from '../Typography';
import {FormattedNumberParts} from 'react-intl';
import CurrencyUtils from '../../../../utils/CurrencyUtils';
import cls from './style.module.scss';
import Icon from '../../graphics/Icon';
import WithTooltip from '../../misc/Tooltip/wrapper';

interface Props {
  label: HText;
  amount: number;
  currency: string;
  tooltip?: React.ReactNode;
}

const BigAmountDisplay: FC<Props> = ({label, amount, currency, tooltip}) => (
  <div className={cls.container}>
    <WithTooltip tooltip={tooltip} className={cls.heading}>
      <Typography text={label} className={cls.label} />
      {tooltip && <Icon name={'question'} size={16} />}
    </WithTooltip>

    <span className={cls.number}>
      <FormattedNumberParts
        value={amount}
        minimumFractionDigits={CurrencyUtils.getDisplayMinFractionalDigits(
          currency,
        )}
        maximumFractionDigits={CurrencyUtils.getDisplayMaxFractionalDigits(
          currency,
        )}
      >
        {CurrencyUtils.formatBalance}
      </FormattedNumberParts>
    </span>
  </div>
);

export default BigAmountDisplay;
