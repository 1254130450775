import {FC} from 'react';
import cls from './style.module.scss';
import Skeleton from 'react-loading-skeleton';

const Loader: FC = () => (
  <div className={cls.container}>
    <div className={cls.icon}>
      <Skeleton width={40} height={40} borderRadius={24} />
    </div>
    <div className={cls.content}>
      <span className={cls.skeleton}>
        <Skeleton borderRadius={6} />
      </span>
      <span className={cls.skeleton}>
        <Skeleton borderRadius={6} />
      </span>
    </div>
    <div className={cls.balances}>
      <span className={cls.skeleton}>
        <Skeleton borderRadius={6} />
      </span>
      <span className={cls.skeleton}>
        <Skeleton borderRadius={6} />
      </span>
    </div>
  </div>
);

export default Loader;
