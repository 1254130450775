import React, {useId} from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import cls from '../TextInput/style.module.scss';
import Select from 'react-select';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

interface Props<T> {
  header?: MessageDescriptor;
  items: {value: T; label: string}[];
  onChange?: (item: T) => void;
  value?: T;
  tooltip?: React.ReactNode;
}

function SelectInput<T>({
  header,
  items,
  onChange,
  value,
  tooltip,
}: Props<T>): React.ReactElement {
  const id = useId();
  return (
    <div className={cls.container} id={id}>
      {header && (
        <label>
          <FormattedMessage {...header} />
          {tooltip && <Icon name={'info'} size={16} />}
        </label>
      )}
      <div className={cls.inputContainer}>
        <Select
          className={'basic-single'}
          classNamePrefix={'select'}
          options={items}
          onChange={(newValue) => {
            if (!newValue || !onChange) return;
            onChange(newValue.value);
          }}
          isClearable={false}
          isSearchable={false}
          defaultValue={items[0]}
          value={items.find((v) => v.value === value)}
        />
      </div>
      {tooltip && (
        <Tooltip target={id} place={'top'}>
          {tooltip}
        </Tooltip>
      )}
    </div>
  );
}

export default SelectInput;
