import Typography, {HText} from '../Typography';
import {FC} from 'react';
import cls from './style.module.scss';

interface Props {
  text: HText;
}

const Text: FC<Props> = ({text}) => (
  <span className={cls.text}>
    <Typography text={text} />
  </span>
);

export default Text;
