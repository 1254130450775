import {FC, useState} from 'react';
import Icon from '../../graphics/Icon';
import {format} from 'date-fns';
import DatePeriodPickerBottomSheet from '../../sheet/DatePeriodPickerBottomSheet';
import cls from './style.module.scss';
import {observer} from 'mobx-react';

interface Props {
  from: Date;
  to: Date;
  onChange: (from: Date, to: Date) => void;
}

function formatDate(date: Date): string {
  if (date.getFullYear() == new Date().getFullYear()) {
    return format(date, 'dd LLL');
  } else {
    return format(date, 'dd LLL yyyy');
  }
}

const PeriodFilter: FC<Props> = ({from, to, onChange}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div onClick={() => setOpen(true)} className={cls.container}>
        <Icon name={'calendar'} size={16} tint={'default'} />
        {formatDate(from)}
        {' - '}
        {formatDate(to)}
      </div>

      <DatePeriodPickerBottomSheet
        open={open}
        from={from}
        to={to}
        onSubmit={(f, t) => {
          onChange(f, t);
          setOpen(false);
        }}
        onDismiss={() => setOpen(false)}
      />
    </>
  );
};

export default observer(PeriodFilter);
