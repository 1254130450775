import {format, parseISO} from 'date-fns';

class DateUtils {
  static formatTimestamp(date: Date) {
    const now = new Date();
    if (
      now.getFullYear() == date.getFullYear() &&
      now.getMonth() == date.getMonth() &&
      now.getDate() == date.getDate()
    ) {
      return format(date, 'HH:mm');
    } else {
      return format(date, 'yyyy-MM-dd HH:mm');
    }
  }

  static formatFullTimestamp(date: Date | string): string {
    const ts = typeof date === 'string' ? DateUtils.parse(date) : date;
    return format(ts, 'yyyy-MM-dd HH:mm:ss');
  }

  static parse(date: string): Date {
    return parseISO(date);
  }
}

export default DateUtils;
