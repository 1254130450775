import {AcquisitionRequestStatusEnum} from '../api';

type SimpleStatus = 'loading' | 'error' | 'success';

export default class AcqusitionUtils {
  static simplifyStatus(status: AcquisitionRequestStatusEnum): SimpleStatus {
    switch (status) {
      case AcquisitionRequestStatusEnum.Success:
        return 'success';
      case AcquisitionRequestStatusEnum.FailedNotFound:
        return 'error';
      case AcquisitionRequestStatusEnum.Created:
        return 'loading';
      default:
        return 'loading';
    }
  }
}
