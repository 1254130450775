import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import setup1 from '../../../../../assets/onboarding/binance/first/setup-2fa-1.png';
import setup2 from '../../../../../assets/onboarding/binance/first/setup-2fa-2.png';

const Setup2FAFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.Setup2FAFragment.title',
        defaultMessage:
          'In Binance App, click on <h>Profile icon</h> at top left corner, then on <h>Security</h> tab, press <h>Authenticator App</h> option and follow the instructions.',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={setup1} second={setup2} />
  </>
);

export default Setup2FAFragment;
