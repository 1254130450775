import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/first/verify-identity-end.png';

const IdentityVerityEndFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.IdentityVerityEnd.title',
        defaultMessage:
          'Now, wait for verification to complete. It takes from 15 minutes to 48 hours',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} />
  </>
);

export default IdentityVerityEndFragment;
