import {RobotInfo} from '../../../../api';
import {FC} from 'react';
import KVCard from '../../container/KVCard';
import Amount from '../../typo/CoinBalance';
import DateTime from '../../typo/DateTime';

interface Props {
  robot?: RobotInfo;
  buyId: string;
  buyPrice: number;
  buyAmount: number;
  boughtTimestamp?: string;
}

const TradeBuyCard: FC<Props> = ({
  robot,
  boughtTimestamp,
  buyId,
  buyPrice,
  buyAmount,
}) => (
  <KVCard>
    <KVCard.Item
      name={{id: 'common.trade.buyId', defaultMessage: 'Buy ID'}}
      value={buyId}
      copyable
      ellipsis
    />
    <KVCard.Item
      name={{id: 'common.trade.buyPrice', defaultMessage: 'Buy price'}}
      value={
        <Amount
          amount={buyPrice}
          coin={robot?.pair?.money ?? ''}
          maxDigits={8}
        />
      }
    />
    <KVCard.Item
      name={{id: 'common.trade.buyAmount', defaultMessage: 'Buy amount'}}
      value={<Amount amount={buyAmount} coin={robot?.pair?.asset ?? ''} />}
    />
    <KVCard.Item
      name={{id: 'common.trade.buyCost', defaultMessage: 'Buy cost'}}
      value={
        <Amount amount={buyPrice * buyAmount} coin={robot?.pair?.money ?? ''} />
      }
    />
    <KVCard.Item
      name={{id: 'common.trade.boughtTimestamp', defaultMessage: 'Bought at'}}
      value={boughtTimestamp ? <DateTime value={boughtTimestamp} /> : undefined}
    />
  </KVCard>
);

export default TradeBuyCard;
