import {makeAutoObservable, observable, runInAction} from 'mobx';
import {RobotTradeDetails} from '../api';
import {notLoaded, wrapPromise} from '../utils';
import stores from '../store';

class RobotTradeDetailsViewModel {
  details: AsyncState<RobotTradeDetails> = notLoaded();

  constructor(readonly robot: string, readonly id: string) {
    makeAutoObservable(this, {
      details: observable,
    });
    runInAction(() => {
      void wrapPromise(
        stores.robot.getTradeDetails(robot, id),
        this.details,
        (v) => runInAction(() => (this.details = v)),
      );
    });
  }
}

export default RobotTradeDetailsViewModel;
