import {FC} from 'react';
import cls from './style.module.scss';

interface Props {
  image: string;
  alt?: string;
}

const Image: FC<Props> = ({image, alt}) => (
  <img src={image} alt={alt} className={cls.img} />
);

export default Image;
