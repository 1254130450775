import React, {useMemo} from 'react';
import {inject, observer} from 'mobx-react';
import {RobotStore} from '../../store/RobotStore';
import {useNavigate, useParams} from 'react-router-dom';
import {Formik, FormikProps} from 'formik';
import {FormattedMessage, IntlShape, useIntl} from 'react-intl';
import ErrorUtils from '../../utils/ErrorUtils';
import Scaffolding from '../../component/container/Scaffolding';
import Form from '../../component/container/Form';
import TextField from '../../component/form/TextField';
import Spacer from '../../component/design/utils/Spacer';
import EditRobotConfigViewModel from '../../viewmodel/EditRobotConfigViewModel';
import AsyncStateWrapper from '../../component/ui/AsyncStateWrapper';
import SelectField from '../../component/form/SelectField';
import {ifSuccessful} from '../../utils';

interface Props {
  robot: RobotStore;
}

interface RobotConfigForm {
  order: string;
  buy: string;
  sell: string;
  correction: string;
  sellTimeout: number;
}

const ValuesForm: React.FunctionComponent<
  FormikProps<RobotConfigForm>
> = () => {
  const intl = useIntl();
  return (
    <Form
      submitLabel={{
        id: 'screen.EditRobotConfigScreen.saveConfig',
        defaultMessage: 'Save configuration',
      }}
    >
      <TextField
        header={{
          id: 'screen.EditRobotConfigScreen.field.order',
          defaultMessage: 'Order',
        }}
        name={'order'}
        inputMode={'decimal'}
        enterKeyHint={'next'}
        tooltip={
          <FormattedMessage
            id={'screen.EditRobotConfigScreen.field.order.tooltip'}
            defaultMessage={
              'The robot will use this percent of free USDT in your spot wallet to create orders. If order volume is less than minimum market volume (currently 10 USDT), robot will use minimum market volume.'
            }
          />
        }
      />
      <Spacer size={1} />
      <TextField
        header={{
          id: 'screen.EditRobotConfigScreen.field.buy',
          defaultMessage: 'Buy',
        }}
        name={'buy'}
        inputMode={'decimal'}
        enterKeyHint={'next'}
        tooltip={
          <FormattedMessage
            id={'screen.EditRobotConfigScreen.field.buy.tooltip'}
            defaultMessage={
              'Buy price will be lower than current market price by this percent.'
            }
          />
        }
      />
      <Spacer size={1} />
      <TextField
        header={{
          id: 'screen.EditRobotConfigScreen.field.sell',
          defaultMessage: 'Sell',
        }}
        name={'sell'}
        inputMode={'decimal'}
        enterKeyHint={'next'}
        tooltip={
          <FormattedMessage
            id={'screen.EditRobotConfigScreen.field.sell.tooltip'}
            defaultMessage={
              'Sell price will be higher than buy price by this percent.'
            }
          />
        }
      />
      <Spacer size={1} />
      <TextField
        header={{
          id: 'screen.EditRobotConfigScreen.field.correction',
          defaultMessage: 'Correction for 10 minutes',
        }}
        name={'correction'}
        inputMode={'decimal'}
        enterKeyHint={'next'}
        tooltip={
          <FormattedMessage
            id={'screen.EditRobotConfigScreen.field.correction.tooltip'}
            defaultMessage={
              'Robot will stop if 10m correction (delta between minimum and maximum price) will be more than current value. <b>We do not recommend setting higher percent values than 5%.</b>'
            }
            values={{
              b: (chunks) => <b>{chunks}</b>,
              br: () => <br />,
            }}
          />
        }
      />
      <Spacer size={1} />
      <SelectField
        name={'sellTimeout'}
        header={{
          id: 'screens.EditRobotConfigScreen.field.buyTimeout',
          defaultMessage: 'Buy timeout',
        }}
        items={[1, 3, 6, 12, 24].map((hour) => ({
          value: hour,
          label: intl.formatMessage(
            {id: 'common.hoursPassed', defaultMessage: '{count} hours'},
            {count: hour},
          ),
        }))}
        tooltip={
          <FormattedMessage
            id={'screen.EditRobotConfigScreen.field.buyTimeout.tooltip'}
            defaultMessage={
              'time of buy trade expiry. The smaller it is, the faster bot will react on rising price.'
            }
          />
        }
      />
    </Form>
  );
};

function toFloat(value: string): number {
  return parseFloat(value.replace(',', '.'));
}

function checkValue(
  intl: IntlShape,
  value: string,
  max = 100,
): string | undefined {
  try {
    const f = parseFloat(value.replace(',', '.'));
    if (isNaN(f)) {
      return intl.formatMessage({
        id: 'form.notANumber',
        defaultMessage: 'Should be a number',
      });
    }
    if (f <= 0) {
      return intl.formatMessage({
        id: 'from.shouldBePositive',
        defaultMessage: 'Value should be positive',
      });
    }
    if (f > max) {
      return intl.formatMessage(
        {
          id: 'from.shouldBeLessThan',
          defaultMessage: 'Value should be less or equal {max}',
        },
        {max},
      );
    }
    return undefined;
  } catch (e: unknown) {
    return intl.formatMessage({
      id: 'form.notANumber',
      defaultMessage: 'Should be a number',
    });
  }
}

const EditRobotConfigScreen: React.FunctionComponent<Props> = ({robot}) => {
  const params = useParams();
  const intl = useIntl();
  const nav = useNavigate();
  const viewModel = useMemo(
    () => new EditRobotConfigViewModel(params.robot as string),
    [params.robot],
  );
  return (
    <Scaffolding
      header={{
        id: 'nav.changeRobotConfig',
        defaultMessage: 'Change robot config',
      }}
    >
      <AsyncStateWrapper state={viewModel.initialConfig}>
        {(config) => (
          <Formik
            validateOnMount={false}
            validateOnChange={false}
            initialValues={{
              order: (config.order / 100).toString(),
              buy: (config.buy / 100).toString(),
              sell: (config.sell / 100).toString(),
              correction: (config.correction10m / 100).toString(),
              sellTimeout: config.sellOrderTimeout,
            }}
            validate={(values) => {
              const obj = {
                order: checkValue(intl, values.order, 99),
                buy: checkValue(intl, values.buy),
                sell: checkValue(intl, values.sell),
                correction: checkValue(intl, values.correction),
              };
              Object.keys(obj).forEach((key) =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                obj[key] === undefined ? delete obj[key] : {},
              );
              return obj;
            }}
            onSubmit={(values, bag) => {
              return robot
                .setConfig(viewModel.id, {
                  order: toFloat(values.order) * 100,
                  buy: toFloat(values.buy) * 100,
                  sell: toFloat(values.sell) * 100,
                  correction10m: toFloat(values.correction) * 100,
                  deposit: ifSuccessful(
                    viewModel.initialConfig,
                    (t) => t.deposit,
                    10000,
                  ),
                  sellOrderTimeout: values.sellTimeout,
                })
                .then(() => nav(-1))
                .catch((reason: Error) => {
                  ErrorUtils.setFormikError(bag, reason);
                  throw reason;
                });
            }}
          >
            {(form) => <ValuesForm {...form} />}
          </Formik>
        )}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default inject('robot')(
  observer(EditRobotConfigScreen),
) as unknown as React.FC;
