import React, {useCallback, useMemo, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {RobotStore} from '../../store/RobotStore';
import {
  FormattedMessage,
  IntlShape,
  MessageDescriptor,
  useIntl,
} from 'react-intl';
import {Formik, FormikProps} from 'formik';
import {
  MarketAccessEnum,
  MarketList,
  MarketPair,
  MarketPairAccessEnum,
  RobotCreateRequestMarketEnum,
  RobotPair,
  SubscriptionInfoStatusEnum,
  SubscriptionInfoTypeEnum,
} from '../../api';
import Form from '../../component/container/Form';
import TextField from '../../component/form/TextField';
import Spacer from '../../component/design/utils/Spacer';
import {useNavigate} from 'react-router-dom';
import Link from '../../component/ui/Link';
import {APP_URLS} from '../../config';
import ActionSheetSelectField from '../../component/form/ActionSheetSelectField';
import DialogBottomSheet from '../../component/ui/DialogBottomSheet';
import SubscriptionStore from '../../store/SubscriptionStore';
import AsyncStateWrapper from '../../component/ui/AsyncStateWrapper';
import MarketUtils from '../../utils/MarketUtils';
import TradingPairSelectField from '../../component/form/TradingPairSelectField';
import RobotUtils from '../../utils/RobotUtils';

interface Props {
  robot: RobotStore;
  subscription: SubscriptionStore;
}

interface AddBotFormValues {
  exchange: RobotCreateRequestMarketEnum;
  apiKey: string;
  apiSecret: string;
  pair: RobotPair;
}

interface ValuesProps {
  subscriptionType: SubscriptionInfoTypeEnum;
  subscriptionStatus: SubscriptionInfoStatusEnum;
  markets: MarketList;
}

const BUSINESS_TAG: MessageDescriptor = {
  id: 'common.plans.business',
  defaultMessage: 'Business',
};

const ValuesForm: React.FunctionComponent<
  FormikProps<AddBotFormValues> & ValuesProps
> = ({
  subscriptionStatus,
  subscriptionType,
  markets,
  values,
  setFieldValue,
}) => {
  const navigate = useNavigate();
  const [businessSubRequiredDialogOpen, setBusinessSubRequiredDialogOpen] =
    useState(false);
  const checkMarketForSub = useCallback(
    (market: RobotCreateRequestMarketEnum) => {
      const m = markets.markets.find(
        (value) => MarketUtils.nameToCreateRequest(value.name) === market,
      );
      if (!m) {
        return;
      }
      if (m.access === MarketAccessEnum.BusinessSub) {
        if (
          subscriptionStatus === SubscriptionInfoStatusEnum.Expired ||
          subscriptionType === SubscriptionInfoTypeEnum.None
        ) {
          setBusinessSubRequiredDialogOpen(true);
          return false;
        }
      }
      return true;
    },
    [subscriptionStatus, subscriptionType],
  );
  const marketList = useMemo(
    () =>
      markets.markets
        .filter((value) => !value.hidden)
        .map((value) => ({
          value: MarketUtils.nameToCreateRequest(value.name),
          icon: MarketUtils.getIcon(value.name),
          tag:
            value.access === MarketAccessEnum.BusinessSub
              ? BUSINESS_TAG
              : undefined,
          label: MarketUtils.formatMarket(value.name),
        })),
    [markets],
  );
  const selectedMarket = useMemo(
    () =>
      markets.markets.find(
        (value) =>
          MarketUtils.nameToCreateRequest(value.name) === values.exchange,
      ),
    [markets, values.exchange],
  );
  const checkPairsForSub = useCallback(
    (pair: MarketPair) => {
      if (pair.access !== MarketPairAccessEnum.BusinessSub) {
        return true;
      }
      if (
        subscriptionStatus === SubscriptionInfoStatusEnum.Expired ||
        subscriptionType === SubscriptionInfoTypeEnum.None
      ) {
        setBusinessSubRequiredDialogOpen(true);
        return false;
      }
      return true;
    },
    [subscriptionStatus, subscriptionType],
  );
  const onMarketSelected = useCallback(
    (market: RobotCreateRequestMarketEnum) => {
      const m = markets.markets.find(
        (value) => MarketUtils.nameToCreateRequest(value.name) === market,
      );
      if (!m) {
        return;
      }
      setFieldValue('pair', MarketUtils.getDefaultPair(m));
    },
    [markets],
  );

  return (
    <Form
      paddingTop={1}
      submitLabel={{
        id: 'fragments.AddBotFragment.form.submit',
        defaultMessage: 'Create robot',
      }}
      footer={<Spacer size={1} />}
    >
      <ActionSheetSelectField
        name={'exchange'}
        header={{
          id: 'screens.AddBotMainContent.form.exchange',
          defaultMessage: 'Exchange name',
        }}
        items={marketList}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        preSelect={checkMarketForSub}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        postSelect={onMarketSelected}
      />
      {selectedMarket && (
        <>
          <Spacer size={1} />
          <TradingPairSelectField
            name={'pair'}
            header={{
              id: 'screens.AddBotMainContent.form.pair',
              defaultMessage: 'Trading pair',
            }}
            pairs={selectedMarket.pairs}
            onPairSelect={checkPairsForSub}
          />
        </>
      )}
      <Spacer size={1} />
      <TextField
        name={'apiKey'}
        header={{
          id: 'form.apiKey',
          defaultMessage: 'Api key',
        }}
        password={'fake'}
      />
      <Spacer size={1} />
      <TextField
        name={'apiSecret'}
        header={{
          id: 'form.apiSecret',
          defaultMessage: 'Api secret',
        }}
        password={'fake'}
      />
      <Spacer size={1} />
      {/*<Link*/}
      {/*  url={`${API_URL}/api/v1/user/links/robot-add-tutorial?market=${values.exchange}`}*/}
      {/*>*/}
      {/*  <FormattedMessage*/}
      {/*    id={'screens.AddBotMainContent.form.seeTutorial'}*/}
      {/*    defaultMessage={'See tutorial video'}*/}
      {/*  />*/}
      {/*</Link>*/}
      {/*<Spacer size={1} />*/}
      <Link url={APP_URLS['support']}>
        <FormattedMessage
          id={'screens.AddBotMainContent.form.askQuestion'}
          defaultMessage={'Ask an adviser a question'}
        />
      </Link>
      <Spacer size={2} />
      <DialogBottomSheet
        title={{
          id: 'screen.AddRobotFragment.businessSubRequiredDialog.title',
          defaultMessage: 'Business plan required',
        }}
        text={{
          id: 'screen.AddRobotFragment.businessSubRequiredDialog.text',
          defaultMessage:
            'To use this feature, you should subscribe at least to Business plan',
        }}
        open={businessSubRequiredDialogOpen}
        onDismiss={() => setBusinessSubRequiredDialogOpen(false)}
        onConfirm={() => {
          navigate('/app/subscription');
          setBusinessSubRequiredDialogOpen(false);
        }}
        confirmText={{
          id: 'screen.AddRobotFragment.businessSubRequiredDialog.confirm',
          defaultMessage: 'Buy now',
        }}
      />
    </Form>
  );
};

function checkPairValid(
  markets: MarketList,
  values: AddBotFormValues,
  intl: IntlShape,
  errors: Partial<Record<keyof AddBotFormValues, string>>,
): Partial<Record<keyof AddBotFormValues, string>> {
  const market = markets.markets.find(
    (value) => MarketUtils.nameToCreateRequest(value.name) === value.name,
  );
  if (!market || market.hidden) {
    errors.apiKey = intl.formatMessage({
      id: 'form.marketInvalid',
      defaultMessage: 'Market not found. Please, restart your app',
    });
    return errors;
  }
  const pair = market.pairs.find(
    (value) => value.pair === values.pair.asset + values.pair.money,
  );
  if (!pair || pair.hidden) {
    errors.apiKey = intl.formatMessage({
      id: 'form.pairInvalid',
      defaultMessage: 'Pair not supported! Please, select another one',
    });
    return errors;
  }
  return errors;
}

const AddRobotFragment: React.FunctionComponent<Props> = ({
  robot,
  subscription,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <AsyncStateWrapper state={subscription.info}>
      {(subInfo) => (
        <AsyncStateWrapper state={robot.markets}>
          {(markets) => (
            <Formik
              validateOnMount={false}
              validateOnChange={false}
              initialValues={{
                exchange: MarketUtils.nameToCreateRequest(
                  MarketUtils.getDefaultMarket(markets).name,
                ),
                apiKey: '',
                apiSecret: '',
                pair: MarketUtils.getDefaultPair(
                  MarketUtils.getDefaultMarket(markets),
                ),
              }}
              onSubmit={({apiSecret, apiKey, exchange, pair}) =>
                robot
                  .onAdd({
                    apiSecret,
                    apiKey,
                    market: exchange,
                    pair,
                    exchangeConnection: undefined as unknown as string,
                  })
                  .then((value) => navigate(`/app/dashboard/robot/${value}`))
              }
              validate={(values) =>
                checkPairValid(
                  markets,
                  values,
                  intl,
                  RobotUtils.checkApiKeysOnForm(intl, values),
                )
              }
            >
              {(form) => (
                <ValuesForm
                  {...form}
                  subscriptionType={subInfo.type}
                  subscriptionStatus={subInfo.status}
                  markets={markets}
                />
              )}
            </Formik>
          )}
        </AsyncStateWrapper>
      )}
    </AsyncStateWrapper>
  );
};

export default inject(
  'robot',
  'subscription',
)(observer(AddRobotFragment)) as unknown as React.FC;
