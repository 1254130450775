import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import createApiButtom from '../../../../../assets/onboarding/binance/apikeys/create-api-button.png';
import systemGeneraated from '../../../../../assets/onboarding/binance/apikeys/system-generated.png';

const CreateApiKeyFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.CreateApiKeyFragment.title',
        defaultMessage:
          'Click on <h>Create API</h>, keep default <h>System generated</h> variant and press <h>Next</h>',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={createApiButtom} second={systemGeneraated} />
  </>
);

export default CreateApiKeyFragment;
