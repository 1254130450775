import React, {useCallback, useContext} from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import cls from './style.module.scss';
import BottomActionSheetContext from './Ctx';
import Icon, {IconName} from '../Icon';
import Tag from '../Tag';

interface Props {
  text: MessageDescriptor;
  destructible?: boolean;
  onClick?: () => void;
  dismissOnClick?: boolean;
  selected?: boolean;
  icon?: IconName;
  tag?: MessageDescriptor;
}

const Item: React.FunctionComponent<Props> = ({
  text,
  destructible,
  onClick,
  dismissOnClick = true,
  selected,
  icon,
  tag,
}) => {
  const ctx = useContext(BottomActionSheetContext);
  const onClk = useCallback(() => {
    if (onClick) {
      if (ctx.onDismiss && dismissOnClick) {
        ctx.onDismiss();
      }
      onClick();
    }
  }, [ctx.onDismiss, onClick, dismissOnClick]);

  return (
    <div
      className={cls.card + ' ' + (selected && cls.selected)}
      onClick={onClk}
    >
      {icon && <Icon className={cls.icon} name={icon} size={20} />}
      <span className={cls.item + ' ' + (destructible ? cls.destructible : '')}>
        <FormattedMessage {...text} />
      </span>
      {tag && <Tag label={tag} className={cls.tag} />}
    </div>
  );
};

export default Item;
