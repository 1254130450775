import {FC} from 'react';

interface Props {
  width?: number;
  height?: number;
}

const AppStoreIcon: FC<Props> = ({width, height}) => (
  <svg
    width={width ?? 32}
    height={height ?? 32}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.247 21.8205C27.8161 22.7987 27.3061 23.6992 26.7151 24.5271C25.9096 25.6557 25.2501 26.4369 24.7418 26.8707C23.9539 27.5828 23.1097 27.9475 22.2057 27.9682C21.5567 27.9682 20.774 27.7867 19.863 27.4186C18.949 27.0522 18.109 26.8707 17.3409 26.8707C16.5354 26.8707 15.6715 27.0522 14.7475 27.4186C13.822 27.7867 13.0765 27.9786 12.5065 27.9976C11.6396 28.0339 10.7755 27.6588 9.91301 26.8707C9.36252 26.3989 8.67396 25.59 7.84911 24.4441C6.9641 23.2205 6.2365 21.8015 5.66649 20.1838C5.05602 18.4364 4.75 16.7444 4.75 15.1062C4.75 13.2298 5.1626 11.6114 5.98904 10.2551C6.63855 9.16575 7.50263 8.30642 8.58409 7.67557C9.66555 7.04473 10.8341 6.72326 12.0925 6.70269C12.781 6.70269 13.684 6.91199 14.806 7.32334C15.925 7.73607 16.6434 7.94537 16.9584 7.94537C17.1939 7.94537 17.992 7.70063 19.345 7.21272C20.6245 6.76024 21.7044 6.57289 22.5891 6.64669C24.9863 6.83681 26.7872 7.76545 27.9849 9.43848C25.841 10.715 24.7805 12.503 24.8016 14.7967C24.8209 16.5833 25.4805 18.07 26.7767 19.2505C27.3641 19.7983 28.0201 20.2218 28.75 20.5225C28.5917 20.9736 28.4246 21.4057 28.247 21.8205ZM22.7491 0.560159C22.7491 1.96048 22.2285 3.26795 21.1909 4.47814C19.9386 5.91681 18.424 6.74814 16.7815 6.61696C16.7606 6.44897 16.7484 6.27216 16.7484 6.08636C16.7484 4.74206 17.3439 3.30338 18.4015 2.12707C18.9295 1.53149 19.6009 1.03627 20.4153 0.641218C21.2278 0.252064 21.9964 0.0368538 22.7192 0C22.7403 0.187201 22.7491 0.374414 22.7491 0.560141V0.560159Z"
      fill="black"
    />
  </svg>
);

export default AppStoreIcon;
