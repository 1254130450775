import {FC} from 'react';
import HashUtils from '../../../../utils/HashUtils';

const MAP: string[] = [
  '#FF5252',
  '#FC7C1F',
  '#EEAB00',
  '#5EB21C',
  '#11C383',
  '#08B7CF',
  '#3772FF',
  '#813BF3',
  '#E16B6B',
  '#DE8E54',
  '#B79A4E',
  '#74A151',
  '#589E85',
  '#4B8E97',
  '#637AB1',
  '#876CB4',
];

interface Props {
  src: string;
  width: number;
  height: number;
  className?: string;
  onClick?: () => void;
}

function mapId(id: string): number {
  const d = id.replace('http://', '').replace('https://', '').split('/');
  if (d.length < 4) {
    return HashUtils.crc4(id);
  } else {
    return parseInt(d[3]) % MAP.length;
  }
}

const DefaultAvatar: FC<Props> = ({src, width, height, className, onClick}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <g clipPath="url(#clip0_887_10212)">
      <rect width="60" height="60" rx="30" fill={MAP[mapId(src)]} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.2338 47.6875C48.7767 55.1516 39.9545 60 30 60C20.0454 60 11.2232 55.1516 5.76611 47.6874C10.5048 41.486 18.3533 36 29.9998 36C41.6464 36 49.4951 41.4861 54.2338 47.6875Z"
        fill="url(#paint0_radial_887_10212)"
      />
      <path
        d="M20 21C20 14.9249 24.4772 10 30 10C35.5228 10 40 14.9249 40 21C40 27.0751 35.5228 32 30 32C24.4772 32 20 27.0751 20 21Z"
        fill="white"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_887_10212"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(30 30) rotate(90) scale(30)"
      >
        <stop offset="0.221522" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </radialGradient>
      <clipPath id="clip0_887_10212">
        <rect width="60" height="60" rx="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DefaultAvatar;
