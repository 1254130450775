import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/apikeys/api-management-onboarding.png';

const ApiManagementOnboardingFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.ApiManagementOnboardingFragment.title',
        defaultMessage: 'Press <h>Create an API Key</h> button',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} />
  </>
);

export default ApiManagementOnboardingFragment;
