import React from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import cls from './style.module.scss';

interface Props {
  label: MessageDescriptor;
  className?: string;
}

const Tag: React.FunctionComponent<Props> = ({label, className}) => (
  <span className={cls.tag + ' ' + className}>
    <FormattedMessage {...label} />
  </span>
);

export default Tag;
