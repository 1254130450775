import React, {useEffect, useState} from 'react';
import BottomActionSheet from '../../ui/BottomActionSheet';
import {useNavigate} from 'react-router-dom';
import {useSwiper} from 'swiper/react';
import {RobotStore} from '../../../store/RobotStore';
import {inject, observer} from 'mobx-react';

interface InjectProps {
  robot: RobotStore;
}

interface Props {
  id: string;
  modalOpen: boolean;
}

const RobotCardActions: React.FunctionComponent<Props & InjectProps> = ({
  robot,
  id,
  modalOpen,
}) => {
  const navigate = useNavigate();
  const swiper = useSwiper();
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (!modalOpen) {
      setConfirmDelete(false);
    }
  }, [modalOpen]);

  return (
    <>
      <BottomActionSheet.Item
        text={{
          id: 'screen.RobotDashboardFragment.changeName',
          defaultMessage: 'Change name',
        }}
        onClick={() => navigate(`/app/robot/${id}/edit-name`)}
      />
      <BottomActionSheet.Item
        text={{
          id: 'screen.RobotDashboardFragment.changeApiKeys',
          defaultMessage: 'Change API keys',
        }}
        onClick={() => navigate(`/app/robot/${id}/edit-keys`)}
      />
      <BottomActionSheet.Item
        text={{
          id: 'screen.RobotDashboardFragment.changeConfig',
          defaultMessage: 'Change config',
        }}
        onClick={() => navigate(`/app/robot/${id}/edit-config`)}
      />
      <BottomActionSheet.Item
        text={
          confirmDelete
            ? {
                id: 'screen.RobotDashboardFragment.confirmDeleteRobot',
                defaultMessage: 'Are you sure?',
              }
            : {
                id: 'screen.RobotDashboardFragment.deleteRobot',
                defaultMessage: 'Delete robot',
              }
        }
        dismissOnClick={confirmDelete}
        destructible
        onClick={() => {
          if (confirmDelete) {
            swiper.slidePrev();
            robot.deleteRobot(id);
          } else {
            setConfirmDelete(true);
          }
        }}
      />
    </>
  );
};

export default inject('robot')(
  observer(RobotCardActions),
) as unknown as React.FC<Props>;
