import {PublicUserInfo, UserInfo} from '../../../../api';
import {FC} from 'react';
import cls from './style.module.scss';
import Typography from '../../typo/Typography';
import Avatar from '../../misc/Avatar';

export interface Props {
  tree: PublicUserInfo[];
  me: UserInfo;
}

function toItem(item: PublicUserInfo, idx: number) {
  const i = (
    <span className={cls.treeItem} key={idx}>
      {item.name}
      <Avatar size={24} src={item.photoUrl} />
    </span>
  );
  if (idx === 0) {
    return (
      <span className={cls.treeLine} key={idx}>
        <Typography
          text={{id: 'common.from', defaultMessage: 'From'}}
          className={cls.name}
        />
        {i}
      </span>
    );
  } else {
    return i;
  }
}

function addLine(value: JSX.Element, idx: number) {
  return (
    <div className={cls.treeChunk} key={idx}>
      {value}
      <svg
        className={cls.vline}
        width="24"
        height="12"
        viewBox="0 0 24 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_882_2308)">
          <path d="M12 0V12" stroke="white" strokeLinecap="round" />
        </g>
        <defs>
          <clipPath id={'clip0_882_2308'}>
            <rect width="24" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

const FromTree: FC<Props> = ({tree, me}) => (
  <div className={cls.item + ' ' + cls.tree}>
    {tree.length === 1 ? tree.map(toItem) : tree.map(toItem).map(addLine)}

    {tree.length > 1 && (
      <span className={cls.treeLine}>
        <Typography
          text={{id: 'common.to', defaultMessage: 'To'}}
          className={cls.name}
        />
        <span className={cls.treeItem}>
          <span>
            {me.name ?? me.email}{' '}
            <span className={cls.you}>
              <Typography
                text={{id: 'common.youBracketed', defaultMessage: '(you)'}}
              />
            </span>
          </span>
          <Avatar size={24} src={me.photo} />
        </span>
      </span>
    )}
  </div>
);

export default FromTree;
