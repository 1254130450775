import React from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import Spacer from '../../design/utils/Spacer';
import SubmitWideButton from '../../form/SubmitWideButton';
import cls from './style.module.scss';

interface Props {
  submitLabel: MessageDescriptor;
  children: React.ReactNode;
  footer?: React.ReactNode;
  paddingTop?: number;
  noFooter?: boolean;
}

const Form: React.FunctionComponent<Props> = ({
  submitLabel,
  children,
  footer,
  paddingTop,
  noFooter,
}) => (
  <div
    className={cls.form}
    style={{
      paddingTop: paddingTop !== undefined ? `${paddingTop}rem` : undefined,
    }}
  >
    <div className={cls.content}>{children}</div>
    <SubmitWideButton>
      <FormattedMessage {...submitLabel} />
    </SubmitWideButton>
    {noFooter ? <></> : footer ?? <Spacer size={5.75} flex={1} />}
  </div>
);

export default Form;
