import {FC, ReactNode, useRef} from 'react';
import cls from './style.module.scss';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import Item from './item';

interface Props {
  anchor: ReactNode;
  children?: ReactNode;
  open: boolean;
  invertX?: boolean;
  onDismiss?: () => void;
}

interface SubComponents {
  Item: typeof Item;
}

const Menu: FC<Props> & SubComponents = ({
  anchor,
  children,
  open,
  invertX,
  onDismiss,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideClick(ref, onDismiss, open);

  return (
    <div className={cls.container} ref={ref}>
      {anchor}
      <div
        className={
          cls.menu + ' ' + (open && cls.open) + ' ' + (invertX && cls.invertX)
        }
      >
        {children}
      </div>
    </div>
  );
};

Menu.Item = Item;

export default Menu;
