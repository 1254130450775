import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import cls from './style.module.scss';

interface Props {
  label?: HText;
  image: string;
  alt?: string;
}

const QRCode: FC<Props> = ({label, image, alt}) => (
  <div className={cls.container}>
    {label && <Typography className={cls.label} text={label} />}
    <div className={cls.imgContainer}>
      <img src={image} alt={alt} className={cls.img} />
    </div>
  </div>
);

export default QRCode;
