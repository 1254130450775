import React, {FC, ReactNode, useId} from 'react';
import {PlacesType} from 'react-tooltip';
import Tooltip from './index';
import cls from './style.module.scss';

interface Props {
  children: ReactNode;
  place?: PlacesType;
  tooltip?: React.ReactNode;
  className?: string;
  addIcon?: boolean;
}

const WithTooltip: FC<Props> = ({children, place, tooltip, className}) => {
  const id = useId();
  return (
    <div
      className={cls.container + ' ' + className}
      id={tooltip ? id : undefined}
    >
      {children}
      <Tooltip target={id} place={place}>
        {tooltip}
      </Tooltip>
    </div>
  );
};

export default WithTooltip;
