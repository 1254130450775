import {FC} from 'react';

interface Props {
  width?: number;
  height?: number;
}

const HuobiIcon: FC<Props> = ({width, height}) => (
  <svg
    width={width ?? 24}
    height={height ?? 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8457 7.38204C14.8457 3.93856 13.0744 0.972968 11.7254 0.0104202C11.7254 0.0104202 11.6216 -0.0438078 11.6324 0.0951515C11.5214 6.74825 7.93244 8.55133 5.96081 10.978C1.40924 16.5839 5.63876 22.7286 9.95416 23.864C12.3588 24.5012 9.39595 22.7387 9.01307 19.0207C8.54432 14.5334 14.8457 11.1034 14.8457 7.38204Z"
      fill="white"
    />
    <path
      d="M16.9138 9.64936C16.8851 9.63241 16.8458 9.61886 16.8207 9.66292C16.7456 10.5001 15.8439 12.2896 14.6988 13.9368C10.8164 19.5155 13.0278 22.2065 14.273 23.6538C14.9958 24.4909 14.273 23.6537 16.0764 22.7963C18.3057 21.5151 19.7513 19.2986 19.966 16.838C20.3203 12.8725 17.8083 10.3747 16.9138 9.64936Z"
      fill="#2CA6E0"
    />
  </svg>
);

export default HuobiIcon;
