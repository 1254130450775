import {
  WalletTransactionDetailsCauseEnum,
  WalletTransactionDetailsStateEnum,
} from '../../../../api';
import React, {FC} from 'react';
import Illustration from '../../graphics/Illustration';
import Typography from '../../typo/Typography';
import WalletTransactionUtils from '../../../../utils/WalletTransactionUtils';
import {FormattedNumberParts} from 'react-intl';
import CurrencyUtils from '../../../../utils/CurrencyUtils';
import DateUtils from '../../../../utils/DateUtils';
import cls from './style.module.scss';
import Icon from '../../graphics/Icon';

interface Props {
  amount: number;
  cause: WalletTransactionDetailsCauseEnum;
  state: WalletTransactionDetailsStateEnum;
  createdAt: string;
}

const WalletTransactionHeader: FC<Props> = ({
  amount,
  cause,
  createdAt,
  state,
}) => (
  <div className={cls.container}>
    {amount > 0 ? <Illustration.Deposit /> : <Illustration.Withdrawal />}
    <span className={cls.cause}>
      <Typography text={WalletTransactionUtils.formatCause(cause)} />
    </span>
    <span className={cls.amount + ' ' + (amount > 0 ? cls.up : cls.down)}>
      <FormattedNumberParts
        value={amount}
        signDisplay={'exceptZero'}
        maximumFractionDigits={8}
      >
        {CurrencyUtils.formatAmount}
      </FormattedNumberParts>{' '}
      <span className={cls.rco}>RCO</span>
    </span>
    <span className={cls.time}>{DateUtils.formatFullTimestamp(createdAt)}</span>
    <span
      className={
        cls.badge +
        ' ' +
        (state === WalletTransactionDetailsStateEnum.Failed && cls.failure) +
        ' ' +
        (state === WalletTransactionDetailsStateEnum.Successful && cls.success)
      }
    >
      <Icon
        name={
          state === WalletTransactionDetailsStateEnum.InProgress
            ? 'clock-mini'
            : state === WalletTransactionDetailsStateEnum.Failed
            ? 'close'
            : 'checkmark'
        }
        size={16}
        tint={
          state === WalletTransactionDetailsStateEnum.InProgress
            ? 'default'
            : state === WalletTransactionDetailsStateEnum.Failed
            ? 'red'
            : 'green'
        }
      />
      <Typography text={WalletTransactionUtils.formatState(state)} />
    </span>
  </div>
);

export default WalletTransactionHeader;
