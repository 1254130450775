import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {FieldConfig, useField} from 'formik';
import SelectInput from '../../ui/SelectInput';

interface Props<T> {
  header?: MessageDescriptor;
  items: {value: T; label: string}[];
  children?: React.ReactNode;
  tooltip?: React.ReactNode;
}

function SelectField<T>({
  ...props
}: Props<T> & FieldConfig<T>): React.ReactElement {
  const [field, , helpers] = useField<T>(props);
  return (
    <SelectInput
      items={props.items}
      value={field.value}
      onChange={helpers.setValue}
      header={props.header}
      tooltip={props.tooltip}
    />
  );
}

export default React.memo(SelectField);
