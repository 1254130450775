import {FC, ReactNode} from 'react';
import cls from './style.module.scss';

interface Props {
  children: ReactNode;
  footer?: ReactNode;
  overflow?: boolean;
}

const Form: FC<Props> = ({children, footer, overflow}) => (
  <div className={cls.form}>
    <div className={cls.content + ' ' + (overflow && cls.scroll)}>
      {children}
    </div>
    {footer}
  </div>
);

export default Form;
