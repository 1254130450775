import React from 'react';
import {UserStore} from '../../../store/UserStore';
import {inject, observer} from 'mobx-react';
import Scaffolding from '../../../component/container/Scaffolding';
import TextInput from '../../../component/ui/TextInput';
import AsyncStateWrapper from '../../../component/ui/AsyncStateWrapper';
import {COLOR_QR_FG, INVITE_URL, LOGO} from '../../../config';
import {QRCode} from 'react-qrcode-logo';
import cls from './style.module.scss';
import DesktopCompatibilityStore from '../../../store/DesktopCompatibilityStore';

interface Props {
  user: UserStore;
  desktopCompatibility: DesktopCompatibilityStore;
}

const InviteScreen: React.FunctionComponent<Props> = ({
  user,
  desktopCompatibility,
}) => (
  <Scaffolding
    header={{
      id: 'nav.invite',
      defaultMessage: 'Invite to the team',
    }}
    paddingHorizontal={1}
  >
    <AsyncStateWrapper state={user.user}>
      {(user) => {
        const url = INVITE_URL.replace('{code}', user.id.toString());
        return (
          <>
            <TextInput
              value={url.replace('https://', '')}
              disabled
              copyable
              shareable={'url'}
              shareValue={url}
            />
            <div className={cls.qrContainer}>
              <QRCode
                size={
                  desktopCompatibility.shouldUseCompatibility
                    ? window.innerHeight * 0.5 - 64
                    : window.innerWidth - 64
                }
                value={url}
                logoImage={LOGO}
                logoWidth={
                  desktopCompatibility.shouldUseCompatibility
                    ? window.innerHeight * 0.15
                    : window.innerWidth * 0.25
                }
                logoHeight={
                  desktopCompatibility.shouldUseCompatibility
                    ? window.innerHeight * 0.15
                    : window.innerWidth * 0.25
                }
                qrStyle={'dots'}
                fgColor={COLOR_QR_FG}
                removeQrCodeBehindLogo
                ecLevel={'H'}
              />
            </div>
          </>
        );
      }}
    </AsyncStateWrapper>
  </Scaffolding>
);

export default inject(
  'user',
  'desktopCompatibility',
)(observer(InviteScreen)) as unknown as React.FC;
