import React from 'react';
import cls from './style.module.scss';
import {FormattedNumberParts} from 'react-intl';
import CurrencyUtils from '../../../utils/CurrencyUtils';

interface Props {
  moneyCurrency: string;
  totalMoney: number;
  income: number | undefined;
  incomePercent: number | undefined;
}

const TotalBalanceRow: React.FunctionComponent<Props> = ({
  moneyCurrency,
  totalMoney,
  income,
  incomePercent,
}) => (
  <span className={cls.balanceUsd}>
    {moneyCurrency.toUpperCase() + ' '}
    {Math.abs(totalMoney) < 0.000000001 && (
      <FormattedNumberParts
        value={totalMoney}
        maximumFractionDigits={CurrencyUtils.getDisplayMaxFractionalDigits(
          moneyCurrency,
        )}
      >
        {CurrencyUtils.formatBalance}
      </FormattedNumberParts>
    )}
    <span
      className={
        cls.balanceCost +
        ' ' +
        (income !== undefined && income >= 0 ? cls.balanceUp : cls.balanceDown)
      }
    >
      {income !== undefined ? (
        <>
          {' +'}
          <FormattedNumberParts
            value={income}
            maximumFractionDigits={CurrencyUtils.getDisplayMaxFractionalDigits(
              moneyCurrency,
            )}
          >
            {CurrencyUtils.formatBalance}
          </FormattedNumberParts>
          <span
            className={
              cls.balancePercent +
              ' ' +
              (incomePercent !== undefined && incomePercent >= 0
                ? cls.balanceUp
                : cls.balanceDown)
            }
          >
            {incomePercent !== undefined && ` (≈ ${incomePercent / 100}%)`}
          </span>
        </>
      ) : (
        ''
      )}
    </span>
  </span>
);

export default React.memo(TotalBalanceRow);
