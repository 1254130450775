import React from 'react';
import Scaffolding from '../../../../component/container/Scaffolding';
import {FormattedMessage, useIntl} from 'react-intl';
import {WalletStore} from '../../../../store/WalletStore';
import {inject, observer} from 'mobx-react';
import {Formik, FormikProps} from 'formik';
import ErrorUtils from '../../../../utils/ErrorUtils';
import {useNavigate} from 'react-router-dom';
import SubmitWideButton from '../../../../component/form/SubmitWideButton';
import Title from '../../../../component/design/typo/Title';
import FlexCol from '../../../../component/design/container/FlexCol';
import TextField from '../../../../component/design/field/TextField';
import Form from '../../../../component/design/container/Form';
import IntlUtils from '../../../../utils/IntlUtils';
import Image from '../../../../component/design/graphics/Image';
import ReceiptInstruction from '../../../../assets/images/receipt-instruction.png';
import Spacer from '../../../../component/design/utils/Spacer';

interface Props {
  wallet: WalletStore;
}

interface AcquireForm {
  txid: string;
}

const ValuesForm: React.FC<FormikProps<AcquireForm>> = () => (
  <Scaffolding
    header={{
      id: 'nav.buyRCO',
      defaultMessage: 'Buy RCO',
    }}
  >
    <Form
      overflow
      footer={
        <SubmitWideButton>
          <FormattedMessage
            id={'common.continue'}
            defaultMessage={'Continue'}
          />
        </SubmitWideButton>
      }
    >
      <FlexCol>
        <Title
          text={{
            id: 'screens.BuyRcoTxidScreen.text',
            defaultMessage:
              'To confirm sent transaction, <h>copy data</h> from the <h>receipt</h> according to the instructions below.',
          }}
        />
        <Spacer size={1.5} />
        <TextField
          name={'txid'}
          enterKeyHint={'go'}
          label={{
            id: 'screens.BuyRcoTxidScreen.txid',
            defaultMessage: 'Txid',
          }}
          pasteable
          clearable
          submitForm
          placeholder={{
            id: 'screens.BuyRcoTxidScreen.txidPlaceholder',
            defaultMessage: 'Place TxID here',
          }}
        />
        <Spacer size={2.5} />
        <Image image={ReceiptInstruction} />
      </FlexCol>
    </Form>
  </Scaffolding>
);

const BuyRcoTxidScreen: React.FunctionComponent<Props> = ({wallet}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{txid: ''}}
      validateOnMount={false}
      validateOnChange={false}
      validate={(values) => {
        const ret: Partial<Record<keyof AcquireForm, string>> = {};
        if (values.txid.trim() === '') {
          ret.txid = IntlUtils.form(intl, 'not-empty');
        }
        Object.keys(ret).forEach((key) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ret[key] === undefined ? delete ret[key] : {},
        );
        return ret;
      }}
      onSubmit={(values, bag) => {
        return wallet
          .createAcquisitionRequest(values.txid)
          .then((req) =>
            navigate(`/app/wallet/buy/${req.id}/status`, {replace: true}),
          )
          .catch((reason: Error) => {
            ErrorUtils.setFormikError(bag, reason);
            throw reason;
          });
      }}
    >
      {(form) => <ValuesForm {...form} />}
    </Formik>
  );
};

export default inject('wallet')(
  observer(BuyRcoTxidScreen),
) as unknown as React.FC;
