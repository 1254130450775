import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/apikeys/enable-spot-trade-1.png';
import v2 from '../../../../../assets/onboarding/bybit/apikeys/enable-spot-trade-2.png';

const EnableSpotTradingFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.EnableSpotTradingFragment.title',
        defaultMessage:
          'Scroll down, enable <h>Spot - Trade</h> option. Then, press <h>Submit</h>',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default EnableSpotTradingFragment;
