import {FC} from 'react';
import {Dex, DexLabel} from './Dex';
import {Business, BusinessLabel} from './Business';
import {Pro, ProLabel} from './Pro';
import cls from './style.module.scss';
import Typography from '../../typo/Typography';

interface Props {
  subscription: 'BUSINESS' | 'PRO' | 'DEX';
}

const SubscriptionLogo: FC<Props> = ({subscription}) => {
  switch (subscription) {
    case 'BUSINESS':
      return (
        <div className={cls.container}>
          <div className={cls.logo}>
            <Business />
            <div className={cls.label}>
              <BusinessLabel />
            </div>
          </div>
          <Typography
            text={{
              id: 'subscriptions.business.desc',
              defaultMessage: 'Status opens possibilities',
            }}
            className={cls.description}
          />
        </div>
      );
    case 'DEX':
      return (
        <div className={cls.container}>
          <div className={cls.logo}>
            <Dex />
            <div className={cls.label}>
              <DexLabel />
            </div>
          </div>
          <Typography
            text={{
              id: 'subscriptions.dex.desc',
              defaultMessage: 'Status opens possibilities',
            }}
            className={cls.description}
          />
        </div>
      );
    case 'PRO':
      return (
        <div className={cls.container}>
          <div className={cls.logo}>
            <Pro />
            <div className={cls.label}>
              <ProLabel />
            </div>
          </div>
          <Typography
            text={{
              id: 'subscriptions.pro.desc',
              defaultMessage: 'Status opens possibilities',
            }}
            className={cls.description}
          />
        </div>
      );
  }
};

export default SubscriptionLogo;
