import React from 'react';
import cls from './style.module.scss';
import {TailSpin} from 'react-loader-spinner';
import {COLOR_TEXT} from '../../../config';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  state?: 'none' | 'loading' | 'error' | 'warning';
}

const WideButton: React.FunctionComponent<Props> = ({
  children,
  onClick,
  className,
  state,
}) => (
  <button
    onClick={onClick}
    className={
      cls.button +
      ' ' +
      (state === 'error' ? cls.error : state === 'warning' ? cls.warning : '') +
      ' ' +
      (className ?? '')
    }
  >
    {children}
    {state === 'loading' && (
      <div className={cls.loading}>
        <TailSpin
          width={24}
          height={24}
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
          color={COLOR_TEXT}
        />
      </div>
    )}
  </button>
);

export default WideButton;
