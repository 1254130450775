import {FC, ReactNode} from 'react';
import Typography from '../../typo/Typography';
import cls from './style.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
}

const Ips: FC<Props> = ({children}) => (
  <div className={cls.ips}>
    <Typography
      text={{id: 'common.ips', defaultMessage: 'IP Whitelist'}}
      className={cls.label}
    />
    {children}
  </div>
);

export default Ips;
