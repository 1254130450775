import {FC, ReactNode, useCallback, useEffect, useState} from 'react';
import {Ctx} from './ctx';
import {HText} from '../../typo/Typography';
import Snackbar from '../../misc/Snackbar';
import cls from './style.module.scss';
import {useSnackbar} from './useSnackbar';

export {useSnackbar};

const LENGTH_SHORT = 2000;
const LENGTH_LONG = 3500;
let SNACK_KEY = 0;

function getHideAt(delay: 'short' | 'long' | number): number {
  if (delay === 'short') {
    return LENGTH_SHORT + Date.now();
  } else if (delay === 'long') {
    return LENGTH_LONG + Date.now();
  } else {
    return delay + Date.now();
  }
}

interface Snack {
  key: number;
  text: HText;
  hideAt: number;
  type: 'info' | 'error' | 'warning';
  dedupKey?: string;
}

interface Props {
  children: ReactNode;
}

const SnackbarContainer: FC<Props> = ({children}) => {
  const [snacks, setSnacks] = useState<Snack[]>([]);
  const showInfo = useCallback(
    (text: HText, delay: 'short' | 'long' | number, key?: string) => {
      setSnacks([
        ...snacks.filter((value) => !key || value.dedupKey !== key),
        {
          hideAt: getHideAt(delay),
          text,
          type: 'info',
          key: SNACK_KEY++,
          dedupKey: key,
        },
      ]);
    },
    [snacks],
  );
  const showWarning = useCallback(
    (text: HText, delay: 'short' | 'long' | number, key?: string) => {
      setSnacks([
        ...snacks.filter((value) => !key || value.dedupKey !== key),
        {
          hideAt: getHideAt(delay),
          text,
          type: 'warning',
          key: SNACK_KEY++,
          dedupKey: key,
        },
      ]);
    },
    [snacks],
  );
  const showError = useCallback(
    (text: HText, delay: 'short' | 'long' | number, key?: string) => {
      setSnacks([
        ...snacks.filter((value) => !key || value.dedupKey !== key),
        {
          hideAt: getHideAt(delay),
          text,
          type: 'error',
          key: SNACK_KEY++,
          dedupKey: key,
        },
      ]);
    },
    [snacks],
  );
  useEffect(() => {
    const now = Date.now();
    const minHideAt = Math.min(...snacks.map((value) => value.hideAt - now));
    if (!isNaN(minHideAt) && isFinite(minHideAt)) {
      const t = setTimeout(() => {
        setSnacks(snacks.filter((v) => v.hideAt - now > 0));
      }, Math.max(minHideAt, 0));
      return () => clearTimeout(t);
    }
  }, [snacks]);

  return (
    <Ctx.Provider value={{showError, showWarning, showInfo}}>
      {children}
      <div className={cls.container}>
        {snacks.map((s) => (
          <Snackbar
            type={s.type}
            text={s.text}
            key={s.key}
            onClose={() => setSnacks(snacks.filter((v) => v.key !== s.key))}
          />
        ))}
      </div>
    </Ctx.Provider>
  );
};

export default SnackbarContainer;
