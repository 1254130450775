import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import {Formik, FormikProps} from 'formik';
import Form from '../../../component/container/Form';
import Scaffolding from '../../../component/container/Scaffolding';
import ErrorUtils from '../../../utils/ErrorUtils';
import {NotificationPreferences} from '../../../api';
import SwitchField from '../../../component/form/SwitchField';
import EditEmailNotificationsPreferencesViewModel from '../../../viewmodel/EditEmailNotificationsPreferencesViewModel';
import AsyncStateWrapper from '../../../component/ui/AsyncStateWrapper';
import cls from './style.module.scss';

interface FormProps {
  email: string;
  saved: boolean;
}

const ValuesForm: React.FunctionComponent<
  FormikProps<NotificationPreferences> & FormProps
> = ({email, saved}) => (
  <Form
    submitLabel={
      saved
        ? {
            id: 'screen.EditEmailNotificationsPreferencesScreen.saveSuccessful',
            defaultMessage: 'Preferences saved',
          }
        : {
            id: 'screen.EditEmailNotificationsPreferencesScreen.save',
            defaultMessage: 'Save preferences',
          }
    }
  >
    <span className={cls.email}>{email}</span>
    <SwitchField
      name={'notificationsEnabled'}
      header={{
        id: 'screen.EditEmailNotificationsPreferencesScreen.fields.notificationsEnabled',
        defaultMessage: 'Enable notifications',
      }}
      className={cls.globalEnable}
    />
    <SwitchField
      name={'robotStoppedEnabled'}
      header={{
        id: 'screen.EditEmailNotificationsPreferencesScreen.fields.robotStoppedEnabled',
        defaultMessage: 'Robot stopped',
      }}
      dependsOn={'notificationsEnabled'}
    />
    <SwitchField
      name={'balanceDepositEnabled'}
      header={{
        id: 'screen.EditEmailNotificationsPreferencesScreen.fields.balanceDepositEnabled',
        defaultMessage: 'Successful deposit to your RCO wallet',
      }}
      dependsOn={'notificationsEnabled'}
    />
    <SwitchField
      name={'lowBalanceEnabled'}
      header={{
        id: 'screen.EditEmailNotificationsPreferencesScreen.fields.lowBalanceEnabled',
        defaultMessage: 'Low balance warning',
      }}
      dependsOn={'notificationsEnabled'}
    />
    <SwitchField
      name={'lowReferralBalanceEnabled'}
      header={{
        id: 'screen.EditEmailNotificationsPreferencesScreen.fields.lowReferralBalanceEnabled',
        defaultMessage: 'Low balance referral warning',
      }}
      dependsOn={'notificationsEnabled'}
    />
    <SwitchField
      name={'newReferralEnabled'}
      header={{
        id: 'screen.EditEmailNotificationsPreferencesScreen.fields.newReferralEnabled',
        defaultMessage: 'New referral',
      }}
      dependsOn={'notificationsEnabled'}
    />
    <SwitchField
      name={'referralCommissionEnabled'}
      header={{
        id: 'screen.EditEmailNotificationsPreferencesScreen.fields.referralCommissionEnabled',
        defaultMessage: 'Received referral commission',
      }}
      dependsOn={'notificationsEnabled'}
    />
    <SwitchField
      name={'successfulReferralSubscriptionEnabled'}
      header={{
        id: 'screen.EditEmailNotificationsPreferencesScreen.fields.successfulReferralSubscriptionEnabled',
        defaultMessage: 'Successful referral subscription',
      }}
      dependsOn={'notificationsEnabled'}
    />
    <SwitchField
      name={'successfulSubscriptionEnabled'}
      header={{
        id: 'screen.EditEmailNotificationsPreferencesScreen.fields.successfulSubscriptionEnabled',
        defaultMessage: 'Successful subscription',
      }}
      dependsOn={'notificationsEnabled'}
    />
    <SwitchField
      name={'tradeClosedEnabled'}
      header={{
        id: 'screen.EditEmailNotificationsPreferencesScreen.fields.tradeClosedEnabled',
        defaultMessage: 'Trade closed',
      }}
      dependsOn={'notificationsEnabled'}
    />
  </Form>
);

const EditEmailNotificationsPreferencesScreen: React.FunctionComponent = () => {
  const vm = useMemo(
    () => new EditEmailNotificationsPreferencesViewModel(),
    [],
  );
  return (
    <Scaffolding
      header={{
        id: 'nav.editEmailNotificationPreferences',
        defaultMessage: 'Email notifications',
      }}
    >
      <AsyncStateWrapper state={vm.prefs}>
        {(data) => (
          <Formik
            validateOnMount={false}
            validateOnChange={false}
            initialValues={data.notificationPreferences}
            onSubmit={(values, bag) => {
              return vm.savePrefs(values).catch((reason: Error) => {
                ErrorUtils.setFormikError(bag, reason);
                throw reason;
              });
            }}
          >
            {(form) => (
              <ValuesForm {...form} email={data.email} saved={vm.saved} />
            )}
          </Formik>
        )}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default observer(EditEmailNotificationsPreferencesScreen);
