import {FC, ReactNode, useCallback, useState} from 'react';
import {Ctx} from './ctx';
import Item from './item';
import cls from './style.module.scss';

interface Props {
  children: ReactNode[];
  open: boolean;
  onClose?: () => void;
}

interface SubComponents {
  Item: typeof Item;
}

const GuideContainer: FC<Props> & SubComponents = ({
  children,
  open,
  onClose,
}) => {
  const [current, setCurrent] = useState(0);

  const onNext = useCallback(() => {
    if (current < children.length - 1) {
      setCurrent(current + 1);
    } else if (onClose) {
      onClose();
    }
  }, [current, onClose]);

  const onPrev = useCallback(() => {
    if (current > 0) {
      setCurrent(current - 1);
    }
  }, [current]);

  if (!open) {
    return <></>;
  }
  return (
    <div className={cls.container}>
      <Ctx.Provider
        value={{
          max: children.length,
          current: current + 1,
          onClose,
          onPrev: current === 0 ? undefined : onPrev,
          onNext,
        }}
      >
        {children[current]}
      </Ctx.Provider>
    </div>
  );
};

GuideContainer.Item = Item;

export default GuideContainer;
