import React from 'react';
import cls from './style.module.scss';
import {FormattedMessage} from 'react-intl';

interface Props {
  small?: boolean;
}

const NoInfoContent: React.FunctionComponent<Props> = ({small}) => (
  <div className={cls.centerMain + ' ' + (small && cls.small)}>
    <h6 className={cls.balanceCrypto}>
      <FormattedMessage
        defaultMessage={'No information available'}
        id={'component.BalanceCard.noInfoHeader'}
      />
    </h6>
    <p className={'caption'}>
      <FormattedMessage
        defaultMessage={'You should start robot to get account balance'}
        id={'component.BalanceCard.noInfoText'}
      />
    </p>
  </div>
);

export default React.memo(NoInfoContent);
