import {ReferralStore} from '../ReferralStore';
import {notLoaded, wrapRefreshablePromise} from '../../utils';
import {
  ReferralSearchPage,
  TeamCount,
  TotalIncome,
  TreeLanePage,
} from '../../api';
import ApiStore from '../ApiStore';
import {action, makeAutoObservable, observable, runInAction} from 'mobx';

class RealReferralStore implements ReferralStore {
  teamCount: RefreshableAsyncState<TeamCount> = notLoaded();
  totalIncome: RefreshableAsyncState<TotalIncome> = notLoaded();

  constructor() {
    makeAutoObservable(this, {
      teamCount: observable,
      totalIncome: observable,
      init: action,
    });
  }

  init(): void {
    void wrapRefreshablePromise(
      ApiStore.referral.getTeamCount().then((value) => value.data),
      this.teamCount,
      (v) => runInAction(() => (this.teamCount = v)),
    );
    void wrapRefreshablePromise(
      ApiStore.referral.getTotalIncome().then((value) => value.data),
      this.totalIncome,
      (v) => runInAction(() => (this.totalIncome = v)),
    );
  }

  searchTransactionsPage(
    query: string,
    nextPageToken?: string,
  ): Promise<ReferralSearchPage> {
    return ApiStore.referral
      .searchReferral({query, nextPageToken})
      .then((v) => v.data);
  }

  loadLane(user: number, nextPageToken?: string): Promise<TreeLanePage> {
    return ApiStore.referral
      .getFirstLane({id: user, nextPageToken})
      .then((v) => v.data);
  }
}

export default RealReferralStore;
