import {
  ExchangeConnectionItem as ECItem,
  ExchangeConnectionItemStateEnum,
} from '../../../../api';
import {FC, useState} from 'react';
import MarketLogo from '../../graphics/MarketLogo';
import Icon from '../../graphics/Icon';
import {FormattedMessage} from 'react-intl';
import cls from './style.module.scss';
import Typography from '../../typo/Typography';
import {format, parseISO} from 'date-fns';
import Menu from '../../container/Menu';
import {observer} from 'mobx-react';
import Badge from '../../container/Badge';

interface Props extends ECItem {
  onClick?: () => void;
  onEdit?: () => void;
}

const ExchangeConnectionItem: FC<Props> = ({
  name,
  exchange,
  expiresAt,
  state,
  onClick,
  onEdit,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div
      className={
        cls.container +
        ' ' +
        ((state === ExchangeConnectionItemStateEnum.Failed ||
          state === ExchangeConnectionItemStateEnum.Expired) &&
          cls.failed)
      }
      onClick={onClick}
    >
      <span className={cls.name}>
        <div className={cls.nameText}>{name}</div>
        <Menu
          invertX
          anchor={
            <Icon
              name={'menu-dots'}
              size={24}
              clickable
              onClick={() => setMenuOpen(true)}
              tint={menuOpen ? 'primary' : 'default'}
            />
          }
          open={menuOpen}
          onDismiss={() => setMenuOpen(false)}
        >
          <Menu.Item
            text={{id: 'common.edit', defaultMessage: 'Edit'}}
            icon={'edit'}
            iconTint={'default'}
            onClick={onEdit}
          />
        </Menu>
      </span>
      <MarketLogo market={exchange} />
      {expiresAt && (
        <div className={cls.expires}>
          <Icon name={'clock'} size={24} tint={'gray500'} />
          <FormattedMessage
            id={'components.ExchangeConnectionItem.expires'}
            defaultMessage={'Expires at: {expiresAt}'}
            values={{
              expiresAt: format(parseISO(expiresAt), 'dd LLL yyyy, HH:mm'),
            }}
          />
          {state === ExchangeConnectionItemStateEnum.ExpiresSoon && (
            <Badge color={'yellow-bg'} small>
              <Typography text={{id: 'common.soon', defaultMessage: 'Soon'}} />
            </Badge>
          )}
        </div>
      )}
      {!expiresAt && (
        <div className={cls.expires}>
          <Icon name={'clock'} size={24} tint={'gray500'} />
          <FormattedMessage
            id={'components.ExchangeConnectionItem.doesNotExpire'}
            defaultMessage={'Does not expire'}
          />
        </div>
      )}
      {state === ExchangeConnectionItemStateEnum.MinorConfigurationProblems && (
        <div className={cls.status}>
          <Icon name={'link-mini-warning'} size={24} tint={'yellow'} />
          <FormattedMessage
            id={'components.ExchangeConnectionItem.incorrectlyConfigured'}
            defaultMessage={'Incorrectly configured'}
          />
        </div>
      )}
      {state ===
        ExchangeConnectionItemStateEnum.CriticalConfigurationProblems && (
        <div className={cls.status + ' ' + cls.error}>
          <Icon name={'link-mini-error'} size={24} tint={'red'} />
          <FormattedMessage
            id={'components.ExchangeConnectionItem.incorrectlyConfigured'}
            defaultMessage={'Incorrectly configured'}
          />
        </div>
      )}
      {state === ExchangeConnectionItemStateEnum.Failed && (
        <div className={cls.status + ' ' + cls.error}>
          <Icon name={'link-mini-error'} size={24} tint={'red'} />
          <FormattedMessage
            id={'components.ExchangeConnectionItem.failed'}
            defaultMessage={'Connection failed'}
          />
        </div>
      )}
      {state === ExchangeConnectionItemStateEnum.Expired && (
        <div className={cls.status + ' ' + cls.error}>
          <Icon name={'link-mini-error'} size={24} tint={'red'} />
          <FormattedMessage
            id={'components.ExchangeConnectionItem.expired'}
            defaultMessage={'Connection expired'}
          />
        </div>
      )}
    </div>
  );
};

export default observer(ExchangeConnectionItem);
