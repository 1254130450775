import {Storage} from './Storage';
import StorageManager from './StorageManager';
import Logger from '../manager/Logger';
import StorageBackend from './backend/StorageBackend';
import LocalStorageBackend from './backend/LocalStorageBackend';
import {MAINTENANCE_STORAGE_KEEP_ALIVE} from '../config';

interface StoredMaintenance {
  id: string;
  when: number;
}

class MaintenanceStorage implements Storage {
  private static instance: MaintenanceStorage;
  private readonly backend: StorageBackend = new LocalStorageBackend(
    'maintenance',
  );

  static get(): MaintenanceStorage {
    if (!this.instance) {
      this.instance = new MaintenanceStorage();
      StorageManager.addStorage('maintenance', this.instance);
      Logger.debug('MaintenanceStorage', 'start', 'MaintenanceStorage started');
      setTimeout(() => this.instance.cleanup(), 10000);
    }
    return this.instance;
  }

  private getMaintenancesFromStorage() {
    const json = this.backend.getString('maintenances');
    if (json == undefined) {
      return [];
    }
    const data: StoredMaintenance[] = JSON.parse(json);
    return data;
  }

  private write(data: StoredMaintenance[]) {
    this.backend.set('maintenances', JSON.stringify(data));
  }

  getViewedMaintenances(): string[] {
    return this.getMaintenancesFromStorage().map((value) => value.id);
  }

  addViewedMaintenance(id: string) {
    const arr = this.getMaintenancesFromStorage();
    arr.push({id, when: Date.now()});
    this.write(arr);
  }

  cleanup() {
    const original = this.getMaintenancesFromStorage();
    const cleanedUp = original.filter(
      (value) => Date.now() - value.when < MAINTENANCE_STORAGE_KEEP_ALIVE,
    );
    Logger.debug(
      'MaintenanceStorage',
      'cleanup',
      `MaintenanceStorage cleaned up ${
        original.length - cleanedUp.length
      } entries`,
    );
    this.write(cleanedUp);
  }

  onUserLogout() {}
}

export default MaintenanceStorage;
