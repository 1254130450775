class ShareUtils {
  static shareUrl(url: string, text?: string, title?: string) {
    if (navigator.share) {
      void navigator.share({url, text, title});
    }
  }

  static canShareUrl(url: string, text?: string, title?: string): boolean {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      return false; // on desktop
    }
    if (navigator.canShare) {
      return navigator.canShare({url, title, text});
    } else {
      return true;
    }
  }
}

export default ShareUtils;
