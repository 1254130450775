import {
  CurrentSubscriptionDetailsPeriodEnum,
  SubscriptionInfoStatusEnum,
  SubscriptionInfoTypeEnum,
} from '../api';
import {MessageDescriptor} from 'react-intl';
import {HText} from '../component/design/typo/Typography';

export interface CostContainer {
  costYearly: number;
  costMonthly: number;
  costThreeMonth: number;
}

class SubscriptionUtils {
  static formatType(type: SubscriptionInfoTypeEnum): MessageDescriptor {
    switch (type) {
      case 'BUSINESS':
        return {
          id: 'common.subscription.business',
          defaultMessage: 'Business',
        };
      case 'DEV':
        return {
          id: 'common.subscription.dev',
          defaultMessage: 'DEV',
        };
      case 'NONE':
        return {
          id: 'common.subscription.solo',
          defaultMessage: 'Solo',
        };
    }
  }

  static formatStatus(status: SubscriptionInfoStatusEnum): MessageDescriptor {
    switch (status) {
      case 'ACTIVE':
        return {
          id: 'common.subscriptionStatus.active',
          defaultMessage: 'Active',
        };
      case 'EXPIRED':
        return {
          id: 'common.subscriptionStatus.expired',
          defaultMessage: 'Expired',
        };
    }
  }

  static statusTextClassName(status: SubscriptionInfoStatusEnum): string {
    switch (status) {
      case 'EXPIRED':
        return 'text-fail';
      case 'ACTIVE':
        return 'text-success';
    }
  }

  static formatPeriod(period: CurrentSubscriptionDetailsPeriodEnum): HText {
    switch (period) {
      case CurrentSubscriptionDetailsPeriodEnum.Monthly:
        return {
          id: 'common.subscriptionPeriod.monthly',
          defaultMessage: 'Monthly',
        };
      case CurrentSubscriptionDetailsPeriodEnum.ThreeMonth:
        return {
          id: 'common.subscriptionPeriod.threeMonth',
          defaultMessage: '3 Months',
        };
      case CurrentSubscriptionDetailsPeriodEnum.Yearly:
        return {
          id: 'common.subscriptionPeriod.yearly',
          defaultMessage: 'Yearly',
        };
    }
  }

  static formatCost(
    currentPeriod: CurrentSubscriptionDetailsPeriodEnum,
    costs: CostContainer,
  ): HText {
    switch (currentPeriod) {
      case CurrentSubscriptionDetailsPeriodEnum.Monthly:
        return {
          id: 'common.subscriptionPeriod.costMonthly',
          defaultMessage: '{price} RCO / month',
          values: {price: costs.costMonthly},
        };
      case CurrentSubscriptionDetailsPeriodEnum.ThreeMonth:
        return {
          id: 'common.subscriptionPeriod.costThreeMonths',
          defaultMessage: '{price} RCO / 3 months',
          values: {price: costs.costThreeMonth},
        };
      case CurrentSubscriptionDetailsPeriodEnum.Yearly:
        return {
          id: 'common.subscriptionPeriod.costYearly',
          defaultMessage: '{price} RCO / year',
          values: {price: costs.costYearly},
        };
    }
  }

  static formatCurrentCost(
    currentPeriod: CurrentSubscriptionDetailsPeriodEnum,
    cost: number,
  ): HText {
    switch (currentPeriod) {
      case CurrentSubscriptionDetailsPeriodEnum.Monthly:
        return {
          id: 'common.subscriptionPeriod.costMonthly',
          defaultMessage: '{price} RCO / month',
          values: {price: cost},
        };
      case CurrentSubscriptionDetailsPeriodEnum.ThreeMonth:
        return {
          id: 'common.subscriptionPeriod.costThreeMonths',
          defaultMessage: '{price} RCO / 3 months',
          values: {price: cost},
        };
      case CurrentSubscriptionDetailsPeriodEnum.Yearly:
        return {
          id: 'common.subscriptionPeriod.costYearly',
          defaultMessage: '{price} RCO / year',
          values: {price: cost},
        };
    }
  }

  static getMonthlyPrice(
    period: CurrentSubscriptionDetailsPeriodEnum,
    price: number,
  ) {
    switch (period) {
      case CurrentSubscriptionDetailsPeriodEnum.Yearly:
        return price / 12;
      case CurrentSubscriptionDetailsPeriodEnum.ThreeMonth:
        return price / 3;
      case CurrentSubscriptionDetailsPeriodEnum.Monthly:
        return price;
    }
  }
}

export default SubscriptionUtils;
