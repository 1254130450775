import {FC, ReactNode} from 'react';
import cls from './style.module.scss';

interface Props {
  children: ReactNode;
}

const FilterHeader: FC<Props> = ({children}) => (
  <div className={cls.container}>{children}</div>
);

export default FilterHeader;
