import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import openAccount from '../../../../../assets/onboarding/binance/apikeys/open-account.png';
import moreServices from '../../../../../assets/onboarding/binance/apikeys/more-services.png';

const GoToServicesFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.GoToServicesFragment.title',
        defaultMessage:
          'Press <h>profile icon</h> in top left corner, then press <h>More Services</h>',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={openAccount} second={moreServices} />
  </>
);

export default GoToServicesFragment;
