import {FC} from 'react';

interface Props {
  width?: number;
  height?: number;
}

const BybitIcon: FC<Props> = ({width, height}) => (
  <svg
    width={width ?? 24}
    height={height ?? 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5C0 2.23858 2.23858 0 5 0H19C21.7614 0 24 2.23858 24 5V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V5Z"
      fill="white"
    />
    <path
      d="M11.1485 10.3401H9.44573L6.96032 5.49189C6.55304 4.69796 6.7946 3.68183 7.5003 3.22358C7.73238 3.07254 7.98587 3.00098 8.2362 3.00098C8.74564 3.00098 9.24139 3.2987 9.51454 3.83167L12 8.67906L11.1485 10.3401Z"
      fill="#FCBC05"
    />
    <path
      d="M16.5 20.7776C15.795 21.2363 14.8927 20.9638 14.485 20.1695L11.9996 15.3221L9.51422 20.1695C9.10695 20.9634 8.20498 21.2363 7.49998 20.7776C7.02706 20.47 6.76233 19.9117 6.76233 19.338C6.76233 19.0561 6.82588 18.7707 6.96 18.5085L9.44541 13.6611H14.5546L17.04 18.5085C17.4472 19.3032 17.205 20.319 16.5 20.7776Z"
      fill="#E94335"
    />
    <path
      d="M21 11.9999C21 12.9172 20.3396 13.6609 19.525 13.6609H14.5546L13.7028 11.9999L12.8518 10.3393L12 8.67831L14.4854 3.83092C14.8927 3.0362 15.795 2.76418 16.5004 3.22282C17.2054 3.68107 17.4476 4.6972 17.0403 5.49113L14.5546 10.3397H19.525C20.3399 10.3397 21 11.083 21 11.9999Z"
      fill="#1973E8"
    />
    <path
      d="M11.1486 10.3398L10.2968 12.0005L9.44578 13.6615H4.4746C3.66041 13.6615 3 12.9177 3 12.0005C3 11.5422 3.16466 11.1271 3.43185 10.8262C3.69903 10.5261 4.06698 10.3398 4.4746 10.3398H11.1486Z"
      fill="#34A853"
    />
    <path
      d="M14.5546 13.6613H9.4458L10.2969 12.0003L11.1486 10.3401L12 8.67871L12.8518 10.3401L13.7028 12.0003L14.5546 13.6613Z"
      fill="#174FA7"
    />
  </svg>
);

export default BybitIcon;
