import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import ActiveText from '../../button/ActiveText';
import cls from './style.module.scss';

interface Props {
  header: HText;
  onSkip?: () => void;
}

const StepHeader: FC<Props> = ({header, onSkip}) => (
  <div className={cls.header}>
    <Typography text={header} className={cls.title} />
    {onSkip && (
      <ActiveText
        text={{id: 'common.skip', defaultMessage: 'Skip'}}
        onClick={onSkip}
      />
    )}
  </div>
);

export default StepHeader;
