import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/p2p/buy-end-1.png';
import v2 from '../../../../../assets/onboarding/bybit/p2p/buy-end-2.png';
import Text from '../../../../../component/design/typo/Text';

const BuyEndFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.BuyEndFragment.title',
        defaultMessage:
          'After transaction is completed, press <h>Payment Completed</h>',
      }}
    />
    <Spacer size={0.5} />
    <Text
      text={{
        id: 'fragments.onboarding.BuyEndFragment.text',
        defaultMessage:
          'Aou can also concat trader by opening char in right top corner',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default BuyEndFragment;
