import React from 'react';
import {FormattedMessage, FormattedNumberParts} from 'react-intl';
import CurrencyUtils from '../../../utils/CurrencyUtils';
import cls from './style.module.scss';
import Avatar from '../../design/misc/Avatar';

interface Props {
  photo: string;
  name: string;
  depth: number;
  income: number;
  incomePercent: number;
}

const ReferralSearchItem: React.FunctionComponent<Props> = ({
  photo,
  name,
  depth,
  income,
  incomePercent,
}) => (
  <div className={cls.container}>
    <Avatar src={photo} className={cls.photo} size={48} />
    <div className={cls.mainContainer}>
      <div className={cls.firstLine}>
        <span className={cls.lane}>
          <FormattedMessage
            id={'component.ReferralSearchItem.depth'}
            defaultMessage={
              '{depth, selectordinal,\n' +
              '    one {#st}\n' +
              '    two {#nd}\n' +
              '    few {#rd}\n' +
              '    other {#th}\n' +
              '} line'
            }
            values={{depth: depth}}
          />
        </span>
        <span
          className={
            cls.income +
            ' ' +
            (income !== undefined && income >= 0
              ? 'text-balance-up'
              : 'text-balance-down')
          }
        >
          {income !== undefined ? (
            <>
              {' +'}
              <FormattedNumberParts value={income} maximumFractionDigits={2}>
                {CurrencyUtils.formatBalance}
              </FormattedNumberParts>
              {incomePercent !== undefined && ` USDT (${incomePercent / 100}%)`}
            </>
          ) : (
            ''
          )}
        </span>
      </div>
      <span className={cls.name}>{name}</span>
    </div>
  </div>
);

export default ReferralSearchItem;
