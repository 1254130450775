import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/huobi/first/link-gauth-1.png';
import v2 from '../../../../../assets/onboarding/huobi/first/link-gauth-2.png';

const LinkGAuthFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.huobi.LinkGAuthFragment.title',
        defaultMessage: 'Press <h>Link Now</h> to connect Google Authenticator',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default LinkGAuthFragment;
