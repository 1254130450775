import React from 'react';
import {RobotSummaryInfo} from '../../../api';
import {observer} from 'mobx-react';
import Card from '../Card';
import cls from './style.module.scss';
import AsyncStateWrapper from '../AsyncStateWrapper';
import {FormattedMessage} from 'react-intl';
import {COLOR_TEXT, DISTRIBUTION_CHART_COLORS} from '../../../config';
import {Pie} from 'react-chartjs-2';
import {ChartOptions} from 'chart.js';
import {Context} from 'chartjs-plugin-datalabels';
import CurrencyUtils from '../../../utils/CurrencyUtils';
import LegendItem from './LegendItem';
import NoInfoContent from './NoInfoContent';

interface Props {
  info: RefreshableAsyncState<RobotSummaryInfo> | AsyncState<RobotSummaryInfo>;
}

function getLegend(info: RobotSummaryInfo): {symbol: string; value: string}[] {
  const sum = new Map<string, number>();
  for (const summary of info.robotSummaries) {
    if (!summary.balance) {
      continue;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const v = sum.has(summary.pair.money) ? sum.get(summary.pair.money)! : 0;
    sum.set(
      summary.pair.money,
      v + summary.balance.money * summary.balance.moneyPrice,
    );
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const v1 = sum.has(summary.pair.asset) ? sum.get(summary.pair.asset)! : 0;
    sum.set(
      summary.pair.asset,
      v1 +
        summary.balance.asset *
          summary.balance.price *
          summary.balance.moneyPrice,
    );
  }
  let total =
    sum.size === 0
      ? 0
      : [...sum.values()].reduce(
          (previousValue, currentValue) => previousValue + currentValue,
        );
  if (total === 0) {
    total = 1;
  }
  let toRet = [...sum.entries()].map((value) => ({
    symbol: value[0],
    valueNumeric: Math.round((value[1] * 10 * 100) / total) / 10,
    value: `${(Math.round((value[1] * 10 * 100) / total) / 10).toString(10)}%`,
  }));
  toRet.sort((a, b) => b.valueNumeric - a.valueNumeric);
  if (toRet.length > 6) {
    const otherSum = toRet
      .slice(5)
      .map((value) => value.valueNumeric)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    toRet = toRet.slice(0, 5);
    toRet.push({
      symbol: 'Other',
      valueNumeric: otherSum,
      value: `${(Math.round(otherSum * 10) / 10).toString(10)}%`,
    });
  }
  return toRet;
}

function getPoints(info: RobotSummaryInfo): number[] {
  const sum = new Map<string, number>();
  for (const summary of info.robotSummaries) {
    if (!summary.balance) {
      continue;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const v = sum.has(summary.pair.money) ? sum.get(summary.pair.money)! : 0;
    sum.set(
      summary.pair.money,
      v + summary.balance.money * summary.balance.moneyPrice,
    );
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const v1 = sum.has(summary.pair.asset) ? sum.get(summary.pair.asset)! : 0;
    sum.set(
      summary.pair.asset,
      v1 +
        summary.balance.asset *
          summary.balance.price *
          summary.balance.moneyPrice,
    );
  }
  const entries = [...sum.entries()];
  entries.sort((a, b) =>
    CurrencyUtils.compareByDistributionChartColor(a[0], b[0]),
  );
  const ret = [];
  for (const entry of entries) {
    ret.push(entry[1]);
  }
  return ret;
}

const CHART_OPTIONS: ChartOptions<'pie'> = {
  responsive: true,
  maintainAspectRatio: false,
  parsing: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    parsing: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    normalized: true,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      formatter: (value: number, ctx: Context) => {
        let sum = 0;
        const dataArr = ctx.chart.data.datasets[0].data;
        dataArr.forEach((data) => {
          if (typeof data !== 'number') return;
          sum += data;
        });
        const percent = (value * 100) / sum;
        if (percent < 5) {
          return '';
        } else {
          return percent.toFixed(0) + '%';
        }
      },
      color: COLOR_TEXT,
    },
  },
};

const AssetDistributionCard: React.FunctionComponent<Props> = ({info}) => (
  <Card className={cls.card}>
    <AsyncStateWrapper state={info}>
      {(info) => {
        const legend = getLegend(info);
        if (legend.length === 0) {
          return <NoInfoContent />;
        }
        return (
          <>
            <div className={cls.chart}>
              <Pie
                options={CHART_OPTIONS}
                data={{
                  labels: ['', '', ''],
                  datasets: [
                    {
                      label: '',
                      data: getPoints(info),
                      backgroundColor: DISTRIBUTION_CHART_COLORS,
                      hoverOffset: 0,
                      borderWidth: 0,
                    },
                  ],
                }}
              />
            </div>
            <div className={cls.legend}>
              <p className={cls.legendHeader}>
                <FormattedMessage
                  id={'components.AssetDistributionCard.assetDistribution'}
                  defaultMessage={'Asset\ndistribution:\n'}
                />
              </p>
              {legend.map((value) => (
                <LegendItem key={value.symbol} {...value} />
              ))}
            </div>
          </>
        );
      }}
    </AsyncStateWrapper>
  </Card>
);

export default observer(AssetDistributionCard);
