import StorageBackend from './backend/StorageBackend';
import LocalStorageBackend from './backend/LocalStorageBackend';
import StorageManager from './StorageManager';
import Logger from '../manager/Logger';

class CurrentExchangeConnectionStorage {
  private static instance: CurrentExchangeConnectionStorage;
  private readonly backend: StorageBackend = new LocalStorageBackend(
    'currentEC',
  );

  static get(): CurrentExchangeConnectionStorage {
    if (!this.instance) {
      this.instance = new CurrentExchangeConnectionStorage();
      StorageManager.addStorage('current_ec', this.instance);
      Logger.debug(
        'CurrentExchangeConnectionStorage',
        'start',
        'CurrentExchangeConnectionStorage started',
      );
    }
    return this.instance;
  }

  get id(): string | undefined {
    return this.backend.getString('currentECId');
  }

  set id(id: string | undefined) {
    if (id) {
      this.backend.set('currentECId', id);
    } else {
      this.backend.delete('currentECId');
    }
  }

  onUserLogout() {
    this.backend.delete('currentECId');
  }
}

export default CurrentExchangeConnectionStorage;
