import {FC, ReactNode} from 'react';
import Typography from '../../typo/Typography';
import cls from './style.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
}

const Permissions: FC<Props> = ({children}) => (
  <div className={cls.permissions}>
    <Typography
      text={{id: 'common.permissions', defaultMessage: 'Permissions'}}
      className={cls.label}
    />
    {children}
  </div>
);

export default Permissions;
