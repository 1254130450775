import React from 'react';
import {RobotPair, RobotTradeItemStateEnum} from '../../../api';
import Card from '../Card';
import cls from './style.module.scss';
import FirstLine from './FirstLine';
import TradeLine from './TradeLine';
import Header from './Header';

interface Props {
  state: RobotTradeItemStateEnum;
  pair: RobotPair;
  profit?: number;
  boughtAt?: Date;
  buyPrice: number;
  buyAmount: number;
  buyVolume: number;
  soldAt?: Date;
  soldAmount?: number;
  soldPrice?: number;
  soldVolume?: number;
  onClick?: () => void;
}

const RobotTradeCard: React.FunctionComponent<Props> = (props) => (
  <Card onClick={props.onClick} className={cls.card}>
    <FirstLine pair={props.pair} profit={props.profit} state={props.state} />
    <Header />
    <TradeLine
      type={{id: 'components.RobotTradeCard.buy', defaultMessage: 'BUY'}}
      price={props.buyPrice}
      amount={props.buyAmount}
      volume={props.buyVolume}
      date={props.boughtAt}
    />
    {props.soldAmount && props.soldPrice && props.soldVolume && (
      <>
        <span className={cls.tradePad} />
        <TradeLine
          type={{
            id: 'components.RobotTradeCard.sell',
            defaultMessage: 'SELL',
          }}
          price={props.soldPrice}
          amount={props.soldAmount}
          volume={props.soldVolume}
          date={props.soldAt}
        />
      </>
    )}
  </Card>
);

export default RobotTradeCard;
