import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import {
  AdvanceStateDtoActionEnum,
  AdvanceStateDtoStageEnum,
  ExchangeConnectionDetails,
  ExchangeConnectionDetailsStateEnum,
  RobotCreateRequestMarketEnum,
} from '../../api';
import {
  notLoaded,
  sleep,
  wrapPromise,
  wrapRefreshablePromise,
} from '../../utils';
import stores from '../../store';

const CHECK_INTERVAL = 500;

class ExchangeConnectionStatusViewModel {
  request: RefreshableAsyncState<ExchangeConnectionDetails> = notLoaded();
  createRobotRequest: AsyncState<void> = notLoaded();
  private running = true;

  constructor(
    private readonly id: string,
    private readonly mode: 'onboarding',
    private readonly onSuccess: (robotId: string | undefined) => void,
  ) {
    makeAutoObservable(this, {
      request: observable,
      refresh: action.bound,
    });
  }

  init() {
    if (this.createRobotRequest.status !== 'not-requested') {
      return;
    }
    this.running = true;
    (async () => {
      while (this.running) {
        await wrapRefreshablePromise(
          stores.exchangeConnection.getDetails(this.id),
          this.request,
          (v) => {
            runInAction(() => {
              this.request = v;
              if (v.status === 'success' || v.status === 'refreshing-success') {
                if (
                  v.result.state !== ExchangeConnectionDetailsStateEnum.Fetching
                ) {
                  this.running = false;
                }
                if (
                  v.result.state === ExchangeConnectionDetailsStateEnum.Ok ||
                  v.result.state ===
                    ExchangeConnectionDetailsStateEnum.ExpiresSoon ||
                  v.result.state ===
                    ExchangeConnectionDetailsStateEnum.MinorConfigurationProblems ||
                  v.result.state ===
                    ExchangeConnectionDetailsStateEnum.CriticalConfigurationProblems
                ) {
                  if (this.mode === 'onboarding') {
                    void wrapPromise(
                      this.createRobotForEC(v.result),
                      this.createRobotRequest,
                      (v) => runInAction(() => (this.createRobotRequest = v)),
                    );
                  } else {
                    this.onSuccess(undefined);
                  }
                }
              }
            });
          },
        );
        await sleep(CHECK_INTERVAL);
      }
    })();
  }

  private createRobotForEC(ec: ExchangeConnectionDetails): Promise<void> {
    return stores.robot
      .getMarkets()
      .then((value) => {
        const currentMarket = value.markets.find((m) => m.name == ec.exchange);
        if (!currentMarket) {
          return Promise.reject(new Error('No market found! Contact support'));
        }
        const defaultPair = currentMarket.pairs.find(
          (p) => p.pair === currentMarket.defaultPair,
        );
        if (!defaultPair) {
          return Promise.reject(
            new Error('No default pair found! Contact support'),
          );
        }
        return stores.robot.onAdd({
          exchangeConnection: ec.id,
          market: undefined as unknown as RobotCreateRequestMarketEnum,
          apiKey: undefined as unknown as string,
          apiSecret: undefined as unknown as string,
          pair: {
            money: defaultPair.money,
            asset: defaultPair.asset,
          },
        });
      })
      .then((value) => {
        stores.onboarding.advance(
          AdvanceStateDtoStageEnum.ApiKeys,
          AdvanceStateDtoActionEnum.Pass,
          ec.exchange,
        );
        this.onSuccess(value);
      });
  }

  stopUpdate() {
    this.running = false;
  }

  refresh() {
    this.request = {status: 'loading'};
    stores.exchangeConnection
      .refreshConnection(this.id)
      .then(() => {
        this.running = true;
        this.init();
      })
      .catch((e) => {
        runInAction(() => {
          this.request = {status: 'error', error: e};
        });
      });
  }

  retryRobotCreate() {
    if (this.request.status === 'success') {
      void wrapPromise(
        this.createRobotForEC(this.request.result),
        this.createRobotRequest,
        (v) => runInAction(() => (this.createRobotRequest = v)),
      );
    }
  }
}

export default ExchangeConnectionStatusViewModel;
