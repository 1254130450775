import Typography, {HText} from '../../typo/Typography';
import {FC, FormEvent, useCallback, useRef} from 'react';
import InputBase from '../InputBase';
import {BaseInputProps} from '../types';
import cls from './style.module.scss';
import useHText from '../../../../hooks/useHText';
import useIsFocused from '../../../../hooks/useIsFocused';
import useHandleSubmitEditing from '../../../../hooks/useHandleSubmitEditing';
import {useMeasureTextWidth} from '../../../../hooks/useMeasureTextWidth';

export interface Props extends BaseInputProps {
  min?: number;
  max?: number;
  decimals?: number;
  currency: HText;
}

const AmountInput: FC<Props> = ({
  label,
  min,
  max,
  decimals,
  currency,
  value,
  onChange,
  error,
  enterKeyHint,
  onFocus,
  disabled,
  onSubmitEditing,
  onBlur,
  placeholder,
}) => {
  const hText = useHText();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focused, focus, blur] = useIsFocused(onFocus, onBlur);
  const submitHandle = useHandleSubmitEditing(onSubmitEditing);
  const measureTextWidth = useMeasureTextWidth(inputRef);

  const onInput = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      if (onChange) {
        let v = (event.currentTarget.value ?? '').replace(',', '.');
        while (v.startsWith('0') && v != '0') {
          v = v.substring(1);
        }
        let charI = 0;
        let hasDot = false;
        for (const char of v) {
          if (charI === 0 && char === '-' && (min === undefined || min < 0)) {
            charI++;
            continue;
          }
          charI++;
          if (
            !hasDot &&
            char === '.' &&
            (decimals === undefined || decimals > 0)
          ) {
            hasDot = true;
            continue;
          }
          if (char >= '0' && char <= '9') {
            continue;
          }
          event.preventDefault();
          return;
        }

        if (
          v.length === 0 ||
          v === '-' ||
          (v.endsWith('.') && !isNaN(parseInt(v.substring(0, v.length - 1))))
        ) {
          onChange(v);
        } else {
          const parsed = decimals === 0 ? parseInt(v) : parseFloat(v);
          if (isNaN(parsed)) {
            event.preventDefault();
            return;
          }
          if (
            (min !== undefined && parsed < min) ||
            (max !== undefined && parsed > max)
          ) {
            event.preventDefault();
            return;
          }
          if (decimals !== undefined) {
            const pow = Math.pow(10, decimals);
            if (
              Math.abs(Math.floor(parsed * pow) / pow - parsed) >
              Math.pow(10, -(decimals + 1))
            ) {
              event.preventDefault();
              return;
            }
          }
          onChange(v);
        }
      }
    },
    [onChange, min, max, decimals],
  );

  return (
    <InputBase
      contentClassName={cls.content}
      label={label}
      error={error}
      selected={focused}
      onContentClick={() => inputRef.current?.focus()}
    >
      <input
        ref={inputRef}
        value={value}
        placeholder={hText(placeholder)}
        onInput={!disabled && onChange ? onInput : undefined}
        onBlur={blur}
        onFocus={focus}
        onKeyUp={submitHandle}
        enterKeyHint={enterKeyHint}
        style={{
          width:
            measureTextWidth(
              value.length === 0 ? hText(placeholder) ?? '' : value,
            ) + 'px',
        }}
      />
      <Typography className={cls.currency} text={currency} />
    </InputBase>
  );
};

export default AmountInput;
