import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import cls from './style.module.scss';
import {ExchangeConnectionItemExchangeEnum} from '../../../../api';
import MarketLogo from '../../graphics/MarketLogo';

interface Props {
  name: HText;
  value: ExchangeConnectionItemExchangeEnum;
}

const ExchangeItem: FC<Props> = ({name, value}) => (
  <div className={cls.item}>
    <Typography text={name} className={cls.label} />
    <MarketLogo market={value} />
  </div>
);

export default ExchangeItem;
