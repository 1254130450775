import {
  Configuration,
  UserApi,
  AuthApi,
  RobotApi,
  AccessToken,
  WalletApi,
  ReferralApi,
  SubscriptionApi,
  NotificationsApi,
  MaintenanceApi,
  ExchangeConnectionApi,
  OnboardingApi,
} from '../api';
import {API_URL, AUTH_EXPIRY_DELAY} from '../config';
import TokenStorage from '../storage/TokenStorage';
import Logger from '../manager/Logger';
import AsyncLock from 'async-lock';

interface EnrichedAccessToken extends AccessToken {
  createdAt: number;
}

class ApiStore {
  private static initConfig = new Configuration({
    basePath: API_URL,
  });
  private static accessToken: EnrichedAccessToken | undefined = undefined;
  private static refreshTokenLock = new AsyncLock();
  public static user: UserApi = new UserApi(ApiStore.initConfig);
  public static robot: RobotApi = new RobotApi(ApiStore.initConfig);
  public static auth: AuthApi = new AuthApi(ApiStore.initConfig);
  public static wallet: WalletApi = new WalletApi(ApiStore.initConfig);
  public static referral: ReferralApi = new ReferralApi(ApiStore.initConfig);
  public static subscription: SubscriptionApi = new SubscriptionApi(
    ApiStore.initConfig,
  );
  public static notifications: NotificationsApi = new NotificationsApi(
    ApiStore.initConfig,
  );
  public static maintenance: MaintenanceApi = new MaintenanceApi(
    ApiStore.initConfig,
  );
  public static exchangeConnection: ExchangeConnectionApi =
    new ExchangeConnectionApi(ApiStore.initConfig);
  public static onboarding: OnboardingApi = new OnboardingApi(
    ApiStore.initConfig,
  );

  static init(token: AccessToken): void {
    TokenStorage.get().setRefreshToken(token.refresh_token);
    ApiStore.accessToken = {...token, createdAt: Date.now()};
    const configuration = new Configuration({
      basePath: API_URL,
      accessToken: async () => {
        return await ApiStore.refreshTokenLock.acquire('refresh', async () => {
          const accessToken = ApiStore.accessToken;
          if (!accessToken) {
            return await ApiStore.refresh();
          }
          const expiresWhen =
            accessToken.createdAt +
            accessToken.expires_in * 1000 +
            AUTH_EXPIRY_DELAY;
          if (Date.now() > expiresWhen) {
            return await ApiStore.refresh();
          } else {
            return accessToken.access_token;
          }
        });
      },
    });
    ApiStore.user = new UserApi(configuration);
    ApiStore.robot = new RobotApi(configuration);
    ApiStore.wallet = new WalletApi(configuration);
    ApiStore.referral = new ReferralApi(configuration);
    ApiStore.subscription = new SubscriptionApi(configuration);
    ApiStore.notifications = new NotificationsApi(configuration);
    ApiStore.maintenance = new MaintenanceApi(configuration);
    ApiStore.exchangeConnection = new ExchangeConnectionApi(configuration);
    ApiStore.onboarding = new OnboardingApi(configuration);
    Logger.debug('ApiStore', 'init', 'ApiStore got tokens, ready to go');
  }

  static async refresh(): Promise<string> {
    const token = TokenStorage.get().getRefreshToken();
    if (!token) {
      throw Error('Refresh token not found');
    }
    const t = await this.auth.getToken({
      grantType: 'refresh_token',
      refreshToken: token,
    });
    ApiStore.init(t.data);
    Logger.debug('ApiStore', 'refresh', 'Refresh successful');
    return t.data.access_token;
  }

  static reset(): void {
    ApiStore.user = new UserApi(ApiStore.initConfig);
    ApiStore.robot = new RobotApi(ApiStore.initConfig);
    ApiStore.wallet = new WalletApi(ApiStore.initConfig);
    ApiStore.referral = new ReferralApi(ApiStore.initConfig);
    ApiStore.subscription = new SubscriptionApi(ApiStore.initConfig);
    ApiStore.notifications = new NotificationsApi(ApiStore.initConfig);
    ApiStore.maintenance = new MaintenanceApi(ApiStore.initConfig);
    ApiStore.onboarding = new OnboardingApi(ApiStore.initConfig);
    ApiStore.exchangeConnection = new ExchangeConnectionApi(
      ApiStore.initConfig,
    );
  }
}

export default ApiStore;
