import {WalletStore} from '../../../../store/WalletStore';
import React, {FC, useEffect, useMemo} from 'react';
import {inject, observer} from 'mobx-react';
import AsyncStateWrapper from '../../../../component/ui/AsyncStateWrapper';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {IntlShape, useIntl} from 'react-intl';
import IntlUtils from '../../../../utils/IntlUtils';
import {WithdrawalRequestConfig} from '../../../../api';
import ErrorUtils from '../../../../utils/ErrorUtils';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import Scaffolding from '../../../../component/container/Scaffolding';
import Form from '../../../../component/container/Form';
import BigAmountDisplay from '../../../../component/design/typo/BigAmountDisplay';
import FlexCol from '../../../../component/design/container/FlexCol';
import InfoBanner from '../../../../component/design/banner/InfoBanner';
import AmountField from '../../../../component/design/field/AmountField';
import TextField from '../../../../component/design/field/TextField';
import WarningBanner from '../../../../component/design/banner/WarningBanner';
import Typography from '../../../../component/design/typo/Typography';

interface FormValues {
  amount: number;
  address: string;
}

function validate(
  intl: IntlShape,
  cfg: WithdrawalRequestConfig,
  values: FormValues,
): Partial<Record<keyof FormValues, string>> {
  const ret: Partial<Record<keyof FormValues, string>> = {};
  if (values.amount <= 0) {
    ret.amount = IntlUtils.form(intl, 'positive');
  }
  if (values.address.length === 0) {
    ret.address = IntlUtils.form(intl, 'not-empty');
  }

  const net = cfg.networks.find((value) => value.isDefault);
  if (!net) {
    ret.address = IntlUtils.form(intl, 'network-unsupported', {net: 'TRX'});
    return ret;
  }

  if (net.addressRegex) {
    if (!new RegExp(net.addressRegex).test(values.address)) {
      ret.address = IntlUtils.form(intl, 'address-invalid', {net: net.name});
    }
  }

  const min = Math.max(cfg.minAmount, net.minAmount);
  if (values.amount < min) {
    ret.amount = IntlUtils.form(intl, 'at-least', {v: min});
  }

  return ret;
}

function submit(
  wallet: WalletStore,
  cfg: WithdrawalRequestConfig,
  values: FormValues,
  navigate: NavigateFunction,
  bag: FormikHelpers<FormValues>,
): Promise<void> {
  const net = cfg.networks.find((value) => value.isDefault);
  if (!net) {
    return Promise.reject(new Error('No default network set!'));
  }
  return wallet
    .createWithdrawal(values.address, values.amount, net.network)
    .then((data) => {
      navigate('/app/wallet/sell/' + data.id + '/confirm', {replace: true});
    })
    .catch((reason: Error) => {
      ErrorUtils.setFormikError(bag, reason);
      throw reason;
    });
}

interface RProps {
  wallet: WalletStore;
}

const ValuesForm: React.FunctionComponent<
  FormikProps<FormValues> & {balance: number; cfg: WithdrawalRequestConfig}
> = (props) => {
  const intl = useIntl();
  const defaultNet = useMemo(
    () => props.cfg.networks.find((value) => value.isDefault),
    [props.cfg],
  );
  if (!defaultNet) {
    return <span>Default net not selected. Contact support immediately</span>;
  }
  const min = Math.max(defaultNet.minAmount, props.cfg.minAmount, 0);

  return (
    <Scaffolding
      header={{
        id: 'nav.sellRCO',
        defaultMessage: 'Sell RCO',
      }}
    >
      <Form
        submitLabel={{
          id: 'common.continue',
          defaultMessage: 'Continue',
        }}
        noFooter
      >
        <FlexCol gap={1.5}>
          {props.balance < min && (
            <WarningBanner
              text={IntlUtils.formatMessage(
                intl,
                {
                  id: 'screens.SellRcoStartScreen.notEnough',
                  defaultMessage:
                    "You don't have enough money to make a withdrawal. Minimum <b>{min} RCO</b> required.",
                },
                {min},
              )}
            />
          )}
          <BigAmountDisplay
            label={{
              id: 'common.availRcoBalance',
              defaultMessage: 'Available RCO Balance',
            }}
            amount={props.balance}
            currency={'RCO'}
            tooltip={
              <Typography
                text={{
                  id: 'screens.SellRcoStartScreen.availRcoBalanceTooltip',
                  defaultMessage:
                    'You can only sell RCO received from others or bought by "Buy RCO" option.',
                }}
              />
            }
          />
          <FlexCol gap={0.75}>
            <AmountField
              name={'amount'}
              enterKeyHint={'next'}
              label={intl.formatMessage(
                {
                  id: 'screens.SellRcoStartScreen.amount',
                  defaultMessage: 'Withdrawal amount (minimum {min} RCO)',
                },
                {min},
              )}
              max={props.balance}
              decimals={8}
              currency={{
                id: 'currency.RCO',
                defaultMessage: 'RCO',
              }}
              placeholder={'0'}
            />
            <TextField
              name={'address'}
              enterKeyHint={'go'}
              label={{
                id: 'screens.SellRcoStartScreen.address',
                defaultMessage: 'Address',
              }}
              pasteable
              clearable
              submitForm
            />
          </FlexCol>
          <InfoBanner
            text={{
              id: 'screens.SellRcoStartScreen.info',
              defaultMessage:
                'Withdrawal commission is determined by Binance. If you provide Binance address, it will be 0. If not - it will be 1 RCO.',
            }}
          />
        </FlexCol>
      </Form>
    </Scaffolding>
  );
};

const SellRcoStartScreen: FC<RProps> = ({wallet}) => {
  const intl = useIntl();
  const nav = useNavigate();
  useEffect(() => {
    wallet.initWithdrawal();
  }, []);

  const walletInfo = wallet.wallet;
  const withdrawalConfig = wallet.withdrawalRequestConfig;

  return (
    <AsyncStateWrapper state={walletInfo}>
      {(info) => (
        <AsyncStateWrapper state={withdrawalConfig}>
          {(cfg) => (
            <Formik
              validateOnMount={false}
              validateOnChange={false}
              initialValues={{
                amount: 0,
                address: '',
              }}
              validate={(values) => validate(intl, cfg, values)}
              onSubmit={(values, bag) => submit(wallet, cfg, values, nav, bag)}
            >
              {(form) => (
                <ValuesForm {...form} balance={info.money} cfg={cfg} />
              )}
            </Formik>
          )}
        </AsyncStateWrapper>
      )}
    </AsyncStateWrapper>
  );
};

export default inject('wallet')(observer(SellRcoStartScreen)) as FC<
  Partial<RProps>
>;
