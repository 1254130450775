import React from 'react';
import cls from './style.module.scss';
import ErrorUtils from '../../../utils/ErrorUtils';

interface Props {
  error: Error;
}

const ErrorBlock: React.FunctionComponent<Props> = ({error}) => (
  <div className={cls.container}>
    <h6 className={cls.title}>{ErrorUtils.getTitle(error)}</h6>
    <p className={cls.detail}>{ErrorUtils.getDetail(error)}</p>
  </div>
);

export default ErrorBlock;
