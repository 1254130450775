import {NotificationItem, NotificationList} from '../api';
import {notLoaded, wrapPromise, wrapRefreshablePromise} from '../utils';
import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import stores from '../store';

class NotificationsListViewModel {
  items: NotificationItem[] = [];
  currentPage: RefreshableAsyncState<NotificationList> = notLoaded();
  nextToken?: string;
  ackAllState: AsyncState<void> = notLoaded();

  constructor() {
    makeAutoObservable(this, {
      currentPage: observable,
      items: observable,
      ackAllState: observable,
      nextPage: action.bound,
      checkAll: action.bound,
      resetAckAllState: action.bound,
    });

    this.loadPage();
  }

  nextPage() {
    if (
      this.currentPage.status === 'success' &&
      this.nextToken !== undefined &&
      this.nextToken !== null
    ) {
      this.loadPage(this.nextToken);
    }
  }

  private loadPage(token?: string) {
    void wrapRefreshablePromise(
      stores.notifications.getPage(token),
      this.currentPage,
      (v) =>
        runInAction(() => {
          if (v.status === 'success') {
            this.nextToken = v.result.nextPageToken;
            this.items.push(...v.result.items);
            stores.notifications.ackItems(v.result.items);
          }
          this.currentPage = v;
        }),
    );
  }

  checkAll() {
    void wrapPromise(stores.notifications.ackAll(), this.ackAllState, (v) =>
      runInAction(() => (this.ackAllState = v)),
    );
  }

  resetAckAllState() {
    this.ackAllState = {status: 'not-requested'};
  }
}

export default NotificationsListViewModel;
