import {RobotStore} from '../RobotStore';
import {
  MarketList,
  RobotConfig,
  RobotCreateRequest,
  RobotSummary,
  RobotSummaryInfo,
  RobotSummaryStatusEnum,
  RobotTradeChart,
  RobotTradeDetails,
  RobotTradeList,
} from '../../api';
import {
  ifSuccessful,
  mapRefreshableValues,
  notLoaded,
  wrapPromise,
  wrapRefreshablePromise,
} from '../../utils';
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import ApiStore from '../ApiStore';
import Logger from '../../manager/Logger';
import {format} from 'date-fns';
import {SUMMARY_UPDATE_INTERVAL} from '../../config';

class RealRobotStore implements RobotStore {
  currentRobotId: string | undefined = undefined;
  summary: RefreshableAsyncState<RobotSummaryInfo> = notLoaded();
  chartData: AsyncState<RobotTradeChart> = notLoaded();
  chartPeriod: Date = new Date();
  configuration: AsyncState<RobotConfig> = notLoaded();
  markets: RefreshableAsyncState<MarketList> = notLoaded();
  private deletedRobots = new Set<string>();

  constructor() {
    makeAutoObservable(this, {
      summary: observable,
      chartData: observable,
      configuration: observable,
      currentRobotId: observable,
      chartPeriod: observable,
      markets: observable,
      onChangeToRobot: action,
      onChangeToMain: action,
      currentSummary: computed,
      switchCurrentState: action.bound,
      setChartPeriod: action.bound,
    });
    setInterval(() => {
      const summary = this.currentSummary;
      if (summary && this.summary.status === 'success') {
        if (
          summary.statusDetails.state === RobotSummaryStatusEnum.Starting ||
          summary.statusDetails.state === RobotSummaryStatusEnum.Stopping ||
          !summary.balance
        ) {
          this.reloadSummary();
        }
      }
    }, 1000);
    setInterval(
      () => runInAction(() => this.reloadSummary(true)),
      SUMMARY_UPDATE_INTERVAL,
    );
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.reloadSummary(true);
        this.loadRobotMarkets();
      }
    });
  }

  get currentSummary(): RobotSummary | undefined {
    return ifSuccessful(
      this.summary,
      (t) => t.robotSummaries.find((value) => value.id === this.currentRobotId),
      undefined,
    );
  }

  filterSummary(t: RobotSummaryInfo): RobotSummaryInfo {
    return {
      ...t,
      robotSummaries: t.robotSummaries.filter(
        (value) => !this.deletedRobots.has(value.id),
      ),
    };
  }

  load() {
    Logger.debug('RealRobotStore', 'load', 'Loading RealRobotStore');
    this.summary = {status: 'not-requested'};
    this.reloadSummary();
    void wrapPromise(
      ApiStore.robot
        .getSummaryTradeHistoryChart({
          month: format(this.chartPeriod, 'yyyy-MM'),
        })
        .then((value) => value.data),
      this.chartData,
      (v) => runInAction(() => (this.chartData = v)),
    );
    this.loadRobotMarkets();
  }

  onChangeToRobot(id: string) {
    this.currentRobotId = id;
    void wrapPromise(
      ApiStore.robot.getRobotConfig({id}).then((value) => value.data),
      this.configuration,
      (v) => runInAction(() => (this.configuration = v)),
    );
    this.loadRobotChartData(id);
  }

  private loadRobotChartData(id: string) {
    void wrapPromise(
      ApiStore.robot
        .getRobotTradeHistoryChart({
          month: format(this.chartPeriod, 'yyyy-MM'),
          id,
        })
        .then((value) => value.data),
      this.chartData,
      (v) => runInAction(() => (this.chartData = v)),
    );
  }

  onChangeToMain() {
    this.currentRobotId = undefined;
    this.loadSummaryChartData();
  }

  private loadSummaryChartData() {
    void wrapPromise(
      ApiStore.robot
        .getSummaryTradeHistoryChart({
          month: format(this.chartPeriod, 'yyyy-MM'),
        })
        .then((value) => value.data),
      this.chartData,
      (v) => runInAction(() => (this.chartData = v)),
    );
  }

  async onAdd(request: RobotCreateRequest): Promise<string> {
    const robot = await ApiStore.robot.createRobot({
      robotCreateRequest: request,
    });
    await ApiStore.robot.enableRobot({id: robot.data.id});
    await wrapRefreshablePromise(
      ApiStore.robot.getRobots().then((value) => value.data),
      this.summary,
      (v) =>
        runInAction(
          () =>
            (this.summary = mapRefreshableValues(v, (v1) =>
              this.filterSummary(v1),
            )),
        ),
    );
    return robot.data.id;
  }

  async setConfig(id: string, config: RobotConfig): Promise<void> {
    Logger.debug('RealRobotStore', 'setConfig', `Setting config of bot ${id}`);
    await ApiStore.robot.setRobotConfig({id: id, robotConfig: config});
    if (this.currentRobotId === id) {
      runInAction(
        () => (this.configuration = {status: 'success', result: config}),
      );
    }
  }

  reloadSummary(ignoreError = false) {
    void wrapRefreshablePromise(
      ApiStore.robot.getRobots().then((value) => value.data),
      this.summary,
      (v) =>
        runInAction(() => {
          if (
            ignoreError &&
            v.status === 'error' &&
            this.summary.status === 'refreshing-success'
          ) {
            this.summary = {
              status: 'success',
              result: this.filterSummary(this.summary.result),
            };
          } else {
            this.summary = v;
          }
        }),
    );
  }

  switchCurrentState(): void {
    const summary = this.currentSummary;
    const id = this.currentRobotId;
    if (summary && !!id) {
      if (
        summary.statusDetails.state === RobotSummaryStatusEnum.Stopping ||
        summary.statusDetails.state === RobotSummaryStatusEnum.Starting
      ) {
        return;
      }
      if (summary.statusDetails.state === RobotSummaryStatusEnum.Started) {
        Logger.debug(
          'RealRobotStore',
          'switchCurrentState',
          `Stopping bot ${id}`,
        );
        void ApiStore.robot.disableRobot({id}).then(() => this.reloadSummary());
      } else {
        Logger.debug(
          'RealRobotStore',
          'switchCurrentState',
          `Starting bot ${id}`,
        );
        void ApiStore.robot.enableRobot({id}).then(() => this.reloadSummary());
      }
    }
  }

  setChartPeriod(date: Date): void {
    this.chartPeriod = date;
    if (this.currentRobotId) {
      this.loadRobotChartData(this.currentRobotId);
    } else {
      this.loadSummaryChartData();
    }
  }

  getName(id: string): string | undefined {
    return ifSuccessful(
      this.summary,
      (t) => t.robotSummaries.find((value) => value.id === id)?.name,
      undefined,
    );
  }

  async setName(id: string, name: string): Promise<void> {
    await ApiStore.robot.editRobot({
      id,
      robotEditDto: {
        name,
      },
    });
    runInAction(() => {
      if (this.summary.status === 'success') {
        for (const summary of this.summary.result.robotSummaries) {
          if (summary.id === id) {
            summary.name = name;
            break;
          }
        }
      } else {
        this.reloadSummary();
      }
    });
    return Promise.resolve(undefined);
  }

  async setApiKeys(
    id: string,
    apiKey: string,
    apiSecret: string,
  ): Promise<void> {
    await ApiStore.robot.editRobot({
      id,
      robotEditDto: {
        apiKey,
        apiSecret,
      },
    });
    this.reloadSummary();
    return Promise.resolve(undefined);
  }

  getAllTradesPage(nextPageToken?: string): Promise<RobotTradeList> {
    return ApiStore.robot
      .getAllTrades({nextPageToken})
      .then((value) => value.data);
  }

  getRobotTradesPage(
    robot: string,
    nextPageToken?: string,
  ): Promise<RobotTradeList> {
    return ApiStore.robot
      .getRobotTrades({nextPageToken, id: robot})
      .then((value) => value.data);
  }

  getTradeDetails(robot: string, trade: string): Promise<RobotTradeDetails> {
    return ApiStore.robot
      .getTradeDetails({trade, id: robot})
      .then((value) => value.data);
  }

  deleteRobot(id: string) {
    this.deletedRobots.add(id);
    this.summary = mapRefreshableValues(this.summary, (t) =>
      this.filterSummary(t),
    );
    ApiStore.robot.deleteRobot({id}).then(() => this.reloadSummary());
  }

  loadRobotMarkets() {
    void wrapRefreshablePromise(
      ApiStore.robot.getRobotMarkets().then((value) => value.data),
      this.markets,
      (v) => runInAction(() => (this.markets = v)),
    );
  }

  getMarkets(): Promise<MarketList> {
    if (
      this.markets.status === 'success' ||
      this.markets.status === 'refreshing-success'
    ) {
      return Promise.resolve(this.markets.result);
    }
    return ApiStore.robot.getRobotMarkets().then((value) => value.data);
  }
}

export default RealRobotStore;
