import React from 'react';
import cls from './style.module.scss';

interface Props {
  children: React.ReactNode;
}

const index: React.FunctionComponent<Props> = ({children}) => (
  <div className={cls.container}>
    <div className={cls.bg} />
    {children}
  </div>
);

export default index;
