import React from 'react';
import {TailSpin} from 'react-loader-spinner';

interface Props {
  size: number;
  className?: string;
}

const Loader: React.FunctionComponent<Props> = ({className, size}) => (
  <TailSpin
    wrapperClass={className}
    width={size}
    height={size}
    ariaLabel="tail-spin-loading"
    radius="1"
    visible={true}
    color={'#FFF'}
  />
);

export default Loader;
