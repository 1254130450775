import React from 'react';
import {FormattedMessage, FormattedNumberParts} from 'react-intl';
import CurrencyUtils from '../../../utils/CurrencyUtils';
import cls from './style.module.scss';
import Avatar from '../../design/misc/Avatar';

interface Props {
  name: string;
  photoUrl: string;
  teamCount: number;
  relativeDepth: number;
  randomMemberPhotoUrl?: string;
  treeIncome: number;
  treeIncomePercent: number;
  onUserClick?: () => void;
}

const TeamTreeItem: React.FunctionComponent<Props> = ({
  name,
  photoUrl,
  treeIncomePercent,
  treeIncome,
  randomMemberPhotoUrl,
  relativeDepth,
  teamCount,
  onUserClick,
}) => (
  <>
    <div className={cls.hline} />
    <span className={cls.item} onClick={onUserClick}>
      <div className={cls.itemProfile}>
        <Avatar size={48} src={photoUrl} className={cls.itemPhoto} />
      </div>
      <div className={cls.mainPart}>
        <span
          className={
            cls.partIncome +
            ' ' +
            (treeIncome > 0 ? 'text-balance-up' : 'text-balance-down')
          }
        >
          $
          <FormattedNumberParts value={treeIncome} maximumFractionDigits={2}>
            {CurrencyUtils.formatBalance}
          </FormattedNumberParts>
          {' (' + treeIncomePercent / 100 + '%)'}
        </span>
        <span
          className={cls.vline + ' ' + (randomMemberPhotoUrl ? '' : cls.dashed)}
        />
        <span className={cls.depth}>
          <FormattedMessage
            id={'screen.TeamScreen.depth'}
            defaultMessage={
              '{depth, selectordinal,\n' +
              '    one {#st}\n' +
              '    two {#nd}\n' +
              '    few {#rd}\n' +
              '    other {#th}\n' +
              '} line'
            }
            values={{depth: relativeDepth + 1}}
          />
        </span>
      </div>
      {randomMemberPhotoUrl && (
        <Avatar
          size={48}
          src={randomMemberPhotoUrl}
          className={cls.teamPhoto}
        />
      )}
      {teamCount >= 2 && '+' + (teamCount - 1)}
    </span>
    <span className={cls.nick}>{name}</span>
  </>
);

export default React.memo(TeamTreeItem);
