import React from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import cls from './style.module.scss';
import {FormatXMLElementFn, PrimitiveType} from 'intl-messageformat';

interface Props {
  values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>;
}

const Text: React.FunctionComponent<MessageDescriptor & Props> = (desc) => {
  const intl = useIntl();
  return (
    <span className={cls.text}>{intl.formatMessage(desc, desc.values)}</span>
  );
};

export default React.memo(Text);
