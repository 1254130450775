import React, {FC} from 'react';
import Typography from '../../typo/Typography';
import cls from './style.module.scss';
import Icon from '../../graphics/Icon';
import Skeleton from 'react-loading-skeleton';

const CauseDistributionCardSkeleton: FC = () => {
  return (
    <div className={cls.container}>
      <span className={cls.heading}>
        <Typography
          text={{
            id: 'components.CauseDistributionCard.header',
            defaultMessage: 'Analytics',
          }}
        />
      </span>
      <Skeleton
        className={cls.chartSkeleton}
        width={'100%'}
        height={8}
        borderRadius={4}
      />
      <div className={cls.totals}>
        <Skeleton width={50} height={16} borderRadius={6} />
        <Skeleton width={50} height={16} borderRadius={6} />
      </div>
      <div className={cls.button + ' ' + cls.disabled}>
        <Icon name={'arrow-mini-down'} size={16} tint={'default'} />
      </div>
    </div>
  );
};

export default CauseDistributionCardSkeleton;
