import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import createAcc from '../../../../../assets/onboarding/bybit/first/create-account.png';
import verifyAcc from '../../../../../assets/onboarding/bybit/first/verify-account.png';

const CreateAccountFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.CreateAccountFragment.title',
        defaultMessage:
          'Create an account using email or mobile phone. After, enter verification code',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={createAcc} second={verifyAcc} />
  </>
);

export default CreateAccountFragment;
