import {FC, useCallback, useState} from 'react';
import {HText} from '../../../component/design/typo/Typography';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import SteppedScaffolding from '../../../component/design/container/SteppedScaffolding';
import {
  InterceptorHandler,
  useInterceptorHandler,
} from '../../../hooks/useInterceptor';
import {notLoaded} from '../../../utils';
import {AdvanceStateDtoActionEnum} from '../../../api';

interface Props {
  header: HText;
  count: number;
  nextBlockLink?: string;
  lastBlockLink?: string;
  currentLink: string;
  first?: boolean;
  onExit?: (type: AdvanceStateDtoActionEnum) => void;
}

export interface OnboardingFragmentCtx {
  nextAction: InterceptorHandler;
  backAction: InterceptorHandler;
  skipAction: InterceptorHandler;
  setNextButtonState(state: AsyncState<void>): void;
}

const OnboardingBaseFragment: FC<Props> = ({
  header,
  count,
  nextBlockLink,
  lastBlockLink,
  currentLink,
  first,
  onExit,
}) => {
  const location = useLocation();
  const nav = useNavigate();
  const nextAction = useInterceptorHandler();
  const backAction = useInterceptorHandler();
  const skipAction = useInterceptorHandler();
  const [nextButtonState, setNextButtonState] = useState(notLoaded<void>());

  const split = location.pathname.split('/');
  const currentStep = parseInt(split[split.length - 1] ?? '0');

  const goNext = useCallback(() => {
    if (!nextAction.invoke()) {
      return;
    }
    if (currentStep + 1 < count) {
      nav(currentLink + '/' + (currentStep + 1));
    } else if (nextBlockLink) {
      if (onExit) {
        onExit('PASS');
      }
      nav(nextBlockLink);
    }
  }, [nextBlockLink, currentLink, currentStep, onExit]);

  const goBack = useCallback(() => {
    if (!backAction.invoke()) {
      return;
    }
    if (currentStep - 1 >= 0) {
      nav(currentLink + '/' + (currentStep - 1));
    } else if (lastBlockLink) {
      nav(lastBlockLink);
    }
  }, [currentLink, currentStep, lastBlockLink]);

  return (
    <SteppedScaffolding
      header={header}
      currentStep={currentStep}
      totalSteps={count}
      onSkip={
        nextBlockLink
          ? () => {
              if (!skipAction.invoke()) {
                return;
              }
              if (onExit) {
                onExit('SKIP');
              }
              nav(nextBlockLink);
            }
          : undefined
      }
      onBackClick={first && currentStep === 0 ? undefined : goBack}
      onNextClick={goNext}
      nextButtonState={nextButtonState}
    >
      <Outlet
        context={
          {nextAction, backAction, setNextButtonState} as OnboardingFragmentCtx
        }
      />
    </SteppedScaffolding>
  );
};

export default OnboardingBaseFragment;
