import React, {ReactNode} from 'react';
import BottomSheet from '../BottomSheet';
import cls from './style.module.scss';
import {FormattedMessage} from 'react-intl';
import BottomActionSheetContext from './Ctx';
import Item from './Item';

interface Embeds {
  Item: typeof Item;
}

interface Props {
  children: ReactNode;
  open: boolean;
  onDismiss: () => void;
}

const BottomActionSheet: React.FunctionComponent<Props> & Partial<Embeds> = ({
  children,
  open,
  onDismiss,
}) => (
  <BottomActionSheetContext.Provider value={{onDismiss}}>
    <BottomSheet open={open} onDismiss={onDismiss}>
      <div className={cls.container}>
        <div>{children}</div>
        <div className={cls.cancelPad} />
        <div>
          <div className={cls.card} onClick={onDismiss}>
            <span className={cls.cancel}>
              <FormattedMessage
                id={'common.cancel'}
                defaultMessage={'Cancel'}
              />
            </span>
          </div>
        </div>
        <div className={cls.bottomPad} />
      </div>
    </BottomSheet>
  </BottomActionSheetContext.Provider>
);

BottomActionSheet.Item = Item;
export default BottomActionSheet as React.FunctionComponent<Props> & Embeds;
