import {FC} from 'react';

const BinanceLogo: FC = () => (
  <svg
    width="96"
    height="24"
    viewBox="0 0 96 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_423_7470)">
      <path
        d="M5.8718 10.468L9.60079 6.74052L13.3313 10.471L15.4999 8.30097L9.60079 2.40039L3.70173 8.29945L5.8718 10.468Z"
        fill="#F3BA2F"
      />
      <path
        d="M-0.000732422 12.0007L2.16854 9.83141L4.33781 12.0007L2.16854 14.17L-0.000732422 12.0007Z"
        fill="#F3BA2F"
      />
      <path
        d="M5.87176 13.5327L9.60075 17.2617L13.3313 13.5312L15.5013 15.6982L9.60075 21.6003L3.69866 15.6997L5.87176 13.5327Z"
        fill="#F3BA2F"
      />
      <path
        d="M14.8601 12.0013L17.0294 9.832L19.1987 12.0013L17.0294 14.1705L14.8601 12.0013Z"
        fill="#F3BA2F"
      />
      <path
        d="M11.8011 11.9996L9.60074 9.7977L7.97357 11.4249L7.78553 11.6114L7.39731 11.9996L7.40035 12.0042L9.60074 14.203L11.8027 11.9996H11.8011Z"
        fill="#F3BA2F"
      />
      <path
        d="M22.5834 7.05281H27.3027C28.4734 7.05281 29.359 7.3561 29.9595 7.96269C30.1858 8.18676 30.3641 8.45446 30.4838 8.74955C30.6034 9.04464 30.6619 9.36097 30.6556 9.67933V9.70814C30.6597 9.96895 30.6233 10.2288 30.5479 10.4785C30.4807 10.6935 30.3832 10.8977 30.2583 11.0851C30.1418 11.2618 30.0009 11.4211 29.8397 11.5582C29.6823 11.6938 29.5098 11.8108 29.3256 11.907C29.8566 12.092 30.3341 12.4044 30.7162 12.8169C31.0539 13.2011 31.2227 13.7318 31.2227 14.4092V14.438C31.2323 14.861 31.1403 15.2802 30.9543 15.6603C30.7741 16.0105 30.51 16.3106 30.1855 16.5338C29.8186 16.7797 29.4101 16.957 28.9799 17.0569C28.4687 17.1782 27.9447 17.2367 27.4194 17.2313H22.5834V7.05281ZM26.8295 11.1685C27.2378 11.1882 27.6442 11.1004 28.0078 10.9137C28.1497 10.8309 28.2655 10.71 28.3421 10.5647C28.4188 10.4195 28.4532 10.2556 28.4415 10.0918V10.063C28.449 9.91204 28.4184 9.76163 28.3524 9.62565C28.2864 9.48968 28.1872 9.37254 28.0639 9.28505C27.8122 9.10611 27.4488 9.01663 26.9736 9.01663H24.7656V11.1685H26.8295ZM27.4255 15.2705C27.8323 15.2933 28.2372 15.2003 28.5932 15.0021C28.7319 14.913 28.8441 14.7884 28.9183 14.6412C28.9924 14.4939 29.0257 14.3295 29.0148 14.165V14.1362C29.0202 13.9799 28.9873 13.8246 28.9188 13.684C28.8503 13.5433 28.7484 13.4216 28.622 13.3295C28.3612 13.1273 27.9396 13.0262 27.3573 13.0262H24.7656V15.2705H27.4255Z"
        fill="white"
      />
      <path
        d="M34.0343 7.05281H36.2741V17.2344H34.0343V7.05281Z"
        fill="white"
      />
      <path
        d="M39.6634 7.05281H41.7288L46.5026 13.3189V7.05281H48.7136V17.2344H46.8074L41.8774 10.7621V17.2344H39.6634V7.05281Z"
        fill="white"
      />
      <path
        d="M55.4952 6.98015H57.5576L61.9205 17.2345H59.5821L58.651 14.9507H54.3457L53.4146 17.2345H51.1308L55.4952 6.98015ZM57.8518 12.9732L56.4961 9.67036L55.1464 12.9732H57.8518Z"
        fill="white"
      />
      <path
        d="M64.3423 7.05281H66.4077L71.1786 13.3189V7.05281H73.3896V17.2344H71.4834L66.5533 10.7621V17.2344H64.3423V7.05281Z"
        fill="white"
      />
      <path
        d="M81.451 17.4132C80.7372 17.4193 80.0295 17.2811 79.3704 17.0068C78.75 16.7506 78.1871 16.3728 77.7149 15.8957C77.2427 15.4186 76.8708 14.8517 76.6211 14.2286C76.3576 13.5769 76.2242 12.8799 76.2283 12.1769V12.1481C76.2166 10.7623 76.7498 9.4275 77.7129 8.43119C78.1868 7.94452 78.7542 7.55868 79.3811 7.29687C80.0629 7.01445 80.795 6.8736 81.5329 6.88288C81.9694 6.87962 82.4051 6.91873 82.834 6.99964C83.201 7.06905 83.5598 7.17628 83.9047 7.31962C84.2176 7.45134 84.5151 7.61713 84.7918 7.81399C85.0618 8.00598 85.3154 8.22003 85.55 8.45394L84.1261 10.0978C83.7622 9.7621 83.354 9.47789 82.9129 9.25312C82.4796 9.04591 82.0041 8.94209 81.5238 8.94982C81.1241 8.94556 80.7281 9.02638 80.3621 9.18691C79.9961 9.34745 79.6684 9.58403 79.4008 9.88093C79.1354 10.1771 78.9277 10.5201 78.7881 10.8924C78.642 11.2859 78.5685 11.7026 78.5713 12.1223V12.1511C78.5698 12.5702 78.6433 12.9862 78.7881 13.3794C78.9259 13.7531 79.1316 14.098 79.3947 14.397C79.6602 14.6976 79.9871 14.9376 80.3534 15.1009C80.7197 15.2641 81.1167 15.3468 81.5178 15.3432C82.0284 15.3593 82.5352 15.2494 82.9933 15.0233C83.4294 14.7856 83.8327 14.4919 84.1928 14.1498L85.6183 15.5904C85.3666 15.8636 85.0944 16.1171 84.8039 16.3487C84.5186 16.5743 84.2088 16.767 83.8804 16.9234C83.5304 17.0881 83.1618 17.2098 82.7825 17.2858C82.3442 17.3737 81.898 17.4164 81.451 17.4132Z"
        fill="white"
      />
      <path
        d="M88.2615 7.05281H95.9242V9.04545H90.465V11.1109H95.2722V13.1035H90.4725V15.2417H96.0001V17.2344H88.2661L88.2615 7.05281Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_423_7470">
        <rect width="96" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BinanceLogo;
