import {FC, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';
import AsyncStateWrapper from '../../../../component/ui/AsyncStateWrapper';
import {useNavigate, useParams} from 'react-router-dom';
import WideButton from '../../../../component/ui/WideButton';
import {FormattedMessage} from 'react-intl';
import Status from '../../../../component/design/container/Status';
import Illustration from '../../../../component/design/graphics/Illustration';
import IntlUtils from '../../../../utils/IntlUtils';
import BuyRcoStatusViewModel from '../../../../viewmodel/wallet/BuyRcoStatusViewModel';
import AcqusitionUtils from '../../../../utils/AcqusitionUtils';
import Scaffolding from '../../../../component/container/Scaffolding';

const SellRcoStatusScreen: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const vm = useMemo(
    () => new BuyRcoStatusViewModel(params.id ?? ''),
    [params.id],
  );
  useEffect(() => {
    vm.init();
    return () => vm.stopUpdate();
  }, [vm]);

  const req = vm.request;
  return (
    <Scaffolding>
      <AsyncStateWrapper state={req}>
        {(req) => {
          const status = AcqusitionUtils.simplifyStatus(req.status);
          return (
            <Status
              illustration={
                status === 'loading' ? (
                  <Illustration.Progress />
                ) : status === 'error' ? (
                  <Illustration.Failure />
                ) : (
                  <Illustration.Success />
                )
              }
              title={IntlUtils.acquisitionTitle(status)}
              description={IntlUtils.acquisitionDescription(status)}
              footer={
                status !== 'loading' && (
                  <WideButton onClick={() => navigate(-1)}>
                    <FormattedMessage id={'common.ok'} defaultMessage={'Ok'} />
                  </WideButton>
                )
              }
            />
          );
        }}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default observer(SellRcoStatusScreen);
