import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import AppCard from '../../../../../component/design/data/AppCard';
import FlexRow from '../../../../../component/design/container/FlexRow';
import StoreButton from '../../../../../component/design/button/StoreButton';
import Button from '../../../../../component/design/button/Button';
import {App} from '../../../../../utils/AppUtils';

const DownloadHuobiFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.huobi.DownloadHuobiFragment.title',
        defaultMessage: 'Download HTX app',
      }}
    />
    <Spacer size={1.5} />
    <AppCard app={App.Huobi} />
    <Spacer size={1.5} />
    <FlexRow gap={1} stretch>
      <StoreButton
        store={'gplay'}
        link={
          'https://play.google.com/store/apps/details/Huobi_Global?id=pro.huobi'
        }
      />
      <StoreButton
        store={'appstore'}
        link={'https://www.htx.com/support/360000055872'}
      />
    </FlexRow>
    <Spacer size={1} />
    <Button
      text={{id: 'common.visitWebsite', defaultMessage: 'Visit Website'}}
      icon={'link'}
      type={'default'}
      onClick={() => (window.location.href = 'https://www.htx.com/')}
    />
  </>
);

export default DownloadHuobiFragment;
