import React from 'react';
import {inject, observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import {Formik, FormikProps} from 'formik';
import {useIntl} from 'react-intl';
import ErrorUtils from '../../utils/ErrorUtils';
import Scaffolding from '../../component/container/Scaffolding';
import Form from '../../component/container/Form';
import TextField from '../../component/form/TextField';
import {UserStore} from '../../store/UserStore';
import {ifSuccessful} from '../../utils';

interface Props {
  user: UserStore;
}

interface RobotKeysForm {
  name: string;
}

const ValuesForm: React.FunctionComponent<FormikProps<RobotKeysForm>> = () => (
  <Form
    submitLabel={{
      id: 'screen.EditProfileScreen.save',
      defaultMessage: 'Save profile',
    }}
  >
    <TextField
      name={'name'}
      header={{
        id: 'form.nickname',
        defaultMessage: 'Nickname',
      }}
    />
  </Form>
);

const ReditProfileScreen: React.FunctionComponent<Props> = ({user}) => {
  const intl = useIntl();
  const nav = useNavigate();
  return (
    <Scaffolding
      header={{
        id: 'nav.editProfile',
        defaultMessage: 'Edit profile',
      }}
    >
      <Formik
        validateOnMount={false}
        validateOnChange={false}
        initialValues={{
          name: ifSuccessful(user.user, (t) => t.name ?? '', ''),
        }}
        validate={(values) => {
          const ret: Partial<Record<keyof RobotKeysForm, string>> = {};
          if (values.name === '') {
            ret.name = intl.formatMessage({
              id: 'form.nicknameEmpty',
              defaultMessage: 'Nickname cannot be empty',
            });
          }
          Object.keys(ret).forEach((key) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ret[key] === undefined ? delete ret[key] : {},
          );
          return ret;
        }}
        onSubmit={(values, bag) => {
          return user
            .editProfile({
              name:
                values.name.trim().length === 0
                  ? undefined
                  : values.name.trim(),
            })
            .then(() => nav(-1))
            .catch((reason: Error) => {
              ErrorUtils.setFormikError(bag, reason);
              throw reason;
            });
        }}
      >
        {(form) => <ValuesForm {...form} />}
      </Formik>
    </Scaffolding>
  );
};

export default inject('user')(
  observer(ReditProfileScreen),
) as unknown as React.FC;
