import {DependencyList, useEffect, useMemo} from 'react';

export class InterceptorHandler {
  private readonly interceptors: (() => boolean)[] = [];

  addInterceptor(fn: () => boolean): () => void {
    this.interceptors.push(fn);
    let deleted = false;
    return () => {
      if (!deleted) {
        this.interceptors.splice(this.interceptors.indexOf(fn), 1);
        deleted = true;
      }
    };
  }

  public invoke(): boolean {
    for (const interceptor of this.interceptors) {
      if (!interceptor()) {
        return false;
      }
    }
    return true;
  }
}

export function useInterceptorHandler(): InterceptorHandler {
  return useMemo(() => new InterceptorHandler(), []);
}

export function useInterceptor(
  handler: InterceptorHandler,
  fn: () => boolean,
  deps?: DependencyList,
) {
  useEffect(() => handler.addInterceptor(fn), deps);
}
