export default class HashUtils {
  static crc4(str: string) {
    const POLY = 0xc,
      XOROUT = 0;
    let crc = 0;
    for (let i = 0; i < str.length; i++) {
      crc = crc ^ str.charCodeAt(crc);
      for (let j = 0; j < 8; j++) {
        crc = crc & 1 ? (crc >>> 1) ^ POLY : crc >>> 1;
      }
    }
    return (crc ^ XOROUT) & 0xf;
  }
}
