import React from 'react';
import cls from './style.module.scss';
import {FormattedMessage} from 'react-intl';
import Text from './Text';
import Money from './Money';
import Profile from './Profile';

interface Embed {
  Text: typeof Text;
  Money: typeof Money;
  Profile: typeof Profile;
}

interface Props {
  banner: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  footer: string[];
  header: string;
  to: string;
  children: React.ReactNode;
}

const NotificationDetailsScaffold: React.FunctionComponent<Props> &
  Partial<Embed> = ({banner, footer, header, to, children}) => {
  const Banner = banner;
  return (
    <div className={cls.container}>
      <Banner className={cls.banner} />
      <span className={cls.to}>
        <FormattedMessage
          id={'components.NotificationDetailsScaffold.to'}
          defaultMessage={'Hi {to}'}
          values={{to}}
        />
      </span>
      <span className={cls.header}>{header}</span>
      <div className={cls.content}>{children}</div>
      {footer.map((e) => (
        <span key={e} className={cls.footer}>
          {e}
        </span>
      ))}
    </div>
  );
};

NotificationDetailsScaffold.Text = Text;
NotificationDetailsScaffold.Money = Money;
NotificationDetailsScaffold.Profile = Profile;

export default NotificationDetailsScaffold as React.FC<Props> & Embed;
