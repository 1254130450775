import {GA_TAG} from '../config';

const SCRIPT_ID = 'ga-gtag';

interface WindowDataLayer {
  dataLayer: IArguments[];
}

class Analytics {
  static gtag(): void {
    // Can't use arrow func + destructuring as Google expects
    // arguments objects in dataLayer (not an array of arguments).
    // eslint-disable-next-line prefer-rest-params
    (window as unknown as WindowDataLayer).dataLayer.push(arguments);
  }

  static install(): void {
    if (document.getElementById(SCRIPT_ID)) {
      return;
    }

    const script = document.createElement('script');
    script.id = SCRIPT_ID;
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TAG}`;
    document.head.insertBefore(script, document.head.firstChild);

    const w = window as unknown as WindowDataLayer;
    w.dataLayer = w.dataLayer || [];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Analytics.gtag('js', new Date());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Analytics.gtag('config', GA_TAG);
  }

  static pageview(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Analytics.gtag('send', 'pageview');
  }
}

Analytics.install();

export default Analytics;
