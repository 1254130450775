import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/apikeys/copy-key-1.png';
import v2 from '../../../../../assets/onboarding/bybit/apikeys/copy-key-2.png';

const CopyGAuthKeyFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.CopyGAuthKeyFragment.title',
        defaultMessage:
          'Open Bybit, and copy secret key from popup into authenticator.',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage
      first={v1}
      second={v2}
      overlayX={'27.2%'}
      overlayY={'26.8%'}
    >
      <svg
        width="26%"
        height="26%"
        viewBox="0 0 78 99"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.707266 96.9986C0.732623 97.9013 1.4849 98.6124 2.38753 98.5871C3.29016 98.5617 4.00133 97.8095 3.97598 96.9068L0.707266 96.9986ZM77.0971 13.2974C77.7217 12.6453 77.6995 11.6103 77.0473 10.9857L66.4206 0.807068C65.7685 0.182458 64.7335 0.20475 64.1089 0.856859C63.4843 1.50897 63.5066 2.54395 64.1587 3.16856L73.6047 12.2162L64.557 21.6622C63.9324 22.3143 63.9547 23.3493 64.6068 23.9739C65.2589 24.5985 66.2939 24.5762 66.9185 23.9241L77.0971 13.2974ZM2.34162 96.9527C3.97598 96.9068 3.97599 96.9072 3.97599 96.9073C3.97598 96.9069 3.97597 96.9067 3.97595 96.9059C3.97591 96.9044 3.97584 96.9016 3.97574 96.8975C3.97554 96.8894 3.97524 96.8764 3.97486 96.8584C3.97411 96.8224 3.97309 96.7669 3.97209 96.6923C3.9701 96.5432 3.96822 96.3182 3.96892 96.0223C3.9703 95.4305 3.98194 94.5555 4.02339 93.4372C4.1063 91.2001 4.30838 87.9929 4.78538 84.1354C5.74041 76.4121 7.79229 66.1256 12.1677 55.8112C16.5418 45.4996 23.2159 35.2108 33.391 27.401C43.5484 19.6048 57.2923 14.2029 75.9516 13.801L75.8812 10.5318C56.5851 10.9474 42.1545 16.5526 31.4 24.807C20.6632 33.048 13.688 43.8536 9.15732 54.5342C4.62783 65.212 2.51928 75.8155 1.54009 83.7341C1.04998 87.6977 0.841498 90.9994 0.755633 93.3161C0.712691 94.4747 0.70039 95.3877 0.698924 96.0147C0.698192 96.3282 0.700169 96.5703 0.702382 96.736C0.703489 96.8188 0.704656 96.8826 0.705572 96.9265C0.706031 96.9485 0.706427 96.9656 0.706722 96.9776C0.706869 96.9836 0.706992 96.9884 0.707084 96.9919C0.70713 96.9937 0.707175 96.9954 0.707199 96.9962C0.707236 96.9976 0.707266 96.9986 2.34162 96.9527Z"
          fill="#FF4040"
        />
      </svg>
    </SideBySideImage>
  </>
);

export default CopyGAuthKeyFragment;
