import {FC} from 'react';
import cls from './style.module.scss';

interface Props {
  checked: boolean;
  onClick?: () => void;
}

const Radio: FC<Props> = ({checked, onClick}) => (
  <div
    className={cls.content + ' ' + (checked && cls.selected)}
    onClick={onClick}
  >
    <div className={cls.inner} />
  </div>
);

export default Radio;
