import React from 'react';
import cls from './style.module.scss';

interface Props {
  url: string;
  children: React.ReactNode;
}

const index: React.FunctionComponent<Props> = ({children, url}) => (
  <div className={cls.container}>
    <a className={cls.link} href={url}>
      {children}
    </a>
    <span className={cls.line} />
  </div>
);

export default index;
