import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/first/verify-identity-3.png';
import v2 from '../../../../../assets/onboarding/bybit/first/verify-identity-4.png';

const IdentityVerify2Fragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.IdentityVerity2.title',
        defaultMessage:
          'Select your country and an identity document you will be using for verification',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default IdentityVerify2Fragment;
