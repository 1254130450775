import {FC, useEffect, useState} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/huobi/apikeys/access-key.png';
import TextInput from '../../../../../component/design/input/TextInput';
import {inject, observer} from 'mobx-react';
import {ExchangeConnectionStore} from '../../../../../store/ExchangeConnectionStore';
import AsyncStateWrapper from '../../../../../component/ui/AsyncStateWrapper';
import {useOutletContext} from 'react-router-dom';
import {OnboardingFragmentCtx} from '../../OnboardingBaseFragment';
import {useInterceptor} from '../../../../../hooks/useInterceptor';

interface RProps {
  exchangeConnection: ExchangeConnectionStore;
}

const CopyApiKeyFragment: FC<RProps> = ({exchangeConnection}) => {
  const ctx = useOutletContext<OnboardingFragmentCtx>();
  const [key, setKey] = useState('');
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    exchangeConnection.ensureEncryptorLoaded();
  }, [exchangeConnection]);

  useInterceptor(
    ctx.nextAction,
    () => {
      setChecked(true);
      if (key.trim().length === 0) {
        return false;
      } else {
        return exchangeConnection.setFormApiKey(key);
      }
    },
    [key, exchangeConnection],
  );

  return (
    <AsyncStateWrapper state={exchangeConnection.publicKey}>
      {() => (
        <>
          <Subtitle
            text={{
              id: 'fragments.onboarding.huobi.CopyApiKeyFragment.title',
              defaultMessage: 'Copy <h>Access Key</h> into field below.',
            }}
          />
          <Spacer size={1.5} />
          <TextInput
            value={key}
            label={{id: 'common.apiKey', defaultMessage: 'Access Key'}}
            pasteable
            onChange={(v) => setKey(v)}
            error={
              checked && key.trim().length === 0
                ? {
                    id: 'onboarding.accessKeyMissing',
                    defaultMessage: 'Access Key missing',
                  }
                : undefined
            }
          />
          <Spacer size={1.5} />
          <SideBySideImage first={v1} />
        </>
      )}
    </AsyncStateWrapper>
  );
};

export default inject('exchangeConnection')(observer(CopyApiKeyFragment)) as FC<
  Partial<RProps>
>;
