import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import Icon from '../../graphics/Icon';
import cls from './style.module.scss';
import Badge from '../Badge';

interface Props {
  ip: string;
  adviceLevel?: 'warning' | 'error';
  advice?: HText;
}

const Ip: FC<Props> = ({ip, advice, adviceLevel}) => (
  <div className={cls.ip}>
    <Typography
      text={ip}
      className={
        cls.value +
        ' ' +
        (adviceLevel === 'warning' && cls.warning) +
        ' ' +
        (adviceLevel === 'error' && cls.error)
      }
    />
    {adviceLevel && advice && (
      <Badge color={adviceLevel === 'error' ? 'red' : 'yellow'}>
        <Icon
          name={'attention'}
          size={16}
          tint={adviceLevel === 'error' ? 'red' : 'yellow'}
        />
        <Typography
          text={advice ?? ''}
          className={
            adviceLevel === 'error' ? cls.adviceerror : cls.advicewarning
          }
        />
      </Badge>
    )}
  </div>
);

export default Ip;
