import React from 'react';
import Scaffolding from '../../component/container/Scaffolding';
import {UserStore} from '../../store/UserStore';
import {inject, observer} from 'mobx-react';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import ErrorUtils from '../../utils/ErrorUtils';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {FormattedMessage, IntlShape, useIntl} from 'react-intl';
import {EmailVerificationStore} from '../../store/EmailVerificationStore';
import Form from '../../component/design/container/Form';
import SubmitWideButton from '../../component/form/SubmitWideButton';
import TextField from '../../component/design/field/TextField';

interface Props {
  user: UserStore;
  emailVerification: EmailVerificationStore;
}

interface LoginFormValues {
  email: string;
}

function submit(
  user: UserStore,
  values: LoginFormValues,
  bag: FormikHelpers<LoginFormValues>,
  emailVerification: EmailVerificationStore,
  navigate: NavigateFunction,
) {
  return user
    .sendCode(values.email)
    .then(() => {
      emailVerification.putCredentials({
        login: values.email,
      });
      navigate('/verify-email');
    })
    .catch((reason: Error) => {
      ErrorUtils.setFormikError(bag, reason);
      throw reason;
    });
}

function validate(values: LoginFormValues, intl: IntlShape) {
  const ret: Partial<Record<keyof LoginFormValues, string>> = {};
  if (values.email.trim() === '') {
    ret.email = intl.formatMessage({
      id: 'form.emailEmpty',
      defaultMessage: 'Email is empty',
    });
  }
  const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
  if (!reg.test(values.email)) {
    ret.email = intl.formatMessage({
      id: 'form.emailInvalid',
      defaultMessage: 'Email is invalid',
    });
  }
  return ret;
}

const ValuesForm: React.FunctionComponent<
  FormikProps<LoginFormValues>
> = () => (
  <Scaffolding
    header={{
      id: 'nav.signIn',
      defaultMessage: 'Sign In',
    }}
  >
    <Form
      footer={
        <SubmitWideButton>
          <FormattedMessage id={'common.login'} defaultMessage={'Log in'} />
        </SubmitWideButton>
      }
    >
      <TextField
        label={{id: 'common.email', defaultMessage: 'Email address'}}
        name={'email'}
        inputMode={'email'}
        submitForm
      />
    </Form>
  </Scaffolding>
);

const LoginScreen: React.FunctionComponent<Props> = ({
  user,
  emailVerification,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <Formik
      validateOnMount={false}
      validateOnChange={false}
      initialValues={{
        email: '',
      }}
      onSubmit={(values, bag) =>
        submit(user, values, bag, emailVerification, navigate)
      }
      validate={(values) => validate(values, intl)}
    >
      {(form) => <ValuesForm {...form} />}
    </Formik>
  );
};

export default inject(
  'user',
  'emailVerification',
)(observer(LoginScreen)) as unknown as React.FC;
