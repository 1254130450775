import React from 'react';
import Icon, {IconName} from '../Icon';
import cls from './style.module.scss';

interface Props {
  selected: string;
  onSelect: (id: string) => void;
  children: React.ReactElement<TabProps>[] | React.ReactElement<TabProps>;
}

interface TabProps {
  id: string;
  icon: IconName;
  selectedIcon: IconName;
}

interface Ext {
  Tab: React.FC<TabProps>;
}

const BottomTabs: React.FunctionComponent<Props> & Ext = ({
  children,
  selected,
  onSelect,
}) => (
  <div className={cls.container}>
    {(Array.isArray(children) ? children : [children]).map((value) => (
      <div
        className={cls.tab}
        onClick={() => onSelect(value.props.id)}
        key={value.props.id}
      >
        <Icon
          name={
            selected === value.props.id
              ? value.props.selectedIcon
              : value.props.icon
          }
          size={24}
          clickable
          key={value.props.id}
        />
      </div>
    ))}
  </div>
);

BottomTabs.Tab = () => <></>;

export default BottomTabs;
