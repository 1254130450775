import React from 'react';
import cls from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  clickable?: boolean;
  onClick?: () => void;
}

const Card: React.FunctionComponent<Props> = ({
  children,
  className,
  clickable,
  onClick,
}) => (
  <div
    className={
      cls.card +
      ' ' +
      (clickable ? cls.clickable : '') +
      ' ' +
      (className ?? '')
    }
    onClick={onClick}
  >
    {children}
  </div>
);

export default Card;
