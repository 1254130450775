import {HText} from '../component/design/typo/Typography';
import {useIntl} from 'react-intl';
import {useCallback} from 'react';

type HTextConverter = (text: HText | undefined) => string | undefined;

export default function useHText(): HTextConverter {
  const intl = useIntl();
  return useCallback(
    (text) => {
      if (text) {
        if (typeof text === 'string') {
          return text;
        } else {
          return intl.formatMessage(text);
        }
      } else {
        return undefined;
      }
    },
    [intl],
  );
}
