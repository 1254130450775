import React from 'react';
import {FormattedMessage} from 'react-intl';
import cls from './style.module.scss';

const Header: React.FunctionComponent = () => (
  <div className={cls.header}>
    <span className={cls.item + ' ' + cls.date}>
      <FormattedMessage
        id={'components.RobotTradeCard.header.date'}
        defaultMessage={'Date'}
      />
    </span>
    <span className={cls.item + ' ' + cls.field}>
      <FormattedMessage
        id={'components.RobotTradeCard.header.price'}
        defaultMessage={'Price'}
      />
    </span>
    <span className={cls.operation} />
    <span className={cls.item + ' ' + cls.field}>
      <FormattedMessage
        id={'components.RobotTradeCard.header.amount'}
        defaultMessage={'Amount'}
      />
    </span>
    <span className={cls.operation + ' ' + cls.eq} />
    <span className={cls.item + ' ' + cls.field + ' ' + cls.volume}>
      <FormattedMessage
        id={'components.RobotTradeCard.header.volume'}
        defaultMessage={'Volume'}
      />
    </span>
  </div>
);

export default React.memo(Header);
