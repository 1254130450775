import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/apikeys/create-new-1.png';
import v2 from '../../../../../assets/onboarding/bybit/apikeys/create-new-2.png';

const CreateApiKeyFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.EnterApiManagementFragment.title',
        defaultMessage:
          'Scroll to right and press <h>Create Api Key</h> button',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default CreateApiKeyFragment;
