import {FC} from 'react';

const BybitLogo: FC = () => (
  <svg
    width="46"
    height="24"
    viewBox="0 0 46 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_423_7471)">
      <path
        d="M32.7213 14.4611V2.72266H35.0744V14.4611H32.7213Z"
        fill="#F7A600"
      />
      <path
        d="M5.30482 17.9477H0.262451V6.20917H5.102C7.4551 6.20917 8.82415 7.49566 8.82415 9.50732C8.82455 10.0102 8.68547 10.5032 8.42247 10.9313C8.15947 11.3595 7.78291 11.7057 7.33491 11.9315C8.06169 12.2611 8.99316 13.0033 8.99316 14.5685C8.99504 16.761 7.4551 17.9477 5.30482 17.9477ZM4.91608 8.25474H2.61556V10.9577H4.91796C5.91704 10.9577 6.4748 10.4133 6.4748 9.60527C6.4748 8.79721 5.91516 8.25474 4.91608 8.25474ZM5.06819 13.0202H2.61556V15.9059H5.07007C6.13676 15.9059 6.64382 15.2466 6.64382 14.4555C6.64382 13.6644 6.13113 13.0183 5.06819 13.0183V13.0202Z"
        fill="white"
      />
      <path
        d="M16.169 13.1332V17.9477H13.8309V13.1332L10.2102 6.20917H12.7661L15.0197 10.9407L17.2357 6.20917H19.7916L16.169 13.1332Z"
        fill="white"
      />
      <path
        d="M26.4602 17.9477H21.416V6.20917H26.2555C28.6086 6.20917 29.9777 7.49566 29.9777 9.50732C29.9781 10.0102 29.839 10.5032 29.576 10.9313C29.313 11.3595 28.9364 11.7057 28.4884 11.9315C29.2152 12.2611 30.1467 13.0033 30.1467 14.5685C30.1486 16.761 28.6086 17.9477 26.4602 17.9477ZM26.0715 8.25474H23.7691V10.9577H26.0715C27.0706 10.9577 27.6283 10.4133 27.6283 9.60527C27.6283 8.79721 27.0687 8.25474 26.0696 8.25474H26.0715ZM26.2236 13.0202H23.7691V15.9059H26.2255C27.2922 15.9059 27.7992 15.2466 27.7992 14.4555C27.7992 13.6644 27.2884 13.0183 26.2255 13.0183L26.2236 13.0202Z"
        fill="white"
      />
      <path
        d="M42.6559 8.25474V17.9495H40.3103V8.25474H37.1553V6.20917H45.8034V8.25474H42.6559Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_423_7471">
        <rect width="46" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BybitLogo;
