import React, {useMemo} from 'react';
import Logo from '../../../component/ui/Logo';
import WideButton from '../../../component/ui/WideButton';
import {FormattedMessage} from 'react-intl';
import Spacer from '../../../component/design/utils/Spacer';
import SecondaryButton from '../../../component/ui/SecondaryButton';
import {MAIN_SITE_URL} from '../../../config';
import {observer} from 'mobx-react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import UnsubscribeViewModel from '../../../viewmodel/UnsubscribeViewModel';
import AsyncStateWrapper from '../../../component/ui/AsyncStateWrapper';
import cls from './style.module.scss';

const UnsubscribeScreen: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const id = params.get('r');
  const token = params.get('t');
  const vm = useMemo(
    () => new UnsubscribeViewModel(id ?? '', token ?? ''),
    [id, token],
  );
  return (
    <div className={cls.container}>
      <AsyncStateWrapper state={vm.status}>
        {(status) => (
          <>
            <Logo withText className={cls.logo} />
            <div className={cls.text}>
              {status.subscribed ? (
                <>
                  <FormattedMessage
                    id={'screens.UnsubscribeScreen.text'}
                    defaultMessage={
                      'Are you sure to unsubscribe from notifications?'
                    }
                  />
                  <div className={cls.caption}>
                    <FormattedMessage
                      id={'screens.UnsubscribeScreen.caption'}
                      defaultMessage={
                        'You can re-enable email notifications in profile page'
                      }
                    />
                  </div>
                </>
              ) : (
                <FormattedMessage
                  id={'screens.UnsubscribeScreen.alreadyUnsubscribed'}
                  defaultMessage={
                    'You are already unsubscribed from notifications'
                  }
                />
              )}
            </div>
            <div className={cls.content}>
              <WideButton onClick={() => navigate(-1)}>
                <FormattedMessage
                  id={'screens.UnsubscribeScreen.goBack'}
                  defaultMessage={'Go back'}
                />
              </WideButton>
              <Spacer size={2} />
              {status.subscribed && (
                <SecondaryButton onClick={() => vm.unsubscribe()}>
                  <FormattedMessage
                    id={'screens.UnsubscribeScreen.unsubscribe'}
                    defaultMessage={'Unsubscribe'}
                  />
                </SecondaryButton>
              )}
              <Spacer size={5} />
              <a href={MAIN_SITE_URL}>
                <FormattedMessage
                  id={'screens.UnsubscribeScreen.footerText'}
                  defaultMessage={'www.robowallet.org'}
                />
              </a>
            </div>
          </>
        )}
      </AsyncStateWrapper>
    </div>
  );
};

export default observer(UnsubscribeScreen);
