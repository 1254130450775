import {FC, useEffect, useMemo} from 'react';
import {inject, observer} from 'mobx-react';
import AsyncStateWrapper from '../../../../component/ui/AsyncStateWrapper';
import {useNavigate, useParams} from 'react-router-dom';
import SellRcoConfirmViewModel from '../../../../viewmodel/wallet/SellRcoConfirmViewModel';
import Scaffolding from '../../../../component/container/Scaffolding';
import Form from '../../../../component/design/container/Form';
import Text from '../../../../component/design/typo/Text';
import Spacer from '../../../../component/design/utils/Spacer';
import CodeInput from '../../../../component/design/input/CodeInput';
import WideButton from '../../../../component/ui/WideButton';
import {FormattedMessage} from 'react-intl';
import WithdrawalRequestCard from '../../../../component/design/data/WithdrawalRequestCard';
import {WalletStore} from '../../../../store/WalletStore';

interface RProps {
  wallet: WalletStore;
}

const SellRcoConfirmScreen: FC<RProps> = ({wallet}) => {
  const navigate = useNavigate();
  const params = useParams();
  const vm = useMemo(
    () =>
      new SellRcoConfirmViewModel(params.id ?? '', (id) =>
        navigate(`/app/wallet/sell/${id}/status`, {replace: true}),
      ),
    [params.id],
  );
  useEffect(() => {
    wallet.initWithdrawal();
    vm.init();
  }, [vm]);

  const req = vm.request;
  const code = vm.code;
  const alreadySubmitted = vm.alreadySubmitted;
  const confirmError = vm.confirmError;
  const inProgress = vm.inProgress;
  return (
    <AsyncStateWrapper state={wallet.withdrawalRequestConfig}>
      {(cfg) => (
        <AsyncStateWrapper state={req}>
          {(req) => (
            <Scaffolding
              header={{
                id: 'nav.confirmationCode',
                defaultMessage: 'Confirmation Code',
              }}
            >
              <Form
                footer={
                  (code.length === SellRcoConfirmViewModel.LENGTH ||
                    alreadySubmitted) && (
                    <WideButton
                      onClick={vm.confirm}
                      state={inProgress ? 'loading' : 'none'}
                    >
                      <FormattedMessage
                        id={'common.continue'}
                        defaultMessage={'Continue'}
                      />
                    </WideButton>
                  )
                }
              >
                <Text
                  text={{
                    id: 'screen.SellRcoConfirmScreen.message',
                    defaultMessage:
                      'Message with verification code has been sent to your email. To continue, enter code from it',
                  }}
                />
                <Spacer size={1.5} />
                <CodeInput
                  length={SellRcoConfirmViewModel.LENGTH}
                  enterKeyHint={'enter'}
                  onChange={vm.setCode}
                  allowedCharacters={'numeric'}
                  error={confirmError}
                  onSubmitEditing={vm.codeSubmit}
                />
                <Spacer size={1} />
                <WithdrawalRequestCard {...req} cfg={cfg} />
              </Form>
            </Scaffolding>
          )}
        </AsyncStateWrapper>
      )}
    </AsyncStateWrapper>
  );
};

export default inject('wallet')(observer(SellRcoConfirmScreen)) as FC<
  Partial<RProps>
>;
