import Typography, {HText} from '../../typo/Typography';
import {FC} from 'react';
import cls from './style.module.scss';

interface Props {
  text: HText;
  onClick?: () => void;
}

const ActiveText: FC<Props> = ({onClick, text}) => (
  <span onClick={onClick} className={cls.text}>
    <Typography text={text} />
  </span>
);

export default ActiveText;
