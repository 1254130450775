import Icon from '../../graphics/Icon';
import Typography from '../../typo/Typography';
import {FC, useState} from 'react';
import BottomSheetCard from '../../container/BottomSheetCard';
import Button from '../../button/Button';
import BottomSheet from '../BottomSheet';
import cls from './style.module.scss';
import {
  CurrentSubscriptionDetails,
  CurrentSubscriptionDetailsPeriodEnum,
} from '../../../../api';
import SubscriptionPeriodSelect from '../../data/SubscriptionPeriodSelect';
import {observer} from 'mobx-react';
import {useStateErrorAutoReset} from '../../../../hooks/useErrorAutoReset';
import {useAsyncStateErrorSnack} from '../../../../hooks/useAsyncStateErrorSnack';

interface Props {
  open: boolean;
  details: CurrentSubscriptionDetails;
  onConfirm: (period: CurrentSubscriptionDetailsPeriodEnum) => void;
  changeState: AsyncState<unknown>;
  onDismiss: () => void;
}

const SelectSubscriptionPeriodBottomSheet: FC<Props> = ({
  open,
  details,
  changeState,
  onDismiss,
  onConfirm,
}) => {
  const [period, setPeriod] = useState<CurrentSubscriptionDetailsPeriodEnum>(
    details.period,
  );
  const btnErrored = useStateErrorAutoReset(changeState);
  useAsyncStateErrorSnack(changeState);

  return (
    <BottomSheet open={open} onDismiss={onDismiss}>
      <BottomSheetCard
        mainClassName={cls.main}
        buttons={
          <>
            <Button
              type={'default'}
              text={{id: 'common.cancel', defaultMessage: 'Cancel'}}
              onClick={onDismiss}
              disabled={changeState.status === 'loading'}
            />
            <Button
              type={btnErrored ? 'error' : 'primary'}
              text={{id: 'common.apply', defaultMessage: 'Apply'}}
              loading={changeState.status === 'loading'}
              onClick={() => {
                if (period === details.period) {
                  onDismiss();
                } else {
                  onConfirm(period);
                }
              }}
            />
          </>
        }
      >
        <Icon name={'calendar'} size={48} tint={'white'} />
        <Typography
          text={{
            id: 'components.SelectSubscriptionPeriodBottomSheet.title',
            defaultMessage: 'Change Billing Period',
          }}
          className={cls.title}
        />
        <Typography
          text={{
            id: 'components.SelectSubscriptionPeriodBottomSheet.message',
            defaultMessage:
              'New billing period will be applied <b>after closest payment.</b> Current subscription duration and pricing will not be affected',
          }}
          className={cls.message}
        />
        <SubscriptionPeriodSelect
          onChange={setPeriod}
          current={period}
          costMonthly={details.costMonthly}
          costThreeMonth={details.costThreeMonth}
          costYearly={details.costYearly}
        />
      </BottomSheetCard>
    </BottomSheet>
  );
};

export default observer(SelectSubscriptionPeriodBottomSheet);
