import React from 'react';
import cls from './style.module.scss';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
}

const SecondaryButton: React.FunctionComponent<Props> = ({
  children,
  onClick,
}) => (
  <button onClick={onClick} className={cls.button}>
    {children}
  </button>
);

export default SecondaryButton;
