import React from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import BottomSheet from '../BottomSheet';
import cls from './style.module.scss';
import Icon, {IconName} from '../Icon';

interface Props {
  title: MessageDescriptor;
  text?: MessageDescriptor | string;
  open: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
  dismissText?: MessageDescriptor;
  confirmText?: MessageDescriptor;
  hideDismissButton?: boolean;
  icon?: IconName;
}

const DISMISS_TEXT: MessageDescriptor = {
  id: 'common.cancel',
  defaultMessage: 'Cancel',
};

const CONFIRM_TEXT: MessageDescriptor = {
  id: 'common.confirm',
  defaultMessage: 'Confirm',
};

const DialogBottomSheet: React.FunctionComponent<Props> = (props) => (
  <BottomSheet open={props.open} onDismiss={props.onDismiss}>
    <div className={cls.container}>
      <Icon name={props.icon ?? 'alert-circle'} size={48} />
      <h3>
        <FormattedMessage {...props.title} />
      </h3>
      {props.text && (
        <p>
          {typeof props.text === 'string' ? (
            props.text
          ) : (
            <FormattedMessage {...props.text} />
          )}
        </p>
      )}
      <div className={cls.buttons}>
        {!props.hideDismissButton && (
          <div className={cls.button} onClick={props.onDismiss}>
            <span>
              <FormattedMessage {...(props.dismissText ?? DISMISS_TEXT)} />
            </span>
          </div>
        )}
        <div
          className={
            cls.button +
            ' ' +
            (props.hideDismissButton && cls.full) +
            ' ' +
            cls.primary
          }
          onClick={props.onConfirm}
        >
          <span>
            <FormattedMessage {...(props.confirmText ?? CONFIRM_TEXT)} />
          </span>
        </div>
      </div>
    </div>
  </BottomSheet>
);

export default DialogBottomSheet;
