import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import emailVerification from '../../../../../assets/onboarding/binance/first/email-verification.png';
import phoneVerification from '../../../../../assets/onboarding/binance/first/phone-verification.png';

const VerificationFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.binance.VerificationFragment.title',
        defaultMessage: 'Enter verification code',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={phoneVerification} second={emailVerification} />
  </>
);

export default VerificationFragment;
