import {FC, ReactNode} from 'react';
import cls from './style.module.scss';

interface Props {
  header?: ReactNode;
  main: ReactNode;
  list: ReactNode;
}

const WalletContainer: FC<Props> = ({header, main, list}) => (
  <div className={cls.container}>
    {header}
    <div className={cls.content}>
      <div className={cls.main}>{main}</div>
      {list}
    </div>
  </div>
);

export default WalletContainer;
