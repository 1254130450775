import React from 'react';
import {useNavigate} from 'react-router-dom';
import cls from './style.module.scss';
import Header, {HeaderActions} from '../../design/container/Header';
import {HText} from '../../design/typo/Typography';

interface Props {
  header?: HText;
  actions?: HeaderActions;
  hideBackButton?: boolean;
  children: React.ReactNode;
  bottomTabs?: React.ReactNode;
  paddingHorizontal?: number;
  centerHorizontal?: boolean;
  overflow?: boolean;
  positionRelative?: boolean;
  onBackPress?: () => void;
}

const Scaffolding: React.FunctionComponent<Props> = ({
  header,
  children,
  hideBackButton,
  bottomTabs,
  paddingHorizontal,
  centerHorizontal,
  actions,
  overflow,
  positionRelative,
  onBackPress,
}) => {
  const navigate = useNavigate();
  return (
    <div className={cls.content + ' ' + (overflow ? cls.overflow : '')}>
      {header && (
        <Header
          text={header}
          onBackClick={
            !hideBackButton ? onBackPress ?? (() => navigate(-1)) : undefined
          }
          actions={actions}
        />
      )}
      <div
        className={
          cls.main +
          ' ' +
          (overflow ? cls.overflow : '') +
          ' ' +
          (centerHorizontal ? cls.center : '') +
          ' ' +
          (positionRelative ? cls.posRelative : '')
        }
        style={{
          paddingLeft: paddingHorizontal
            ? `${paddingHorizontal}rem`
            : undefined,
          paddingRight: paddingHorizontal
            ? `${paddingHorizontal}rem`
            : undefined,
        }}
      >
        {children}
      </div>
      {bottomTabs}
    </div>
  );
};

export default Scaffolding;
