import React, {FC} from 'react';
import {FormattedNumberParts} from 'react-intl';
import CurrencyUtils from '../../../../utils/CurrencyUtils';

interface Props {
  amount: number;
  withoutCurrency?: boolean;
}

const RcoBalance: FC<Props> = ({amount, withoutCurrency}) => (
  <>
    <FormattedNumberParts value={amount} maximumFractionDigits={8}>
      {CurrencyUtils.formatBalance}
    </FormattedNumberParts>
    {withoutCurrency ? '' : ' RCO'}
  </>
);

export default React.memo(RcoBalance);
