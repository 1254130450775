import {HText} from '../../typo/Typography';
import {FC} from 'react';
import GuidePopup from '../../misc/GuidePopup';
import cls from './style.module.scss';
import {useCtx} from './ctx';

interface Props {
  text: HText;
  nextText?: HText;
  x: string;
  y: string;
  w: string;
  h: string;
  position: 'top' | 'bottom';
}

const Item: FC<Props> = ({text, x, y, w, h, position, nextText}) => {
  const ctx = useCtx();
  return (
    <>
      <div
        className={cls.overlay}
        style={{top: y, left: x, width: w, height: h}}
      />
      <GuidePopup
        className={cls.popup}
        style={{
          top:
            position === 'top'
              ? 'calc(' + h + ' + ' + y + ')'
              : 'calc(' + y + ' - 120px)',
          left: x,
        }}
        text={text}
        current={ctx.current}
        max={ctx.max}
        onClose={ctx.onClose}
        onNext={ctx.onNext}
        onPrev={ctx.onPrev}
        position={position}
        nextText={nextText}
      />
    </>
  );
};

export default Item;
