import {Storage} from './Storage';
import StorageManager from './StorageManager';
import Logger from '../manager/Logger';
import StorageBackend from './backend/StorageBackend';
import AESEncryptedStorageBackendDecorator from './backend/AESEncryptedStorageBackendDecorator';
import LocalStorageBackend from './backend/LocalStorageBackend';

class TokenStorage implements Storage {
  private static instance: TokenStorage;
  private readonly backend: StorageBackend =
    new AESEncryptedStorageBackendDecorator(
      new LocalStorageBackend('token'),
      'qvattsZB5ETrYkgWqFQwRD4t',
    );

  static get(): TokenStorage {
    if (!this.instance) {
      this.instance = new TokenStorage();
      StorageManager.addStorage('token', this.instance);
      Logger.debug('TokenStorage', 'start', 'TokenStorage started');
    }
    return this.instance;
  }

  getRefreshToken(): string | undefined {
    return this.backend.getString('refreshToken');
  }

  setRefreshToken(token: string) {
    this.backend.set('refreshToken', token);
  }

  onUserLogout() {
    this.backend.delete('refreshToken');
  }
}

export default TokenStorage;
