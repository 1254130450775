import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/p2p/start-p2p-1.png';
import v2 from '../../../../../assets/onboarding/bybit/p2p/start-p2p-2.png';

const StartP2PFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.StartP2PFragment.title',
        defaultMessage:
          'Select a <h>Buy</h> proposal. You can also filter proposals by amount, currency or payment type',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default StartP2PFragment;
