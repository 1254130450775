import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import Scaffolding from '../../component/container/Scaffolding';
import AsyncStateWrapper from '../../component/ui/AsyncStateWrapper';
import {observer} from 'mobx-react';
import Spacer from '../../component/design/utils/Spacer';
import RobotTradeDetailsViewModel from '../../viewmodel/RobotTradeDetailsViewModel';
import TransactionDetails from '../../component/design/container/TransactionDetails';
import TradeBuyCard from '../../component/design/data/TradeBuyCard';
import TradeSellCard from '../../component/design/data/TradeSellCard';
import RobotTradeHeader from '../../component/design/data/RobotTradeHeader';
import RobotTradeCard from '../../component/design/data/RobotTradeCard';

const RobotTradeDetailsScreen: React.FunctionComponent = () => {
  const params = useParams();
  const vm = useMemo(
    () =>
      new RobotTradeDetailsViewModel(
        params.robot as string,
        params.id as string,
      ),
    [params.robot, params.id],
  );
  return (
    <Scaffolding
      header={{
        id: 'nav.walletTransactionList',
        defaultMessage: 'My wallet',
      }}
    >
      <AsyncStateWrapper state={vm.details}>
        {(details) => (
          <TransactionDetails>
            <RobotTradeHeader {...details} />
            <Spacer size={2} />
            <RobotTradeCard {...details} />
            <Spacer size={0.5} />
            {details && (
              <>
                <TradeBuyCard {...details} />
                <Spacer size={0.5} />
              </>
            )}
            {details.sellId &&
              details.sellAmount !== undefined &&
              details.sellPrice !== undefined && (
                <>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <TradeSellCard {...details} />
                  <Spacer size={0.5} />
                </>
              )}
          </TransactionDetails>
        )}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default observer(RobotTradeDetailsScreen);
