import {SubscriptionInfoStatusEnum} from '../../../../api';
import {FC} from 'react';
import Icon from '../../graphics/Icon';
import Typography from '../../typo/Typography';
import cls from './style.module.scss';
import SubscriptionUtils from '../../../../utils/SubscriptionUtils';

interface Props {
  status: SubscriptionInfoStatusEnum;
}

const SubscriptionStatus: FC<Props> = ({status}) => (
  <span className={cls.container}>
    <Icon
      name={
        status === SubscriptionInfoStatusEnum.Active ? 'checkmark' : 'close'
      }
      size={24}
      tint={status === SubscriptionInfoStatusEnum.Active ? 'green' : 'red'}
    />
    <span
      className={
        cls.text +
        ' ' +
        (status === SubscriptionInfoStatusEnum.Active && cls.success) +
        ' ' +
        (status === SubscriptionInfoStatusEnum.Expired && cls.error)
      }
    >
      <Typography text={SubscriptionUtils.formatStatus(status)} />
    </span>
  </span>
);

export default SubscriptionStatus;
