import {useEffect} from 'react';
import {useSnackbar} from '../component/design/container/SnackbarContainer';
import ErrorUtils from '../utils/ErrorUtils';

export function useAsyncStateErrorSnack<T>(
  state: AsyncState<T> | RefreshableAsyncState<T>,
) {
  const snackbar = useSnackbar();
  useEffect(() => {
    if (state.status === 'error' || state.status === 'refreshing-error') {
      snackbar.showError(ErrorUtils.getMessage(state.error), 'long');
    }
  }, [state]);
}
