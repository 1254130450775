import {FC} from 'react';
import BinanceIcon from './BinanceIcon';
import BybitIcon from './BybitIcon';
import HuobiIcon from './HuobiIcon';
import GAuthIcon from './GAuthIcon';
import {App} from '../../../../utils/AppUtils';

interface Props {
  market: App;
  size?: number;
}

const AppIcon: FC<Props> = ({market, size}) => {
  switch (market) {
    case App.Binance:
      return <BinanceIcon width={size} height={size} />;
    case App.Bybit:
      return <BybitIcon width={size} height={size} />;
    case App.Huobi:
      return <HuobiIcon width={size} height={size} />;
    case App.GAuth:
      return <GAuthIcon width={size} height={size} />;
  }
};

export default AppIcon;
