import {FC} from 'react';
import {HText} from '../../typo/Typography';
import InputBase from '../InputBase';
import Icon from '../../graphics/Icon';
import cls from './style.module.scss';
import ClipboardUtils from '../../../../utils/ClipboardUtils';
import {useSnackbar} from '../../container/SnackbarContainer';

interface Props {
  value: string;
  label?: HText;
}

const ReadOnlyInput: FC<Props> = ({value, label}) => {
  const snackbar = useSnackbar();
  return (
    <InputBase label={label} contentClassName={cls.content}>
      <span className={cls.text}>{value}</span>
      <Icon
        className={cls.icon}
        name={'copy'}
        size={24}
        clickable
        onClick={() => {
          ClipboardUtils.write(value);
          snackbar.showInfo(
            {id: 'common.textCopied', defaultMessage: 'Copied to clipboard'},
            'short',
            'copied',
          );
        }}
      />
    </InputBase>
  );
};

export default ReadOnlyInput;
