import {FC, useEffect} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/apikeys/create-key-1.png';
import v2 from '../../../../../assets/onboarding/bybit/apikeys/create-key-2.png';
import ReadOnlyInput from '../../../../../component/design/input/ReadOnlyInput';
import {inject, observer} from 'mobx-react';
import {ExchangeConnectionStore} from '../../../../../store/ExchangeConnectionStore';
import AsyncStateWrapper from '../../../../../component/ui/AsyncStateWrapper';

interface IProps {
  exchangeConnection: ExchangeConnectionStore;
}

const CreateKeyFragment: FC<IProps> = ({exchangeConnection}) => {
  useEffect(() => {
    exchangeConnection.ensureClusterIpsLoaded();
  }, [exchangeConnection]);
  return (
    <AsyncStateWrapper state={exchangeConnection.clusterIps}>
      {(ips) => (
        <>
          <Subtitle
            text={{
              id: 'fragments.onboarding.CreateKeyFragment.title',
              defaultMessage:
                'Press <h>Create New Key</h> again, select <h>System-generated</h>, paste IP addresses listed above.',
            }}
          />
          <Spacer size={1.5} />
          <ReadOnlyInput
            value={ips.exchangeOutbound.reduce((a, b) => a + ',' + b)}
            label={{
              id: 'fragments.onboarding.CreateKeyFragment.ips',
              defaultMessage: 'IP Addresses',
            }}
          />
          <Spacer size={1.5} />
          <SideBySideImage first={v1} second={v2} />
        </>
      )}
    </AsyncStateWrapper>
  );
};

export default inject('exchangeConnection')(observer(CreateKeyFragment)) as FC<
  Partial<IProps>
>;
