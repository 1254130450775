import React, {FC} from 'react';
import Typography, {HText} from '../../typo/Typography';
import cls from './style.module.scss';

interface Props {
  illustration: React.ReactNode;
  title: HText;
  description?: HText;
  card?: React.ReactNode;
  footer?: React.ReactNode;
}

const Status: FC<Props> = ({
  illustration,
  title,
  description,
  card,
  footer,
}) => (
  <div className={cls.main}>
    <div className={cls.content}>
      {illustration}
      <Typography text={title} className={cls.title} />
      {description && <Typography text={description} className={cls.desc} />}
      {card}
    </div>
    {footer && <div className={cls.footer}>{footer}</div>}
  </div>
);

export default Status;
