import StorageBackend from './StorageBackend';

class LocalStorageBackend implements StorageBackend {
  constructor(readonly name: string) {}

  delete(key: string): void {
    localStorage.removeItem(this.name + ':' + key);
  }

  getString(key: string): string | undefined {
    const item = localStorage.getItem(this.name + ':' + key);
    return item === null ? undefined : item;
  }

  set(key: string, value: string): void {
    localStorage.setItem(this.name + ':' + key, value);
  }
}

export default LocalStorageBackend;
