import {WithdrawalRequest, WithdrawalRequestConfig} from '../../../../api';
import {FC} from 'react';
import KVCard from '../../container/KVCard';

interface Props extends WithdrawalRequest {
  cfg: WithdrawalRequestConfig;
}

const WithdrawalRequestCard: FC<Props> = (props) => {
  const net = props.cfg.networks.find(
    (value) => value.network === props.network,
  );
  return (
    <KVCard>
      <KVCard.Item
        name={{
          id: 'components.WithdrawalRequestCard.amount',
          defaultMessage: 'Amount',
        }}
        value={props.amount + ' RCO'}
      />
      <KVCard.Item
        name={{
          id: 'components.WithdrawalRequestCard.network',
          defaultMessage: 'Network',
        }}
        value={net?.name ?? props.network}
      />
      <KVCard.Item
        name={{
          id: 'components.WithdrawalRequestCard.address',
          defaultMessage: 'Address',
        }}
        value={props.address}
      />
    </KVCard>
  );
};

export default WithdrawalRequestCard;
