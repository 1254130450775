import React from 'react';
import {BottomSheet as RBottomSheet} from 'react-spring-bottom-sheet';

interface Props {
  open: boolean;
  children: React.ReactNode;
  onDismiss?: () => void;
}

const BottomSheet: React.FunctionComponent<Props> = ({
  open,
  children,
  onDismiss,
}) => (
  <RBottomSheet open={open} onDismiss={onDismiss}>
    {children}
  </RBottomSheet>
);

export default React.memo(BottomSheet);
