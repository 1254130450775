import {Route} from 'react-router-dom';
import OnboardingBaseFragment from '../OnboardingBaseFragment';
import stores from '../../../../store';
import {
  AdvanceStateDtoSelectedMarketEnum,
  AdvanceStateDtoStageEnum,
} from '../../../../api';
import DownloadBybitFragment from './first/DownloadBybitFragment';
import CreateAccountFragment from './first/CreateAccountFragment';
import IdentityVerify1Fragment from './first/IdentityVerify1Fragment';
import IdentityVerify2Fragment from './first/IdentityVerify2Fragment';
import IdentityVerityEndFragment from './first/IdentityVerityEndFragment';
import OpenP2PFragment from './p2p/OpenP2PFragment';
import StartP2PFragment from './p2p/StartP2PFragment';
import BuyInitFragment from './p2p/BuyInitFragment';
import BuyEndFragment from './p2p/BuyEndFragment';
import TransferFundsFragment from './p2p/TransferFundsFragment';
import EnterApiManagementFragment from './api-keys/EnterApiManagementFragment';
import ApiManagementOnboardingFragment from './api-keys/ApiManagementOnboardingFragment';
import CreateApiKeyFragment from './api-keys/CreateApiKeyFragment';
import SetupGAuthStartFragment from './api-keys/SetupGAuthStartFragment';
import SetupGAuthFragment from './api-keys/SetupGAuthFragment';
import DownloadGAuthFragment from './api-keys/DownloadGAuthFragment';
import StartGAuthSetupFragment from './api-keys/StartGAuthSetupFragment';
import CopyGAuthKeyFragment from './api-keys/CopyGAuthKeyFragment';
import EndGAuthSetupFragment from './api-keys/EndGAuthSetupFragment';
import CreateKeyFragment from './api-keys/CreateKeyFragment';
import EnableSpotTradingFragment from './api-keys/EnableSpotTradingFragment';
import CopyApiKeyFragment from './api-keys/CopyApiKeyFragment';
import CopyApiSecretFragment from './api-keys/CopyApiSecretFragment';
import React from 'react';

export default function bybitOnboardingRoutes() {
  return (
    <Route path={'bybit'}>
      <Route
        path={'exchange-register'}
        element={
          <OnboardingBaseFragment
            header={{
              id: 'nav.onboarding.exRegister.title',
              defaultMessage: 'Exchange registration',
            }}
            count={5}
            nextBlockLink={'/app/onboarding/bybit/p2p/0'}
            currentLink={'/app/onboarding/bybit/exchange-register'}
            onExit={(a) =>
              stores.onboarding.advance(
                AdvanceStateDtoStageEnum.Exchange,
                a,
                AdvanceStateDtoSelectedMarketEnum.Bybit,
              )
            }
            first
          />
        }
      >
        <Route path={'0'} element={<DownloadBybitFragment />} />
        <Route path={'1'} element={<CreateAccountFragment />} />
        <Route path={'2'} element={<IdentityVerify1Fragment />} />
        <Route path={'3'} element={<IdentityVerify2Fragment />} />
        <Route path={'4'} element={<IdentityVerityEndFragment />} />
      </Route>
      <Route
        path={'p2p'}
        element={
          <OnboardingBaseFragment
            header={{
              id: 'nav.onboarding.p2p.title',
              defaultMessage: 'Crypto acquisition',
            }}
            count={5}
            nextBlockLink={'/app/onboarding/bybit/api-keys/0'}
            lastBlockLink={'/app/onboarding/bybit/exchange-register/4'}
            currentLink={'/app/onboarding/bybit/p2p'}
            onExit={(a) =>
              stores.onboarding.advance(
                AdvanceStateDtoStageEnum.P2P,
                a,
                AdvanceStateDtoSelectedMarketEnum.Bybit,
              )
            }
          />
        }
      >
        <Route path={'0'} element={<OpenP2PFragment />} />
        <Route path={'1'} element={<StartP2PFragment />} />
        <Route path={'2'} element={<BuyInitFragment />} />
        <Route path={'3'} element={<BuyEndFragment />} />
        <Route path={'4'} element={<TransferFundsFragment />} />
      </Route>
      <Route
        path={'api-keys'}
        element={
          <OnboardingBaseFragment
            header={{
              id: 'nav.onboarding.agpiKeys.title',
              defaultMessage: 'Connection setup',
            }}
            count={13}
            lastBlockLink={'/app/onboarding/bybit/p2p/4'}
            currentLink={'/app/onboarding/bybit/api-keys'}
            nextBlockLink={'/app/dashboard'}
            onExit={(a) =>
              stores.onboarding.advance(
                AdvanceStateDtoStageEnum.ApiKeys,
                a,
                AdvanceStateDtoSelectedMarketEnum.Bybit,
              )
            }
          />
        }
      >
        <Route path={'0'} element={<EnterApiManagementFragment />} />
        <Route path={'1'} element={<ApiManagementOnboardingFragment />} />
        <Route path={'2'} element={<CreateApiKeyFragment />} />
        <Route path={'3'} element={<SetupGAuthStartFragment />} />
        <Route path={'4'} element={<SetupGAuthFragment />} />
        <Route path={'5'} element={<DownloadGAuthFragment />} />
        <Route path={'6'} element={<StartGAuthSetupFragment />} />
        <Route path={'7'} element={<CopyGAuthKeyFragment />} />
        <Route path={'8'} element={<EndGAuthSetupFragment />} />
        <Route path={'9'} element={<CreateKeyFragment />} />
        <Route path={'10'} element={<EnableSpotTradingFragment />} />
        <Route path={'11'} element={<CopyApiKeyFragment />} />
        <Route path={'12'} element={<CopyApiSecretFragment />} />
      </Route>
    </Route>
  );
}
