import React, {useCallback, useState} from 'react';
import {MessageDescriptor} from 'react-intl';
import {IconName} from '../Icon';
import BottomActionSheet from '../BottomActionSheet';
import FakeSelect from '../FakeSelect';

export interface Item<T> {
  value: T;
  label: MessageDescriptor;
  icon?: IconName;
  tag?: MessageDescriptor;
}

interface Props<T> {
  header?: MessageDescriptor;
  value: T;
  items: Item<T>[];
  onChange: (value: T) => void;
}

function ActionSheetSelect<T>({
  header,
  value,
  items,
  onChange,
}: Props<T>): React.ReactElement {
  const [sheetOpen, setSheetOpen] = useState(false);
  const openSheet = useCallback(() => setSheetOpen(true), []);
  const closeSheet = useCallback(() => setSheetOpen(false), []);

  const selected = items.find((v) => v.value === value);
  return (
    <>
      <FakeSelect
        icon={selected?.icon}
        value={selected?.label}
        onClick={openSheet}
        header={header}
      />
      <BottomActionSheet onDismiss={closeSheet} open={sheetOpen}>
        {items.map((v) => (
          <BottomActionSheet.Item
            text={v.label}
            onClick={() => {
              onChange(v.value);
              closeSheet();
            }}
            selected={v.value === value}
            icon={v.icon}
            tag={v.tag}
          />
        ))}
      </BottomActionSheet>
    </>
  );
}

export default ActionSheetSelect;
