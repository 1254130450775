import {BaseInputProps, InputMode} from '../types';
import {FC, useRef} from 'react';
import useHText from '../../../../hooks/useHText';
import useIsFocused from '../../../../hooks/useIsFocused';
import useHandleSubmitEditing from '../../../../hooks/useHandleSubmitEditing';
import InputBase from '../InputBase';
import cls from './style.module.scss';
import Icon from '../../graphics/Icon';
import ClipboardUtils from '../../../../utils/ClipboardUtils';

export interface Props extends BaseInputProps {
  pasteable?: boolean;
  clearable?: boolean;
  inputMode?: InputMode;
}

const TextInput: FC<Props> = ({
  label,
  clearable,
  pasteable,
  value,
  onChange,
  error,
  enterKeyHint,
  onFocus,
  disabled,
  onSubmitEditing,
  onBlur,
  placeholder,
  inputMode,
}) => {
  const hText = useHText();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focused, focus, blur] = useIsFocused(onFocus, onBlur);
  const submitHandle = useHandleSubmitEditing(onSubmitEditing);
  return (
    <InputBase
      contentClassName={cls.content}
      label={label}
      error={error}
      selected={focused}
      onContentClick={() => inputRef.current?.focus()}
    >
      <input
        className={
          cls.input +
          ' ' +
          (((clearable && onChange && value.length > 0) ||
            (pasteable && onChange && value.length === 0)) &&
            cls.hasIcon)
        }
        ref={inputRef}
        value={value}
        placeholder={hText(placeholder)}
        onInput={
          !disabled && onChange
            ? (e) => onChange(e.currentTarget.value)
            : undefined
        }
        onBlur={blur}
        onFocus={focus}
        onKeyUp={submitHandle}
        enterKeyHint={enterKeyHint}
        inputMode={inputMode}
      />
      {clearable && onChange && value.length > 0 && (
        <Icon
          className={cls.icon}
          name={'close'}
          size={24}
          clickable
          onClick={() => onChange('')}
        />
      )}
      {pasteable && onChange && value.length === 0 && (
        <Icon
          className={cls.icon}
          name={'paste'}
          size={24}
          tint={'primary'}
          clickable
          onClick={() =>
            ClipboardUtils.readText().then((text) => onChange(text))
          }
        />
      )}
    </InputBase>
  );
};

export default TextInput;
