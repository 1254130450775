import {FC} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/bybit/p2p/go-to-transfer.png';
import v2 from '../../../../../assets/onboarding/bybit/p2p/exec-transfer.png';

const TransferFundsFragment: FC = () => (
  <>
    <Subtitle
      text={{
        id: 'fragments.onboarding.TransferFundsFragment.title',
        defaultMessage:
          'Go to <h>Assets</h> and select <h>Transfer</h>. After, ensure that transfer is happening between <h>Funding</h> and <h>Spot</h> account, press <h>Max</h> and <h>Confirm</h>.',
      }}
    />
    <Spacer size={1.5} />
    <SideBySideImage first={v1} second={v2} />
  </>
);

export default TransferFundsFragment;
