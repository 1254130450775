import {FC, useEffect, useState} from 'react';
import Subtitle from '../../../../../component/design/typo/Subtitle';
import Spacer from '../../../../../component/design/utils/Spacer';
import SideBySideImage from '../../../../../component/design/container/SideBySideImage';
import v1 from '../../../../../assets/onboarding/binance/apikeys/copy-secret.png';
import TextInput from '../../../../../component/design/input/TextInput';
import {useNavigate, useOutletContext} from 'react-router-dom';
import {OnboardingFragmentCtx} from '../../OnboardingBaseFragment';
import {useInterceptor} from '../../../../../hooks/useInterceptor';
import {ExchangeConnectionStore} from '../../../../../store/ExchangeConnectionStore';
import {inject, observer} from 'mobx-react';
import AsyncStateWrapper from '../../../../../component/ui/AsyncStateWrapper';
import {CreateConnectionRequestExchangeEnum} from '../../../../../api';
import {notLoaded} from '../../../../../utils';

interface RProps {
  exchangeConnection: ExchangeConnectionStore;
}

const CopyApiSecretFragment: FC<RProps> = ({exchangeConnection}) => {
  const ctx = useOutletContext<OnboardingFragmentCtx>();
  const nav = useNavigate();
  const [key, setKey] = useState('');
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    exchangeConnection.ensureEncryptorLoaded();
  }, [exchangeConnection]);

  useInterceptor(
    ctx.nextAction,
    () => {
      setChecked(true);
      if (key.trim().length === 0) {
        return false;
      } else {
        const ok = exchangeConnection.setFormApiSecret(key);
        if (ok) {
          ctx.setNextButtonState({status: 'loading'});
          exchangeConnection
            .createOrEditConnection(CreateConnectionRequestExchangeEnum.Binance)
            .then((value) =>
              nav(
                `/app/exchange-connections/${value.id}/status?mode=onboarding`,
              ),
            )
            .catch((e) => ctx.setNextButtonState({status: 'error', error: e}));
        }
        return false;
      }
    },
    [key, exchangeConnection],
  );

  useEffect(() => {
    return () => ctx.setNextButtonState(notLoaded());
  }, []);

  return (
    <AsyncStateWrapper state={exchangeConnection.publicKey}>
      {() => (
        <>
          <Subtitle
            text={{
              id: 'fragments.onboarding.binance.CopyApiSecretFragment.title',
              defaultMessage: 'Copy <h>API secret</h> into field below',
            }}
          />
          <Spacer size={1.5} />
          <TextInput
            value={key}
            label={{id: 'common.apiSecret', defaultMessage: 'API Secret'}}
            pasteable
            onChange={(v) => setKey(v)}
            error={
              checked && key.trim().length === 0
                ? {
                    id: 'onboarding.apiSecretMissing',
                    defaultMessage: 'API secret missing',
                  }
                : undefined
            }
          />
          <Spacer size={1.5} />
          <SideBySideImage first={v1} />
        </>
      )}
    </AsyncStateWrapper>
  );
};

export default inject('exchangeConnection')(
  observer(CopyApiSecretFragment),
) as FC<Partial<RProps>>;
