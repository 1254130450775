import {Route} from 'react-router-dom';
import OnboardingBaseFragment from '../OnboardingBaseFragment';
import stores from '../../../../store';
import {
  AdvanceStateDtoSelectedMarketEnum,
  AdvanceStateDtoStageEnum,
} from '../../../../api';
import DownloadHuobiFragment from './first/DownloadHuobiFragment';
import SignUpFragment from './first/SignUpFragment';
import EnterCodeFragment from './first/EnterCodeFragment';
import EnterPasswordFragment from './first/EnterPasswordFragment';
import DownloadGAuthFragment from './first/DownloadGAuthFragment';
import LinkGAuthFragment from './first/LinkGAuthFragment';
import LinkGAuthNextFragment from './first/LinkGAuthNextFragment';
import EnterEmailCodeFragment from './first/EnterEmailCodeFragment';
import OpenVerificationFragment from './first/OpenVerificationFragment';
import VerifyFragment from './first/VerifyFragment';
import OpenP2PFragment from './p2p/OpenP2PFragment';
import PayP2PFragment from './p2p/PayP2PFragment';
import PaidP2PFragment from './p2p/PaidP2PFragment';
import OpenSettingsFragment from './apikeys/OpenSettingsFragment';
import OpenApiManagementFragment from './apikeys/OpenApiManagementFragment';
import SetConfigFragment from './apikeys/SetConfigFragment';
import VerifyKeyCreationFragment from './apikeys/VerifyKeyCreationFragment';
import CopyApiKeyFragment from './apikeys/CopyApiKeyFragment';
import CopyApiSecretFragment from './apikeys/CopyApiSecretFragment';

export default function huobiOnboardingRoutes() {
  return (
    <Route path={'huobi'}>
      <Route
        path={'exchange-register'}
        element={
          <OnboardingBaseFragment
            header={{
              id: 'nav.onboarding.exRegister.title',
              defaultMessage: 'Exchange registration',
            }}
            count={10}
            nextBlockLink={'/app/onboarding/huobi/p2p/0'}
            currentLink={'/app/onboarding/huobi/exchange-register'}
            onExit={(a) =>
              stores.onboarding.advance(
                AdvanceStateDtoStageEnum.Exchange,
                a,
                AdvanceStateDtoSelectedMarketEnum.Huobi,
              )
            }
            first
          />
        }
      >
        <Route path={'0'} element={<DownloadHuobiFragment />} />
        <Route path={'1'} element={<SignUpFragment />} />
        <Route path={'2'} element={<EnterCodeFragment />} />
        <Route path={'3'} element={<EnterPasswordFragment />} />
        <Route path={'4'} element={<DownloadGAuthFragment />} />
        <Route path={'5'} element={<LinkGAuthFragment />} />
        <Route path={'6'} element={<LinkGAuthNextFragment />} />
        <Route path={'7'} element={<EnterEmailCodeFragment />} />
        <Route path={'8'} element={<OpenVerificationFragment />} />
        <Route path={'9'} element={<VerifyFragment />} />
      </Route>
      <Route
        path={'p2p'}
        element={
          <OnboardingBaseFragment
            header={{
              id: 'nav.onboarding.p2p.title',
              defaultMessage: 'Crypto acquisition',
            }}
            count={3}
            nextBlockLink={'/app/onboarding/huobi/api-keys/0'}
            lastBlockLink={'/app/onboarding/huobi/exchange-register/9'}
            currentLink={'/app/onboarding/huobi/p2p'}
            onExit={(a) =>
              stores.onboarding.advance(
                AdvanceStateDtoStageEnum.P2P,
                a,
                AdvanceStateDtoSelectedMarketEnum.Huobi,
              )
            }
          />
        }
      >
        <Route path={'0'} element={<OpenP2PFragment />} />
        <Route path={'1'} element={<PayP2PFragment />} />
        <Route path={'2'} element={<PaidP2PFragment />} />
      </Route>
      <Route
        path={'api-keys'}
        element={
          <OnboardingBaseFragment
            header={{
              id: 'nav.onboarding.apiKeys.title',
              defaultMessage: 'Connection setup',
            }}
            count={6}
            lastBlockLink={'/app/onboarding/huobi/p2p/2'}
            currentLink={'/app/onboarding/huobi/api-keys'}
            nextBlockLink={'/app/dashboard'}
            onExit={(a) =>
              stores.onboarding.advance(
                AdvanceStateDtoStageEnum.ApiKeys,
                a,
                AdvanceStateDtoSelectedMarketEnum.Huobi,
              )
            }
          />
        }
      >
        <Route path={'0'} element={<OpenSettingsFragment />} />
        <Route path={'1'} element={<OpenApiManagementFragment />} />
        <Route path={'2'} element={<SetConfigFragment />} />
        <Route path={'3'} element={<VerifyKeyCreationFragment />} />
        <Route path={'4'} element={<CopyApiKeyFragment />} />
        <Route path={'5'} element={<CopyApiSecretFragment />} />
      </Route>
    </Route>
  );
}
