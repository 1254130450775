import {FC, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import Scaffolding from '../../../component/container/Scaffolding';
import WalletTransactionDetailsViewModel from '../../../viewmodel/wallet/WalletTransactionDetailsViewModel';
import AsyncStateWrapper from '../../../component/ui/AsyncStateWrapper';
import {inject, observer} from 'mobx-react';
import WalletTransactionHeader from '../../../component/design/data/WalletTransactionHeader';
import Spacer from '../../../component/design/utils/Spacer';
import WalletTransactionCard from '../../../component/design/data/WalletTransactionCard';
import TradeInfoCard from '../../../component/design/data/TradeInfoCard';
import TradeBuyCard from '../../../component/design/data/TradeBuyCard';
import TradeSellCard from '../../../component/design/data/TradeSellCard';
import TransactionDetails from '../../../component/design/container/TransactionDetails';
import {UserStore} from '../../../store/UserStore';

interface RProps {
  user: UserStore;
}

const WalletTransactionDetailsScreen: FC<RProps> = ({user}) => {
  const params = useParams();
  const vm = useMemo(
    () => new WalletTransactionDetailsViewModel(params.transaction as string),
    [params.transaction],
  );
  const details = vm.details;
  return (
    <Scaffolding
      header={{
        id: 'nav.walletTransactionList',
        defaultMessage: 'My wallet',
      }}
    >
      <AsyncStateWrapper state={user.user}>
        {(user) => (
          <AsyncStateWrapper state={details}>
            {(details) => (
              <TransactionDetails>
                <WalletTransactionHeader {...details} />
                <Spacer size={2} />
                <WalletTransactionCard {...details} me={user} />
                <Spacer size={0.5} />
                {details.trade && (
                  <>
                    <TradeInfoCard {...details.trade} />
                    <Spacer size={0.5} />
                  </>
                )}
                {details.trade && (
                  <>
                    <TradeBuyCard {...details.trade} />
                    <Spacer size={0.5} />
                  </>
                )}
                {details.trade &&
                  details.trade.sellId &&
                  details.trade.sellAmount !== undefined &&
                  details.trade.sellPrice !== undefined && (
                    <>
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      <TradeSellCard {...details.trade} />
                      <Spacer size={0.5} />
                    </>
                  )}
              </TransactionDetails>
            )}
          </AsyncStateWrapper>
        )}
      </AsyncStateWrapper>
    </Scaffolding>
  );
};

export default inject('user')(observer(WalletTransactionDetailsScreen)) as FC<
  Partial<RProps>
>;
