import RealUserStore from './real/RealUserStore';
import {UserStore} from './UserStore';
import {RobotStore} from './RobotStore';
import RealRobotStore from './real/RealRobotStore';
import {EmailVerificationStore} from './EmailVerificationStore';
import RealEmailVerificationStore from './real/RealEmailVerificationStore';
import {WalletStore} from './WalletStore';
import RealWalletStore from './real/RealWalletStore';
import {ReferralStore} from './ReferralStore';
import RealReferralStore from './real/RealReferralStore';
import SubscriptionStore from './SubscriptionStore';
import RealSubscriptionStore from './real/RealSubscriptionStore';
import NotificationsStore from './NotificationsStore';
import RealNotificationsStore from './real/RealNotificationsStore';
import DesktopCompatibilityStore from './DesktopCompatibilityStore';
import RealDesktopCompatibilityStore from './real/RealDesktopCompatibilityStore';
import InviteCodeStore from './InviteCodeStore';
import RealInviteCodeStore from './real/RealInviteCodeStore';
import MaintenanceStore from './MaintenanceStore';
import RealMaintenanceStore from './real/RealMaintenanceStore';
import {ExchangeConnectionStore} from './ExchangeConnectionStore';
import RealExchangeConnectionStore from './real/RealExchangeConnectionStore';
import OnboardingStore from './OnboardingStore';
import RealOnboardingStore from './real/RealOnboardingStore';

export interface Stores {
  user: UserStore;
  robot: RobotStore;
  wallet: WalletStore;
  emailVerification: EmailVerificationStore;
  referral: ReferralStore;
  subscription: SubscriptionStore;
  notifications: NotificationsStore;
  desktopCompatibility: DesktopCompatibilityStore;
  inviteCode: InviteCodeStore;
  maintenance: MaintenanceStore;
  exchangeConnection: ExchangeConnectionStore;
  onboarding: OnboardingStore;
}

const stores: Stores = {
  user: new RealUserStore(),
  robot: new RealRobotStore(),
  wallet: new RealWalletStore(),
  referral: new RealReferralStore(),
  emailVerification: new RealEmailVerificationStore(),
  subscription: new RealSubscriptionStore(),
  notifications: new RealNotificationsStore(),
  desktopCompatibility: new RealDesktopCompatibilityStore(),
  inviteCode: new RealInviteCodeStore(),
  maintenance: new RealMaintenanceStore(),
  exchangeConnection: new RealExchangeConnectionStore(),
  onboarding: new RealOnboardingStore(),
};

export default stores;
