import {WithdrawalRequest, WithdrawalRequestStatusEnum} from '../../api';
import {notLoaded, wrapPromise} from '../../utils';
import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import stores from '../../store';
import ErrorUtils from '../../utils/ErrorUtils';

class SellRcoConfirmViewModel {
  static LENGTH = 6;

  code = '';
  request: AsyncState<WithdrawalRequest> = notLoaded();
  confirmError?: string;
  inProgress = false;
  alreadySubmitted = false;

  constructor(
    private readonly id: string,
    private readonly onNext: (id: string) => void,
  ) {
    makeAutoObservable(this, {
      code: observable,
      request: observable,
      confirmError: observable,
      inProgress: observable,
      alreadySubmitted: observable,
      init: action,
      confirm: action,
      setCode: action.bound,
      codeSubmit: action.bound,
    });
  }

  init() {
    void wrapPromise(stores.wallet.getWithdrawal(this.id), this.request, (v) =>
      runInAction(() => (this.request = v)),
    );
  }

  setCode(code: string) {
    this.code = code;
  }

  codeSubmit() {
    if (!this.alreadySubmitted) {
      this.confirm();
    }
  }

  confirm() {
    this.alreadySubmitted = true;
    this.inProgress = true;
    stores.wallet
      .confirmWithdrawal(this.id, this.code)
      .then((value) => {
        this.inProgress = false;
        if (value.status === WithdrawalRequestStatusEnum.WaitingConfirmation) {
          this.confirmError = 'Invalid confirmation code';
        } else {
          this.onNext(this.id);
        }
      })
      .catch((reason) =>
        runInAction(() => {
          this.inProgress = false;
          this.confirmError = ErrorUtils.getMessage(reason);
        }),
      );
  }
}

export default SellRcoConfirmViewModel;
