import MaintenanceStore, {CurrentMaintenance} from '../MaintenanceStore';
import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import ApiStore from '../ApiStore';
import {InternetUnreachableError} from '../../api';
import {ServiceUnavailableError} from '../../api/axios/ServiceUnavailable';
import MaintenanceStorage from '../../storage/MaintenanceStorage';

class RealMaintenanceStore implements MaintenanceStore {
  currentMaintenance: CurrentMaintenance | undefined = undefined;

  constructor() {
    makeAutoObservable(this, {
      currentMaintenance: observable,
      load: action,
      onDismiss: action.bound,
    });
  }

  load(): void {
    const ignored = new Set(MaintenanceStorage.get().getViewedMaintenances());
    ApiStore.maintenance
      .getCurrentMaintenance()
      .then((value) =>
        runInAction(() => {
          if (
            value.data.maintenance &&
            !value.data.maintenance.blockAccess &&
            ignored.has(value.data.maintenance.id)
          ) {
            return;
          }
          this.currentMaintenance = value.data.maintenance;
        }),
      )
      .catch((reason) => {
        if (reason instanceof ServiceUnavailableError) {
          runInAction(() => (this.currentMaintenance = 'SERVICE_UNAVAILABLE'));
        } else if (reason instanceof InternetUnreachableError) {
          runInAction(() => (this.currentMaintenance = 'NO_INTERNET'));
        }
      });
  }

  onDismiss(): void {
    if (
      this.currentMaintenance === 'SERVICE_UNAVAILABLE' ||
      this.currentMaintenance === 'NO_INTERNET' ||
      !this.currentMaintenance
    ) {
      return;
    }
    MaintenanceStorage.get().addViewedMaintenance(this.currentMaintenance.id);
    this.currentMaintenance = undefined;
  }
}

export default RealMaintenanceStore;
