import {FC} from 'react';

interface Props {
  className?: string;
}

const Withdrawal: FC<Props> = ({className}) => (
  <svg
    className={className}
    width="300"
    height="200"
    viewBox="0 0 300 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_687_12200)">
      <g opacity="0.6">
        <path
          d="M223.867 99.9998C223.867 140.796 190.795 173.867 149.999 173.867C109.203 173.867 76.1317 140.796 76.1317 99.9998C76.1317 59.2039 109.203 26.1322 149.999 26.1322C190.795 26.1322 223.867 59.2039 223.867 99.9998Z"
          stroke="url(#paint0_radial_687_12200)"
        />
        <path
          d="M248.489 99.9998C248.489 154.394 204.394 198.49 149.999 198.49C95.6047 198.49 51.5092 154.394 51.5092 99.9998C51.5092 45.6052 95.6047 1.50971 149.999 1.50971C204.394 1.50971 248.489 45.6052 248.489 99.9998Z"
          stroke="url(#paint1_radial_687_12200)"
        />
        <path
          d="M273.112 99.9998C273.112 167.993 217.992 223.112 149.999 223.112C82.006 223.112 26.8866 167.993 26.8866 99.9998C26.8866 32.0066 82.006 -23.1128 149.999 -23.1128C217.992 -23.1128 273.112 32.0066 273.112 99.9998Z"
          stroke="url(#paint2_radial_687_12200)"
        />
        <path
          d="M297.735 99.9998C297.735 181.592 231.592 247.735 150 247.735C68.4079 247.735 2.26465 181.592 2.26465 99.9998C2.26465 18.4079 68.4079 -47.7354 150 -47.7354C231.592 -47.7354 297.735 18.4079 297.735 99.9998Z"
          stroke="url(#paint3_radial_687_12200)"
        />
      </g>
      <g filter="url(#filter0_dd_687_12200)">
        <circle cx="150" cy="100" r="50" fill="url(#paint4_linear_687_12200)" />
        <circle
          cx="150"
          cy="100"
          r="49.5"
          stroke="url(#paint5_linear_687_12200)"
          strokeOpacity="0.35"
        />
        <circle
          cx="150"
          cy="100"
          r="49.5"
          stroke="#FF5252"
          strokeOpacity="0.35"
        />
      </g>
      <path
        d="M136 95.3332L150 81.3332M150 81.3332L164 95.3332M150 81.3332V118.667"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_687_12200"
        x="52"
        y="2"
        width="196"
        height="196"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.321569 0 0 0 0 0.321569 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_687_12200"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="24" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.321569 0 0 0 0 0.321569 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_687_12200"
          result="effect2_dropShadow_687_12200"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_687_12200"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_687_12200"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(150 100) rotate(-90) scale(100.5 161.95)"
      >
        <stop stopColor="#FF5252" />
        <stop offset="1" stopColor="#FF5252" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_687_12200"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(150 100) rotate(-90) scale(100.5 161.95)"
      >
        <stop stopColor="#FF5252" />
        <stop offset="1" stopColor="#FF5252" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_687_12200"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(150 100) rotate(-90) scale(100.5 161.95)"
      >
        <stop stopColor="#FF5252" />
        <stop offset="1" stopColor="#FF5252" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_687_12200"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(150 100) rotate(-90) scale(100.5 161.95)"
      >
        <stop stopColor="#FF5252" />
        <stop offset="1" stopColor="#FF5252" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_687_12200"
        x1="115"
        y1="65"
        x2="186"
        y2="136"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#662424" />
        <stop offset="0.512367" stopColor="#8C3131" />
        <stop offset="1" stopColor="#662424" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_687_12200"
        x1="100"
        y1="50"
        x2="178.174"
        y2="164.856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_687_12200">
        <rect width="300" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Withdrawal;
