import Icon from '../../graphics/Icon';
import Typography from '../../typo/Typography';
import cls from './style.module.scss';
import {FC} from 'react';

const NoTransactionsItem: FC = () => (
  <div className={cls.container}>
    <Icon name={'failed-transaction'} size={24} tint={'gray500'} />
    <Typography
      text={{id: 'common.noTransactions', defaultMessage: 'No transactions'}}
    />
  </div>
);

export default NoTransactionsItem;
