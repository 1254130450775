import sodium from 'libsodium-wrappers';

export default class SodiumUtils {
  private static loaded = false;

  static async ensureLoaded() {
    if (SodiumUtils.loaded) {
      return;
    }
    await sodium.ready;
    SodiumUtils.loaded = true;
  }

  static seal(data: string, key: string) {
    const box = sodium.crypto_box_seal(
      data,
      sodium.from_base64(key, sodium.base64_variants.ORIGINAL),
    );
    return sodium.to_base64(box, sodium.base64_variants.ORIGINAL);
  }
}
