import React from 'react';
import {TeamCount, TotalIncome} from '../../../api';
import Card from '../Card';
import {FormattedMessage, FormattedNumberParts} from 'react-intl';
import cls from './style.module.scss';
import CurrencyUtils from '../../../utils/CurrencyUtils';

interface Props {
  team: TeamCount;
  income: TotalIncome;
}

const TeamCard: React.FunctionComponent<Props> = ({team, income}) => (
  <Card className={cls.card}>
    <div className={cls.item}>
      <p className={cls.title}>
        <FormattedMessage
          id={'components.TeamCard.command'}
          defaultMessage={'Command'}
        />
      </p>
      <h6 className={cls.value}>{team.count}</h6>
    </div>
    <div className={cls.item}>
      <p className={cls.title}>
        <FormattedMessage
          id={'components.TeamCard.income'}
          defaultMessage={'Income'}
        />
      </p>
      <h6
        className={
          cls.value +
          ' ' +
          (income.income > 0 ? 'text-balance-up' : 'text-balance-down')
        }
      >
        {'$'}
        <FormattedNumberParts value={income.income} maximumFractionDigits={2}>
          {CurrencyUtils.formatBalance}
        </FormattedNumberParts>
        <span className={cls.percent}> ({income.percent / 100}%)</span>
      </h6>
    </div>
  </Card>
);

export default TeamCard;
